import { SET_EVENTS_DATA } from '../actions/eventActionType';

const initialState = {
  eventsData: {
    1: {
      id: '1',
      name: 'Enrollment',
      description: 'This event is checkup to see suitablility of participant',
      repeating: false,
      type: 'VB',
      order: 1,
    },
    2: {
      id: '2',
      name: 'Monthly F/U',
      description: 'Monthly follow up',
      repeating: false,
      type: 'VB',
      order: 2,
    },
    3: {
      id: '3',
      name: 'Completion/Termination',
      description: 'Occurs to complete or terminate a participant',
      repeating: false,
      type: 'CNR',
      order: 3,
    },
  },
};

export default function eventReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_EVENTS_DATA:
      return { ...state, eventsData: payload };
    default:
      return state;
  }
}
