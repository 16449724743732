/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_CRT_TEAM,
  GET_CRT_TEAM_API_STATE,
  GET_INITIATION_SITE,
  GET_INITIATION_SITE_API_STATE,
  GET_INITIATION_SITES,
  GET_INITIATION_SITES_API_STATE,
  SET_CRT_TEAM,
  SET_INITIATION_SITE,
  SET_INITIATION_SITES,
} from '../actions/siteInitiationActionType';
import { SET_TOAST_ERROR } from '../actions/toastActionType';
import { getCrtTeamAPI, getInitiationSitesAPI } from '../api/siteInitiation';
import { getSiteAPI } from '../api/sites';
import API_STATES from '../constant/api_constants';

function* getInitiationSites(action) {
  yield put({
    type: GET_INITIATION_SITES_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getInitiationSitesAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_INITIATION_SITES,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching initiation sites',
      });
    }
  } finally {
    yield put({
      type: GET_INITIATION_SITES_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

// TODO: Move this to global sites api
function* getInitiationSite(action) {
  yield put({
    type: GET_INITIATION_SITE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getSiteAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_INITIATION_SITE,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching site information',
      });
    }
  } finally {
    yield put({
      type: GET_INITIATION_SITE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getCrtTeam(action) {
  yield put({
    type: GET_CRT_TEAM_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getCrtTeamAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_CRT_TEAM,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching site information',
      });
    }
  } finally {
    yield put({
      type: GET_CRT_TEAM_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* siteInitiationWatcher() {
  yield takeLatest(GET_INITIATION_SITES, getInitiationSites);
  yield takeLatest(GET_INITIATION_SITE, getInitiationSite);
  yield takeLatest(GET_CRT_TEAM, getCrtTeam);
}

export default siteInitiationWatcher;
