import axios from 'axios';

import { loginCheck } from '../actions/userActions';
import {
  INTERNAL_SERVER_ERROR,
  UNAUTHORIZED,
} from '../constant/status_constants';
import store from '../store';

const apiClientMultipart = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
  },
});

apiClientMultipart.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.authorization = token ? `Bearer ${token}` : '';
  return config;
});

apiClientMultipart.interceptors.response.use(
  response => response,
  error => {
    const responseError = { ...error };
    const refreshToken = localStorage.getItem('refresh');

    if (responseError.response && responseError.response.data) {
      const { status } = responseError.response;

      if (status === UNAUTHORIZED) {
        if (responseError && refreshToken) {
          store.dispatch(loginCheck(responseError));
        }
      }

      if (!responseError.response.data.message) {
        responseError.response.data = { message: 'UNKNOWN_ERROR' };
      }
    } else if (responseError.request) {
      responseError.response = {
        data: {
          message:
            responseError.request.message || responseError.request.statusText,
        },
      };
    } else {
      responseError.response = {
        data: { message: responseError.message },
        status: INTERNAL_SERVER_ERROR,
      };
    }

    return Promise.reject(responseError);
  }
);

export default apiClientMultipart;
