import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { eSignDocument } from '../actions/documentsActions';
import { SET_TOAST_SUCCESS } from '../actions/toastActionType';
import { isPending } from '../constant/api_constants';
import { CONFIG_FILE } from '../constants';
import genSitevar from '../helpers/sitevars';
import TButton from './TButton';

const TSigningMethodModal = ({
  setShowSigningMethodModal,
  documentObj,
  setDocumentObj,
}) => {
  const dispatch = useDispatch();

  const [popUpWindow, setPopUpWindow] = useState(null);
  const [method, setMethod] = useState('');
  const [showEmailSign, setShowEmailSign] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { eSignDocumentLink, esigningError, eSignDocumentApi } = useSelector(
    state => state.documentsReducer
  );

  useEffect(() => {
    genSitevar(CONFIG_FILE.ACCESS_CONSTANTS_FILE).then(res => {
      setShowEmailSign(res.showEmailSign);
    });
    let docObj = documentObj;
    docObj.password = '';
    setDocumentObj(docObj);
  }, []);

  // useEffect(() => {
  //   if (esigningError) {
  //     let popUp = popUpWindow;
  //     popUp.close();
  //     setPopUpWindow(popUp);
  //   }
  //   if (eSignDocumentLink) {
  //     let popUp = popUpWindow;
  //     popUp.location.href = eSignDocumentLink;
  //     setPopUpWindow(popUp);
  //     setShowSigningMethodModal(false);
  //   }
  // }, [eSignDocumentLink]);

  const handleSign = method => {
    setMethod(method);
    dispatch(eSignDocument({ ...documentObj, signingMethod: method }));

    if (method === 'esign') {
      dispatch({
        type: SET_TOAST_SUCCESS,
        payload: 'Please continue the process from email',
      });
      setShowSigningMethodModal(false);
    }
  };

  // const handleOpenLink = () => {
  //   if (method === 'aadhaar_sign') {
  //     let newPopUpWindow = window.open(
  //       `${window.location.origin}/signing`,
  //       '_blank'
  //     );
  //     // newPopUpWindow.document.write("Please don't reload or close this tab...");

  //     setPopUpWindow(newPopUpWindow);
  //   }
  // };

  return (
    <Paper elevation={0} className="p-4 custom-modal">
      {eSignDocumentLink === '' ? (
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TextField
              size="small"
              className="mt-5"
              label="Password"
              fullWidth
              value={documentObj.password}
              variant="outlined"
              onChange={e =>
                setDocumentObj({ ...documentObj, password: e.target.value })
              }
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" color="primary" />
                      ) : (
                        <VisibilityOff fontSize="small" color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className="mb-2">
            <TButton
              onClick={() => handleSign('aadhaar_sign')}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              isLoading={
                method === 'aadhaar_sign' && isPending(eSignDocumentApi)
              }
            >
              Sign via Aadhaar {showEmailSign ? '(preferred)' : ''}
            </TButton>
          </Grid>
          {showEmailSign ? (
            <Grid item xs={12} className="mb-2">
              <TButton
                onClick={() => handleSign('esign')}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                isLoading={method === 'esign' && isPending(eSignDocumentApi)}
              >
                Sign via email
              </TButton>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Grid container>
          <Grid item>
            <TButton
              variant="contained"
              onClick={() => setShowSigningMethodModal(false)}
              color="primary"
              href={eSignDocumentLink}
              style={{ color: 'white' }}
              target="_blank"
            >
              Continue process
            </TButton>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default TSigningMethodModal;
