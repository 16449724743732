import { colors } from '../common/Theme';
import { MONTHS } from '../constant/utils_constants';

export const randomProperty = obj => {
  var keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
};

export const randomColor = () => {
  return randomProperty(colors);
};

export const getOrdinalNumber = num => {
  if (num > 8 && num < 21) return num + 'th';
  switch (num % 10) {
    case 1:
      return num + 'st';
    case 2:
      return num + 'nd';
    case 3:
      return num + 'rd';
    default:
      return num + 'th';
  }
};

export const formatDate = date => {
  let parsedDate = new Date(date);

  return (
    MONTHS[parsedDate.getMonth()] +
    ' ' +
    getOrdinalNumber(parsedDate.getDate()) +
    ', ' +
    parsedDate.getFullYear()
  );
};

export const formatDateTime = date => {
  let parsedDate = new Date(date);

  return (
    MONTHS[parsedDate.getMonth()] +
    ' ' +
    getOrdinalNumber(parsedDate.getDate()) +
    ', ' +
    parsedDate.getFullYear() +
    ' at ' +
    parsedDate.getHours() +
    ':' +
    ('00' + parsedDate.getMinutes()).slice(-2)
  );
};

export const genSleep = async ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const getTimeDeltaHours = dateTime => {
  const now = new Date();
  const date = new Date(dateTime);

  const milliseconds = date - now;
  const hours = Math.floor(milliseconds / 36e5);

  return hours;
};

export const getTimeDeltaMinutes = dateTime => {
  const now = new Date();
  const date = new Date(dateTime);

  const milliseconds = date - now;
  const minutes = Math.ceil(milliseconds / 6e4);

  return minutes;
};
