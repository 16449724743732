import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';

import useStyles from './classes';

const emptyData = ['', 'none', null, []];

const TSelectField = props => {
  const classes = useStyles();
  const { label, key, multiple, data } = props.field;
  const { value } = props;

  const handleChange = event => {
    const { value: newValue } = event.target;
    props.handleChange(key, newValue);
  };

  return (
    <FormControl
      variant="outlined"
      className={`${classes.field} ${
        props?.field?.inline ? classes.inlineField : null
      }`}
      error={props.error}
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
      size="small"
    >
      <FormLabel
        htmlFor={key}
        error={props.error}
        style={props.dataGrid ? { display: 'none' } : null}
        className={`${classes.label}${
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
            ? ' required-field'
            : ''
        } `}
      >
        {label}
      </FormLabel>
      <div>
        <Select
          value={value && value !== '' ? value : 'none'}
          onChange={handleChange}
          multiple={multiple}
          defaultValue="none"
          error={props.error}
          className={props?.field?.inline ? classes.inlineInput : null}
          MenuProps={{
            classes: {
              paper: 'select-menu',
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          style={emptyData.includes(value) ? { color: '#9D9D9D' } : null}
        >
          <MenuItem value="none" className="select-menu-item" disabled>
            Select one
          </MenuItem>
          {!!data.values &&
            data.values.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                className="select-menu-item"
              >
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText error={props.error}>
          {props.errorMessage}
        </FormHelperText>
      </div>
    </FormControl>
  );
};

export default TSelectField;
