import {
  ASSIGN_CRA,
  GET_CRAS,
  GET_QUALIFICATION_SITES,
} from './siteQualificationActionType';

export const getQualificationSites = payload => ({
  type: GET_QUALIFICATION_SITES,
  payload,
});

export const getAllCRAs = payload => ({
  type: GET_CRAS,
  payload,
});

export const assignCRA = payload => ({
  type: ASSIGN_CRA,
  payload,
});
