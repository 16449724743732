import { CRA_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';

export const getCRAInitiationPanelAPI = async () => {
  const apiUrl = `${CRA_APIS.visitPanel}/Initiation/`;
  return apiClient.get(apiUrl);
};

export const getCRAQualificationPanelAPI = async () => {
  const apiUrl = `${CRA_APIS.visitPanel}/Qualification/`;
  return apiClient.get(apiUrl);
};

export const patchVisitAPI = async data => {
  const apiUrl = `${CRA_APIS.trials}/visit/${data.visitId}/`;
  return apiClient.patch(apiUrl, data);
};

export const getCRASDVSitesAPI = async data => {
  const apiUrl = `${CRA_APIS.rsdv}/trial/${data.trialId}/sites/`;
  return apiClient.get(apiUrl, data);
};

export const getTrialSiteParticipantsAPI = async data => {
  const apiUrl = `${CRA_APIS.rsdv}/trial_sites/${data.trialSiteId}/participants/`;
  return apiClient.get(apiUrl, data);
};

export const getRSDVDocumentAPI = async data => {
  const apiUrl = `${CRA_APIS.rsdv}/${data.id}/get_document/`;
  return apiClient.get(apiUrl, data);
};

export const getParticipantRSDVDocumentsAPI = async data => {
  const apiUrl = `${CRA_APIS.rsdv}/participants/${
    data.participantId
  }/rsdv/?status=${data.filter || ''}`;
  return apiClient.get(apiUrl, data);
};

export const createRSDVDocumentRequestAPI = async data => {
  const apiUrl = `${CRA_APIS.rsdv}/request/`;
  return apiClient.post(apiUrl, data);
};

export const changeRSDVStatusAPI = async data => {
  const apiUrl = `${CRA_APIS.rsdv}/${data.id}/status/`;
  return apiClient.patch(apiUrl, data.data);
};
