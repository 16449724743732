import React, { useEffect } from 'react';

import ViewSDKClient from '../helpers/ViewSDKClient';

const TPDFBlobRenderer = ({ document }) => {
  useEffect(() => {
    const docURL = window.URL.createObjectURL(
      new Blob([document], { type: 'application/pdf' })
    );

    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile('pdf-rendering-div', docURL, {
        showDownloadPDF: false,
        showPrintPDF: false,
      });
    });
  }, [document]);

  return (
    <div className="d-flex justify-content-center">
      <div id="pdf-rendering-div" style={{ height: '80vh' }} />
    </div>
  );
};

export default TPDFBlobRenderer;
