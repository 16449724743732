// A simple function to fetch public files from S3
export default async function genFileFromS3(bucketName, fileKey, version) {
  const filePath = `https://${bucketName}.s3.ap-south-1.amazonaws.com/${fileKey}`;

  // Adding cache policy: `no-cache` checks the resources on disk against that of network call;
  const response = await fetch(filePath, {
    cache: 'no-cache',
  });

  return response;
}
