import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import React from 'react';

import useStyles from './classes';

/**
 Custom checkbox field component.
 **/
const TCheckboxField = props => {
  const classes = useStyles();

  const { label, key } = props.field;
  const { value } = props;

  const handleChange = event => {
    const { value } = event.target;
    props.handleChange(key, value);
  };

  return (
    <FormControl className={classes.field}>
      <FormControlLabel
        control={
          <Checkbox checked={value} onChange={handleChange} name={key} />
        }
        label={label}
        required={
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
        }
      />
    </FormControl>
  );
};

export default TCheckboxField;
