import { Grid } from '@material-ui/core';
import React from 'react';

const InfoTile = ({ label, value }) => {
  return (
    <Grid item container xs={3}>
      <Grid
        item
        xs={12}
        className="p-3 custom-box fs-xl fw-semibold d-flex flex-column justify-content-center align-items-center"
      >
        <div className="text-center">{label}</div>
        <div className="fs-xxlarge fw-semibold">{value}</div>
      </Grid>
    </Grid>
  );
};

export default InfoTile;
