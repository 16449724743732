import {
  Backdrop,
  Box,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import {
  downloadDocument,
  getAllDocuments,
  getDocument,
  uploadDocument,
} from '../../actions/documentsActions';
import { PER_PAGE_OPTIONS } from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { CheckmarkIcon, DownloadIcon, UploadIcon } from '../../common/icons';
import TButton from '../../common/TButton';
import TConfirmationModal from '../../common/TConfirmationModal';
import TSignDocumentModal from '../../common/TSignDocumentModal';
import TSignPasswordModal from '../../common/TSignPasswordModal';
import TUploadDocumentsModal from '../../common/TUploadDocumentsModal';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
} from '../../constant/document_constants';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '1000px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.16px',
    padding: '25px 48px',
  },
}));

const ImportantDocuments = ({
  selectedTrialId,
  selectedTrialSiteId,
  trialId,
}) => {
  const siteImportantDocuments = useSelector(
    state => state.documentsReducer.documents
  );
  const user = useSelector(state => state.userReducer.user);
  const pdfDocument = useSelector(state => state.documentsReducer.document);
  const classes = useStyles();

  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [showUploadDocumentsModal, setShowUploadDocumentsModal] =
    useState(false);
  const [showSignPasswordModal, setShowSignPasswordModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [documentDownloading, setDocumentDownloading] = useState(false);
  const [confirmationModalLabel, setConfirmationModalLabel] = useState('');
  const [action, setAction] = useState(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
  const { id } = useParams();
  const [documentObj, setDocumentObj] = useState({
    trialId: selectedTrialId ? selectedTrialId : trialId,
    trialSiteId: selectedTrialSiteId ? selectedTrialSiteId : id,
  });

  const dispatch = useDispatch();

  const columnDefs = [
    {
      headerName: 'FORM',
      field: 'display_name',
    },
    {
      headerName: 'STATUS',
      cellRenderer: 'documentStatusRenderer',
      maxWidth: 200,
    },
    {
      headerName: 'ACTIONS',
      cellRenderer: 'actionCellRenderer',
      maxWidth: 200,
      filter: false,
      cellRendererParams: {
        documentObj: documentObj,
      },
    },
  ];

  useEffect(() => {
    if ((selectedTrialId && selectedTrialSiteId) || (trialId && id)) {
      dispatch(
        getAllDocuments({
          trialId: selectedTrialId ? selectedTrialId : trialId,
          trialSiteId: selectedTrialSiteId ? selectedTrialSiteId : id,
        })
      );
      setDocumentObj({
        ...documentObj,
        trialId: selectedTrialId ? selectedTrialId : trialId,
        trialSiteId: selectedTrialSiteId ? selectedTrialSiteId : id,
      });
    }
  }, [selectedTrialId, selectedTrialSiteId, trialId, id]);

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  const handleUploadDocument = () => {
    dispatch(uploadDocument(documentObj));
  };

  const documentStatusRenderer = params => {
    if (!params.data.status) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ marginRight: '15px' }}>---</div>
        </div>
      );
    }

    if (params.data.is_direct) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ marginRight: '1.125rem' }}>Directly uploaded</div>
        </div>
      );
    }

    if (params.data.status === DOCUMENT_STATUS.SIGNED) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CheckmarkIcon style={{ fontSize: '16px' }} />
          <div style={{ margin: '0 15px' }}>{params.data.status}</div>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <div style={{ marginRight: '15px' }}>{params.data.status}</div>
      </div>
    );
  };

  const actionCellRenderer = params => {
    // console.log(params.data);
    const handleSetDocumentObj = () => {
      setDocumentObj({
        ...params.documentObj,
        ...params.data,
      });
    };

    return (
      <div
        className="ag-react-container"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container alignItems="center" spacing={1}>
          {/* {!params.data.status !== DOCUMENT_STATUS.RESPONSE_PENDING ? (
            <Grid item>
              <Tooltip title="Upload document">
                <IconButton
                  onClick={() => {
                    let docObj = {
                      ...documentObj,
                      document: params.data.type,
                      docType: params.data.doc_type,
                    };
                    setDocumentObj(docObj);
                    setSelectedDocument(params.data);
                    setShowUploadDocumentsModal(true);
                  }}
                >
                  <UploadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null} */}
          {params.data.status === DOCUMENT_STATUS.SIGNED ||
          (user.role === 'Sponsor' &&
            params.data.status === DOCUMENT_STATUS.RESPONSE_RECEIVED) ? (
            <>
              <Grid item>
                <Tooltip title="View document">
                  <IconButton
                    onClick={() => {
                      setDocumentDownloading(false);
                      if (
                        params.data?.status ===
                        DOCUMENT_STATUS.RESPONSE_RECEIVED
                      ) {
                        setAction(DOCUMENT_MODAL_ACTIONS.APPROVING);
                      } else {
                        setAction(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
                      }
                      handleSetDocumentObj();
                      setShowSignDocumentModal(true);
                    }}
                  >
                    <Visibility color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Download document">
                  <IconButton
                    onClick={() => {
                      handleSetDocumentObj();
                      dispatch(
                        downloadDocument({
                          ...params.documentObj,
                          ...params.data,
                        })
                      );
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          ) : null}
        </Grid>
      </div>
    );
  };

  return (
    <Paper elevation={0} className="p-3 custom-box">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="mb-2"
      >
        <Typography variant="h6" className="fw-semibold m-1 px-2 fs-xl">
          Important Documents
        </Typography>
        {user.role !== 'Sponsor' ? (
          <Grid item>
            <TButton
              variant="contained"
              color="primary"
              onClick={() => setShowUploadDocumentsModal(true)}
            >
              Upload documents
            </TButton>
          </Grid>
        ) : null}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            className="ag-react-container"
            style={{
              height: '60vh',
              width: '100%',
            }}
          >
            <AgGridReact
              onGridReady={onGridReady}
              rowData={siteImportantDocuments}
              gridOptions={DEFAULT_GRID_OPTIONS}
              columnDefs={columnDefs}
              frameworkComponents={{
                actionCellRenderer,
                documentStatusRenderer,
              }}
              defaultColDef={{
                ...DEFAULT_COL_DEF,
                cellRendererParams: { checkbox: true },
              }}
              pagination
              suppressPaginationPanel
              suppressRowHoverHighlight
              paginationPageSize={PER_PAGE_OPTIONS[0]}
              animateRows
              onGridSizeChanged={params => {
                params.api.sizeColumnsToFit();
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Modal
        className="modal-container"
        open={showUploadDocumentsModal}
        onClose={() => setShowUploadDocumentsModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showUploadDocumentsModal}>
          <TUploadDocumentsModal
            setShowUploadDocumentsModal={setShowUploadDocumentsModal}
            setShowConfirmationModal={setShowConfirmationModal}
            setConfirmationModalLabel={setConfirmationModalLabel}
            documentType={selectedDocument.site?.name}
            handleUploadDocument={handleUploadDocument}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showSignPasswordModal}
        onClose={() => setShowSignPasswordModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignPasswordModal}>
          <TSignPasswordModal
            setShowSignPasswordModal={setShowSignPasswordModal}
            setShowConfirmationModal={setShowConfirmationModal}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      {/* <Modal
        className="modal-container"
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showConfirmationModal}>
          <TConfirmationModal label={confirmationModalLabel} />
        </Fade>
      </Modal> */}
      <Modal
        className="modal-container"
        open={showSignDocumentModal}
        onClose={() => setShowSignDocumentModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignDocumentModal}>
          <TSignDocumentModal
            setShowSignPasswordModal={setShowSignPasswordModal}
            setShowSignDocumentModal={setShowSignDocumentModal}
            action={action}
            downloading={true}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ImportantDocuments;
