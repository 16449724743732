import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAllCities,
  getDoctorAreaOfInterest,
  getDoctorSpecializations,
} from '../../../actions/doctorActions';
import TButton from '../../../common/TButton';
import TCircularProgress from '../../../common/TCircularProgress';
import { isCompleted } from '../../../constant/api_constants';
import ProfileForm from './ProfileForm';

const ProfileView = props => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const doctorProfile = useSelector(
    state => state.doctorReducer.doctorProfile[0]
  );
  const doctorProfileAPI = useSelector(
    state => state.doctorReducer.doctorProfileAPI
  );
  const siteProfileAPI = useSelector(
    state => state.doctorReducer.siteProfileAPI
  );
  const doctorAreaOfInterestAPI = useSelector(
    state => state.doctorReducer.doctorAreaOfInterestAPI
  );
  const doctorSpecializationsAPI = useSelector(
    state => state.doctorReducer.doctorSpecializationsAPI
  );

  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState();

  useEffect(() => {
    dispatch(getAllCities());
    dispatch(getDoctorSpecializations());
  }, []);

  useEffect(() => {
    if (
      isCompleted(doctorProfileAPI) &&
      isCompleted(siteProfileAPI) &&
      isCompleted(doctorSpecializationsAPI)
    ) {
      setIsLoading(false);
    }
  }, [doctorProfileAPI, siteProfileAPI, doctorSpecializationsAPI]);

  useEffect(() => {
    setProfile(doctorProfile);
  }, [doctorProfile]);

  return (
    <>
      {isLoading ? (
        <TCircularProgress />
      ) : user.role === 'Clinical Research Coordinator' &&
        (!doctorProfile || !user.verified) ? (
        <Grid container justify="center">
          <Grid item xs={8}>
            <Typography variant="h4" className="text-center mt-5">
              {!user.verified
                ? 'Your profile is not verified yet. Our teams will contact you soon.'
                : 'There are no doctors registered in your hospital.'}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Grid
            className="py-4 px-5"
            container
            justify="space-between"
            alignItems="center"
          >
            <Typography
              as="h2"
              variant="h2"
              className="fs-xxl-temp fw-semibold c-subheading-text"
              style={{ width: 'fit-content' }}
            >
              {!doctorProfile?.is_profile_created_by_user
                ? 'Create Profile'
                : 'Edit Profile'}
            </Typography>
            <TButton
              variant="contained"
              color="primary"
              className="fs-xxl"
              style={{ float: 'right' }}
              onClick={props.toggleView}
              disabled={!doctorProfile?.is_profile_created_by_user}
            >
              View Profile
            </TButton>
          </Grid>
          <ProfileForm
            toggleView={props.toggleView}
            createMode={!doctorProfile?.is_profile_created_by_user}
          />
        </Box>
      )}
    </>
  );
};

export default ProfileView;
