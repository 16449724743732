import '../styles.css';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import { isComponentDisplayed as shouldDisplay } from '../../../helpers/formUtil';
import _useStyles from './fields/classes';
import FormField from './FormField';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    margin: '15px 0',
  },
}));

/**
 * Custom form renderer.
 **/
const FormRenderer = props => {
  const { components, formData, handleChange } = props;
  const classes = useStyles();
  const _classes = _useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      {!!components &&
        components.map(component => {
          if (
            shouldDisplay({
              component: component,
              formData: formData,
            })
          ) {
            return (
              <Paper
                className={clsx(classes.paper, _classes.paper)}
                key={component.key}
              >
                <FormField
                  type={component.type}
                  field={component}
                  key={component.key}
                  handleChange={handleChange}
                  error={component.error}
                  errorMessage={component.errorMessage}
                  errorRows={component.errorRows || null}
                  value={
                    component.type === 'table'
                      ? formData
                      : formData[component.key]
                  }
                />
              </Paper>
            );
          }
        })}
    </form>
  );
};

export default FormRenderer;
