import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';

import {
  addDoctor,
  getAllCities,
  getAllHospitals,
} from '../actions/doctorActions';
import {
  requestEmailOTP,
  requestPhoneOTP,
  signUp,
} from '../actions/userActions';
import PasswordValidator from '../common/PasswordValidator';
import TButton from '../common/TButton';
import TCircularProgress from '../common/TCircularProgress';
import { isPending } from '../constant/api_constants';
import { ERROR_MESSAGE } from '../constant/auth_constants';
import { validateForm } from '../helpers/validateHelper';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 400,
    width: 400,
    textOverflow: 'ellipses',
  },
  helperText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .required()
    .matches(/^([1-9]\d{9})$/g),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9._~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    .email()
    .required(),
  first_name: yup.string().required(),
  license_number: yup.string().required(),
  issuing_authority: yup.string().required(),
});

const TAddDoctorModal = ({ setShowAddDoctorModal, documentObj }) => {
  const dispatch = useDispatch();

  const loginAPI = useSelector(state => state.userReducer.loginAPI);
  const errorMsg = useSelector(state => state.userReducer.error);

  const signUpObj = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    license_number: '',
    issuing_authority: '',
    role: 'Doctor',
  };

  const [formData, setFormData] = useState(Object.assign({}, signUpObj));
  const [valErrorList, setValErrorList] = useState([]);

  const signUpAPI = useSelector(state => state.doctorReducer.signUpAPI);

  const handleChange = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleAddDoctor = () => {
    validateForm(validationSchema, formData).then(({ isError, valErrors }) => {
      if (isError) setValErrorList(valErrors);
      else {
        setValErrorList([]);
        let formDataFormatted = { ...formData };

        formDataFormatted.phone = '+91' + formData.phone;
        dispatch(addDoctor(formDataFormatted));
        setShowAddDoctorModal(false);
      }
    });
  };

  return (
    <Paper
      elevation={0}
      className="p-4 custom-modal"
      style={{ maxWidth: '40rem', maxHeight: '90vh', overflow: 'scroll' }}
    >
      {isPending(loginAPI) ? (
        <TCircularProgress />
      ) : (
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="h6">Add new doctor</Typography>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={6}>
              <TextField
                size="small"
                label="First Name"
                value={formData.first_name}
                required
                onChange={handleChange('first_name')}
                variant="outlined"
                className="bg-white"
                fullWidth
                error={valErrorList.indexOf('first_name') > -1}
                helperText={
                  valErrorList.indexOf('first_name') > -1
                    ? ERROR_MESSAGE.REQUIRED_MESSAGE
                    : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                label="Last Name"
                value={formData.last_name}
                required
                onChange={handleChange('last_name')}
                variant="outlined"
                className="bg-white"
                fullWidth
                error={valErrorList.indexOf('last_name') > -1}
                helperText={
                  valErrorList.indexOf('last_name') > -1
                    ? ERROR_MESSAGE.REQUIRED_MESSAGE
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Email"
                value={formData.email}
                required
                onChange={handleChange('email')}
                variant="outlined"
                className="bg-white"
                fullWidth
                error={valErrorList.indexOf('email') > -1}
                helperText={
                  valErrorList.indexOf('email') > -1
                    ? ERROR_MESSAGE.EMAIL_ERROR_MESSAGE
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Phone Number"
                value={formData.phone}
                required
                onChange={handleChange('phone')}
                variant="outlined"
                className="bg-white"
                fullWidth
                error={valErrorList.indexOf('phone') > -1}
                helperText={
                  valErrorList.indexOf('phone') > -1
                    ? ERROR_MESSAGE.PHONE_ERROR_MESSAGE
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size="small"
                label="License Number"
                value={formData.license_number}
                required
                onChange={handleChange('license_number')}
                variant="outlined"
                className="bg-white"
                fullWidth
                error={valErrorList.indexOf('license_number') > -1}
                helperText={
                  valErrorList.indexOf('license_number') > -1
                    ? ERROR_MESSAGE.REQUIRED_MESSAGE
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Issuing Authority"
                value={formData.issuing_authority}
                required
                onChange={handleChange('issuing_authority')}
                variant="outlined"
                className="bg-white"
                fullWidth
              />
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <TButton
                  onClick={() => handleAddDoctor()}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  isLoading={isPending(signUpAPI)}
                >
                  <Typography className="fs-l fw-semibold">Sign Up</Typography>
                </TButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default TAddDoctorModal;
