import {
  SET_TOAST_CUSTOM,
  SET_TOAST_ERROR,
  SET_TOAST_INFO,
  SET_TOAST_SUCCESS,
} from '../actions/toastActionType';
import TOAST_TYPES from '../constant/toast_constant';

const initialState = {
  toastType: '',
  message: '',
  timestamp: Date.now(),
  color: '',
};

const toastReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TOAST_SUCCESS:
      return {
        ...state,
        toastType: TOAST_TYPES.SUCCESS,
        message: payload,
        color: '#07bc0c',
        timestamp: Date.now(),
      };
    case SET_TOAST_ERROR:
      return {
        ...state,
        toastType: TOAST_TYPES.ERROR,
        message: payload,
        color: '#e74c3c',
        timestamp: Date.now(),
      };
    case SET_TOAST_INFO:
      return {
        ...state,
        toastType: TOAST_TYPES.INFO,
        message: payload,
        color: '#3498db',
        timestamp: Date.now(),
      };
    case SET_TOAST_CUSTOM:
      return {
        ...state,
        toastType: TOAST_TYPES.INFO,
        message: payload.message,
        color: payload.color,
        timestamp: Date.now(),
      };

    default:
      return state;
  }
};

export default toastReducer;
