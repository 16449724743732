export const GET_CRA_INITIATION_PANEL = 'GET_CRA_INITIATION_PANEL';
export const GET_CRA_QUALIFICATION_PANEL = 'GET_CRA_QUALIFICATION_PANEL';
export const GET_CRA_SDV_SITES = 'GET_CRA_SDV_SITES';
export const GET_TRIAL_SITE_PARTICIPANTS = 'GET_TRIAL_SITE_PARTICIPANTS';
export const GET_RSDV_DOCUMENT = 'GET_RSDV_DOCUMENT';
export const GET_PARTICIPANT_RSDV_DOCUMENTS = 'GET_PARTICIPANT_RSDV_DOCUMENTS';
export const SET_PARTICIPANT_RSDV_DOCUMENTS = 'SET_PARTICIPANT_RSDV_DOCUMENTS';
export const SET_CRA_SDV_SITES = 'SET_CRA_SDV_SITES';
export const SET_TRIAL_SITE_PARTICIPANTS = 'SET_TRIAL_SITE_PARTICIPANTS';
export const SET_RSDV_DOCUMENT = 'SET_RSDV_DOCUMENT';
export const CREATE_RSDV_DOCUMENT_REQUEST = 'CREATE_RSDV_DOCUMENT_REQUEST';
export const CHANGE_RSDV_STATUS = 'CHANGE_RSDV_STATUS';
export const SET_CRA_PANEL = 'SET_CRA_PANEL';
export const PATCH_VISIT = 'PATCH_VISIT';

export const GET_CRA_INITIATION_PANEL_API_STATE =
  'GET_CRA_INITIATION_PANEL_API_STATE';
export const GET_CRA_QUALIFICATION_PANEL_API_STATE =
  'GET_CRA_QUALIFICATION_PANEL_API_STATE';
export const PATCH_VISIT_API_STATE = 'PATCH_VISIT_API_STATE';
export const GET_CRA_SDV_SITES_API_STATE = 'GET_CRA_SDV_SITES_API_STATE';
export const GET_TRIAL_SITE_PARTICIPANTS_API_STATE =
  'GET_TRIAL_SITE_PARTICIPANTS_API_STATE';
export const GET_RSDV_DOCUMENT_API_STATE = 'GET_RSDV_DOCUMENT_API_STATE';
export const CREATE_RSDV_DOCUMENT_REQUEST_API_STATE =
  'CREATE_RSDV_DOCUMENT_REQUEST_API_STATE';
export const CHANGE_RSDV_STATUS_API_STATE = 'CHANGE_RSDV_STATUS_API_STATE';
export const GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE =
  'GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE';
