import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';

import { getAllCities, getAllHospitals } from '../../actions/doctorActions';
import {
  requestEmailOTP,
  requestPhoneOTP,
  signUp,
} from '../../actions/userActions';
import PasswordValidator from '../../common/PasswordValidator';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';
import { ERROR_MESSAGE } from '../../constant/auth_constants';
import { validateForm } from '../../helpers/validateHelper';
import AuthCard from './AuthCard';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 400,
    width: 400,
    textOverflow: 'ellipses',
  },
  helperText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const validationSchema = yup.object().shape({
  phone: yup
    .string()
    .required()
    .matches(/^([1-9]\d{9})$/g),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9._~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    .email()
    .required(),
  first_name: yup.string().required(),
  password: yup
    .string()
    .required()
    .min(9)
    .matches(/[a-z]/)
    .matches(/[A-Z]/)
    .matches(/\d/)
    .matches(/[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/),
  confirm_pass: yup.string().oneOf([yup.ref('password'), null]),
  role: yup.string().required(),
  hospital_name: yup.string().required(),
  license_number: yup.string().when('role', {
    is: 'Doctor',
    then: yup.string().required('This field is required'),
  }),
  issuing_authority: yup.string().when('role', {
    is: 'Doctor',
    then: yup.string().required('This field is required'),
  }),
  city: yup.string().required(),
});

const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginAPI = useSelector(state => state.userReducer.loginAPI);
  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const errorMsg = useSelector(state => state.userReducer.error);
  const firstLogin = useSelector(state => state.userReducer.user?.first_login);
  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );

  const signUpObj = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_pass: '',
    license_number: '',
    issuing_authority: '',
    hospital: '',
    hospital_name: '',
    role: '',
    city: '',
  };

  const [formData, setFormData] = useState(Object.assign({}, signUpObj));
  const [showPassword, setShowPassword] = useState(false);
  const [valErrorList, setValErrorList] = useState([]);

  const cities = useSelector(state => state.doctorReducer.citiesList);
  const signUpAPI = useSelector(state => state.doctorReducer.signUpAPI);
  const hospitals = useSelector(state => state.doctorReducer.hospitalsList);
  const phone_verified = useSelector(
    state => state.userReducer.user?.phone_verified
  );
  const email_verified = useSelector(
    state => state.userReducer.user?.email_verified
  );

  useEffect(() => {
    dispatch(getAllHospitals());
    dispatch(getAllCities());
  }, []);

  const handleChange = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleSignUp = () => {
    validateForm(validationSchema, formData).then(({ isError, valErrors }) => {
      if (isError) setValErrorList(valErrors);
      else {
        if (formData.password !== formData.confirm_pass) {
          setValErrorList([...valErrorList, 'confirm_pass']);
        } else {
          setValErrorList([]);
          let formDataFormatted = { ...formData };
          if (formData.hospital) {
            formDataFormatted.hospital_id = formData.hospital.id;
          }
          formDataFormatted.phone = '+91' + formData.phone;
          dispatch(signUp(formDataFormatted));
        }
      }
    });
  };

  if (isAuthenticated && firstLogin) {
    return <Redirect to="/forgot-password/user/update" />;
  }

  if (isAuthenticated && !phone_verified) {
    dispatch(requestPhoneOTP());
    dispatch(push('/phone/verify'));
  } else if (isAuthenticated && !email_verified) {
    dispatch(requestEmailOTP());
    return <Redirect to="/email/verify" />;
  } else if (isAuthenticated) {
    return <Redirect to={viewPermissions[0].resource_path} />;
  }

  return (
    <>
      {isPending(loginAPI) ? (
        <TCircularProgress />
      ) : (
        <AuthCard
          cardHeader={
            <>
              <Typography
                variant="h5"
                style={{ color: '#3c3c3c' }}
                className="fw-medium"
              >
                Sign up for your
              </Typography>
              <Typography variant="h4" className="fw-semibold">
                Triomics Account
              </Typography>
            </>
          }
        >
          <Grid container spacing={4}>
            <Grid item container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="First Name"
                  value={formData.first_name}
                  required
                  onChange={handleChange('first_name')}
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                  error={valErrorList.indexOf('first_name') > -1}
                  helperText={
                    valErrorList.indexOf('first_name') > -1
                      ? ERROR_MESSAGE.REQUIRED_MESSAGE
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  label="Last Name"
                  value={formData.last_name}
                  required
                  onChange={handleChange('last_name')}
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                  error={valErrorList.indexOf('last_name') > -1}
                  helperText={
                    valErrorList.indexOf('last_name') > -1
                      ? ERROR_MESSAGE.REQUIRED_MESSAGE
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Email"
                  value={formData.email}
                  required
                  onChange={handleChange('email')}
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                  error={valErrorList.indexOf('email') > -1}
                  helperText={
                    valErrorList.indexOf('email') > -1
                      ? ERROR_MESSAGE.EMAIL_ERROR_MESSAGE
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Phone Number"
                  value={formData.phone}
                  required
                  onChange={handleChange('phone')}
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                  error={valErrorList.indexOf('phone') > -1}
                  helperText={
                    valErrorList.indexOf('phone') > -1
                      ? ERROR_MESSAGE.PHONE_ERROR_MESSAGE
                      : ''
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Password"
                  value={formData.password}
                  onChange={handleChange('password')}
                  variant="outlined"
                  required
                  className="bg-white"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  error={valErrorList.indexOf('password') > -1}
                  helperText={
                    valErrorList.indexOf('password') > -1
                      ? ERROR_MESSAGE.REQUIRED_MESSAGE
                      : ''
                  }
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility fontSize="small" color="primary" />
                          ) : (
                            <VisibilityOff fontSize="small" color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <PasswordValidator password={formData.password} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Confirm Password"
                  value={formData.confirm_pass}
                  onChange={handleChange('confirm_pass')}
                  variant="outlined"
                  required
                  className="bg-white"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  error={valErrorList.indexOf('confirm_pass') > -1}
                  helperText={
                    valErrorList.indexOf('confirm_pass') > -1
                      ? ERROR_MESSAGE.CONFIRM_PASSWORD_ERROR_MESSAGE
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility fontSize="small" color="primary" />
                          ) : (
                            <VisibilityOff fontSize="small" color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {true ? (
                  <TextField
                    size="small"
                    label=" Hospital Name"
                    value={formData.hospital_name}
                    required
                    onChange={handleChange('hospital_name')}
                    variant="outlined"
                    className="bg-white"
                    fullWidth
                  />
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                  >
                    {/* HACK: https://stackoverflow.com/a/68242447  */}
                    <InputLabel style={{ backgroundColor: 'white' }}>
                      Hospital Name
                    </InputLabel>
                    <Select
                      MenuProps={{
                        classes: { paper: classes.menuPaper },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      select
                      size="medium"
                      fullWidth
                      value={formData.hospital}
                      onChange={handleChange('hospital')}
                      error={valErrorList.indexOf('hospital') > -1}
                      helperText={
                        valErrorList.indexOf('hospital') > -1
                          ? ERROR_MESSAGE.DROPDOWN_REQUIRED_MESSAGE
                          : ''
                      }
                    >
                      {hospitals.map(hospital => {
                        return (
                          <MenuItem key={hospital} value={hospital}>
                            <Grid container direction="column">
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  className="fw-semibold"
                                  noWrap
                                >
                                  {hospital.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1" noWrap>
                                  {hospital.address}
                                </Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {true ? (
                  <TextField
                    size="small"
                    label="City"
                    value={formData.city}
                    required
                    onChange={handleChange('city')}
                    variant="outlined"
                    className="bg-white"
                    fullWidth
                  />
                ) : (
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    size="small"
                  >
                    <InputLabel style={{ backgroundColor: 'white' }}>
                      Select your city
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      select
                      size="medium"
                      fullWidth
                      value={formData.city}
                      onChange={handleChange('city')}
                      error={valErrorList.indexOf('city') > -1}
                      helperText={
                        valErrorList.indexOf('city') > -1
                          ? ERROR_MESSAGE.DROPDOWN_REQUIRED_MESSAGE
                          : ''
                      }
                    >
                      {cities.map(city => {
                        return (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: '100%', backgroundColor: 'white' }}
                  size="small"
                >
                  <InputLabel>Select your role</InputLabel>
                  <Select
                    MenuProps={{
                      classes: {
                        paper: 'select-menu',
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    label="Select your role"
                    select
                    size="medium"
                    fullWidth
                    value={formData.role}
                    onChange={handleChange('role')}
                    error={valErrorList.indexOf('role') > -1}
                    helperText={
                      valErrorList.indexOf('role') > -1
                        ? ERROR_MESSAGE.DROPDOWN_REQUIRED_MESSAGE
                        : ''
                    }
                  >
                    <MenuItem value="Doctor" className="select-menu-item">
                      Physician/Investigator
                    </MenuItem>
                    <MenuItem
                      value="Clinical Research Coordinator"
                      className="select-menu-item"
                    >
                      Clinical Research Staff
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {formData.role === 'Doctor' ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      label="License Number"
                      value={formData.license_number}
                      required
                      onChange={handleChange('license_number')}
                      variant="outlined"
                      className="bg-white"
                      fullWidth
                      error={valErrorList.indexOf('license_number') > -1}
                      helperText={
                        valErrorList.indexOf('license_number') > -1
                          ? ERROR_MESSAGE.REQUIRED_MESSAGE
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      label="Issuing Authority"
                      value={formData.issuing_authority}
                      required
                      onChange={handleChange('issuing_authority')}
                      variant="outlined"
                      className="bg-white"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : null}
              {errorMsg && (
                <Grid container item xs={12}>
                  <Typography variant="body1" color="error">
                    <ErrorOutline color="error" /> {errorMsg}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <Typography variant="subtitle2">
                  By clicking below to signup, you agree to our{' '}
                  <a
                    style={{ textDecoration: 'none', color: '#008CFF' }}
                    href="https://triomics.in/terms"
                  >
                    Terms of Service{' '}
                  </a>
                  and{' '}
                  <a
                    style={{ textDecoration: 'none', color: '#008CFF' }}
                    href="https://triomics.in/privacy"
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </Grid>

              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <TButton
                    onClick={handleSignUp}
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    isLoading={isPending(signUpAPI)}
                  >
                    <Typography className="fs-l fw-semibold">
                      Sign Up
                    </Typography>
                  </TButton>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Already have an account?{' '}
                    <a
                      href="/login"
                      style={{
                        color: '#008CFF',
                        fontWeight: 600,
                        textUnderlineOffset: 4,
                      }}
                    >
                      SIGN IN!
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AuthCard>
      )}
    </>
  );
};

export default SignUp;
