import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import TPDFBlobRenderer from '../../common/TPDFBlobRenderer';
import { isPending } from '../../constant/api_constants';

const SDVDocumentModal = ({ closePDFModal, docUrl, docType }) => {
  const { getRSDVDocumentAPI } = useSelector(state => state.craReducer);

  return (
    <Paper
      elevation={0}
      className="p-4 custom-modal"
      style={{ minWidth: '65rem' }}
    >
      <Grid
        container
        className="p-2"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Typography className="fw-semibold">View Document</Typography>
        </Grid>
      </Grid>
      {isPending(getRSDVDocumentAPI) ? (
        <TCircularProgress />
      ) : docType?.startsWith('image/') ? (
        <div
          className="scrollbar"
          style={{ maxWidth: '65rem', maxHeight: '75vh', overflow: 'auto' }}
        >
          <div className="d-flex justify-content-center">
            <img
              className="sheet-of-paper"
              src={window.URL.createObjectURL(new Blob([docUrl]))}
              style={{ maxWidth: '60rem' }}
            />
          </div>
        </div>
      ) : (
        <Grid>
          <TPDFBlobRenderer document={docUrl} />
        </Grid>
      )}
      <Grid container spacing={2} className="my-2 mb-0" justify="flex-end">
        <Grid item>
          <TButton variant="outlined" color="secondary" onClick={closePDFModal}>
            Close
          </TButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SDVDocumentModal;
