import { Divider } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.25),
    top: theme.spacing(0.25),
    color: theme.palette.grey[700],
  },
  divider: {
    backgroundColor: '#BFE2FF',
  },
});

const TStyledDialog = withStyles(theme => ({
  root: {
    margin: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
}))(MuiDialog);

const TStyledDialogTitle = withStyles(styles)(props => {
  const {
    children,
    classes,
    onClose,
    disableUnderline,
    typographyProps,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className="fw-semibold" {...typographyProps}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {!disableUnderline && <Divider className={classes.divider} />}
    </MuiDialogTitle>
  );
});

const TStyledDialogContent = withStyles(() => ({
  root: {
    // padding: 0,
    // height: '70vh',
    display: 'flex',
    alignItems: 'center',
    '& > .MuiGrid-root': {
      width: '100% important',
      height: '100% important',
      margin: '0em important',
    },
  },
}))(MuiDialogContent);

const TStyledDialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default TStyledDialog;
export {
  TStyledDialog,
  TStyledDialogActions,
  TStyledDialogContent,
  TStyledDialogTitle,
};
