import {
  ADD_DOCTOR_API_STATE,
  ADD_GRANT_API_STATE,
  CREATE_PARTICIPANT_API_STATE,
  DOCTOR_FILE_UPLOAD_API_STATE,
  EDIT_DOCTOR_PROFILE_API_STATE,
  GET_DOCTOR_PROFILES_API_STATE,
  GET_DOCTOR_TRIALS_API_STATE,
  GET_FIXED_DOCUMENTS_API_STATE,
  GET_HOSPITALS_API_STATE,
  GET_PARTICIPANT_SDV_API_STATE,
  GET_SDV_PARTICIPANTS_LIST_API_STATE,
  SET_API_SUCCESS,
  SET_CITIES,
  SET_DOCTOR_AREA_OF_INTEREST,
  SET_DOCTOR_AREA_OF_INTEREST_API_STATE,
  SET_DOCTOR_PROFILE,
  SET_DOCTOR_PROFILE_API_STATE,
  SET_DOCTOR_PROFILES,
  SET_DOCTOR_SPECIALIZATIONS,
  SET_DOCTOR_SPECIALIZATIONS_API_STATE,
  SET_DOCTOR_TRIALS,
  SET_FIXED_DOCUMENTS,
  SET_HOSPITALS,
  SET_PARTICIPANT_SDV,
  SET_SDV_PARTICIPANTS_LIST,
  SET_SITE_PROFILE,
  SET_SITE_PROFILE_API_STATE,
  UPLOAD_SDV_DOCUMENT_API_STATE,
} from '../actions/doctorActionType';
import API_STATES from '../constant/api_constants';
import { EMPTY_SITE_PROFILE } from '../constant/doctor_constant';

const initialPendingState = {
  doctorProfileAPI: API_STATES.NOT_INITIATED,
  getDoctorProfilesAPI: API_STATES.NOT_INITIATED,
  doctorAreaOfInterestAPI: API_STATES.NOT_INITIATED,
  doctorSpecializationsAPI: API_STATES.NOT_INITIATED,
  siteProfileAPI: API_STATES.NOT_INITIATED,
  editDoctorProfileAPI: API_STATES.NOT_INITIATED,
  getDoctorTrialsApi: API_STATES.NOT_INITIATED,
  addGrantAPI: API_STATES.NOT_INITIATED,
  doctorFileUploadApi: API_STATES.NOT_INITIATED,
  hospitalsListApi: API_STATES.NOT_INITIATED,
  getFixedDocumentsApi: API_STATES.NOT_INITIATED,
  addDoctorAPI: API_STATES.NOT_INITIATED,
  getSDVParticipantsAPI: API_STATES.NOT_INITIATED,
  getParticipantSDVAPI: API_STATES.NOT_INITIATED,
  createParticipantAPI: API_STATES.NOT_INITIATED,
  uploadSDVDocumentAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialPendingState,
  doctorProfile: [],
  doctorAreaOfInterest: [],
  doctorSpecializations: [],
  siteProfile: EMPTY_SITE_PROFILE,
  doctorTrials: [],
  citiesList: [],
  hospitalsList: [],
  doctorProfiles: [],
  fixedDocuments: [],
  esigningError: false,
  apiSuccess: false,
  sdvParticipants: [],
  participantSDV: [],
};

export default function doctorReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_DOCTOR_PROFILE:
      return { ...state, doctorProfile: payload };
    case SET_DOCTOR_PROFILE_API_STATE:
      return { ...state, doctorProfileAPI: payload };
    case GET_DOCTOR_PROFILES_API_STATE:
      return { ...state, getDoctorProfilesAPI: payload };
    case SET_DOCTOR_AREA_OF_INTEREST:
      return { ...state, doctorAreaOfInterest: payload };
    case SET_DOCTOR_AREA_OF_INTEREST_API_STATE:
      return { ...state, doctorAreaOfInterestAPI: payload };
    case SET_DOCTOR_SPECIALIZATIONS:
      return { ...state, doctorSpecializations: payload };
    case SET_DOCTOR_SPECIALIZATIONS_API_STATE:
      return { ...state, doctorSpecializationsAPI: payload };
    case SET_SITE_PROFILE:
      return { ...state, siteProfile: payload };
    case SET_FIXED_DOCUMENTS:
      return { ...state, fixedDocuments: payload };
    case SET_SITE_PROFILE_API_STATE:
      return { ...state, siteProfileAPI: payload };
    case EDIT_DOCTOR_PROFILE_API_STATE:
      return { ...state, editDoctorProfileAPI: payload };
    case SET_DOCTOR_TRIALS:
      return { ...state, doctorTrials: payload };
    case SET_CITIES:
      return { ...state, citiesList: payload };
    case SET_API_SUCCESS:
      return { ...state, apiSuccess: payload };
    case GET_DOCTOR_TRIALS_API_STATE:
      return { ...state, getDoctorTrialsApi: payload };
    case DOCTOR_FILE_UPLOAD_API_STATE:
      return { ...state, doctorFileUploadApi: payload };
    case GET_FIXED_DOCUMENTS_API_STATE:
      return { ...state, getFixedDocumentsApi: payload };
    case ADD_GRANT_API_STATE:
      return { ...state, addGrantAPI: payload };
    case ADD_DOCTOR_API_STATE:
      return { ...state, addDoctorAPI: payload };
    case SET_HOSPITALS:
      return { ...state, hospitalsList: payload };
    case GET_HOSPITALS_API_STATE:
      return { ...state, hospitalsListApi: payload };
    case SET_DOCTOR_PROFILES:
      return { ...state, doctorProfiles: payload };
    case GET_SDV_PARTICIPANTS_LIST_API_STATE:
      return { ...state, getSDVParticipantsAPI: payload };
    case SET_SDV_PARTICIPANTS_LIST:
      return { ...state, sdvParticipants: payload };
    case GET_PARTICIPANT_SDV_API_STATE:
      return { ...state, getParticipantSDVAPI: payload };
    case SET_PARTICIPANT_SDV:
      return { ...state, participantSDV: payload };
    case CREATE_PARTICIPANT_API_STATE:
      return { ...state, createParticipantAPI: payload };
    case UPLOAD_SDV_DOCUMENT_API_STATE:
      return { ...state, uploadSDVDocumentAPI: payload };
    default:
      return state;
  }
}
