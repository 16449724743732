import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  ClickAwayListener,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editDoctorProfile } from '../../../../actions/doctorActions';
import { SET_API_SUCCESS } from '../../../../actions/doctorActionType';
import { SET_TOAST_ERROR } from '../../../../actions/toastActionType';
import TButton from '../../../../common/TButton';
import TCircularProgress from '../../../../common/TCircularProgress';
import TDivider from '../../../../common/TDivider';
import TDropZone from '../../../../common/TDropZone';
import { isCompleted, isPending } from '../../../../constant/api_constants';
import { preventSymbolsInNumberField } from '../../../../constant/form_constants';
import { VALIDATION_ERROR } from '../../../../constant/form_validation';
import {
  validateFormFieldWithMessages,
  validateFormWithMessages,
} from '../../../../helpers/validateHelper';
import SitesIcon from '../../../../images/Sites.svg';
import { v1Partial as validator } from '../validators';

const education = {
  college_name: '',
  degree: '',
  graduation_year: '',
  currently_studying: false,
};

const work_experience = {
  name: '',
  city: '',
  position: '',
  from_year: '',
  to_year: '',
  currently_working: false,
};

const newAddNew = {
  education: { ...education },
  work_experience: { ...work_experience },
  valErrors: {
    education: [],
    work_experience: [],
  },
};

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

const TabPanel = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [profile, setProfile] = useState({});
  const [addNew, setAddNew] = useState(newAddNew);
  const [files, setFiles] = useState([]);
  const [valErrorList, setValErrorList] = useState([]);
  const [valErrorMessageList, setValErrorMessageList] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [popper, setPopper] = React.useState({
    open: false,
    anchorEl: null,
  });
  const [newAOI, setNewAOI] = React.useState('');
  const [deleteFile, setDeleteFile] = React.useState(false);

  const handleNewAOIChange = event => {
    setNewAOI(event.target.value);
  };

  const user = useSelector(state => state.userReducer.user);
  const doctorProfile = useSelector(
    state => state.doctorReducer.doctorProfile[0]
  );
  const {
    doctorAreaOfInterest,
    doctorProfileAPI,
    doctorSpecializations,
    editDoctorProfileAPI,
    cities,
    apiSuccess,
  } = useSelector(state => state.doctorReducer);

  const validateAll = async () => {
    let returnVal = false;
    await validateFormWithMessages(validator, profile)
      .then(({ isError, valErrors, errorMessages }) => {
        if (isError) {
          dispatch({
            type: SET_TOAST_ERROR,
            payload: VALIDATION_ERROR,
          });
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
          returnVal = false;
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
          returnVal = true;
        }
      })
      .catch(error => console.error(error));
    return returnVal;
  };

  const validateOnly = (key, innerProfile) => {
    validateFormFieldWithMessages(validator, innerProfile, key).then(
      ({ isError, valErrors, errorMessages }) => {
        if (isError) {
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
        }
      }
    );
  };

  const addAOI = () => {
    if (newAOI !== '' && newAOI.trim() !== '') {
      setProfile(prevProfile => {
        let newProfile = { ...prevProfile };

        if (!prevProfile?.area_of_interest?.includes(newAOI.trim())) {
          newProfile?.area_of_interest?.push(newAOI.trim());
        }

        return newProfile;
      });

      setNewAOI('');
    }
  };

  const deleteAOI = index => {
    setProfile(prevProfile => {
      let newProfile = { ...prevProfile };

      newProfile.area_of_interest = prevProfile.area_of_interest.filter(
        (v, i) => i !== index
      );

      return newProfile;
    });
  };

  const handlePopperClick = event => {
    setPopper({
      anchorEl: event.currentTarget,
      open: !popper.open,
    });
  };

  const handlePopperClickAway = () => {
    setPopper({
      open: false,
    });
  };

  const editValue = (key, value) => {
    let newValue = value;
    if (value === '') {
      newValue = null;
    }
    const newProfile = {
      ...profile,
      [key]: newValue,
    };
    setProfile(newProfile);
    validateOnly(key, newProfile);
  };

  const editInList = (listName, index, key, value) => {
    let newProfile = { ...profile };
    newProfile[listName][index][key] = value;

    if (key === 'currently_working') {
      newProfile[listName][index]['to_year'] = null;
    }

    if (key === 'currently_studying') {
      newProfile[listName][index]['graduation_year'] = null;
    }

    setProfile(newProfile);
    validateOnly(key, newProfile);
  };

  const removeFromList = (listName, index) => {
    let newProfile = { ...profile };
    newProfile[listName] = newProfile[listName]?.filter(
      (item, idx) => index !== idx
    );
    setProfile(newProfile);
  };

  const addNewToList = listName => {
    const newProfile = { ...profile };
    newProfile[listName]?.push({ ...newAddNew[listName] });
    setProfile(newProfile);
  };

  const deleteUploadedFile = () => {
    setDeleteFile(true);
    setProfile(prevProfile => ({
      ...prevProfile,
      resume: null,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isFormValid = await validateAll();
    if (isFormValid) {
      let fileData = {};

      if (files[0] !== undefined) {
        let formData = new FormData();
        formData.append('resume', files[0]);
        fileData = {
          data: formData,
          id: doctorProfile.id,
        };
      } else {
        fileData = {
          fileName: 'resume',
          delete: deleteFile,
          id: doctorProfile?.id,
        };
      }

      const combinedData = {
        profile: profile,
        fileData: fileData,
      };

      dispatch(editDoctorProfile(combinedData));
    }
  };

  useEffect(() => {
    if (apiSuccess && !isPending(editDoctorProfileAPI)) props.setTabValue(1);
    if (isCompleted(editDoctorProfileAPI)) {
      dispatch({ type: SET_API_SUCCESS, payload: false });
    }
  }, [apiSuccess, editDoctorProfileAPI]);

  useEffect(() => {
    let dProfile = { ...doctorProfile };

    if (!dProfile?.education?.length) {
      dProfile?.education?.push({ ...newAddNew.education });
    }

    if (!dProfile?.work_experience?.length) {
      dProfile?.work_experience?.push({ ...newAddNew.work_experience });
    }

    setProfile(dProfile || {});
  }, [doctorProfile]);

  return (
    <div className={classes.formWrapper}>
      {isPending(doctorProfileAPI) ? (
        <TCircularProgress />
      ) : (
        <form>
          <Grid container spacing={5}>
            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Typography
                variant="h6"
                className="c-subheading-text fw-semibold fs-xl mb-5"
              >
                Basic Information
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="site_name"
                    variant="outlined"
                    label="Trial Site Name"
                    value={profile.hospital_name}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={valErrorList.includes(`field_of_study`)}
                    size="small"
                  >
                    <InputLabel>Speciality</InputLabel>
                    <Select
                      name="field_of_study"
                      variant="outlined"
                      fullWidth
                      label="Speciality"
                      value={`${profile.field_of_study}`}
                      onChange={e => {
                        editValue(e.target.name, e.target.value);
                      }}
                      MenuProps={{
                        classes: {
                          paper: 'select-menu',
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" style={{ display: 'none' }}>
                        <em>Select one</em>
                      </MenuItem>
                      {doctorSpecializations.map((spec, indx) => (
                        <MenuItem
                          value={`${spec}`}
                          key={indx}
                          className="select-menu-item"
                        >
                          {spec}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {valErrorList.includes(`field_of_study`)
                        ? valErrorMessageList[`field_of_study`]
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6} style={{ position: 'relative' }}>
                  {profile?.area_of_interest?.length > 0 && (
                    <InputLabel
                      shrink
                      style={{
                        position: 'absolute',
                        top: '6px',
                        left: '25px',
                        backgroundColor: '#ffffff',
                        zIndex: 1,
                        padding: '0 5px',
                      }}
                    >
                      Area of Interest
                    </InputLabel>
                  )}

                  <Button
                    aria-describedby={
                      popper.anchorEl ? 'aoi-popper' : undefined
                    }
                    onClick={handlePopperClick}
                    fullWidth
                    variant="outlined"
                    size="large"
                    style={{
                      minHeight: '38px',
                      padding: '4px 2px',
                      textTransform: 'none',
                    }}
                    disableRipple
                  >
                    <div className="chips-container" style={{ width: '100%' }}>
                      {profile?.area_of_interest?.length ? (
                        <>
                          {profile?.area_of_interest?.map((value, index) => (
                            <Chip
                              key={value}
                              label={value}
                              className="mx-2 my-1"
                              clickable
                              color="primary"
                              onDelete={() => {
                                deleteAOI(index);
                              }}
                            />
                          ))}
                        </>
                      ) : (
                        <InputLabel style={{ marginLeft: '10px' }}>
                          Area of Interest
                        </InputLabel>
                      )}
                    </div>
                  </Button>

                  <FormHelperText>
                    {valErrorList.includes(`area_of_interest`)
                      ? valErrorMessageList[`area_of_interest`]
                      : ''}
                  </FormHelperText>

                  <Popper
                    style={{
                      zIndex: 1,
                    }}
                    id={popper.anchorEl ? 'aoi-popper' : undefined}
                    open={popper.open}
                    anchorEl={popper.anchorEl}
                  >
                    <ClickAwayListener onClickAway={handlePopperClickAway}>
                      <Box>
                        <Card className="p-3" elevation={5}>
                          <IconButton
                            onClick={() => {
                              handlePopperClickAway();
                            }}
                            size="small"
                            style={{ float: 'right' }}
                          >
                            <Close />
                          </IconButton>
                          <Typography variant="body1">
                            Add Area of Interest
                          </Typography>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              addAOI();
                            }}
                            style={{ display: 'flex' }}
                          >
                            <TextField
                              size="small"
                              variant="outlined"
                              helperText="Click on Add (or press enter) to add area of interest. Example: Covid-19, Breast Cancer, etc."
                              value={newAOI}
                              id="newAreaOfInterest"
                              onChange={handleNewAOIChange}
                              autoFocus
                            />
                            <TButton
                              size="small"
                              color="primary"
                              variant="contained"
                              className="p-4"
                              style={{ marginLeft: '10px' }}
                              onClick={addAOI}
                            >
                              Add
                            </TButton>
                          </form>
                        </Card>
                      </Box>
                    </ClickAwayListener>
                  </Popper>
                </Grid>

                <Grid item container spacing={5}>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      name="linkedin_profile"
                      variant="outlined"
                      label="LinkedIn Profile Link"
                      value={
                        profile.linkedin_profile ? profile.linkedin_profile : ''
                      }
                      onChange={e => {
                        editValue(e.target.name, e.target.value);
                      }}
                      fullWidth
                      error={valErrorList.includes(`linkedin_profile`)}
                      helperText={
                        valErrorList.includes(`linkedin_profile`)
                          ? valErrorMessageList[`linkedin_profile`]
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      name="researchgate_profile"
                      variant="outlined"
                      label="ResearchGate Profile Link"
                      value={
                        profile.researchgate_profile
                          ? profile.researchgate_profile
                          : ''
                      }
                      onChange={e => {
                        editValue(e.target.name, e.target.value);
                      }}
                      fullWidth
                      error={valErrorList.includes(`researchgate_profile`)}
                      helperText={
                        valErrorList.includes(`researchgate_profile`)
                          ? valErrorMessageList[`researchgate_profile`]
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      name="twitter_profile"
                      variant="outlined"
                      label="Twitter Profile Link"
                      value={
                        profile.twitter_profile ? profile.twitter_profile : ''
                      }
                      onChange={e => {
                        editValue(e.target.name, e.target.value);
                      }}
                      fullWidth
                      error={valErrorList.includes(`twitter_profile`)}
                      helperText={
                        valErrorList.includes(`twitter_profile`)
                          ? valErrorMessageList[`twitter_profile`]
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Grid item xs={12}>
              <TDivider />
            </Grid>

            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Typography
                variant="h5"
                className="c-subheading-text fs-xl fw-semibold mb-5"
              >
                Education
              </Typography>

              {profile.education?.length === 0 ? (
                <TButton
                  variant="contained"
                  color="primary"
                  style={{ float: 'right' }}
                  onClick={() => {
                    addNewToList('education');
                  }}
                >
                  Add Education
                </TButton>
              ) : null}

              {profile.education?.map((ed, idx) => (
                <>
                  <Grid container spacing={3} key={idx}>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        name="college_name"
                        variant="outlined"
                        label="Name of College"
                        value={ed.college_name}
                        onChange={e => {
                          editInList(
                            'education',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        fullWidth
                        required
                        error={valErrorList.includes(
                          `education[${idx}].college_name`
                        )}
                        helperText={
                          valErrorList.includes(
                            `education[${idx}].college_name`
                          )
                            ? valErrorMessageList[
                                `education[${idx}].college_name`
                              ]
                            : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        name="degree"
                        value={ed.degree}
                        onChange={e => {
                          editInList(
                            'education',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        label="Degree"
                        required
                        fullWidth
                        error={valErrorList.includes(
                          `education[${idx}].degree`
                        )}
                        helperText={
                          valErrorList.includes(`education[${idx}].degree`)
                            ? valErrorMessageList[`education[${idx}].degree`]
                            : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        name="graduation_year"
                        value={
                          ed.currently_studying ? 'Ongoing' : ed.graduation_year
                        }
                        onChange={e => {
                          editInList(
                            'education',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        label="Year of Graduation"
                        required
                        fullWidth
                        type={ed.currently_studying ? 'string' : 'number'}
                        error={valErrorList.includes(
                          `education[${idx}].graduation_year`
                        )}
                        helperText={
                          valErrorList.includes(
                            `education[${idx}].graduation_year`
                          )
                            ? valErrorMessageList[
                                `education[${idx}].graduation_year`
                              ]
                            : ''
                        }
                        onWheel={event => {
                          event.target.blur();
                        }}
                        disabled={Boolean(ed.currently_studying)}
                        InputLabelProps={{
                          shrink:
                            ed.currently_studying ||
                            ed.graduation_year !== null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} align="right">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(ed.currently_studying)}
                            onChange={e => {
                              editInList(
                                'education',
                                idx,
                                'currently_studying',
                                e.target.checked
                              );
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="Currently studying here"
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <TButton
                      variant="outlined"
                      color="secondary"
                      className="mx-5 mt-5"
                      onClick={() => {
                        removeFromList('education', idx);
                      }}
                      disabled={profile?.education?.length < 2}
                    >
                      Remove
                    </TButton>

                    {idx === profile.education.length - 1 ? (
                      <TButton
                        variant="contained"
                        color="primary"
                        className="mx-5 mt-5"
                        style={{ float: 'right' }}
                        onClick={() => {
                          addNewToList('education');
                        }}
                      >
                        Add Education
                      </TButton>
                    ) : null}
                  </Box>

                  <Grid item xs={12} className="my-5">
                    <TDivider />
                  </Grid>
                </>
              ))}
            </Box>

            <Grid item xs={12}>
              <TDivider />
            </Grid>

            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Typography
                variant="h5"
                className="c-subheading-text fw-semibold fs-xl mb-5"
              >
                Work Experience
              </Typography>
              {profile.work_experience?.length === 0 ? (
                <TButton
                  variant="contained"
                  color="primary"
                  style={{ float: 'right' }}
                  onClick={() => {
                    addNewToList('work_experience');
                  }}
                >
                  Add Work Exp.
                </TButton>
              ) : null}

              {profile.work_experience?.map((wx, idx) => (
                <>
                  <Grid container spacing={3} key={idx}>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        name="name"
                        value={wx.name}
                        onChange={e => {
                          editInList(
                            'work_experience',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        label="Hospital/Institution Name"
                        fullWidth
                        required
                        error={valErrorList.includes(
                          `work_experience[${idx}].name`
                        )}
                        helperText={
                          valErrorList.includes(`work_experience[${idx}].name`)
                            ? valErrorMessageList[
                                `work_experience[${idx}].name`
                              ]
                            : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {true ? (
                        <TextField
                          size="small"
                          name="city"
                          value={wx.city}
                          onChange={e => {
                            editInList(
                              'work_experience',
                              idx,
                              e.target.name,
                              e.target.value
                            );
                          }}
                          variant="outlined"
                          label="City"
                          fullWidth
                          required
                          error={valErrorList.includes(
                            `work_experience[${idx}].city`
                          )}
                          helperText={
                            valErrorList.includes(
                              `work_experience[${idx}].city`
                            )
                              ? valErrorMessageList[
                                  `work_experience[${idx}].city`
                                ]
                              : ''
                          }
                        />
                      ) : (
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={valErrorList.includes(
                            `work_experience[${idx}].city`
                          )}
                          size="small"
                        >
                          <InputLabel>City</InputLabel>
                          <Select
                            name="city"
                            variant="outlined"
                            fullWidth
                            label="City"
                            value={wx.city}
                            onChange={e => {
                              editInList(
                                'work_experience',
                                idx,
                                e.target.name,
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>Select one</em>
                            </MenuItem>
                            {cities.map(city => (
                              <MenuItem value={`${city}`} key={city}>
                                {city}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {valErrorList.includes(
                              `work_experience[${idx}].city`
                            )
                              ? valErrorMessageList[
                                  `work_experience[${idx}].city`
                                ]
                              : ''}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        name="position"
                        value={wx.position}
                        onChange={e => {
                          editInList(
                            'work_experience',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        label="Position"
                        fullWidth
                        required
                        error={valErrorList.includes(
                          `work_experience[${idx}].position`
                        )}
                        helperText={
                          valErrorList.includes(
                            `work_experience[${idx}].position`
                          )
                            ? valErrorMessageList[
                                `work_experience[${idx}].position`
                              ]
                            : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        name="from_year"
                        value={wx.from_year}
                        onChange={e => {
                          editInList(
                            'work_experience',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        label="From"
                        required
                        fullWidth
                        type="number"
                        error={valErrorList.includes(
                          `work_experience[${idx}].from_year`
                        )}
                        helperText={
                          valErrorList.includes(
                            `work_experience[${idx}].from_year`
                          )
                            ? valErrorMessageList[
                                `work_experience[${idx}].from_year`
                              ]
                            : ''
                        }
                        onKeyDown={e =>
                          preventSymbolsInNumberField.includes(e.key) &&
                          e.preventDefault()
                        }
                        onWheel={event => {
                          event.target.blur();
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        name="to_year"
                        value={wx.currently_working ? 'Ongoing' : wx.to_year}
                        onChange={e => {
                          editInList(
                            'work_experience',
                            idx,
                            e.target.name,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        label="To"
                        required
                        fullWidth
                        type={wx.currently_working ? 'string' : 'number'}
                        error={valErrorList.includes(
                          `work_experience[${idx}].to_year`
                        )}
                        helperText={
                          valErrorList.includes(
                            `work_experience[${idx}].to_year`
                          )
                            ? valErrorMessageList[
                                `work_experience[${idx}].to_year`
                              ]
                            : ''
                        }
                        onWheel={event => {
                          event.target.blur();
                        }}
                        disabled={Boolean(wx.currently_working)}
                        InputLabelProps={{
                          shrink: wx.currently_working || wx.to_year !== null,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} align="right">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(wx.currently_working)}
                            onChange={e => {
                              editInList(
                                'work_experience',
                                idx,
                                'currently_working',
                                e.target.checked
                              );
                            }}
                          />
                        }
                        label="Currently working here"
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <TButton
                      variant="outlined"
                      color="secondary"
                      className="mx-5 mt-5"
                      onClick={() => {
                        removeFromList('work_experience', idx);
                      }}
                      disabled={profile?.work_experience?.length < 2}
                    >
                      Remove
                    </TButton>
                    {profile.work_experience?.length - 1 === idx ? (
                      <TButton
                        variant="contained"
                        color="primary"
                        style={{ float: 'right' }}
                        className="mx-5 mt-5"
                        onClick={() => {
                          addNewToList('work_experience');
                        }}
                      >
                        Add Work Exp.
                      </TButton>
                    ) : null}
                  </Box>

                  <Grid item xs={12} className="my-5">
                    <TDivider />
                  </Grid>
                </>
              ))}
            </Box>

            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Typography
                variant="h5"
                className="c-subheading-text fw-semibold fs-xl mb-5"
              >
                Resume
              </Typography>

              <Grid container spacing={12}>
                {profile?.resume?.length ? (
                  <Paper className="p-2 custom-box-small w-100">
                    <Grid
                      item
                      container
                      spacing={2}
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item className="mw-3">
                        <img src={SitesIcon} />
                      </Grid>
                      <Grid item xs>
                        <a
                          href={profile?.resume}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Grid item className="fw-semibold">
                            {profile?.resume_name}
                          </Grid>
                        </a>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={deleteUploadedFile}>
                          <Delete color="action" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <TDropZone
                    setDocumentObj={() => {}}
                    setDocument={setFiles}
                    documentObj={{}}
                  />
                )}
              </Grid>
            </Box>

            <Grid item xs={12}>
              <Typography className="fs-xxl c-disabled-text">
                In order to proceed your onboarding, you need to provide the
                following information. Please note that all fields marked with
                an asterisk (*) are required
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Container maxWidth="lg">
                <Box display="flex" flexDirection="row-reverse">
                  <Box>
                    <TButton
                      variant="contained"
                      color="primary"
                      disabled={isPending(editDoctorProfileAPI)}
                      isLoading={isPending(editDoctorProfileAPI)}
                      onClick={e => {
                        onSubmit(e);
                      }}
                    >
                      Save & Continue
                    </TButton>
                  </Box>
                  <Box pr={2}>
                    <TButton
                      variant="outlined"
                      disabled={isPending(editDoctorProfileAPI)}
                      onClick={() => {
                        props.toggleView();
                      }}
                    >
                      Cancel
                    </TButton>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default TabPanel;
