import './styles.css';

import { Button, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const StyledMenu = withStyles({
  paper: {
    border: '2px solid #c7e6ff',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px #00000021',
    top: '66px !important',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    height: '3rem',
    minWidth: '10rem',
    '& .MuiListItemText-primary': {
      color: '#000000B3',
      fontSize: '1rem',
    },
    '& .active-menu-item': {
      color: '#000000',
      '& .MuiListItemText-primary': {
        color: '#000000',
        fontWeight: '600',
      },
    },
  },
}))(MenuItem);

// TO DO: Make use of a single component (TMenu)
// instead of two separate components for menu.

// The following component is not to be imported.
// Just for demonstration purposes.
const TMenu = props => {
  const { children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="t-menu">
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
      </Button>
      <StyledMenu
        id="customized-menu"
        className="t-menu-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem className="active-menu-item">
          <ListItemText primary="Sent mail" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="Drafts" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="Inbox" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default TMenu;
export { StyledMenu, StyledMenuItem };
