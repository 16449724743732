import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { CancelOutlined, CheckCircleOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const PasswordValidator = ({ password }) => {
  const [errors, setErrors] = useState({
    lengthError: { value: true, text: 'Must contain at least 9 characters' },
    lowercaseError: {
      value: true,
      text: 'Must contain at least 1 lower case letter',
    },
    uppercaseError: {
      value: true,
      text: 'Must contain at least 1 upper case letter',
    },
    numberError: {
      value: true,
      text: 'Must contain at least 1 number',
    },
    specialError: {
      value: true,
      text: 'Must contain at least 1 special character',
    },
  });
  useEffect(() => {
    setErrors(prevErrors => ({
      lengthError: {
        ...prevErrors.lengthError,
        value: !(password.length >= 9),
      },
      lowercaseError: {
        ...prevErrors.lowercaseError,
        value: !/[a-z]/.test(password),
      },
      uppercaseError: {
        ...prevErrors.uppercaseError,
        value: !/[A-Z]/.test(password),
      },
      numberError: {
        ...prevErrors.numberError,
        value: !/\d/.test(password),
      },
      specialError: {
        ...prevErrors.specialError,
        value: !/[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/.test(password),
      },
    }));
  }, [password]);
  return (
    <List className="p-0">
      {password.length > 0 ? (
        Object.keys(errors).map((error, index) => (
          <ListItem key={index} className="py-0">
            <ListItemIcon style={{ minWidth: 'unset' }} className="pe-1">
              {errors[error].value ? (
                <CancelOutlined className="fs-s text-danger" />
              ) : (
                <CheckCircleOutlined className="fs-s c-blue" />
              )}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: clsx('fs-s', {
                  ['text-danger']: errors[error].value,
                  ['c-blue']: !errors[error].value,
                }),
              }}
            >
              {errors[error].text}
            </ListItemText>
          </ListItem>
        ))
      ) : (
        <ListItem className="py-0">
          <ListItemText
            primaryTypographyProps={{
              className: 'fs-s c-blue',
            }}
          >
            {
              'Use 9 or more characters with a mix of letters, numbers & symbols'
            }
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default PasswordValidator;
