import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setTabIndex } from '../actions/siteIdentificationActions';
import { toggleSideBarMenu } from '../actions/utilsActions';
import getSidebarNavItems from '../data/sidebar-nav-items';

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(10),
    justifyContent: 'flex-end',
  },
  paper: {
    top: theme.spacing(10),
    height: `calc(100% - ${theme.spacing(2)}px)`,
    overflowX: 'hidden',
    transition: 'all 250ms cubic-bezier(.4,0,0,1) 0ms',
    width: theme.spacing(10),
  },
  drawer: {
    width: '15%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: '15%',
    border: '0',
  },
  list: { padding: theme.spacing(2, 0) },
  listHeader: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  listItem: {
    minWidth: theme.spacing(8),
    margin: '4px 0',
    height: theme.spacing(7),
    padding: theme.spacing(1.5),
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    whiteSpace: 'normal',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
    },
    borderLeft: `4px solid white`,
    color: theme.palette.secondary.main,
    fontWeight: '500 !important',
    fontSize: '1rem !important',
  },
  listItemSelected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(5),
    marginRight: theme.spacing(2),
    transform: 'scale(0.75)',
    color: theme.palette.secondary.main,
  },
  listItemIconSelected: {
    color: theme.palette.primary.main,
  },
  listItemText: {
    minWidth: theme.spacing(20),
  },
}));

const TSideNav = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );
  const currentPath = useSelector(state => state.router.location.pathname);
  const [open, setOpen] = useState(true);
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    async function getNavItems() {
      setNavItems(await getSidebarNavItems(viewPermissions));
    }
    getNavItems();
  }, []);

  const isSelectedNavItem = navItem => {
    const basePath = currentPath.split('/')[1];
    return navItem.navigation_name === basePath;
  };

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: clsx(classes.paper, { [classes.drawerOpen]: open }),
      }}
    >
      <List component="nav" classes={{ root: classes.list }}>
        {navItems.map((navItem, index) => (
          <ListItem
            key={index}
            className={clsx(classes.listItem, {
              [classes.listItemSelected]: isSelectedNavItem(navItem),
            })}
            onClick={() => dispatch(setTabIndex(0))}
            component={Link}
            to={navItem.resource_path}
          >
            <ListItemIcon
              className={clsx(classes.listItemIcon, {
                [classes.listItemIconSelected]: isSelectedNavItem(navItem),
              })}
            >
              {navItem.icon}
            </ListItemIcon>
            <ListItemText
              disableTypography
              className={classes.listItemText}
              primary={
                <Typography className="fw-medium" variant="body1">
                  {navItem.resource_name}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default TSideNav;
