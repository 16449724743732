import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

const SiteDetails = () => {
  const site = useSelector(state => state.siteInitiationReducer.site);

  return (
    <Paper elevation={0} className="p-4 custom-box h-100">
      <Grid container spacing={2} className="h-100">
        <Grid item>
          <Typography variant="h6" className="fw-semibold fs-xl">
            Site Details
          </Typography>
        </Grid>
        <Grid item xs={12} className="mx-3">
          <Typography>
            <strong>Name of doctor:</strong>{' '}
            {site?.doctor?.first_name + ' ' + site?.doctor?.last_name}
          </Typography>
        </Grid>
        <Grid item xs={12} className="mx-3">
          <Typography>
            <strong>Name of site:</strong> {site?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} className="mx-3">
          <Typography>
            <strong>Address:</strong> {site?.hospital?.address}
          </Typography>
        </Grid>
        <Grid item xs={12} className="mx-3">
          <Typography>
            <strong>Contact:</strong> {site?.doctor?.phone}
          </Typography>
        </Grid>
        <Grid item xs={12} className="mx-3">
          <Typography>
            <strong>Email ID:</strong> {site?.doctor?.email}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SiteDetails;
