import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import SignUp from '../../components/Auth/SignUp';
import SignUpImg from '../../images/SignUpImg.png';

const SignUpView = () => {
  return (
    <Grid container className="p-5" direction="row">
      <Grid item xs={6} container justify="center">
        <Grid item xs={7} className="mt-5">
          <Typography variant="h6" className="fw-medium text-center text-black">
            Join our growing network of physicians to conduct upcoming clinical
            trials in your therapeutic speciality
          </Typography>
        </Grid>
        <Grid item>
          <img src={SignUpImg} style={{ height: '500px', width: 'auto' }} />
        </Grid>
      </Grid>
      <Grid item xs={6} container justify="center">
        <Grid item>
          <SignUp />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpView;
