import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVisitDetails } from '../../actions/trialActions';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';
import {
  SITE_VISIT_STATUS,
  SITE_VISIT_TYPE,
} from '../../constant/site_constants';
import { formatDate } from '../../helpers/utils';
import EmptyStateDoctorImg from '../../images/Empty_State_Doctors_Dashboard.png';

const DetailTemplate = ({ detail, value }) => {
  return (
    <Grid item container xs={12}>
      <Grid item xs={2}>
        <Typography className="fw-semibold">{detail}:</Typography>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const SiteVisit = ({ selectedTrialId, selectedTrialSiteId }) => {
  const { visitDetails, getVisitDetailsAPI } = useSelector(
    state => state.trialReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTrialId && selectedTrialSiteId) {
      dispatch(
        getVisitDetails({
          trialId: selectedTrialId,
          trialSiteId: selectedTrialSiteId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (selectedTrialId && selectedTrialSiteId) {
      dispatch(
        getVisitDetails({
          trialId: selectedTrialId,
          trialSiteId: selectedTrialSiteId,
        })
      );
    }
  }, [selectedTrialId, selectedTrialSiteId]);

  return (
    <>
      {isPending(getVisitDetailsAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          {visitDetails?.length ? (
            <Grid container spacing={4}>
              {visitDetails.map(visit => {
                return (
                  <Grid item key={visit.id} xs={12}>
                    <Paper elevation={0} className="p-4 custom-box">
                      <Typography variant="h6">
                        {SITE_VISIT_TYPE[visit.type]} Visit
                      </Typography>
                      <Grid container className="m-1" spacing={2}>
                        <DetailTemplate
                          detail="CRA Name"
                          value={visit.cra_name}
                        />
                        <DetailTemplate
                          detail="Email Id"
                          value={visit.cra_email}
                        />
                        <DetailTemplate
                          detail="Contact"
                          value={visit.cra_contact}
                        />
                        <DetailTemplate
                          detail="Visit Status"
                          value={
                            visit.status === 2
                              ? `${
                                  SITE_VISIT_STATUS[visit.status]
                                } on ${formatDate(visit.scheduled_on)}`
                              : SITE_VISIT_STATUS[visit.status]
                          }
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '100px' }}
                    src={EmptyStateDoctorImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  There are no visits scheduled for this site at the moment.
                  Please check back later.
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default SiteVisit;
