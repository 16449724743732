import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput as Input,
} from '@material-ui/core';
import React from 'react';

import { preventSymbolsInNumberField } from '../../../../constant/form_constants';
import useStyles from './classes';

const TNumberField = props => {
  const classes = useStyles();

  const { label, key } = props.field;
  const { value, type } = props;

  const handleChange = event => {
    const newValue = event.target.value;
    props.handleChange(key, newValue);
  };

  return (
    <FormControl
      className={`${classes.field} ${
        props?.field?.inline ? classes.inlineField : null
      }`}
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
    >
      <FormLabel
        htmlFor={key}
        error={props.error}
        style={props.dataGrid ? { display: 'none' } : null}
        className={`${classes.label}${
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
            ? ' required-field'
            : ''
        } `}
      >
        {label}
      </FormLabel>
      <div>
        <Input
          id={key}
          key={key}
          name={key}
          fullWidth
          type="number"
          onChange={handleChange}
          value={value}
          variant="outlined"
          placeholder="Your answer"
          error={props.error}
          className={props?.field?.inline ? classes.inlineInput : null}
          onWheel={e => {
            e.target.blur();
          }}
          onKeyDown={e =>
            preventSymbolsInNumberField.includes(e.key) && e.preventDefault()
          }
        />
        <FormHelperText error={props.error}>
          {props.errorMessage}
        </FormHelperText>
      </div>
    </FormControl>
  );
};

export default TNumberField;
