import { Grid, TextField, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import * as yup from 'yup';

import {
  sendOTPResetPassword,
  setUserAPISuccess,
} from '../../actions/userActions';
import TButton from '../../common/TButton';
import TDivider from '../../common/TDivider';
import TPhoneInput from '../../common/TPhoneInput';
import { isPending } from '../../constant/api_constants';
import { ERROR_MESSAGE } from '../../constant/auth_constants';
import { DEFAULT_COUNTRY_CODE } from '../../constant/country_codes';
import { validateForm } from '../../helpers/validateHelper';
import AuthCard from './AuthCard';

const ForgotPassword = props => {
  const { verificationMethod } = props.match.params;
  const isPhone = verificationMethod => verificationMethod === 'phone';
  const resetMethod = isPhone(verificationMethod)
    ? verificationMethod
    : 'email';

  const dispatch = useDispatch();

  const APISuccess = useSelector(state => state.userReducer.apiSuccess);
  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const sendOTPAPIState = useSelector(
    state => state.userReducer.sendOTPResetPasswordAPI
  );
  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );

  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    countryCode: DEFAULT_COUNTRY_CODE,
  });
  const [valErrorList, setValErrorList] = useState([]);
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [sendOTPRequest, setSendOTPRequest] = useState(false);

  const schema = {
    phone: yup.object().shape({
      phone: yup
        .string()
        .required()
        .matches(/^([1-9]\d{9})$/g),
    }),
    email: yup.object().shape({
      email: yup.string().email().required(),
    }),
  };

  useEffect(() => {
    if (APISuccess && sendOTPRequest) {
      dispatch(setUserAPISuccess(null));
      setSendOTPRequest(false);
      dispatch(push(`/forgot-password/${resetMethod}/verify`, formData));
    }
  }, [APISuccess, sendOTPRequest, formData]);

  const handleChange = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleOTPSend = () => {
    let data = { [resetMethod]: formData[resetMethod] };
    validateForm(schema[resetMethod], data).then(({ isError, valErrors }) => {
      if (isError) setValErrorList(valErrors);
      else {
        setValErrorList([]);
        if (isPhone(resetMethod))
          data = {
            phone: `${countryCode.dial_code}${formData.phone}`,
          };
        dispatch(sendOTPResetPassword(data));
        setSendOTPRequest(true);
      }
    });
  };

  const changeVerificationMethod = () => {
    dispatch(
      push(
        `/forgot-password/${isPhone(verificationMethod) ? 'email' : 'phone'}`
      )
    );
  };

  if (isAuthenticated) {
    return <Redirect to={viewPermissions[0].resource_path} />;
  }

  return (
    <AuthCard>
      <Grid container spacing={4}>
        <Grid item container spacing={3}>
          <>
            <Grid item xs={12}>
              <Typography className="fs-xl fw-semibold mb-3">
                {`Please enter your ${
                  isPhone(verificationMethod) ? 'phone number' : 'email address'
                } and we will send you One Time
              Password (OTP)`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isPhone(verificationMethod) ? (
                <TPhoneInput
                  label="Phone Number"
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                  value={formData.phone}
                  onChange={handleChange('phone')}
                  countryCodeValue={countryCode}
                  onCountryCodeChange={setCountryCode}
                  error={valErrorList.indexOf('phone') > -1}
                  helperText={
                    valErrorList.indexOf('phone') > -1
                      ? ERROR_MESSAGE.PHONE_ERROR_MESSAGE
                      : ''
                  }
                />
              ) : (
                <TextField
                  size="small"
                  label="Email"
                  value={formData.email}
                  onChange={handleChange('email')}
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                  error={valErrorList.indexOf('email') > -1}
                  helperText={
                    valErrorList.indexOf('email') > -1
                      ? ERROR_MESSAGE.EMAIL_ERROR_MESSAGE
                      : ''
                  }
                />
              )}
            </Grid>
          </>
          <Grid item xs={12} className="mt-2">
            <TButton
              onClick={handleOTPSend}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              isLoading={isPending(sendOTPAPIState)}
            >
              <Typography className="fs-l fw-semibold">Send</Typography>
            </TButton>
          </Grid>
          <Grid item xs={12}>
            <TDivider className="p-4">
              <span className="px-2 fs-m text-muted">OR</span>
            </TDivider>
          </Grid>
          <Grid item xs={12} className="mb-4">
            <TButton
              variant="outlined"
              size="large"
              fullWidth
              onClick={changeVerificationMethod}
            >
              <Typography className="fs-l fw-semibold fc-black-300">
                {`Continue with ${
                  isPhone(verificationMethod) ? 'E-mail' : 'Phone number'
                }`}
              </Typography>
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default ForgotPassword;
