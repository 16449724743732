import { VALIDATION_MESSAGES } from '../constant/form_validation';
import { isComponentDisplayed } from './formUtil';

const Validators = Object.freeze({
  number: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if ((value !== null || value !== '') && isNaN(value)) {
      validity = {
        isValid: false,
        error: VALIDATION_MESSAGES.NUMBER_ERR,
      };

      return validity;
    }

    if (validate) {
      const maxLength = validate.maxLength;
      const minLength = validate.minLength;
      const length = validate.length;
      const max = validate.max;
      const min = validate.min;
      const decimalLimit =
        component?.decimalLimit || component?.extra?.decimalLimit || null;

      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === null || value === '')) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }

        if (value !== null && !isNaN(length)) {
          if (value.toString() !== '' && value.toString().length !== length) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.LENGTH_ERR(length),
            };

            return validity;
          }
        }

        if (value !== null && !isNaN(maxLength)) {
          if (value.toString() !== '' && value.toString().length > maxLength) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MAX_LENGTH_ERR(maxLength),
            };

            return validity;
          }
        }

        if (value !== null && !isNaN(minLength)) {
          if (value.toString() !== '' && value.toString().length < minLength) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MIN_LENGTH_ERR(minLength),
            };

            return validity;
          }
        }

        if (value !== null && !isNaN(max)) {
          if (value.toString() !== '' && value > max) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MAX_VAL_ERR(max),
            };

            return validity;
          }
        }

        if (value !== null && !isNaN(min)) {
          if (value.toString() !== '' && value < min) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MIN_VAL_ERR(min),
            };

            return validity;
          }
        }

        if (value !== null && !isNaN(decimalLimit) && decimalLimit !== null) {
          let decimalPlaces = 0;
          if (Math.floor(value) !== value) {
            decimalPlaces = value.toString().split('.')[1]?.length || 0;
          }
          if (decimalPlaces !== decimalLimit) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.DECIMAL_ERR(decimalLimit),
            };

            return validity;
          }
        }
      }
    }

    return validity;
  },

  textfield: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      const maxLength = validate.maxLength;
      const minLength = validate.minLength;

      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === '' || value === null)) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }

        if (!isNaN(maxLength)) {
          if (value.toString() !== '' && value.length > maxLength) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MAX_LENGTH_ERR(maxLength),
            };

            return validity;
          }
        }

        if (!isNaN(minLength)) {
          if (value.toString() !== '' && value.length < minLength) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MIN_LENGTH_ERR(minLength),
            };

            return validity;
          }
        }
      }
    }

    return validity;
  },

  textarea: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      const maxLength = validate.maxLength;
      const minLength = validate.minLength;
      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === '' || value === null)) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }

        if (value.toString() !== '' && !isNaN(maxLength)) {
          if (value.length > maxLength) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MAX_LENGTH_ERR(maxLength),
            };

            return validity;
          }
        }

        if (value.toString() !== '' && !isNaN(minLength)) {
          if (value.length < minLength) {
            validity = {
              isValid: false,
              error: VALIDATION_MESSAGES.MIN_LENGTH_ERR(minLength),
            };

            return validity;
          }
        }
      }
    }

    return validity;
  },

  select: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === '' || value === null)) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }
      }
    }

    return validity;
  },

  checkbox: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === null || value === '')) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }
      }
    }

    return validity;
  },

  radio: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === null || value === '')) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };
        }
      }
    }

    return validity;
  },

  email: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && (value === null || value === '')) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }
      }
    }

    if (
      value.length &&
      !/^[a-zA-Z0-9._~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
    ) {
      validity = {
        isValid: false,
        error: VALIDATION_MESSAGES.EMAIL_ERR,
      };

      return validity;
    }

    return validity;
  },

  phoneNumber: (value, component, formData) => {
    let validity = { isValid: true, error: '' };
    const validate = component?.validate || component?.extra?.validate || null;
    const conditional =
      component?.conditional || component?.extra?.conditional || null;
    const required = validate?.required;

    if (validate) {
      if (
        conditional === null ||
        (conditional &&
          isComponentDisplayed({
            component: component,
            formData: formData,
          }))
      ) {
        if (required && value.length) {
          validity = {
            isValid: false,
            error: VALIDATION_MESSAGES.REQUIRED_ERR,
          };

          return validity;
        }
      }
    }

    return validity;
  },
});

const validateDataGrid = ({ dgKey, components, data }) => {
  let validity = true;
  let _components = [...components];
  const dgRows = data[dgKey];

  dgRows.forEach((dgRow, index) => {
    components.forEach((component, cIndex) => {
      if (!_components[cIndex]['errorRows']) {
        _components[cIndex]['errorRows'] = [];
      }

      const type = component.type;
      const key = component.key;
      const value = data[dgKey][index][key];

      // eslint-disable-next-line no-prototype-builtins
      if (Validators.hasOwnProperty(type)) {
        const { isValid, error } = Validators[type](value, component, data);

        if (!isValid) {
          validity = false;
        }

        _components[cIndex]['error'] = !isValid;
        _components[cIndex]['errorMessage'] = error;
        !isValid && _components[cIndex]['errorRows'].push(index);
      }
    });
  });

  return { validity, _components };
};

const validateTable = ({ schema, data }) => {
  let validity = true;
  let _schema = [...schema];

  schema?.forEach((row, rowIndex) => {
    row.forEach((cell, columnIndex) => {
      const component = cell;
      const type = component.type;
      const key = component.key;
      const value = data[key];

      // eslint-disable-next-line no-prototype-builtins
      if (Validators.hasOwnProperty(type)) {
        const { isValid, error } = Validators[type](value, component, data);

        if (!isValid) {
          validity = false;
        }

        _schema[rowIndex][columnIndex]['error'] = !isValid;
        _schema[rowIndex][columnIndex]['errorMessage'] = error;
      }
    });
  });

  return { validity, _schema };
};

/**
 * Validator for form.
 * @param components The form section components.
 * @param data The form section data.
 **/
export const validateForm = (components, data) => {
  let _components = JSON.parse(JSON.stringify(components));
  let validity = true;
  let validityList = [];

  components.forEach((component, index) => {
    const type = component.type;
    const key = component.key;

    if (type === 'table') {
      const { validity: isValid, _schema } = validateTable({
        schema: component.schema,
        data: data,
      });

      if (!isValid) {
        validityList.push(key);
        validity = false;
      }

      _components[index]['schema'] = _schema;
    } else if (type === 'datagrid') {
      const { validity: isValid, _components: _component } = validateDataGrid({
        dgKey: key,
        components: component.components,
        data: data,
      });

      if (!isValid) {
        validityList.push(key);
        validity = false;
      }

      _components[index]['components'] = _component;
    } else {
      const value = data[key];

      // eslint-disable-next-line no-prototype-builtins
      if (Validators.hasOwnProperty(type)) {
        const { isValid, error } = Validators[type](value, component, data);

        if (!isValid) {
          validityList.push(key);
          validity = false;
        }

        _components[index]['error'] = !isValid;
        _components[index]['errorMessage'] = error;
      }
    }
  });

  return {
    valid: validity,
    validityList: validityList,
    components: _components,
  };
};
