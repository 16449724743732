import { SPONSOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';

export const getTrialAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data}/`;
  return apiClient.get(apiUrl);
};

export const getTrialStatsAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/trialsite/stats/${data}/`;
  return apiClient.get(apiUrl);
};

export const getAllTrialsAPI = async () => {
  const apiUrl = `${SPONSOR_APIS.trial}/`;
  return apiClient.get(apiUrl);
};

export const updateTrialSiteStatusAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trialSite}/${data.id}/`;
  return apiClient.patch(apiUrl, data);
};

export const getVisitDetailsAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data.trialId}/trialsite/${data.trialSiteId}/visit/`;
  return apiClient.get(apiUrl);
};
