import { ACTION_NAME } from './action_constants';
import { CHUNKS } from './resource_constants';
import { ADMIN, CRA, CRC, DOCTOR, SPONSOR } from './roles_constants';

export default {
  [ADMIN]: [
    {
      ...CHUNKS.ADMIN_TRIAL_DASHBOARD,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.ADMIN_SITES_DASHBOARD,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.ADMIN_USERS_DASHBOARD,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
  ],
  [CRA]: [
    {
      ...CHUNKS.CRA_QUALIFICATION,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.CRA_INITIATION,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.CRA_INITIATION_DOCUMENTS,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.CRA_INITIATION_SITES,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.CRA_SDV,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.CRA_SDV_TRIAL_SITE_PARTICIPANTS,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
  ],
  [DOCTOR]: [
    {
      ...CHUNKS.DOCTOR_PROFILE,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.DOCTOR_UPCOMING_TRIALS,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.DOCTOR_SDVS,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
  ],
  [CRC]: [
    {
      ...CHUNKS.DOCTOR_PROFILE,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.DOCTOR_UPCOMING_TRIALS,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.DOCTOR_SDVS,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
  ],
  [SPONSOR]: [
    {
      ...CHUNKS.DM_DASHBOARD,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.FEASIBILITY,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.SITE_IDENTIFICATION,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.SITE_INITIATION,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
    {
      ...CHUNKS.SITE_QUALIFICATION,
      permission_type: [
        {
          permission: ACTION_NAME.VIEW,
          permission_value: true,
        },
      ],
    },
  ],
};
