import {
  CHANGE_RSDV_STATUS_API_STATE,
  CREATE_RSDV_DOCUMENT_REQUEST_API_STATE,
  GET_CRA_INITIATION_PANEL_API_STATE,
  GET_CRA_QUALIFICATION_PANEL_API_STATE,
  GET_CRA_SDV_SITES_API_STATE,
  GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE,
  GET_RSDV_DOCUMENT_API_STATE,
  GET_TRIAL_SITE_PARTICIPANTS_API_STATE,
  PATCH_VISIT_API_STATE,
  SET_CRA_PANEL,
  SET_CRA_SDV_SITES,
  SET_PARTICIPANT_RSDV_DOCUMENTS,
  SET_RSDV_DOCUMENT,
  SET_TRIAL_SITE_PARTICIPANTS,
} from '../actions/craActionType';
import API_STATES from '../constant/api_constants';

const initialApiState = {
  getCraInititionPanelAPI: API_STATES.NOT_INITIATED,
  getCraQualificationPanelAPI: API_STATES.NOT_INITIATED,
  patchVisitAPI: API_STATES.NOT_INITIATED,
  getSDVSitesAPI: API_STATES.NOT_INITIATED,
  getTrialSiteParticipantsAPI: API_STATES.NOT_INITIATED,
  getRSDVDocumentAPI: API_STATES.NOT_INITIATED,
  createRSDVDocumentRequestAPI: API_STATES.NOT_INITIATED,
  changeRSDVStatusAPI: API_STATES.NOT_INITIATED,
  getParticipantRSDVDocumentsAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialApiState,
  sites: [],
  SDVSites: [],
  trialSiteParticipants: [],
  RSDVDocument: null,
  participantRSDVDocuments: [],
};

export default function craReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CRA_PANEL:
      return { ...state, sites: payload };
    case PATCH_VISIT_API_STATE:
      return { ...state, patchVisitAPI: payload };
    case GET_CRA_INITIATION_PANEL_API_STATE:
      return { ...state, getCraInititionPanelAPI: payload };
    case GET_CRA_QUALIFICATION_PANEL_API_STATE:
      return { ...state, getCraQualificationPanelAPI: payload };
    case SET_CRA_SDV_SITES:
      return { ...state, SDVSites: payload };
    case SET_TRIAL_SITE_PARTICIPANTS:
      return { ...state, trialSiteParticipants: payload };
    case SET_RSDV_DOCUMENT:
      return { ...state, RSDVDocument: payload };
    case SET_PARTICIPANT_RSDV_DOCUMENTS:
      return { ...state, participantRSDVDocuments: payload };
    case GET_CRA_SDV_SITES_API_STATE:
      return { ...state, getSDVSitesAPI: payload };
    case GET_TRIAL_SITE_PARTICIPANTS_API_STATE:
      return { ...state, getTrialSiteParticipantsAPI: payload };
    case GET_RSDV_DOCUMENT_API_STATE:
      return { ...state, getRSDVDocumentAPI: payload };
    case GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE:
      return { ...state, getParticipantRSDVDocumentsAPI: payload };
    case CREATE_RSDV_DOCUMENT_REQUEST_API_STATE:
      return { ...state, createRSDVDocumentRequestAPI: payload };
    case CHANGE_RSDV_STATUS_API_STATE:
      return { ...state, changeRSDVStatusAPI: payload };
    default:
      return state;
  }
}
