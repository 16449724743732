import {
  Backdrop,
  Box,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TAddDoctorModal from '../../../common/TAddDoctorModal';
import TButton from '../../../common/TButton';
import TCircularProgress from '../../../common/TCircularProgress';
import { isCompleted, isPending } from '../../../constant/api_constants';
import { DOCTOR, getRole } from '../../../constant/roles_constants';
import Avatar from '../../../images/IncompleteDoctorProfile.png';
import ProfileSections from './ProfileSections';

const ProfileView = props => {
  const user = useSelector(state => state.userReducer.user);
  const doctorProfile = useSelector(
    state => state.doctorReducer.doctorProfile[0]
  );
  const doctorProfileAPI = useSelector(
    state => state.doctorReducer.doctorProfileAPI
  );
  const siteProfile = useSelector(state => state.doctorReducer.siteProfile);
  const siteProfileAPI = useSelector(
    state => state.doctorReducer.siteProfileAPI
  );
  // const [showAddDoctorModal, setShowAddDoctorModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isPending(doctorProfileAPI) && !isPending(siteProfileAPI)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [doctorProfileAPI, siteProfileAPI]);

  return (
    <>
      {isLoading ? (
        <TCircularProgress />
      ) : user.role === 'Clinical Research Coordinator' &&
        (!user.verified || !doctorProfile) ? (
        <Grid container justify="center">
          <Grid item xs={8}>
            <Typography variant="h4" className="text-center mt-5">
              {!user.verified
                ? 'Your profile is not verified yet. Our teams will contact you soon.'
                : 'There are no doctors registered in your hospital.'}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Box>
            <TButton
              variant="contained"
              color="primary"
              className="fs-xxl mx-5"
              style={{ float: 'right' }}
              onClick={props.toggleView}
            >
              {!doctorProfile?.is_profile_created_by_user
                ? 'Create Profile'
                : 'Edit Profile'}
            </TButton>
            {/* <TButton
              variant="contained"
              color="primary"
              size="large"
              className="fs-xxl fw-semibold mx-5"
              style={{ float: 'right' }}
              onClick={() => setShowAddDoctorModal(true)}
            >
              Add New Doctor
            </TButton> */}
          </Box>

          <Typography
            as="h2"
            variant="h2"
            className="fs-xxxl fw-semibold c-subheading-text mx-5 my-5"
          >
            {getRole(user?.role) === DOCTOR ? 'My Profile' : 'Profile'}
          </Typography>

          {!isNaN(doctorProfile?.id) ? (
            <ProfileSections
              doctorProfile={doctorProfile}
              siteProfile={siteProfile}
            />
          ) : (
            <Box className="my-5 mx-5" textAlign="center">
              <img
                height={300}
                src={Avatar}
                alt="Incpmplete profile"
                className="my-5"
              />
              <Typography variant="h6" className="fw-semibold c-disabled-text">
                Click on Create Profile to complete creating your profile.
              </Typography>
            </Box>
          )}
          {/* <Modal
            className="modal-container"
            open={showAddDoctorModal}
            onClose={() => setShowAddDoctorModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showAddDoctorModal}>
              <TAddDoctorModal setShowAddDoctorModal={setShowAddDoctorModal} />
            </Fade>
          </Modal> */}
        </Box>
      )}
    </>
  );
};

export default ProfileView;
