import { getTimeDeltaHours, getTimeDeltaMinutes } from '../helpers/utils';

export const VISIT_STATUS = Object.freeze({
  PENDING: 1,
  SCHEDULED: 2,
  EXECUTED: 3,
  COMPLETED: 4,
});

export const RSDV_STATUS_STRINGS = Object.freeze({
  1: 'Open',
  2: 'Closed',
  3: 'Expired',
  4: 'Rejected',
});

export const RSDV_STATUS_NUMBERS = Object.freeze({
  open_count: 1,
  closed_count: 2,
  expired_count: 3,
  rejected_count: 4,
});

export const RSDV_STATUS = (status, expiresAfter) => {
  if (status !== 1) {
    return RSDV_STATUS_STRINGS[status];
  } else {
    const hours = getTimeDeltaHours(expiresAfter);

    if (hours < 1) {
      const minutes = getTimeDeltaMinutes(expiresAfter);

      if (minutes < 0) {
        return `Expired`;
      }

      return `Expires in ${minutes} minute${hours !== 1 ? 's' : ''}`;
    } else {
      return `Expires in ${hours} hour${hours !== 1 ? 's' : ''}`;
    }
  }
};

export const RSDV_STATUS_COLORS = expiresAfter => {
  const hours = getTimeDeltaHours(expiresAfter);

  if (hours <= 2) {
    return '#e51400';
  } else if (hours <= 5) {
    return '#f1ad00';
  } else {
    return '#000000';
  }
};
