import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

import { getInitiationSiteDetails } from '../../actions/siteInitiationActions';
import { getAllTrials } from '../../actions/trialActions';
import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TContainer from '../../common/TContainer';
import CRCInfo from '../../components/CRA/CRCInfo';
import ClinicalResearchTeam from '../../components/SiteInitiation/ClinicalResearchTeam';
import ImportantDocuments from '../../components/SiteInitiation/ImportantDocuments';
import SiteDetails from '../../components/SiteInitiation/SiteDetails';

const SiteInfoView = () => {
  const site = useSelector(state => state.siteInitiationReducer.site);
  const user = useSelector(state => state.userReducer.user);

  const dispatch = useDispatch();
  const { id, siteId, trialId } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getInitiationSiteDetails(siteId));
  }, []);

  return (
    <TContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link
            to={
              pathname.includes('site-initiation')
                ? '/site-initiation'
                : '/cra-initiation-sites'
            }
            className="text-decoration-none text-reset color-black"
          >
            <Grid container direction="row">
              <IconButton className="p-0">
                <ArrowBackIos fontSize="small" />
              </IconButton>
              <Typography variant="h5" className="fw-semibold" noWrap>
                {site.name}
              </Typography>
            </Grid>
          </Link>
        </Grid>
        <Grid item container xs={5} spacing={3} alignItems="stretch">
          <Grid item xs={12}>
            <SiteDetails />
          </Grid>
          {!pathname.includes('cra') ? (
            <Grid item xs={12}>
              <ClinicalResearchTeam trialId={trialId} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <CRCInfo trialId={trialId} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={7}>
          <ImportantDocuments trialId={trialId} />
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default SiteInfoView;
