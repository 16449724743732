export const GET_INITIATION_SITES = 'GET_INITIATION_SITES';
export const SET_INITIATION_SITES = 'SET_INITIATION_SITES';
export const GET_INITIATION_SITE = 'GET_INITIATION_SITE';
export const SET_INITIATION_SITE = 'SET_INITIATION_SITE';
export const SET_CRT_TEAM = 'SET_CRT_TEAM';
export const GET_CRT_TEAM = 'GET_CRT_TEAM';

export const GET_INITIATION_SITES_API_STATE = 'GET_INITIATION_SITES_API_STATE';
export const GET_INITIATION_SITE_API_STATE = 'GET_INITIATION_SITE_API_STATE';
export const GET_CRT_TEAM_API_STATE = 'GET_CRT_TEAM_API_STATE';
