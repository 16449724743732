import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { SET_TOAST_ERROR } from '../actions/toastActionType';
import SitesIcon from '../images/Sites.svg';
import UploadDocumentIcon from '../images/UploadDocumentIcon.svg';

const useStyles = makeStyles(theme => ({
  helperText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const TDropZone = ({ setDocument }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({});

  useEffect(() => {
    if (acceptedFiles[0]) {
      if (acceptedFiles[0].size > 100e6)
        dispatch({
          type: SET_TOAST_ERROR,
          payload: 'File size cannot exceed 100 mb',
        });
      else if (acceptedFiles[0].type === 'image/svg+xml') {
        dispatch({
          type: SET_TOAST_ERROR,
          payload: 'SVG file upload is not supported',
        });
      } else if (
        !(
          acceptedFiles[0].type === 'application/pdf' ||
          acceptedFiles[0].type.startsWith('image/')
        )
      )
        dispatch({
          type: SET_TOAST_ERROR,
          payload: 'Only pdf and image files can be uploaded',
        });
      else setDocument(acceptedFiles);
    }
  }, [acceptedFiles]);

  const files = acceptedFiles.map(file => (
    <Grid
      item
      container
      spacing={2}
      alignItems="center"
      justify="space-between"
      key={file}
    >
      <Grid item>
        <img src={SitesIcon} />
      </Grid>
      <Grid item xs>
        <Grid item key={file.path} className="fw-semibold">
          {file.path}
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => {
            acceptedFiles.splice(file, 1);
            setDocument([]);
          }}
        >
          <Delete color="action" />
        </IconButton>
      </Grid>
    </Grid>
  ));

  return (
    <Grid container>
      {acceptedFiles.length ? null : (
        <Grid
          item
          direction="column"
          xs={12}
          spacing={2}
          {...getRootProps({ className: 'dropzone' })}
          style={{ cursor: 'pointer' }}
        >
          <input {...getInputProps()} />
          <img src={UploadDocumentIcon} />
          <Typography className="d-flex flex-column align-items-center fw-semibold">
            Drag 'n' drop to upload
            <div style={{ color: '#008CFF', fontWeight: 'bold' }}>
              or browse
            </div>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {acceptedFiles.length ? (
          <Paper className="p-2 custom-box-small">{files}</Paper>
        ) : null}
      </Grid>
      <Grid item xs={12} className="mt-1">
        <Typography variant="subtitle2" className={classes.helperText}>
          Maximum upload file size: 100MB
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TDropZone;
