export const GET_ALL_TRIALS = 'GET_ALL_TRIALS';
export const SET_TRIALS_LIST = 'SET_TRIALS_LIST';
export const SET_TRIAL = 'SET_TRIAL';
export const GET_TRIAL = 'GET_TRIAL';
export const CREATE_TRIAL = 'CREATE_TRIAL';
export const UPDATE_TRIAL = 'UPDATE_TRIAL';
export const SET_TRIAL_STATS = 'SET_TRIAL_STATS';
export const GET_VISIT_DETAILS = 'GET_VISIT_DETAILS';
export const SET_VISIT_DETAILS = 'SET_VISIT_DETAILS';
export const GET_VISIT_DETAILS_API_STATE = 'GET_VISIT_DETAILS_API_STATE';

export const GET_TRIALS_API_STATE = 'GET_TRIALS_API_STATE';
export const GET_TRIAL_API_STATE = 'GET_TRIAL_API_STATE';
export const CREATE_TRIAL_API_STATE = 'CREATE_TRIAL_API_STATE';
export const UPDATE_TRIAL_SITE_STATUS = 'UPDATE_TRIAL_SITE_STATUS';
export const UPDATE_TRIAL_STATE_API_STATE = 'UPDATE_TRIAL_STATE_API_STATE';
