/* eslint-disable react/prop-types */
import { CircularProgress } from '@material-ui/core';
import React, { forwardRef, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { loginCheck } from './actions/userActions';
import useAccess from './helpers/hooks/useAccess';

const FallbackComponent = forwardRef((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { staticContext, ...restProps } = props;
  return <div ref={ref} {...restProps} />;
});
FallbackComponent.displayName = 'FallbackComponent';

const NewComponent = ({ component: Component, ...restProps }) => (
  <Component {...restProps} />
);

const ProtectedRoute = ({ layout: Layout, component: Component, ...rest }) => {
  const isAccess = useAccess(rest.path);
  const isAuth = useSelector(state => state.userReducer.isAuth);

  return (
    <Route
      path={rest.path}
      exact={rest.exact}
      component={props =>
        isAccess ? (
          <Layout {...props}>
            <Suspense fallback={<CircularProgress />}>
              <NewComponent
                component={Component ? Component : FallbackComponent}
                {...props}
              />
            </Suspense>
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
