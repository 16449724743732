import { Button, Grid, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  createRSDVDocumentRequest,
  getTrialSiteParticipants,
} from '../../actions/craActions';
import TButton from '../../common/TButton';
import TContainer from '../../common/TContainer';
import SDVTrialSiteParticipantsMatrix from '../../components/CRA/SDVTrialSiteParticipantsMatrix';
import { VISIT_STATUS } from '../../constant/cra_constants';
import { SITE_STAGE } from '../../constant/site_constants';

const CRASDVTrialSiteParticipantsView = props => {
  const { createRSDVDocumentRequestAPI, changeRSDVStatusAPI } = useSelector(
    state => state.craReducer
  );

  const [gridApi, setGridApi] = useState(null);
  const [showRequestSDVModal, setShowRequestSDVModal] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [single, setSingle] = useState(false);

  console.log(selectedParticipants);

  const dispatch = useDispatch();

  const selectParticipant = ({ participantId, checked }) => {
    if (checked) {
      setSelectedParticipants(prevState => [...prevState, participantId]);
    } else {
      setSelectedParticipants(prevState =>
        prevState.filter(id => id !== participantId)
      );
    }
  };

  const submitSDVRequest = ({ name, description }) => {
    selectedParticipants.forEach(participant => {
      const data = {
        participant_id: participant,
        name,
        description,
      };
      dispatch(createRSDVDocumentRequest(data));
    });

    setSelectedParticipants([]);
    setShowRequestSDVModal(false);
  };

  const openRequestSDVModal = ({ single: singleParticipant }) => {
    setShowRequestSDVModal(true);
    setSingle(singleParticipant || false);

    if (singleParticipant) {
      const trialSiteId = props.match.params.trialSiteId;

      if (!isNaN(trialSiteId)) {
        dispatch(getTrialSiteParticipants({ trialSiteId }));
      }
    }
  };

  const closeRequestSDVModal = () => {
    setShowRequestSDVModal(false);
    // setSingle(singleParticipant || false);

    if (single) {
      setSelectedParticipants([]);
    }
  };

  useEffect(() => {
    const trialSiteId = props.match.params.trialSiteId;

    if (!isNaN(trialSiteId)) {
      dispatch(getTrialSiteParticipants({ trialSiteId }));
    }
  }, [
    props.match.params.trialSiteId,
    createRSDVDocumentRequestAPI,
    changeRSDVStatusAPI,
  ]);

  return (
    <TContainer>
      <Grid container spacing={4}>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          className="mx-2"
        >
          <Grid item>
            <Link
              to={`/cra-sdv`}
              className="text-decoration-none text-reset color-black"
            >
              <Button className="p-2 tt-none">
                <ArrowBackIos fontSize="small" />
                <Typography variant="h5" className="fw-semibold">
                  Source Document Verification
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <TButton
              color="primary"
              variant="contained"
              onClick={() => openRequestSDVModal({ single: false })}
              disabled={!selectedParticipants.length}
            >
              Request SDV
            </TButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SDVTrialSiteParticipantsMatrix
            gridApi={gridApi}
            setGridApi={setGridApi}
            stage={SITE_STAGE.INITIATION}
            panel={VISIT_STATUS.SCHEDULED}
            showRequestSDVModal={showRequestSDVModal}
            setShowRequestSDVModal={setShowRequestSDVModal}
            openRequestSDVModal={openRequestSDVModal}
            closeRequestSDVModal={closeRequestSDVModal}
            selectedParticipants={selectedParticipants}
            setSelectedParticipants={setSelectedParticipants}
            selectParticipant={selectParticipant}
            submitSDVRequest={submitSDVRequest}
          />
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default CRASDVTrialSiteParticipantsView;
