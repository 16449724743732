/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  SET_TOAST_CUSTOM,
  SET_TOAST_ERROR,
  SET_TOAST_SUCCESS,
} from '../actions/toastActionType';
import {
  GET_ALL_TRIALS,
  GET_TRIAL,
  GET_TRIAL_API_STATE,
  GET_TRIALS_API_STATE,
  GET_VISIT_DETAILS,
  GET_VISIT_DETAILS_API_STATE,
  SET_TRIAL,
  SET_TRIAL_STATS,
  SET_TRIALS_LIST,
  SET_VISIT_DETAILS,
  UPDATE_TRIAL_SITE_STATUS,
  UPDATE_TRIAL_STATE_API_STATE,
} from '../actions/trialActionType';
import {
  getAllTrialsAPI,
  getTrialAPI,
  getTrialStatsAPI,
  getVisitDetailsAPI,
  updateTrialSiteStatusAPI,
} from '../api/trials';
import API_STATE from '../constant/api_constants';
import { SITE_STATES } from '../constant/site_constants';

function* getTrial(action) {
  yield put({ type: GET_TRIAL_API_STATE, payload: API_STATE.INITIATED });
  try {
    let response = yield call(getTrialAPI, action.payload);
    let { data } = response;
    yield put({ type: SET_TRIAL, payload: data });

    response = yield call(getTrialStatsAPI, action.payload);
    data = response.data;
    yield put({ type: SET_TRIAL_STATS, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get trial information. Please try again',
      });
    }
  } finally {
    yield put({ type: GET_TRIAL_API_STATE, payload: API_STATE.COMPLETED });
  }
}

function* getAllTrials(action) {
  yield put({ type: GET_TRIALS_API_STATE, payload: API_STATE.INITIATED });
  try {
    const response = yield call(getAllTrialsAPI, action.payload);
    const { data } = response;
    yield put({ type: SET_TRIALS_LIST, payload: data });
    if (data.length > 0) yield put({ type: SET_TRIAL, payload: data[0] });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get trials. Please try again',
      });
    }
  } finally {
    yield put({ type: GET_TRIALS_API_STATE, payload: API_STATE.COMPLETED });
  }
}

function* getVisitDetails(action) {
  yield put({
    type: GET_VISIT_DETAILS_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    const response = yield call(getVisitDetailsAPI, action.payload);
    const { data } = response;
    yield put({ type: SET_VISIT_DETAILS, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get trials. Please try again',
      });
    }
  } finally {
    yield put({
      type: GET_VISIT_DETAILS_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* updateTrialSiteStatus(action) {
  yield put({
    type: UPDATE_TRIAL_STATE_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    const response = yield call(updateTrialSiteStatusAPI, action.payload);
    const { data } = response;
    let message, color;

    // console.log(action.payload);
    switch (action.payload.status) {
      case SITE_STATES.NONE:
        if (action.payload.currentStage === action.payload.stage) {
          color = '#F2A700';
          message = `Site removed from waitlisting`;
        } else {
          color = '#07bc0c';
          message = `Site moved to ${action.payload.stage.toLowerCase()} stage`;
        }
        break;
      case SITE_STATES.WAITLISTED:
        color = '#F2A700';
        message = `Site waitlisted`;
        break;
      case SITE_STATES.REJECTED:
        color = '#EF7E1D';
        message = 'Site rejected';
        break;
      default:
        break;
    }
    yield put({
      type: SET_TOAST_CUSTOM,
      payload: { message: message, color: color },
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to update trial site status. Please try again',
      });
    }
  } finally {
    yield put({
      type: UPDATE_TRIAL_STATE_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* trialWatcher() {
  yield takeLatest(GET_TRIAL, getTrial);
  yield takeLatest(GET_ALL_TRIALS, getAllTrials);
  yield takeLatest(GET_VISIT_DETAILS, getVisitDetails);
  yield takeLatest(UPDATE_TRIAL_SITE_STATUS, updateTrialSiteStatus);
}

export default trialWatcher;
