import {
  Grid,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  downloadDocument,
  generateAndSignDocument,
} from '../../actions/documentsActions';
import { fetchFeasibilityTable } from '../../actions/feasibilityActions';
import { updateTrialSiteStatus } from '../../actions/trialActions';
import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { TextCellRenderer } from '../../common/AgGrid/TextCellRenderers';
import WaitlistCellRenderer from '../../common/AgGrid/WaitlistCellRenderer';
import { CheckmarkIcon, DownloadIcon } from '../../common/icons';
import TCircularProgress from '../../common/TCircularProgress';
import TConfirmationModal from '../../common/TConfirmationModal';
import TSignDocumentModal from '../../common/TSignDocumentModal';
import TSignPasswordModal from '../../common/TSignPasswordModal';
import TViewAllDocumentsModal from '../../common/TViewAllDocumentsModal';
import API_STATE, { isPending } from '../../constant/api_constants';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
} from '../../constant/document_constants';
import { SITE_STAGE, SITE_STATES_ENUM } from '../../constant/site_constants';
import EmptyListStateImg from '../../images/Empty_State_Empty_List.png';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '1000px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.16px',
    padding: '25px 48px',
  },
}));

const FeasibilityMatrix = () => {
  const classes = useStyles();
  const feasibilityTable = useSelector(
    state => state.feasibilityReducer.feasibilityTable
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const getFeasibilityTableAPI = useSelector(
    state => state.feasibilityReducer.getFeasibilityTableAPI
  );
  const { generateAndSendDocumentApi, sendDocumentAPI, approveDocumentApi } =
    useSelector(state => state.documentsReducer);
  const updateTrialSiteAPI = useSelector(
    state => state.trialReducer.updateTrialSiteAPI
  );
  const templates = useSelector(state => state.documentsReducer.templates);

  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false);
  const [showSignPasswordModal, setShowSignPasswordModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAllDocumentsModal, setShowAllDocumentsModal] = useState(false);
  const [selectedTrialSiteId, setSelectedTrialSiteId] = useState(0);
  const [action, setAction] = useState('');
  const [documentObj, setDocumentObj] = useState({});

  const [gridApi, setGridApi] = useState(null);

  const dispatch = useDispatch();

  const columnDefs = [
    {
      headerName: 'Investigator',
      field: 'site.doctor',
      cellStyle: { padding: 0 },
      cellRenderer: 'WaitlistCellRenderer',
      maxWidth: 300,
    },
    {
      headerName: 'Site',
      field: 'site.hospital',
      cellRenderer: 'TextCellRenderer',
      cellStyle: { paddingLeft: '16px' },
      maxWidth: 300,
    },
    {
      headerName: 'CDA status',
      field: 'documents',
      cellRenderer: 'documentStatusRendered',
      cellRendererParams: {
        docType: 'CDA',
        templates: templates,
      },
      maxWidth: 250,
    },
    {
      headerName: 'Feasibility Form Status',
      field: 'documents',
      cellRenderer: 'documentStatusRendered',
      cellRendererParams: {
        docType: 'Feasibility',
        templates: templates,
      },
      maxWidth: 200,
    },
    {
      headerName: 'Investigator Grant Fees',
      field: 'grant',
      valueFormatter: params =>
        params.value == 0
          ? '---'
          : `INR ${parseFloat(params?.value).toFixed(2)}`,
      maxWidth: 150,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      maxWidth: 200,
      cellRenderer: 'actionCellRenderer',
      cellRendererParams: {
        templates: templates,
        setDocumentObj: setDocumentObj,
      },
    },
  ];

  useEffect(() => {
    if (trialId) dispatch(fetchFeasibilityTable(trialId));
  }, [trialId]);

  useEffect(() => {
    if (
      updateTrialSiteAPI === API_STATE.COMPLETED ||
      generateAndSendDocumentApi === API_STATE.COMPLETED ||
      approveDocumentApi === API_STATE.COMPLETED ||
      sendDocumentAPI === API_STATE.COMPLETED
    ) {
      dispatch(fetchFeasibilityTable(trialId));
    }
  }, [
    updateTrialSiteAPI,
    generateAndSendDocumentApi,
    approveDocumentApi,
    sendDocumentAPI,
  ]);

  const documentStatusRendered = params => {
    let document = params.value.find(document => {
      return document.doc_type === params.docType;
    });

    if (!document) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ marginRight: '15px' }}>---</div>
        </div>
      );
    }

    if (
      document.status === DOCUMENT_STATUS.RESPONSE_RECEIVED ||
      document.status === DOCUMENT_STATUS.DRAFT_PENDING
    )
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div style={{ marginRight: '15px' }}>{document.status}</div>
          <Tooltip title="View document">
            <IconButton
              size="small"
              onClick={() => {
                let docObj = {
                  ...document,
                  trialId: trialId,
                  trialSiteId: params.data.id,
                  siteId: params.data.site.id,
                };
                setDocumentObj(docObj);
                if (
                  document.status === DOCUMENT_STATUS.SIGNED ||
                  document.status === DOCUMENT_STATUS.DRAFT_PENDING
                ) {
                  setAction(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
                } else {
                  setAction(DOCUMENT_MODAL_ACTIONS.APPROVING);
                }
                setShowSignDocumentModal(true);
              }}
            >
              <Visibility color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      );

    if (document.status === DOCUMENT_STATUS.SIGNED) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CheckmarkIcon style={{ fontSize: '16px' }} />
          <div style={{ margin: '0 15px' }}>{document.status}</div>
          <Tooltip title="View document">
            <IconButton
              size="small"
              onClick={() => {
                let docObj = {
                  ...document,
                  trialId: trialId,
                  trialSiteId: params.data.id,
                  siteId: params.data.site.id,
                };
                dispatch(downloadDocument(docObj));
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <div style={{ marginRight: '15px' }}>{document.status}</div>
      </div>
    );
  };

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const actionCellRenderer = props => {
    const [stage, setStage] = useState('');
    const [stageObj, setStageObj] = useState({
      trialId: trialId,
      trialSiteId: props.data.id,
      siteId: props.data.site.id,
    });

    const handleUpdateTrialStatus = trialSiteAction => {
      switch (trialSiteAction) {
        case 'shortlist':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: SITE_STAGE.QUALIFICATION,
              status: 'NONE',
              currentStage: SITE_STAGE.FEASIBILITY,
            })
          );
          break;
        case 'waitlist':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: 'FEASIBILITY',
              status: 'WAITLISTED',
            })
          );
          break;
        case 'unwaitlist':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: SITE_STAGE.FEASIBILITY,
              status: 'NONE',
              currentStage: SITE_STAGE.FEASIBILITY,
            })
          );
          break;
        case 'reject':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: 'FEASIBILITY',
              status: 'REJECTED',
            })
          );
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      let cdaDocument = props.data.documents.find(
        doc => doc.doc_type === 'CDA'
      );
      let feasibilityDocument = props.data.documents.find(
        doc => doc.doc_type === 'Feasibility'
      );

      let finalStageObj;

      finalStageObj = cdaDocument;
      if (cdaDocument?.status === DOCUMENT_STATUS.DRAFT_PENDING) {
        setStage('Send CDA');
      }
      if (cdaDocument?.status === DOCUMENT_STATUS.RESPONSE_PENDING) {
        setStage(DOCUMENT_STATUS.RESPONSE_PENDING);
      }
      if (cdaDocument?.status === DOCUMENT_STATUS.RESPONSE_RECEIVED) {
        setStage('Approve CDA');
      }

      if (cdaDocument?.status === DOCUMENT_STATUS.SIGNED) {
        finalStageObj = feasibilityDocument;
        if (feasibilityDocument?.status === DOCUMENT_STATUS.DRAFT_PENDING) {
          setStage('Send Feasibility');
        }

        if (feasibilityDocument?.status === DOCUMENT_STATUS.RESPONSE_RECEIVED) {
          setStage('Approve Feasibility');
        }

        if (feasibilityDocument?.status === DOCUMENT_STATUS.SIGNED) {
          setStage('Feasibility Completed');
        }
      }
      setStageObj({ ...finalStageObj, ...stageObj });
    }, [props.templates]);

    const handleSendDocument = () => {
      props.setDocumentObj({ ...documentObj, ...stageObj });
      switch (stage) {
        case 'Send CDA':
          dispatch(generateAndSignDocument(stageObj));
          break;
        case 'Approve CDA':
          setShowSignDocumentModal(true);
          setAction(DOCUMENT_MODAL_ACTIONS.APPROVING);
          break;
        case 'Send Feasibility':
          dispatch(generateAndSignDocument(stageObj));
          break;
        case 'Approve Feasibility':
          setShowSignDocumentModal(true);
          setAction(DOCUMENT_MODAL_ACTIONS.APPROVING);
          break;
      }
    };

    return (
      <div className="w-100 h-100 d-flex">
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Select
            size="small"
            value={null}
            className={classes.select}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            input={<OutlinedInput margin="dense" />}
          >
            <MenuItem
              onClick={() => {
                handleSendDocument();
              }}
              disabled={
                stage === 'Feasibility Completed' ||
                stage === DOCUMENT_STATUS.RESPONSE_PENDING
              }
              value="send_cra"
            >
              {stage}
            </MenuItem>
            <MenuItem
              onClick={() => handleUpdateTrialStatus('shortlist')}
              value="shortlist"
              disabled={
                stage !== 'Feasibility Completed' &&
                parseInt(props?.grant) !== 0
              }
            >
              Shortlist this site
            </MenuItem>
            {props.data.status !== SITE_STATES_ENUM.WAITLISTED ? (
              <MenuItem
                onClick={() => handleUpdateTrialStatus('waitlist')}
                value="waitlist"
                disabled={
                  stage === 'Send CDA' ||
                  props.data.status === SITE_STATES_ENUM.WAITLISTED
                }
              >
                Waitlist this site
              </MenuItem>
            ) : null}
            {props.data.status === SITE_STATES_ENUM.WAITLISTED ? (
              <MenuItem
                onClick={() => handleUpdateTrialStatus('unwaitlist')}
                value="waitlist"
              >
                Remove from Waitlist
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => handleUpdateTrialStatus('reject')}
              value="reject"
            >
              Reject this site
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowAllDocumentsModal(true);
                setSelectedTrialSiteId(props.data.id);
              }}
            >
              Show all documents
            </MenuItem>
          </Select>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {isPending(getFeasibilityTableAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          {!feasibilityTable?.length ? (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '150px' }}
                    src={EmptyListStateImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  No sites present in this stage
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                container
                justify="space-between"
                className="m-2"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" className="fw-semibold fs-xxl-temp">
                    Feasibility
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div
                  className="ag-react-container"
                  style={{
                    height: '60vh',
                    width: '100%',
                  }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={feasibilityTable}
                    gridOptions={DEFAULT_GRID_OPTIONS}
                    columnDefs={columnDefs}
                    frameworkComponents={{
                      actionCellRenderer,
                      documentStatusRendered,
                      WaitlistCellRenderer,
                      TextCellRenderer,
                    }}
                    defaultColDef={{
                      ...DEFAULT_COL_DEF,
                      cellClass: ['grid-cell-6n'],
                    }}
                    pagination
                    suppressPaginationPanel
                    suppressRowHoverHighlight
                    paginationPageSize={PER_PAGE_OPTIONS[0]}
                    animateRows
                    onGridSizeChanged={params => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <AgGridPagination gridApi={gridApi} />
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Modal
        className="modal-container"
        open={showSignDocumentModal}
        onClose={() => setShowSignDocumentModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignDocumentModal}>
          <TSignDocumentModal
            setShowSignDocumentModal={setShowSignDocumentModal}
            action={action}
            setShowSignPasswordModal={setShowSignPasswordModal}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showAllDocumentsModal}
        onClose={() => setShowAllDocumentsModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showAllDocumentsModal}>
          <TViewAllDocumentsModal
            setShowAllDocumentsModal={setShowAllDocumentsModal}
            // stage={SITE_STAGE.FEASIBILITY}
            trialSiteId={selectedTrialSiteId}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showSignPasswordModal}
        onClose={() => setShowSignPasswordModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignPasswordModal}>
          <TSignPasswordModal
            setShowSignPasswordModal={setShowSignPasswordModal}
            setShowConfirmationModal={setShowConfirmationModal}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      {/* <Modal
        className="modal-container"
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showConfirmationModal}>
          <TConfirmationModal label={'CDA Signed'} />
        </Fade>
      </Modal> */}
    </>
  );
};

export default FeasibilityMatrix;
