import './styles.css';

import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SET_TOAST_ERROR } from '../../actions/toastActionType';
import TButton from '../../common/TButton';
import { VALIDATION_ERROR } from '../../constant/form_validation';
import {
  cleanDependentComponents,
  hasResponseChanged,
} from '../../helpers/formUtil';
import { validateForm } from '../../helpers/formValidator';
import Form from './components/Form';

const a11yProps = index => {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
};

const LinkTab = props => {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      style={{ color: '#0000008a' }}
      {...props}
    />
  );
};

const TabPanel = props => {
  const dispatch = useDispatch();
  const { children, value, index } = props;

  const onSubmitClick = e => {
    e.preventDefault();

    const cleanData = cleanDependentComponents({
      components: props.section.components,
      data: props.forms[props.index]?.response,
    });

    const data = {
      section_id: props.id,
      data: cleanData,
    };

    const validity = validateForm(
      props.section.components,
      props.section.response
    );

    if (validity.valid) {
      props.onSectionSubmit(data);

      if (props.lastIndex > index) {
        props.continueToNext();
      }
    } else {
      dispatch({
        type: SET_TOAST_ERROR,
        payload: VALIDATION_ERROR,
      });

      props.updateSectionComponents({
        sectionIndex: props.index,
        components: validity.components,
      });
    }
  };

  const onSignClick = () => {
    const validity = validateForm(
      props.section.components,
      props.section.response
    );

    if (validity.valid) {
      props.onSign();
      props.onClose();
    } else {
      props.updateSectionComponents({
        sectionIndex: props.index,
        components: validity.components,
      });
    }
  };

  return (
    <div role="tabpanel" className="tab-panel" hidden={value !== index}>
      {value === index && (
        <Box className="container">
          <Box
            className={
              !props.canSubmit
                ? 'form-fields scrollbar no-submit'
                : 'form-fields scrollbar'
            }
            component="div"
            overflow="auto"
          >
            {children}
          </Box>
          {props.canSubmit && (
            <Grid container spacing={2} justify="flex-end" align="center">
              <Grid item>
                <TButton
                  variant="contained"
                  color="primary"
                  isLoading={props.isLoading}
                  disabled={props.isLoading || !props.changed}
                  onClick={e => {
                    onSubmitClick(e);
                  }}
                >
                  {props.lastIndex > index ? 'Submit & Continue' : 'Submit'}
                </TButton>
              </Grid>
              {props.canSign && props.lastIndex === index && (
                <Grid item>
                  <TButton
                    variant="contained"
                    color="primary"
                    disabled={props.isLoading || props.changed}
                    onClick={() => {
                      onSignClick();
                    }}
                  >
                    Sign
                  </TButton>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Forms = props => {
  // const [tab, setTab] = useState(0);
  const [sections, setSections] = useState();
  const [changedSections, setChangedSections] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const handleTabChange = (event, newTab) => {
    props.setTab(newTab);
  };

  const continueToNextTab = () => {
    if (props.tab < lastIndex) {
      props.setTab(prevTab => prevTab + 1);
    }
  };

  const updateSectionComponents = ({ sectionIndex, components }) => {
    setSections(prevSections => {
      let newSections = [...prevSections];
      newSections[sectionIndex]['components'] = components;
      return newSections;
    });
  };

  useEffect(() => {
    setLastIndex(props.forms?.length - 1 || 0);
    setSections(props.forms);
  }, [props.forms]);

  useEffect(() => {
    const sectionsChanged = [];

    sections?.forEach((section, index) => {
      const responseChanged = hasResponseChanged({
        newResponse: section.response,
        originalResponse: section.originalResponse,
      });
      if (responseChanged) {
        sectionsChanged.push(index);
      }
    });

    setChangedSections(sectionsChanged);
  }, [sections]);

  return (
    <>
      <Tabs
        value={props.tab}
        className="tabs"
        onChange={handleTabChange}
        variant="scrollable"
        orientation="vertical"
        scrollButtons="auto"
        TabIndicatorProps={{
          children: <span className="tab-indicator"></span>,
          style: {
            display: 'none',
          },
        }}
      >
        {sections?.map((section, index) => (
          <LinkTab
            key={index}
            label={
              // props.canSubmit &&
              !hasResponseChanged({
                newResponse: section.response,
                originalResponse: section.originalResponse,
              }) ? (
                <Box>
                  <CheckCircle
                    style={{ color: 'green', marginRight: '10px' }}
                  />
                  {section.name}
                </Box>
              ) : (
                <>{section.name}</>
              )
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      <Box style={{ maxWidth: 'calc(80vw - 28%)', width: 'calc(80vw - 28%)' }}>
        {sections?.map((section, index) => (
          <TabPanel
            value={props.tab}
            index={index}
            onSectionSubmit={props.onSectionSubmit}
            onSign={props.onSign}
            forms={sections}
            originalResponse={section.originalResponse}
            section={section}
            id={section.id}
            key={index}
            lastIndex={lastIndex}
            continueToNext={continueToNextTab}
            isLoading={props.isLoading}
            canSubmit={props.canSubmit}
            changed={changedSections.includes(index)}
            updateSectionComponents={updateSectionComponents}
            onClose={props.onClose}
            canSign={props.canSign}
          >
            <Form
              data={section}
              formData={sections}
              setFormData={setSections}
              filled={section.response ? true : false}
              changed={changedSections.includes(index)}
              index={index}
              onClose={props.onClose}
              isSaved={
                props.canSubmit &&
                !hasResponseChanged({
                  newResponse: section.response,
                  originalResponse: section.originalResponse,
                })
              }
            />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

export default Forms;
