import { Grid, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllIdentificationSites } from '../../actions/siteIdentificationActions';
import TButton from '../../common/TButton';
import { isPending } from '../../constant/api_constants';
import Map from './Map';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  searchButton: {
    height: '2.5rem',
    fontSize: '1.25rem',
    fontWeight: 500,
    borderRadius: '1000px',
  },
}));

const ShortlistSite = () => {
  const classes = useStyles();

  const trialId = useSelector(state => state.trialReducer.trial.id);
  const getSitesAPI = useSelector(
    state => state.siteIdentificationReducer.getSitesAPI
  );

  const siteParamsObj = {
    population: '',
    previousExperience: '',
    investigatorAbility: '',
    trialId: trialId,
  };

  const [formObj, setFormObj] = useState(Object.assign({}, siteParamsObj));

  const dispatch = useDispatch();

  useEffect(() => {
    if ((trialId, formObj.trialId)) {
      let tempFormObj = { ...siteParamsObj, trialId: trialId };
      setFormObj(tempFormObj);
      dispatch(getAllIdentificationSites(formObj));
    }
  }, [trialId]);

  const handleChange = key => site => {
    setFormObj({
      ...formObj,
      [key]: site.target.value,
    });
  };

  const handleGetSites = () => {
    dispatch(getAllIdentificationSites(formObj));
  };

  return (
    <Grid container spacing={6}>
      <Grid
        item
        container
        xs={4}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs="auto" className="fs-m fw-semibold">
          Population
        </Grid>
        <Grid item xs>
          <Select
            size="small"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            size="small"
            value={formObj.population}
            onChange={handleChange('population')}
            className={classes.select}
            input={<OutlinedInput margin="dense" />}
          >
            <MenuItem value={'population_min=10000000'}>
              Greater than 10M
            </MenuItem>
            <MenuItem value={'population_min=5000000&population_max=10000000'}>
              Between 5M and 10M
            </MenuItem>
            <MenuItem value={'population_min=2000000&population_max=5000000'}>
              Between 2M and 5M
            </MenuItem>
            <MenuItem value={'population_max=2000000'}>Less than 2M</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item container xs={4}>
        <Grid item xs={6} className="fs-m fw-semibold">
          Previous experience with COVID-19 vaccine trial
        </Grid>
        <Grid item xs={6}>
          <Select
            size="small"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={formObj.previousExperience}
            onChange={handleChange('previousExperience')}
            className={classes.select}
            input={<OutlinedInput margin="dense" />}
          >
            <MenuItem value={'True'}>Yes</MenuItem>
            <MenuItem value={'False'}>No</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={4}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={6} className="fs-m fw-semibold">
          Investigator Ability
        </Grid>
        <Grid item xs={6}>
          <Select
            size="small"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            value={formObj.investigatorAbility}
            onChange={handleChange('investigatorAbility')}
            className={classes.select}
            input={<OutlinedInput margin="dense" />}
          >
            <MenuItem value={'False'}>No Competing Studies</MenuItem>
            <MenuItem value={'True'}>Competing Studies</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item container justify="center">
        <TButton
          variant="contained"
          color="primary"
          className={classes.searchButton}
          onClick={() => handleGetSites()}
          isLoading={isPending(getSitesAPI)}
        >
          Search
        </TButton>
      </Grid>
      <Grid item xs={12}>
        <Map formObj={formObj} />
      </Grid>
    </Grid>
  );
};

export default ShortlistSite;
