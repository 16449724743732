/* eslint-disable react/prop-types */
import { SvgIcon } from '@material-ui/core';
import React from 'react';

export const Filter = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.33203 7.33301V8.07967C5.33328 8.1676 5.36923 8.25146 5.43203 8.31301L10.2854 13.2197C10.3482 13.2812 10.3841 13.3651 10.3854 13.453V18.0463C10.3852 18.1088 10.4027 18.1701 10.4357 18.2231C10.4687 18.2762 10.5159 18.3189 10.572 18.3463L13.2054 19.2863C13.2562 19.3113 13.3126 19.3228 13.3692 19.3199C13.4258 19.317 13.4807 19.2997 13.5287 19.2697C13.5767 19.2397 13.6163 19.1979 13.6437 19.1483C13.6712 19.0987 13.6855 19.043 13.6854 18.9863V13.393C13.6946 13.3029 13.7374 13.2196 13.8054 13.1597L18.552 8.32634C18.6148 8.2648 18.6508 8.18093 18.652 8.09301V7.33301H5.33203Z"
        fill="#109CF1"
      />
    </SvgIcon>
  );
};

export const WhatsNew = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.13211 23.2496C9.00645 23.2497 8.88229 23.2223 8.76835 23.1693C8.65442 23.1163 8.55347 23.039 8.47261 22.9428C8.39175 22.8466 8.33293 22.7339 8.30029 22.6125C8.26765 22.4912 8.26197 22.3641 8.28367 22.2404V22.2352L9.60133 14.9996H4.49992C4.35832 14.9996 4.21962 14.9595 4.09985 14.8839C3.98009 14.8084 3.88416 14.7005 3.82316 14.5727C3.76216 14.4449 3.73858 14.3024 3.75515 14.1618C3.77172 14.0212 3.82776 13.8881 3.91679 13.778L14.1904 1.0777C14.3073 0.929262 14.4697 0.823207 14.6526 0.775756C14.8355 0.728305 15.0289 0.742071 15.2033 0.814948C15.3776 0.887826 15.5233 1.0158 15.6181 1.17931C15.7128 1.34282 15.7514 1.53285 15.7279 1.72036C15.7279 1.73442 15.7241 1.74802 15.7218 1.76208L14.3995 8.99958H19.4999C19.6415 8.99959 19.7802 9.0397 19.9 9.11524C20.0197 9.19079 20.1157 9.2987 20.1767 9.42648C20.2377 9.55427 20.2613 9.69672 20.2447 9.83734C20.2281 9.97797 20.1721 10.111 20.083 10.2211L9.80805 22.9215C9.72731 23.0235 9.6246 23.1059 9.50757 23.1628C9.39054 23.2196 9.2622 23.2492 9.13211 23.2496Z"
        fill="#90a0b7"
      />
    </SvgIcon>
  );
};

export const DashboardIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g transform="translate(-27 -19)">
        <path
          d="M19.5,19.5v-6h-5v6h5m-10,0v-3h-5v3h5m0-8v-7h-5v7h5m10-3v-4h-5v4h5M20,21H14a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v7A1,1,0,0,1,20,21ZM10,21H4a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v4A1,1,0,0,1,10,21Zm0-8H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3h6a1,1,0,0,1,1,1v8A1,1,0,0,1,10,13Zm10-3H14a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V9A1,1,0,0,1,20,10Z"
          transform="translate(24 16)"
        />
      </g>
    </SvgIcon>
  );
};

export const SiteIdentificationIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
    >
      <path d="M17,15.919a.995.995,0,1,1,0-1.99,1,1,0,0,0,1-.995V4.975a1,1,0,0,0-1-.995H9.415L8.944,2.653A1,1,0,0,0,8,1.99H3a1,1,0,0,0-1,.995v9.949a1,1,0,0,0,1,.995.995.995,0,1,1,0,1.99,2.992,2.992,0,0,1-3-2.985V2.985A2.992,2.992,0,0,1,3,0H8a3,3,0,0,1,2.83,1.99H17a2.992,2.992,0,0,1,3,2.985v7.96A2.992,2.992,0,0,1,17,15.919Zm-5.01-.1A5.019,5.019,0,0,1,5.372,14.81,4.958,4.958,0,0,1,5.7,8.156a5.019,5.019,0,0,1,6.7-.337,4.958,4.958,0,0,1,1.007,6.6L16.3,17.3a.991.991,0,0,1,0,1.407,1,1,0,0,1-1.414,0l-2.9-2.884Zm-.636-2.04a2.975,2.975,0,0,0,0-4.223,3.012,3.012,0,0,0-4.244,0,2.974,2.974,0,0,0,0,4.222,3.011,3.011,0,0,0,4.243,0Z" />
    </SvgIcon>
  );
};

export const FeasibilityIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19.986"
      height="19.044"
      viewBox="0 0 19.986 19.044"
    >
      <g transform="translate(-2 -2)">
        <path
          d="M11.874,14.514a1.05,1.05,0,0,1-.743-.308L8.308,11.382A1.05,1.05,0,0,1,9.793,9.9l2.082,2.082,8.671-8.671A1.05,1.05,0,1,1,22.03,4.793l-9.413,9.413A1.05,1.05,0,0,1,11.874,14.514Z"
          transform="translate(-0.352 -0.059)"
        />
        <path d="M18.112,21.044H4.933A2.936,2.936,0,0,1,2,18.112V4.933A2.936,2.936,0,0,1,4.933,2H15.288a1.05,1.05,0,1,1,0,2.1H4.933a.834.834,0,0,0-.833.833V18.112a.834.834,0,0,0,.833.833H18.112a.834.834,0,0,0,.833-.833V11.522a1.05,1.05,0,1,1,2.1,0v6.589A2.936,2.936,0,0,1,18.112,21.044Z" />
      </g>
    </SvgIcon>
  );
};

export const SiteQualificationIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g transform="translate(-3 -2)">
        <path d="M19,2H9A2,2,0,0,0,7,4v6H5a2,2,0,0,0-2,2v9a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V4A2,2,0,0,0,19,2ZM5,12h6v8H5Zm14,8H13V12a2,2,0,0,0-2-2H9V4H19Z" />
        <path d="M11,6h2V8H11Zm4,0h2V8H15Zm0,4.031h2V12H15ZM15,14h2v2H15ZM7,14H9v2H7Z" />
      </g>
    </SvgIcon>
  );
};

export const SiteInitiationIcon = props => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="19.985"
      height="19.982"
      viewBox="0 0 19.985 19.982"
    >
      <path
        d="M21.694,20.517l-.9-.9-1.441-1.438,2.322-2.322a.833.833,0,0,0-.3-1.371l-7.468-2.71a1.665,1.665,0,0,0-2.132,2.132l2.717,7.468a.832.832,0,0,0,1.37.3l2.32-2.327L19.62,20.8l.9.9a.833.833,0,1,0,1.178-1.178Zm-6.084-.939-2.268-6.237,6.237,2.268Z"
        transform="translate(-1.953 -1.954)"
      />
      <path d="M8.327,16.654a8.327,8.327,0,1,1,8.327-8.327.833.833,0,1,1-1.665,0,6.661,6.661,0,1,0-6.661,6.661.833.833,0,0,1,0,1.665Z" />
      <path
        d="M7.4,13.676a.833.833,0,0,1-.319-.063A5,5,0,1,1,13.615,7.09a.833.833,0,1,1-1.539.636,3.331,3.331,0,1,0-4.356,4.352.833.833,0,0,1-.32,1.6Z"
        transform="translate(-0.67 -0.67)"
      />
    </SvgIcon>
  );
};

export const DownloadIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <circle opacity="0" cx="16" cy="16" r="16" />
      <g transform="translate(2.5 2.456)">
        <path
          // fill="#1abc9c"
          d="M20.455,29.658H6.589A3.092,3.092,0,0,1,3.5,26.57V22.608a1.108,1.108,0,1,1,2.215,0V26.57a.874.874,0,0,0,.873.873H20.455a.874.874,0,0,0,.873-.873V22.608a1.108,1.108,0,0,1,2.215,0V26.57A3.092,3.092,0,0,1,20.455,29.658Z"
          transform="translate(0 -6.114)"
        />
        <path
          // fill="#1abc9c"
          d="M-508.44,1067.623h-.055a1.1,1.1,0,0,1-.729-.323h0l-4.952-4.952a1.108,1.108,0,0,1,0-1.567,1.106,1.106,0,0,1,1.566,0l3.062,3.062v-9.212a1.108,1.108,0,0,1,1.108-1.108,1.108,1.108,0,0,1,1.107,1.108v9.212l3.062-3.062a1.107,1.107,0,0,1,1.567,0,1.109,1.109,0,0,1,0,1.567l-4.949,4.948a1.1,1.1,0,0,1-.759.329Z"
          transform="translate(521.962 -1050.022)"
        />
      </g>
    </SvgIcon>
  );
};

export const UploadIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g transform="translate(3 3)">
        <path
          fill="#2c3e50"
          d="M19.869,28.938H5.977A2.98,2.98,0,0,1,3,25.961V21.992a.992.992,0,0,1,1.985,0v3.969a.993.993,0,0,0,.992.992H19.869a.993.993,0,0,0,.992-.992V21.992a.992.992,0,0,1,1.985,0v3.969A2.98,2.98,0,0,1,19.869,28.938Z"
          transform="translate(0 -6.093)"
        />
        <path
          fill="#2c3e50"
          d="M19.915,9.946a.989.989,0,0,1-.7-.291L14.954,5.4l-4.26,4.26a.992.992,0,0,1-1.4-1.4l4.961-4.961a.992.992,0,0,1,1.4,0l4.961,4.961a.992.992,0,0,1-.7,1.694Z"
          transform="translate(-2.031 0)"
        />
        <path
          fill="#2c3e50"
          d="M17.492,16.892A.992.992,0,0,1,16.5,15.9V3.992a.992.992,0,1,1,1.985,0V15.9A.992.992,0,0,1,17.492,16.892Z"
          transform="translate(-4.569 0)"
        />
      </g>
      <circle fill="#1abc9c" opacity="0" cx="16" cy="16" r="16" />
    </SvgIcon>
  );
};

export const EditIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g transform="translate(5.507 5.689)">
        <path
          fill="#a642fa"
          d="M15.337,20.778H3.942A2.445,2.445,0,0,1,1.5,18.337V6.942A2.445,2.445,0,0,1,3.942,4.5h5.7a.814.814,0,0,1,0,1.628h-5.7a.815.815,0,0,0-.814.814V18.337a.815.815,0,0,0,.814.814H15.337a.815.815,0,0,0,.814-.814v-5.7a.814.814,0,1,1,1.628,0v5.7A2.445,2.445,0,0,1,15.337,20.778Z"
          transform="translate(0 -1.455)"
        />
        <path
          fill="#a642fa"
          d="M21.081,1.318a2.541,2.541,0,0,1,1.8,4.337l-7.732,7.732a.814.814,0,0,1-.378.214l-3.256.814a.814.814,0,0,1-.987-.987l.814-3.256a.814.814,0,0,1,.214-.378l7.732-7.732A2.524,2.524,0,0,1,21.081,1.318ZM14.153,12.077,21.726,4.5a.913.913,0,0,0-1.291-1.291l-7.573,7.573-.43,1.721Z"
          transform="translate(-4.116)"
        />
      </g>
    </SvgIcon>
  );
};

export const AuditTrailIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <defs></defs>
      <circle fill="#fbf6ff" opacity="0" cx="12" cy="12" r="12" />
      <path
        fill="#9630eb"
        d="M0,0V12H10.5V6h-6V0ZM6,0V4.5h4.5ZM1.5,3H3V4.5H1.5Zm0,3H3V7.5H1.5Zm0,3h6v1.5h-6Z"
        transform="translate(3.37 3)"
      />
    </SvgIcon>
  );
};

export const ProfileIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g transform="translate(-2 -2)">
        <path
          d="M4-1A5,5,0,1,1-1,4,5.006,5.006,0,0,1,4-1ZM4,7A3,3,0,1,0,1,4,3,3,0,0,0,4,7Z"
          transform="translate(5 3)"
        />
        <path d="M15,22a1,1,0,0,1-1-1V19a3,3,0,0,0-3-3H7a3,3,0,0,0-3,3v2a1,1,0,0,1-2,0V19a5.006,5.006,0,0,1,5-5h4a5.006,5.006,0,0,1,5,5v2A1,1,0,0,1,15,22Z" />
        <path d="M16,11.88a1,1,0,0,1-.247-1.969,3,3,0,0,0,0-5.812,1,1,0,0,1,.5-1.937,5,5,0,0,1,0,9.688A1,1,0,0,1,16,11.88Z" />
        <path d="M21,22a1,1,0,0,1-1-1V19a3,3,0,0,0-2.25-2.884,1,1,0,1,1,.5-1.936A5,5,0,0,1,22,18.994s0,0,0,.006v2A1,1,0,0,1,21,22Z" />
      </g>
    </SvgIcon>
  );
};

export const TrialIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g transform="translate(-1 -1)">
        <path
          className="a"
          d="M18.264,20.99H3.726A2.729,2.729,0,0,1,1,18.264V3.726A2.729,2.729,0,0,1,3.726,1h.909V2.817H3.726a.91.91,0,0,0-.909.909V18.264a.91.91,0,0,0,.909.909H18.264a.91.91,0,0,0,.909-.909V3.726a.91.91,0,0,0-.909-.909h-.909V1h.909A2.729,2.729,0,0,1,20.99,3.726V18.264A2.729,2.729,0,0,1,18.264,20.99Z"
        />
        <path
          className="a"
          d="M16.086,12.812,11.543,9.4,7,12.812V1h9.086ZM11.543,7.133l2.726,2.044V2.817H8.817V9.178Z"
          transform="translate(-0.548)"
        />
      </g>
    </SvgIcon>
  );
};

export const ShortlistSiteIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g transform="translate(-2 -2)">
        <path
          fill="#1abc9c"
          d="M11.476,13.862a.92.92,0,0,1-.666-.29L8.276,10.908a1.027,1.027,0,0,1,0-1.4.91.91,0,0,1,1.333,0l1.868,1.964,7.78-8.18a.91.91,0,0,1,1.333,0,1.027,1.027,0,0,1,0,1.4l-8.447,8.881A.92.92,0,0,1,11.476,13.862Z"
          transform="translate(-0.932 -0.112)"
        />
        <path
          fill="#1abc9c"
          d="M16.457,19.967H4.632A2.7,2.7,0,0,1,2,17.2V4.767A2.7,2.7,0,0,1,4.632,2h9.291a.967.967,0,0,1,.942.991.967.967,0,0,1-.942.991H4.632a.768.768,0,0,0-.747.785V17.2a.768.768,0,0,0,.747.785H16.457A.768.768,0,0,0,17.2,17.2V10.983a.943.943,0,1,1,1.885,0V17.2A2.7,2.7,0,0,1,16.457,19.967Z"
        />
      </g>
    </SvgIcon>
  );
};

export const TwitterIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      // viewBox="0 0 20 20"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      xmlSpace="preserve"
    >
      <path
        style="fill:#03A9F4;"
        d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
	c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
	c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
	c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
	c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
	c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
	C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
	C480.224,136.96,497.728,118.496,512,97.248z"
      />
    </SvgIcon>
  );
};

export const ResearchGateIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      // viewBox="0 0 20 20"
      aria-label="Research Gate"
      role="img"
      viewBox="0 0 512 512"
    >
      <rect width="512" height="512" rx="15%" fill="#00d0af" />
      <g fill="#feffff">
        <path d="m271 383c-15-4-23-10-36-26-9-12-26-39-35-53l-6-11h-24l0 34c1 43 0 42 19 45l10 1 0 4 0 4h-80l0-4c0-4 1-4 9-6 10-2 14-5 15-14 1-4 1-31 1-79-0-70-1-72-3-77-3-5-7-7-18-8-4-1-5-1-5-5v-4l43-1c55-1 65 0 81 11 15 10 22 24 20 43-1 21-17 42-37 50-4 1-7 3-7 3 0 2 17 28 28 43 15 21 27 32 36 37 4 2 9 3 10 3 3 0 3 1 3 4 0 3-1 5-2 5-5 2-19 2-26 0zm-57-109c14-7 22-18 23-35 1-13-2-22-10-30-9-10-25-14-48-12l-10 1v39c0 36 0 40 2 40 1 0 9 1 18 0 14-0 17-1 24-4z" />
        <path d="m321 228c-25-4-34-20-32-61 1-21 3-30 11-38 7-7 13-9 25-10 13-1 21 2 29 8 5 4 9 10 9 13 0 1-3 2-6 4l-6 3-3-3c-5-6-9-9-14-11-10-3-20 2-25 11-3 5-3 6-3 29 0 22 0 25 3 29 4 7 12 11 21 10 13-1 20-10 20-24v-7l-10-0-10-0v-13h36l-0 15c-0 12-1 16-3 22-6 15-23 24-42 22z" />
      </g>
    </SvgIcon>
  );
};

export const LinkedInIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      // viewBox="0 0 20 20"
      viewBox="0 0 72 72"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Social-Icons---Rounded"
          transform="translate(-376.000000, -267.000000)"
        >
          <g id="Linkedin" transform="translate(376.000000, 267.000000)">
            <path
              d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z"
              id="Rounded"
              fill="#007EBB"
            ></path>
            <path
              d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const CheckmarkIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs></defs>
      <path
        fill={props.color || '#1ABC9C'}
        opacity="0.6"
        d="M22.792,13.32l-1.015-1.044a.218.218,0,0,0-.162-.069h0a.209.209,0,0,0-.162.069l-7.038,7.09L11.854,16.8a.223.223,0,0,0-.323,0L10.5,17.831a.23.23,0,0,0,0,.329l3.231,3.231a1.022,1.022,0,0,0,.675.329,1.071,1.071,0,0,0,.669-.317h.006L22.8,13.649A.247.247,0,0,0,22.792,13.32Z"
        transform="translate(-4.642 -4.96)"
      />
      <path
        fill={props.color || '#1ABC9C'}
        opacity="0.6"
        d="M15.375,4.99a10.381,10.381,0,1,1-7.344,3.04,10.316,10.316,0,0,1,7.344-3.04m0-1.615a12,12,0,1,0,12,12,12,12,0,0,0-12-12Z"
        transform="translate(-3.375 -3.375)"
      />
    </SvgIcon>
  );
};

export const ShieldIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 19 19"
    >
      <g transform="translate(-2 -2)">
        <g className="nc-icon-wrapper">
          <path d="M20.995 6.9a.998.998 0 0 0-.548-.795l-8-4a1 1 0 0 0-.895 0l-8 4a1.002 1.002 0 0 0-.547.795c-.011.107-.961 10.767 8.589 15.014a.987.987 0 0 0 .812 0c9.55-4.247 8.6-14.906 8.589-15.014zM12 19.897C5.231 16.625 4.911 9.642 4.966 7.635L12 4.118l7.029 3.515c.037 1.989-.328 9.018-7.029 12.264z"></path>
          <path d="M11 12.586l-2.293-2.293-1.414 1.414L11 15.414l5.707-5.707-1.414-1.414z"></path>
        </g>
      </g>
    </SvgIcon>
  );
};

export const SDVIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="26.667"
      height="32"
      viewBox="0 0 26.667 32"
    >
      <defs>
        <style>{`.a{fill:#008cff;}.b{fill:#4e4e4e;}`}</style>
      </defs>
      <path
        className="a"
        d="M230.557,170.354a1.333,1.333,0,0,1-1.333,1.333h-2.667v2.667a1.333,1.333,0,0,1-2.667,0v-2.667h-2.667a1.333,1.333,0,1,1,0-2.667h2.667v-2.667a1.333,1.333,0,1,1,2.667,0v2.667h2.667A1.334,1.334,0,0,1,230.557,170.354Z"
        transform="translate(-211.89 -149.021)"
      />
      <path
        className="b"
        d="M238.121,160.379h0l-9.45-9.45c-.011-.011-.025-.014-.035-.023a3.2,3.2,0,0,0-2.2-.884h-7.766a6.281,6.281,0,0,0-6.282,6.282v19.437a6.281,6.281,0,0,0,6.282,6.282h14.1a6.281,6.281,0,0,0,6.282-6.282v-13.1A3.2,3.2,0,0,0,238.121,160.379Zm-2.93-.667-5.822,0,0-5.822,2.912,2.913Zm2.266,16.027a4.687,4.687,0,0,1-4.682,4.682h-14.1a4.687,4.687,0,0,1-4.682-4.682V156.3a4.687,4.687,0,0,1,4.682-4.682h7.766a1.586,1.586,0,0,1,1.131.469l.2.2.005,7.918a1.105,1.105,0,0,0,1.1,1.105l7.918,0,.2.2a1.586,1.586,0,0,1,.469,1.131Z"
        transform="translate(-212.39 -150.021)"
      />
    </SvgIcon>
  );
};

export const ActivationDocumentsIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16.646"
      height="19.976"
      viewBox="0 0 16.646 19.976"
    >
      <defs></defs>
      <g transform="translate(-2 0)">
        <path d="M14.485,11.652a.832.832,0,0,1-.832.832H6.994a.832.832,0,1,1,0-1.665h6.659A.832.832,0,0,1,14.485,11.652Zm-3.329,2.5H6.994a.832.832,0,0,0,0,1.665h4.162a.832.832,0,0,0,0-1.665Zm7.491-5.423v7.087a4.167,4.167,0,0,1-4.162,4.162H6.162A4.167,4.167,0,0,1,2,15.814V4.162A4.167,4.167,0,0,1,6.162,0H9.919a5.791,5.791,0,0,1,4.12,1.706l2.9,2.9a5.785,5.785,0,0,1,1.707,4.119ZM12.863,2.883a4.17,4.17,0,0,0-.875-.649V5.826a.832.832,0,0,0,.832.832h3.592a4.148,4.148,0,0,0-.65-.874Zm4.119,5.844c0-.137-.027-.269-.039-.4H12.82a2.5,2.5,0,0,1-2.5-2.5V1.7c-.135-.012-.267-.039-.4-.039H6.162a2.5,2.5,0,0,0-2.5,2.5V15.814a2.5,2.5,0,0,0,2.5,2.5h8.323a2.5,2.5,0,0,0,2.5-2.5Z" />
      </g>
    </SvgIcon>
  );
};

export const ActivationVisitIcon = props => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19.985"
      height="19.982"
      viewBox="0 0 19.985 19.982"
    >
      <defs></defs>
      <path
        d="M21.694,20.517l-.9-.9-1.441-1.438,2.322-2.322a.833.833,0,0,0-.3-1.371l-7.468-2.71a1.665,1.665,0,0,0-2.132,2.132l2.717,7.468a.832.832,0,0,0,1.37.3l2.32-2.327L19.62,20.8l.9.9a.833.833,0,1,0,1.178-1.178Zm-6.084-.939-2.268-6.237,6.237,2.268Z"
        transform="translate(-1.953 -1.954)"
      />
      <path d="M8.327,16.654a8.327,8.327,0,1,1,8.327-8.327.833.833,0,1,1-1.665,0,6.661,6.661,0,1,0-6.661,6.661.833.833,0,0,1,0,1.665Z" />
      <path
        d="M7.4,13.676a.833.833,0,0,1-.319-.063A5,5,0,1,1,13.615,7.09a.833.833,0,1,1-1.539.636,3.331,3.331,0,1,0-4.356,4.352.833.833,0,0,1-.32,1.6Z"
        transform="translate(-0.67 -0.67)"
      />
    </SvgIcon>
  );
};
