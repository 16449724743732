import * as Yup from 'yup';

import { VALIDATION_MESSAGES as VM } from '../../../../constant/form_validation';

const CRC = Yup.object().shape({
  name: Yup.string().nullable().required(VM.REQUIRED_ERR),
  Position: Yup.string().nullable().required(VM.REQUIRED_ERR),
  Email: Yup.string().nullable().email(VM.EMAIL_ERR).required(VM.REQUIRED_ERR),
  Contact: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .nullable()
    .positive()
    .min(5000000000, VM.PHONE_ERR)
    .max(9999999999, VM.PHONE_ERR)
    .required(VM.REQUIRED_ERR),
});

const SMO = Yup.object().shape({
  smo_name: Yup.string().nullable().required(VM.REQUIRED_ERR),
  contact_person_name: Yup.string().nullable().required(VM.REQUIRED_ERR),
  email: Yup.string().nullable().email(VM.EMAIL_ERR).required(VM.REQUIRED_ERR),
  contact: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .nullable()
    .positive()
    .min(5000000000, VM.PHONE_ERR)
    .max(9999999999, VM.PHONE_ERR)
    .required(VM.REQUIRED_ERR),
});

const requiredBool = Yup.bool(VM.REQUIRED_ERR)
  .nullable()
  .required(VM.REQUIRED_ERR);

const dependentOnSPQ10Number = Yup.number(VM.NUMBER_ERR)
  .nullable()
  .typeError(VM.NUMBER_ERR)
  .min(0, VM.MIN_VAL_ERR(0))
  .when('sp_q10', {
    is: true,
    then: Yup.number(VM.NUMBER_ERR)
      .typeError(VM.NUMBER_ERR)
      .required(VM.REQUIRED_ERR),
  });

const dependentOnSPQ16Number = Yup.number(VM.NUMBER_ERR)
  .nullable()
  .typeError(VM.NUMBER_ERR)
  .min(0, VM.MIN_VAL_ERR(0))
  .when('sp_q16', {
    is: true,
    then: Yup.number(VM.NUMBER_ERR)
      .typeError(VM.NUMBER_ERR)
      .required(VM.REQUIRED_ERR),
  });

const dependentOnSPQ10Text = Yup.string(VM.REQUIRED_ERR)
  .nullable()
  .when('sp_q10', {
    is: true,
    then: Yup.string(VM.REQUIRED_ERR).nullable().required(VM.REQUIRED_ERR),
  });

export const validator = Yup.object().shape({
  type_desc: Yup.string().nullable().required(VM.SELECT_ERR),
  crc: Yup.array().of(CRC),
  smo: Yup.array().of(SMO),
  sp_q01: requiredBool,
  sp_q02: requiredBool,
  sp_q03: requiredBool,
  sp_q04: requiredBool,
  sp_q05: requiredBool,
  sp_q06: requiredBool,
  sp_q07: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .min(0, VM.MIN_VAL_ERR(0))
    .required(VM.REQUIRED_ERR),
  sp_q08: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .min(0, VM.MIN_VAL_ERR(0))
    .required(VM.REQUIRED_ERR),
  sp_q09: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .min(0, VM.MIN_VAL_ERR(0))
    .required(VM.REQUIRED_ERR),
  sp_q10: requiredBool,
  sp_q11: dependentOnSPQ10Number,
  sp_q12: dependentOnSPQ10Number,
  sp_q13: dependentOnSPQ10Number,
  sp_q14: dependentOnSPQ10Number,
  sp_q15: Yup.string()
    .nullable()
    .when('sp_q10', {
      is: true,
      then: Yup.string().nullable().required(VM.REQUIRED_ERR),
    }),
  sp_q19: dependentOnSPQ10Text,
  sp_q16: Yup.bool()
    .nullable()
    .when('sp_q10', {
      is: true,
      then: Yup.bool().nullable().required(VM.SELECT_ERR),
    }),
  sp_q17: dependentOnSPQ16Number,
  sp_q18: dependentOnSPQ16Number,
});
