/* eslint-disable no-unused-vars */
const checkTokenExpirationMiddleware = store => next => action => {
  const exp = localStorage.getItem('exp');
  if (exp && exp < Date.now() / 1000000) {
    localStorage.clear();
    window.location.reload(false);
  }
  next(action);
};

export default checkTokenExpirationMiddleware;
