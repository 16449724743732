import { Checkbox, Grid, Paper } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllCRAs } from '../../actions/siteQualificationActions';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import {
  TTable,
  TTableBody,
  TTableCell,
  TTableContainer,
  TTableHead,
  TTableRow,
} from '../../common/TTable';
import { isCompleted, isPending } from '../../constant/api_constants';

const AssignCRAModal = ({
  setShowAssignCRAModal,
  setShowConfirmAssignCRAModal,
  setSelectedSite,
  selectedSite,
}) => {
  const cras = useSelector(state => state.siteQualificationReducer.cras);
  const getCRAsAPI = useSelector(
    state => state.siteQualificationReducer.getCRAsAPI
  );
  const dispatch = useDispatch();

  const [CRAList, setCRAList] = useState([]);
  const [selectedCRA, setSelectedCRA] = useState(null);

  useEffect(() => {
    dispatch(getAllCRAs());
    if (selectedSite?.visit?.cra_id) {
      setSelectedCRA(selectedSite?.visit?.cra_id);
    }
  }, []);

  useEffect(() => {
    setCRAList(cras);
  }, [cras]);

  const handleListItemClick = cra => {
    setSelectedCRA(cra);
  };

  const handleSelectCRA = () => {
    let selectedSiteObj = selectedSite;
    setSelectedSite({
      ...selectedSiteObj,
      cra_id: selectedCRA,
    });
    setShowAssignCRAModal(false);
    setShowConfirmAssignCRAModal(true);
  };

  return (
    <Paper
      elevation={0}
      className="p-4 custom-modal"
      style={{ height: '50rem', width: '75rem' }}
    >
      {isPending(getCRAsAPI) ? (
        <TCircularProgress />
      ) : (
        <Grid container spacing={2} className="fd-column">
          <Grid item className="fs-xxxl fw-semibold m-1">
            Assign CRA
          </Grid>
          <Grid
            item
            className="scrollbar"
            style={{
              width: '100%',
              height: '39rem',
              maxHeight: '39rem',
              overflow: 'auto',
            }}
          >
            <TTableContainer>
              <TTable size="medium" style={{ padding: '5px' }}>
                <TTableHead>
                  <TTableRow>
                    <TTableCell
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      Name of CRA
                    </TTableCell>
                    <TTableCell
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      City
                    </TTableCell>
                    <TTableCell style={{ textAlign: 'center' }}>
                      Contact Details
                    </TTableCell>
                    <TTableCell style={{ textAlign: 'center' }}>
                      Sites Assigned
                    </TTableCell>
                  </TTableRow>
                </TTableHead>
                <TTableBody>
                  {CRAList?.map((cra, indx) => (
                    <TTableRow
                      key={indx}
                      onClick={() => {
                        handleListItemClick(cra.id);
                      }}
                      className={
                        selectedCRA === cra.id
                          ? 'list-item-selectable list-item-selected'
                          : 'list-item-selectable'
                      }
                    >
                      <TTableCell
                        style={{
                          textAlign: 'left',
                          maxWidth: '250px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        title={`${cra?.first_name} ${cra?.last_name}`}
                      >
                        <Checkbox
                          checked={selectedCRA === cra.id}
                          onClick={() => {
                            handleListItemClick(cra.id);
                          }}
                          icon={<CircleUnchecked fontSize="large" />}
                          checkedIcon={<CircleCheckedFilled fontSize="large" />}
                          color="primary"
                        />
                        {cra?.first_name} {cra?.last_name}
                      </TTableCell>
                      <TTableCell
                        style={{
                          textAlign: 'center',
                          maxWidth: '200px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        title={`${cra?.city}`}
                      >
                        {cra?.city}
                      </TTableCell>
                      <TTableCell style={{ textAlign: 'center' }}>
                        Phone: {cra?.phone}
                        <br />
                        Email: {cra?.email}
                      </TTableCell>
                      <TTableCell style={{ textAlign: 'center' }}>
                        {cra?.num_sites}
                      </TTableCell>
                    </TTableRow>
                  ))}
                </TTableBody>
              </TTable>
            </TTableContainer>
          </Grid>
          <Grid item container justify="flex-end" className="mt-1" spacing={2}>
            <Grid item>
              <TButton
                variant="outlined"
                color="secondary"
                onClick={() => setShowAssignCRAModal(false)}
              >
                Go Back
              </TButton>
            </Grid>
            <Grid item>
              <TButton
                variant="contained"
                color="primary"
                onClick={() => handleSelectCRA()}
                disabled={
                  selectedCRA === selectedSite?.visit?.cra_id ||
                  selectedCRA === null
                }
              >
                {selectedSite.firstTime ? 'Assign' : 'Change'}
              </TButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default AssignCRAModal;
