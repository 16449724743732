const Big = require('big.js');

export const trimDecimal = (num, places) => new Big(num).toFixed(places);

const removeTrailingZeros = str => {
  let numberStr = '';
  if (typeof str === 'number' && !Number.isNaN(str)) {
    numberStr = str.toString();
  } else {
    numberStr = str;
  }
  const subString = numberStr.split('.00')[0];

  return subString;
};

export const add = (addendA, addendB) => {
  let sum = 0;
  try {
    sum = new Big(Number(addendA)).plus(new Big(Number(addendB))).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(sum);
  }

  return removeTrailingZeros(sum);
};

export const minus = (minuend, subtrahend) => {
  let result = 0;
  try {
    result = new Big(Number(minuend))
      .minus(new Big(Number(subtrahend)))
      .toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const multiply = (multiplicand, multiplier) => {
  let result = 0;

  try {
    result = new Big(multiplicand).times(new Big(multiplier)).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const divide = (dividend, divisor) => {
  let result = 0;
  try {
    result = new Big(dividend).div(new Big(divisor)).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const mod = (x, y) => {
  let result = 0;
  try {
    result = new Big(x).mod(new Big(y)).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const abs = x => {
  let result = 0;
  try {
    result = new Big(x).abs().toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const sum = (...args) => {
  const argsArr = Array.from(...args);
  let sum = new Big(0);
  try {
    for (const arg of argsArr) {
      sum = new Big(arg).plus(sum);
    }
  } catch (err) {
    return 0;
  }

  return removeTrailingZeros(sum.toFixed(2));
};

export const percent = (dividend, divisor) => {
  let result = 0;
  try {
    result = new Big(dividend).div(new Big(divisor)).times(100).toFixed(2);
  } catch (err) {
    return removeTrailingZeros(result);
  }

  return removeTrailingZeros(result);
};

export const equal = (paramA, paramB) => {
  let isEqual = false;
  try {
    isEqual = new Big(paramA).eq(new Big(paramB));
  } catch (err) {
    return isEqual;
  }

  return isEqual;
};

export const lessThan = (paramA, paramB) => {
  let isLT = false;
  try {
    isLT = new Big(paramA).lt(new Big(paramB));
  } catch (err) {
    return isLT;
  }

  return isLT;
};

export const greaterThan = (paramA, paramB) => {
  let isGT = false;
  try {
    isGT = new Big(paramA).gt(new Big(paramB));
  } catch (err) {
    return isGT;
  }

  return isGT;
};

export const lessThanEqual = (paramA, paramB) => {
  let isLTE = false;
  try {
    isLTE = new Big(paramA).lte(new Big(paramB));
  } catch (err) {
    return isLTE;
  }
};

export const greaterThanEqual = (paramA, paramB) => {
  let isGTE = false;
  try {
    isGTE = new Big(paramA).gte(new Big(paramB));
  } catch (err) {
    return isGTE;
  }
};

export const toFixed = (value, decimalDigit = 2) =>
  new Big(value).toFixed(decimalDigit);

export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
