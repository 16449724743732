import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TContainer from '../../common/TContainer';
import FeasibilityMatrix from '../../components/Feasibility';

const FeasibilityView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let titleConfig = {
      text: 'Feasibility',
      isLink: false,
      prevIcon: false,
    };
    dispatch(
      setAppBarConfig({
        title: titleConfig,
        showTrials: true,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, []);

  return (
    <TContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FeasibilityMatrix />
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default FeasibilityView;
