import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getInitiationSiteDetails } from '../../actions/siteInitiationActions';
import ClinicalResearchTeam from '../SiteInitiation/ClinicalResearchTeam';
import ImportantDocuments from '../SiteInitiation/ImportantDocuments';
import SiteDetails from '../SiteInitiation/SiteDetails';

const SiteActivation = ({
  doctorTrials,
  selectedTrialId,
  selectedTrialSiteId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitiationSiteDetails(doctorTrials?.site_id));
  }, []);

  return (
    <>
      <Grid item container spacing={3} xs={12}>
        <Grid item container xs={5} spacing={3} alignItems="stretch">
          <Grid item xs={12}>
            <SiteDetails />
          </Grid>
          <Grid item xs={12}>
            <ClinicalResearchTeam
              selectedTrialId={selectedTrialId}
              selectedTrialSiteId={selectedTrialSiteId}
            />
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <ImportantDocuments
            selectedTrialId={selectedTrialId}
            selectedTrialSiteId={selectedTrialSiteId}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SiteActivation;
