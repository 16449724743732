import React from 'react';

import * as Fields from './fields';

const fields = Object.freeze({
  textfield: Fields.TTextField,
  textarea: Fields.TTextArea,
  number: Fields.TNumberField,
  select: Fields.TSelectField,
  checkbox: Fields.TCheckboxField,
  radio: Fields.TRadioField,
  radioAsSelect: Fields.TRadioAsSelectField,
  email: Fields.TTextField,
  url: Fields.TTextField,
  phoneNumber: Fields.TNumberField,
  datagrid: Fields.TDataGridField,
  table: Fields.TTableField,
  content: Fields.TContent,
  default: Fields.TTextField,
});

/**
 * This component is used to render the appropriate
 * form field component based on its type.
 *
 * @param type The field type, e.g. `textfield`, `number`, etc.
 * @param field Field data & properties.
 **/
const FormField = props => {
  let type = props.type;
  if (type === 'radio') {
    if (props?.field?.radioAsSelect || props?.field?.extra?.radioAsSelect) {
      type = 'radioAsSelect';
    }
  }
  let FieldComponent;
  // eslint-disable-next-line no-prototype-builtins
  if (fields.hasOwnProperty(type)) {
    FieldComponent = fields[type];
  } else {
    FieldComponent = fields['default'];
  }

  return <FieldComponent {...props} />;
};

export default FormField;
