import { Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTabIndex } from '../../actions/siteIdentificationActions';
import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TButton from '../../common/TButton';
import TContainer from '../../common/TContainer';
import TTabPanel from '../../common/TTabPanel';
import ShortlistedSited from '../../components/SiteIdentification/ShortlistedSites';
import ShortlistSite from '../../components/SiteIdentification/ShortlistSite';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    maxHeight: '3em',
  },
  scroller: {
    justifyContent: 'center',
  },
  tab: {
    maxHeight: '3em',
    minHeight: '2.5em',
    minWidth: '5em',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '1.125rem',
    textTransform: 'none',
  },
  selected: {
    color: theme.palette.primary.main,
    zIndex: 2,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  indicator: {
    background: theme.palette.primary.main,
    minWidth: '5em',
    height: '4px',
  },
  stickyHeader: {
    position: 'sticky',
    top: '4rem',
    zIndex: 1,
  },
}));

const SiteIdentificationView = () => {
  const classes = useStyles();

  const tabIndex = useSelector(
    state => state.siteIdentificationReducer.tabIndex
  );

  const [gridApi, setGridApi] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAppBarConfig({
        showTrials: true,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, []);

  const handleExportCSV = () => {
    gridApi.exportDataAsCsv();
  };

  return (
    <TContainer>
      <Grid container spacing={4}>
        <Grid item container direction="row" className="mx-2">
          <Grid
            item
            xs
            container
            alignItems="center"
            className={classes.stickyHeader}
          >
            <Typography variant="h6" className="fw-semibold fs-xxl-temp">
              Site Identification
            </Typography>
            <Grid item>
              <Divider
                orientation="vertical"
                style={{
                  background: '#7C7F8450',
                  height: '3rem',
                  width: '2px',
                  marginLeft: '2rem',
                }}
              />
            </Grid>
            <Grid item>
              <Tabs
                value={tabIndex}
                onChange={(_, newValue) => {
                  dispatch(setTabIndex(newValue));
                }}
                classes={{
                  root: classes.root,
                  scroller: classes.scroller,
                  indicator: classes.indicator,
                }}
              >
                <Tab
                  label="SHORTLIST SITE"
                  classes={{ root: classes.tab, selected: classes.selected }}
                />
                <Tab
                  label="SHORTLISTED SITE"
                  classes={{ root: classes.tab, selected: classes.selected }}
                />
              </Tabs>
            </Grid>
          </Grid>
          {/* {tabIndex === 1 ? (
            <Grid item>
              <TButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleExportCSV()}
              >
                Download as CSV
              </TButton>
            </Grid>
          ) : null} */}
        </Grid>
        <Grid item xs={12}>
          <TTabPanel value={tabIndex} index={0}>
            <Grid item xs={12}>
              <ShortlistSite />
            </Grid>
          </TTabPanel>
          <TTabPanel value={tabIndex} index={1}>
            <Grid item xs={12}>
              <ShortlistedSited gridApi={gridApi} setGridApi={setGridApi} />
            </Grid>
          </TTabPanel>
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default SiteIdentificationView;
