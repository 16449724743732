import { Box, Typography } from '@material-ui/core';

import {
  TTable,
  TTableBody,
  TTableCell,
  TTableContainer,
  TTableHead,
  TTableRow,
} from '../../../../common/TTable';

export const Education = ({ doctorProfile }) => {
  return (
    <Box className="my-5 mx-5">
      <Typography
        as="h2"
        variant="h2"
        className="fs-xxl-temp fw-semibold c-subheading-text"
      >
        Education
      </Typography>
      <Box className="m-3">
        {doctorProfile?.education?.length ? (
          <TTableContainer>
            <TTable size="medium">
              <TTableHead>
                <TTableRow>
                  <TTableCell style={{ textAlign: 'center' }}>
                    College Name
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    Degree
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    Graduation
                  </TTableCell>
                </TTableRow>
              </TTableHead>
              <TTableBody>
                {doctorProfile?.education?.map((ed, indx) => (
                  <TTableRow key={indx}>
                    <TTableCell style={{ textAlign: 'center' }}>
                      {ed?.college_name}
                    </TTableCell>
                    <TTableCell style={{ textAlign: 'center' }}>
                      {ed?.degree}
                    </TTableCell>
                    <TTableCell style={{ textAlign: 'center' }}>
                      {ed?.currently_studying
                        ? 'Currently Studying'
                        : ed?.graduation_year}
                    </TTableCell>
                  </TTableRow>
                ))}
              </TTableBody>
            </TTable>
          </TTableContainer>
        ) : (
          <div className="my-4">
            <Typography className="fs-xxl fw-medium c-disabled-text flex mx-3">
              Nothing to show.
            </Typography>
          </div>
        )}
      </Box>
    </Box>
  );
};
