import {
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell as _TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import FormField from '../FormField';
import useStyles from './classes';

/**
 Custom TableCell component.
 **/
const TableCell = withStyles(() => ({
  root: {
    verticalAlign: 'baseline',
  },
  head: {
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
  },
}))(_TableCell);

/**
 Custom table field component.
 **/
const TTableField = props => {
  const classes = useStyles();

  const { label, key } = props.field;
  const { value: values } = props;

  const handleChange = ({ key, newValue, row }) => {
    props.handleChange(key, newValue, 'tableField', {
      key: props.field.key,
      row: row,
    });
  };

  return (
    <FormControl className={classes.field}>
      {!props?.field?.hideLabel && (
        <FormLabel htmlFor={key} className={classes.label}>
          {label}
        </FormLabel>
      )}
      <TableContainer>
        <Table aria-label="table field">
          <TableBody>
            {props?.field?.schema?.map((row, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                {row.map(component => (
                  <TableCell
                    key={component.key}
                    className={
                      !props?.field?.noBorders
                        ? classes.tableCell
                        : classes.tableCellBorderLess
                    }
                  >
                    <FormField
                      id={component.key}
                      key={component.key}
                      name={component.key}
                      type={component.type}
                      error={component.error || false}
                      errorMessage={component.errorMessage || ''}
                      handleChange={(k, v) => {
                        handleChange({
                          key: k,
                          newValue: v,
                          type: component.type,
                          row: rowIndex,
                        });
                      }}
                      value={values[component.key]}
                      field={component}
                      tableCell={true}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default TTableField;
