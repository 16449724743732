import { Box } from '@material-ui/core';
import React from 'react';

import ResetPasswordVerification from '../../components/Auth/resetPasswordVerification';

const ResetPasswordVerificationView = props => {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <ResetPasswordVerification {...props} />
    </Box>
  );
};

export default ResetPasswordVerificationView;
