import { Backdrop, Fade, Grid, Modal } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../../common/AgGrid/constants';
import { SDV_STATUS } from '../../../constant/doctor_constant';
import { formatDate } from '../../../helpers/utils';
import SDVDetailModal from './SDVDetailModal';

const SDVMatrix = () => {
  const [gridApi, setGridApi] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [sdvStatus, setSdvStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const participants = useSelector(
    state => state.doctorReducer.sdvParticipants
  );
  const columnDefs = [
    {
      headerName: 'Participant ID',
      field: 'randomization_id',
    },
    {
      headerName: 'Cohort',
      field: 'cohort',
    },
    {
      headerName: 'Enrolment Date',
      field: 'enrollment_date',
      valueFormatter: params => formatDate(params.value),
    },
    {
      headerName: 'Open SDVs',
      field: 'open_count',
      cellRenderer: 'SDVCountRenderer',
      cellRendererParams: {
        type: SDV_STATUS.OPEN,
      },
      maxWidth: 130,
    },
    {
      headerName: 'Closed SDVs',
      field: 'closed_count',
      cellRenderer: 'SDVCountRenderer',
      cellRendererParams: {
        type: SDV_STATUS.CLOSED,
      },
      maxWidth: 150,
    },
    {
      headerName: 'Expired SDVs',
      field: 'expired_count',
      cellRenderer: 'SDVCountRenderer',
      cellRendererParams: {
        type: SDV_STATUS.EXPIRED,
      },
      maxWidth: 150,
    },
  ];

  const SDVCountRenderer = params => (
    <div
      className="h-100 c-blue fw-semibold text-decoration-underline d-flex align-items-center justify-content-center hover-pointer"
      onClick={() => {
        setSdvStatus(params.type);
        setParticipant(params.data.id);
        setIsModalOpen(true);
      }}
    >
      <span>{params.value}</span>
    </div>
  );

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  return (
    <>
      <Grid item xs={12} className="mt-4">
        <div
          className="ag-react-container"
          style={{
            height: '60vh',
            width: '100%',
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            rowData={participants}
            gridOptions={{ ...DEFAULT_GRID_OPTIONS }}
            columnDefs={columnDefs}
            tooltipShowDelay={0}
            frameworkComponents={{
              SDVCountRenderer,
            }}
            defaultColDef={{
              ...DEFAULT_COL_DEF,
              cellClass: ['grid-cell-6n'],
            }}
            pagination
            suppressRowHoverHighlight
            suppressPaginationPanel
            paginationPageSize={PER_PAGE_OPTIONS[0]}
            animateRows
            onGridSizeChanged={params => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <AgGridPagination gridApi={gridApi} />
      </Grid>
      <Modal
        className="modal-container"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <SDVDetailModal participant={participant} sdvStatus={sdvStatus} />
        </Fade>
      </Modal>
    </>
  );
};

export default SDVMatrix;
