import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons/';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getShortName } from '../../helpers/commonutil';
import Doctor from '../../images/doctor.png';
import Other from '../../images/other.png';
import ProfileMenu from './ProfileMenu';

const useStyles = makeStyles(theme => ({
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  profileImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    marginRight: '10px',
  },
}));

const getInitials = name => {
  let nameArr = name.split(' ');
  let initials =
    nameArr.length === 1 ? nameArr[0][0] : nameArr[0][0] + nameArr[1][0];
  return initials.toUpperCase();
};

const ProfileWidget = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const userData = useSelector(state => state.userReducer.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogin = () => {};

  const renderProfile = () => {
    if (isAuthenticated) {
      return (
        <div className={classes.profile}>
          <Grid item>
            <Avatar
              src={userData.role === 'Site' ? Doctor : Other}
              className={classes.profileImage}
            >
              {getInitials(`${userData.full_name}`)}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography className="fw-medium fs-l">
              {userData
                ? `${getShortName({ name: userData.full_name })}`
                : 'Loading...'}
            </Typography>
          </Grid>
          <Grid item>
            {anchorEl ? (
              <ExpandLess className="mx-2" />
            ) : (
              <ExpandMore className="mx-2" />
            )}
          </Grid>
        </div>
      );
    }
    return (
      <Button onClick={handleLogin} color="inherit">
        Login
      </Button>
    );
  };
  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
      <div className="t-menu">
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {renderProfile()}
        </Button>
        <ProfileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </div>
    </Grid>
  );
};

export default ProfileWidget;
