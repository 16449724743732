import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import * as FormSections from './Tabs';

const useStyles = makeStyles(theme => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="mx-5 px-5"
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProfileForm(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setTabValue = newTabValue => {
    setValue(newTabValue);
  };

  return (
    <Box>
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="secondary"
          variant="fullWidth"
          className="elevated-1"
        >
          <Tab
            label={
              <Box className="p-3 pt-1 pb-1">
                <Box></Box>
                <Typography
                  variant="h6"
                  className={`fw-semibold fs-xl tt-none ${
                    value === 0 ? 'c-blue' : 'c-disabled-text'
                  }`}
                >
                  Doctor's Details
                </Typography>
              </Box>
            }
            {...a11yProps(0)}
          />
          <Tab
            disabled={props.createMode}
            label={
              <Box className="p-3 pt-1 pb-1">
                <Box></Box>
                <Typography
                  variant="h6"
                  className={`fw-semibold fs-xl tt-none ${
                    value === 1 ? 'c-blue' : 'c-disabled-text'
                  }`}
                >
                  Clinical Experience
                </Typography>
              </Box>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Box className="p-3 pt-1 pb-1">
                <Box></Box>
                <Typography
                  variant="h6"
                  className={`fw-semibold fs-xl tt-none ${
                    value === 2 ? 'c-blue' : 'c-disabled-text'
                  }`}
                >
                  Trial Site Details
                </Typography>
              </Box>
            }
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <FormSections.DoctorsDetails
            toggleView={props.toggleView}
            setTabValue={setTabValue}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormSections.ClinicalExperience
            toggleView={props.toggleView}
            setTabValue={setTabValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FormSections.TrialSiteDetails
            setTabValue={setTabValue}
            toggleView={props.toggleView}
          />
        </TabPanel>
      </div>
    </Box>
  );
}
