import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createSiteProfile,
  editSiteProfile,
  getSiteProfile,
} from '../../../../actions/doctorActions';
import { SET_TOAST_ERROR } from '../../../../actions/toastActionType';
import TButton from '../../../../common/TButton';
import TCircularProgress from '../../../../common/TCircularProgress';
import { isCompleted, isPending } from '../../../../constant/api_constants';
import { preventSymbolsInNumberField } from '../../../../constant/form_constants';
import { VALIDATION_ERROR } from '../../../../constant/form_validation';
import {
  SITE_QUESTIONS_LIST as sQL,
  SITE_TYPES as siteTypes,
} from '../../../../constant/site_constants';
import {
  validateFormFieldWithMessages,
  validateFormWithMessages,
} from '../../../../helpers/validateHelper';
import { v2 as validator } from '../validators';

const emptyCRC = {
  name: '',
  Email: '',
  Position: '',
  Contact: '',
};

const emptySMO = {
  smo_name: '',
  contact_person_name: '',
  email: '',
  contact: '',
};

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

const TabPanel = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [profile, setProfile] = useState({});
  const [hasCRC, setHasCRC] = useState(true);
  const [valErrorList, setValErrorList] = useState([]);
  const [valErrorMessageList, setValErrorMessageList] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector(state => state.userReducer.user);
  const siteProfile = useSelector(state => state.doctorReducer.siteProfile);
  const siteProfileAPI = useSelector(
    state => state.doctorReducer.siteProfileAPI
  );
  const editDoctorProfileAPI = useSelector(
    state => state.doctorReducer.editDoctorProfileAPI
  );
  const doctorProfile = useSelector(
    state => state.doctorReducer.doctorProfile[0]
  );

  useEffect(() => {
    dispatch(getSiteProfile());
  }, []);

  useEffect(() => {
    if (isCompleted(siteProfileAPI)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [siteProfileAPI]);

  const validateAll = async () => {
    let returnVal = false;
    await validateFormWithMessages(validator, profile).then(
      ({ isError, valErrors, errorMessages }) => {
        if (isError) {
          // console.log(errorMessages);
          dispatch({
            type: SET_TOAST_ERROR,
            payload: VALIDATION_ERROR,
          });
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
          returnVal = false;
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
          returnVal = true;
        }
      }
    );

    return returnVal;
  };

  const validateOnly = (key, innerProfile) => {
    validateFormFieldWithMessages(validator, innerProfile, key).then(
      ({ isError, valErrors, errorMessages }) => {
        if (isError) {
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
        }
      }
    );
  };

  const toggleSMO = value => {
    let newProfile = profile;

    if (value) {
      newProfile.smo = [{ ...emptySMO }];
    } else {
      newProfile.smo = [];
    }

    setProfile(newProfile);
  };

  const editValue = (key, value) => {
    let extra = {};
    if (key === 'sp_q10') {
      extra = {
        sp_q11: null,
        sp_q12: null,
        sp_q13: null,
        sp_q14: null,
        sp_q15: null,
        sp_q16: null,
        sp_q17: null,
        sp_q18: null,
      };
    }

    if (key === 'sp_q16') {
      extra = {
        sp_q17: null,
        sp_q18: null,
      };
    }

    if (key === 'sp_q06') {
      toggleSMO(value);
    }

    const newProfile = {
      ...profile,
      [key]: value,
      ...extra,
    };

    setProfile(newProfile);
    validateOnly(key, newProfile);
  };

  const editInList = (listName, index, key, value) => {
    let newProfile = { ...profile };
    newProfile[listName][index][key] = value;
    setProfile(newProfile);
    validateOnly(key, newProfile);
  };

  const toggleCRC = e => {
    const value = e.target.value;
    setHasCRC(value);
    if (value) {
      setProfile(prevProfile => ({
        ...prevProfile,
        crc: [{ ...emptyCRC }],
      }));
    } else {
      setProfile(prevProfile => ({
        ...prevProfile,
        crc: [],
      }));
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isFormValid = await validateAll();

    if (isFormValid) {
      if (isNaN(siteProfile?.id)) {
        dispatch(createSiteProfile({ ...profile, user_id: user.pk }));
        props.toggleView();
      } else {
        dispatch(editSiteProfile(profile));
      }
    }
  };

  const addNewToList = listName => {
    const newProfile = { ...profile };
    newProfile[listName]?.push({ ...emptyCRC });
    setProfile(newProfile);
  };

  const removeFromList = (listName, index) => {
    let newProfile = { ...profile };
    newProfile[listName] = newProfile[listName]?.filter(
      (item, idx) => index !== idx
    );
    setProfile(newProfile);
  };

  useEffect(() => {
    let newProfile = {
      ...siteProfile,
      smo: siteProfile?.smo || [],
      crc: siteProfile?.crc || [],
    };
    setProfile(newProfile || {});
    if (siteProfile?.crc?.length) {
      setHasCRC(true);
    } else {
      if (isNaN(siteProfile?.id)) {
        setHasCRC(null);
      } else {
        setHasCRC(false);
      }
    }
  }, [siteProfile]);

  return (
    <div className={classes.formWrapper}>
      {isLoading ? (
        <TCircularProgress />
      ) : (
        <>
          <Grid container spacing={5}>
            {!user?.verified && (
              <Grid item xs={12}>
                <Card className="my-5 mt-3 message-card message-card-negative">
                  <CardContent>
                    <Typography className="c-red fw-semibold fs-xxl">
                      You don't have permission to edit Site Profile. Contact us
                      to get your account verified.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          <form style={{ display: user?.verified ? 'block' : 'none' }}>
            <Grid container spacing={5}>
              <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
                <Grid container spacing={5} className="px-5">
                  <Grid item xs={12} className="d-flex">
                    <Grid item xs={9}>
                      <Typography
                        className="fw-medium c-subheading-text required-field"
                        variant="h6"
                      >
                        What best describes your site?
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={valErrorList.includes(`type_desc`)}
                        size="small"
                      >
                        <Select
                          name="type_desc"
                          variant="outlined"
                          value={`${profile.type_desc}`}
                          onChange={e => {
                            editValue(e.target.name, e.target.value);
                          }}
                          MenuProps={{
                            classes: {
                              paper: 'select-menu',
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value={null} style={{ display: 'none' }}>
                            <em>Select one</em>
                          </MenuItem>
                          {siteTypes.map((option, indx) => (
                            <MenuItem
                              value={option}
                              key={indx}
                              className="select-menu-item"
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {valErrorList.includes(`type_desc`)
                            ? valErrorMessageList[`type_desc`]
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
                <Grid container spacing={5} className="px-5">
                  <Grid item xs={12} className="d-flex">
                    <Grid item xs={9}>
                      <Typography
                        className="fw-medium c-subheading-text required-field"
                        variant="h6"
                      >
                        Does your site have Clinical Research Coordinators?
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          name="has_crc"
                          variant="outlined"
                          value={hasCRC === null ? null : Boolean(hasCRC)}
                          onChange={e => {
                            toggleCRC(e);
                          }}
                          MenuProps={{
                            classes: {
                              paper: 'select-menu',
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value={null} style={{ display: 'none' }}>
                            <em>Select one</em>
                          </MenuItem>
                          {yesNoOptions.map((option, indx) => (
                            <MenuItem
                              value={option.value}
                              key={indx}
                              className="select-menu-item"
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {hasCRC ? (
                  <Box className="mx-5 mt-3">
                    <TButton
                      variant="contained"
                      color="primary"
                      style={{ float: 'right' }}
                      onClick={() => {
                        addNewToList('crc');
                      }}
                    >
                      Add CRC
                    </TButton>
                  </Box>
                ) : null}
              </Box>

              {profile.crc?.length
                ? profile.crc?.map((crc, idx) => (
                    <Box
                      maxWidth="md"
                      className="px-5 my-5 mt-0"
                      width="100%"
                      key={idx}
                    >
                      <Grid container spacing={5} className="px-5">
                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            name="name"
                            variant="outlined"
                            label="Name"
                            value={crc.name}
                            onChange={e => {
                              editInList(
                                'crc',
                                idx,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            fullWidth
                            error={valErrorList.includes(`crc[${idx}].name`)}
                            helperText={
                              valErrorList.includes(`crc[${idx}].name`)
                                ? valErrorMessageList[`crc[${idx}].name`]
                                : ''
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            name="Position"
                            variant="outlined"
                            label="Position"
                            value={crc.Position}
                            onChange={e => {
                              editInList(
                                'crc',
                                idx,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            fullWidth
                            error={valErrorList.includes(
                              `crc[${idx}].Position`
                            )}
                            helperText={
                              valErrorList.includes(`crc[${idx}].Position`)
                                ? valErrorMessageList[`crc[${idx}].Position`]
                                : ''
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            name="Email"
                            variant="outlined"
                            label="Email ID"
                            value={crc.Email}
                            onChange={e => {
                              editInList(
                                'crc',
                                idx,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            required
                            fullWidth
                            error={valErrorList.includes(`crc[${idx}].Email`)}
                            helperText={
                              valErrorList.includes(`crc[${idx}].Email`)
                                ? valErrorMessageList[`crc[${idx}].Email`]
                                : ''
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            name="Contact"
                            variant="outlined"
                            label="Contact"
                            value={crc.Contact}
                            onChange={e => {
                              editInList(
                                'crc',
                                idx,
                                e.target.name,
                                e.target.value
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +91
                                </InputAdornment>
                              ),
                            }}
                            required
                            fullWidth
                            error={valErrorList.includes(`crc[${idx}].Contact`)}
                            helperText={
                              valErrorList.includes(`crc[${idx}].Contact`)
                                ? valErrorMessageList[`crc[${idx}].Contact`]
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                      <TButton
                        variant="outlined"
                        color="secondary"
                        className="mx-5 mt-5"
                        onClick={() => {
                          removeFromList('crc', idx);
                        }}
                      >
                        Remove
                      </TButton>
                    </Box>
                  ))
                : null}

              {/* TODO: fix variables naming */}
              {sQL.map((q, index) => (
                <Box
                  maxWidth="md"
                  className="px-5 my-5 mt-0"
                  width="100%"
                  key={index}
                  style={{
                    display:
                      (q?.dependency?.dependent &&
                        profile[q?.dependency?.key] === q?.dependency?.value) ||
                      !q?.dependency?.dependent
                        ? 'block'
                        : 'none',
                  }}
                >
                  {q.key.includes('sp_q') ? (
                    <Grid container spacing={5} className="px-5">
                      <Grid item xs={12} className="d-flex">
                        <Grid item xs={9}>
                          <Typography
                            className="fw-medium c-subheading-text required-field"
                            variant="h6"
                          >
                            {q.question}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          {q.type === 'boolean' ? (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={valErrorList.includes(q.key)}
                              size="small"
                            >
                              <Select
                                name={q.key}
                                variant="outlined"
                                value={
                                  profile[q.key] === null
                                    ? ''
                                    : Boolean(profile[q.key])
                                }
                                onChange={e => {
                                  editValue(e.target.name, e.target.value);
                                }}
                                MenuProps={{
                                  classes: {
                                    paper: 'select-menu',
                                  },
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                <MenuItem
                                  value={null}
                                  style={{ display: 'none' }}
                                >
                                  <em>Select one</em>
                                </MenuItem>
                                {yesNoOptions.map((option, indx) => (
                                  <MenuItem
                                    value={option.value}
                                    key={indx}
                                    className="select-menu-item"
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {valErrorList.includes(q.key)
                                  ? valErrorMessageList[q.key]
                                  : ''}
                              </FormHelperText>
                            </FormControl>
                          ) : q.type === 'number' ? (
                            <FormControl
                              error={valErrorList.includes(q.key)}
                              variant="outlined"
                              size="small"
                            >
                              <OutlinedInput
                                name={q.key}
                                variant="outlined"
                                value={profile[q.key]}
                                onChange={e => {
                                  editValue(e.target.name, e.target.value);
                                }}
                                required
                                fullWidth
                                type={q.type}
                                error={valErrorList.includes(q.key)}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {q.endAdorment}
                                  </InputAdornment>
                                }
                                startAdornment={
                                  <InputAdornment position="start">
                                    {q.startAdorment}
                                  </InputAdornment>
                                }
                                onWheel={event => {
                                  event.preventDefault();
                                }}
                                onKeyDown={e =>
                                  preventSymbolsInNumberField.includes(e.key) &&
                                  e.preventDefault()
                                }
                              />
                              {valErrorList.includes(q.key) ? (
                                <FormHelperText id="outlined-weight-helper-text">
                                  {valErrorMessageList[q.key]}
                                </FormHelperText>
                              ) : q.helperText ? (
                                <FormHelperText id="outlined-weight-helper-text">
                                  {q.helperText}
                                </FormHelperText>
                              ) : (
                                ''
                              )}
                            </FormControl>
                          ) : q.type === 'text' ? (
                            <FormControl
                              error={valErrorList.includes(q.key)}
                              variant="outlined"
                              size="small"
                            >
                              <OutlinedInput
                                name={q.key}
                                variant="outlined"
                                value={profile[q.key]}
                                onChange={e => {
                                  editValue(e.target.name, e.target.value);
                                }}
                                required
                                fullWidth
                                error={valErrorList.includes(q.key)}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {q.endAdorment}
                                  </InputAdornment>
                                }
                                startAdornment={
                                  <InputAdornment position="start">
                                    {q.startAdorment}
                                  </InputAdornment>
                                }
                              />
                              {valErrorList.includes(q.key) ? (
                                <FormHelperText id="outlined-weight-helper-text">
                                  {valErrorMessageList[q.key]}
                                </FormHelperText>
                              ) : q.helperText ? (
                                <FormHelperText id="outlined-weight-helper-text">
                                  {q.helperText}
                                </FormHelperText>
                              ) : (
                                ''
                              )}
                            </FormControl>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {profile?.smo?.map((smo, idx) => (
                        <Box
                          maxWidth="md"
                          className="px-5 my-5 mt-0"
                          width="100%"
                          key={idx}
                        >
                          <Grid container spacing={5}>
                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                name="smo_name"
                                variant="outlined"
                                label="SMO Name"
                                value={smo.smo_name}
                                onChange={e => {
                                  editInList(
                                    'smo',
                                    idx,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                fullWidth
                                error={valErrorList.includes(
                                  `smo[${idx}].smo_name`
                                )}
                                helperText={
                                  valErrorList.includes(`smo[${idx}].smo_name`)
                                    ? valErrorMessageList[
                                        `smo[${idx}].smo_name`
                                      ]
                                    : ''
                                }
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                name="contact_person_name"
                                variant="outlined"
                                label="Contact person name"
                                value={smo.contact_person_name}
                                onChange={e => {
                                  editInList(
                                    'smo',
                                    idx,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                fullWidth
                                error={valErrorList.includes(
                                  `smo[${idx}].contact_person_name`
                                )}
                                helperText={
                                  valErrorList.includes(
                                    `smo[${idx}].contact_person_name`
                                  )
                                    ? valErrorMessageList[
                                        `smo[${idx}].contact_person_name`
                                      ]
                                    : ''
                                }
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                name="email"
                                variant="outlined"
                                label="Email ID"
                                value={smo.email}
                                onChange={e => {
                                  editInList(
                                    'smo',
                                    idx,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                fullWidth
                                error={valErrorList.includes(
                                  `smo[${idx}].email`
                                )}
                                helperText={
                                  valErrorList.includes(`smo[${idx}].email`)
                                    ? valErrorMessageList[`smo[${idx}].email`]
                                    : ''
                                }
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                size="small"
                                name="contact"
                                variant="outlined"
                                label="Contact"
                                value={smo.contact}
                                onChange={e => {
                                  editInList(
                                    'smo',
                                    idx,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                fullWidth
                                error={valErrorList.includes(
                                  `smo[${idx}].contact`
                                )}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +91
                                    </InputAdornment>
                                  ),
                                }}
                                helperText={
                                  valErrorList.includes(`smo[${idx}].contact`)
                                    ? valErrorMessageList[`smo[${idx}].contact`]
                                    : ''
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              ))}
              <Grid item xs={12}>
                <Container maxWidth="lg">
                  <Box display="flex" flexDirection="row-reverse">
                    <Box>
                      <TButton
                        variant="contained"
                        color="primary"
                        disabled={
                          isPending(editDoctorProfileAPI) || !user?.verified
                        }
                        isLoading={isPending(editDoctorProfileAPI)}
                        onClick={e => {
                          onSubmit(e);
                        }}
                      >
                        {isNaN(siteProfile?.id) ? 'Finish' : 'Save'}
                      </TButton>
                    </Box>
                    <Box pr={2}>
                      <TButton
                        variant="outlined"
                        disabled={
                          isPending(editDoctorProfileAPI) || !user?.verified
                        }
                        onClick={() => {
                          if (!doctorProfile?.is_profile_created_by_user) {
                            props.setTabValue(1);
                          } else {
                            props.toggleView();
                          }
                        }}
                      >
                        {!doctorProfile?.is_profile_created_by_user
                          ? 'Go Back'
                          : 'Cancel'}
                      </TButton>
                    </Box>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </div>
  );
};

export default TabPanel;
