import { mod } from './mathutil';

export const deepCloneArray = arr => Array.from(arr, x => ({ ...x }));

export const isEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const encodeString = str =>
  str.replace(/./g, c => mod(`0${c.charCodeAt(0)}`.slice(-3), 7));

export const capitalizeFirstChar = str => str[0].toUpperCase() + str.slice(1);

export const getShortName = ({ name = '', threshold = 20 }) => {
  if (name.length < threshold) {
    return name;
  }

  var parts = name.split(' ');
  var shortName = '';

  for (var i = 0; i < parts.length - 1; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      shortName += `${parts[i][0]}`;
    }
  }

  shortName = shortName.toUpperCase();
  shortName += ` ${parts[parts.length - 1]}`;

  return shortName;
};
