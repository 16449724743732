import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { approveDocument } from '../actions/documentsActions';
import { isPending } from '../constant/api_constants';
import { SIGNING_METHOD } from '../constant/document_constants';
import TButton from './TButton';

const TSignPasswordModal = ({ setShowSignPasswordModal, documentObj }) => {
  const [password, setPassword] = useState('');
  const [popUpWindow, setPopUpWindow] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const { eSignDocumentLink, approveDocumentApi } = useSelector(
    state => state.documentsReducer
  );

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (eSignDocumentLink) {
  //     let popUp = popUpWindow;
  //     popUp.location.href = eSignDocumentLink;
  //     setPopUpWindow(popUp);
  //     setShowSignPasswordModal(false);
  //   }
  // }, [eSignDocumentLink]);

  const handleSubmitPassword = () => {
    dispatch(approveDocument({ ...documentObj, password: password }));

    // if (documentObj.sign_type === SIGNING_METHOD.AADHAAR) {
    //   let newPopUpWindow = window.open(
    //     `${window.location.origin}/signing`,
    //     '_blank'
    //   );
    //   // newPopUpWindow.document.write("Please don't reload or close this tab...");

    //   setPopUpWindow(newPopUpWindow);
    // }
  };

  return (
    <Paper elevation={0} className="p-4 custom-modal">
      {/* TODO: add file component here */}
      {eSignDocumentLink === '' ? (
        <Grid container direction="column" spacing={3}>
          <Grid item style={{ height: '8.5rem', width: '25rem' }}>
            <Typography
              className="mt-3"
              style={{ font: 'normal normal medium 18px/32px Poppins' }}
            >
              Once you have signed the document, you are accepting all the terms
              and conditions of the trial and you won’t be able to edit it in
              future.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={password}
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" color="primary" />
                      ) : (
                        <VisibilityOff fontSize="small" color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className="mb-2">
            <TButton
              onClick={() => handleSubmitPassword()}
              variant="contained"
              color="primary"
              fullWidth
              isLoading={isPending(approveDocumentApi)}
              size="large"
            >
              Approve
            </TButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item>
            <TButton
              variant="contained"
              onClick={() => setShowSignPasswordModal(false)}
              color="primary"
              style={{ color: 'white' }}
              href={eSignDocumentLink}
              target="_blank"
            >
              Continue process
            </TButton>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default TSignPasswordModal;
