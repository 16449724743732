import { Box, Typography } from '@material-ui/core';
import React from 'react';

export const ClinicalResearchExperience = ({ doctorProfile }) => {
  return (
    <Box className="my-5 mx-5">
      <Typography
        as="h2"
        variant="h2"
        className="fs-xxl-temp fw-semibold c-subheading-text"
      >
        Clinical Research Experience
      </Typography>
      {doctorProfile?.clinical_experience?.clinical_experience_trial_details
        ?.length ? (
        <>
          {doctorProfile?.clinical_experience?.clinical_experience_trial_details?.map(
            (item, index) => (
              <Box className="my-4 mb-5" key={index}>
                <div className="my-4">
                  <Typography className="fs-xxl fw-medium c-content-text my-3">
                    <b className="fw-semibold mx-2">Public Title:</b>{' '}
                    {item.pub_title}
                  </Typography>
                  <Typography className="fs-xxl fw-medium c-content-text my-3">
                    <b className="fw-semibold mx-2">CTRI Number:</b>{' '}
                    {item.ctri_number}
                  </Typography>
                  <Typography className="fs-xxl fw-medium c-content-text my-3">
                    <b className="fw-semibold mx-2">Role:</b> {item.role}
                  </Typography>
                </div>
              </Box>
            )
          )}
        </>
      ) : (
        <Box className="my-5 mx-3">
          <div className="my-4">
            <Typography className="fs-xxl fw-medium c-disabled-text flex mx-3">
              Nothing to show.
            </Typography>
          </div>
        </Box>
      )}
    </Box>
  );
};
