import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { TextCellRenderer } from '../../common/AgGrid/TextCellRenderers';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';

const SDVSitesMatrix = ({ gridApi, setGridApi }) => {
  const { getSDVSitesAPI, SDVSites } = useSelector(state => state.craReducer);

  const columnDefs = [
    {
      headerName: 'Investigator',
      field: 'doctor',
      cellRenderer: 'TextCellRenderer',
      maxWidth: 200,
    },
    {
      headerName: 'Site',
      field: 'hospital',
      cellRenderer: 'TextCellRenderer',
    },
    {
      headerName: 'Open SDVs',
      field: 'open_count',
      maxWidth: 150,
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Closed SDVs',
      field: 'closed_count',
      maxWidth: 150,
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Expired SDVs',
      field: 'expired_count',
      maxWidth: 150,
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      cellRenderer: 'actionCellRenderer',
      maxWidth: 120,
    },
  ];

  const actionCellRenderer = params => {
    const trialSiteId = params.data.trial_site_id;

    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center" spacing={1}>
          <Link to={`/cra-sdv/trial-site/${trialSiteId}/participants`}>
            <Tooltip title="View participants">
              <IconButton color="primary">
                <Visibility />
              </IconButton>
            </Tooltip>
          </Link>
        </Grid>
      </div>
    );
  };

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      {isPending(getSDVSitesAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="ag-react-container"
                style={{
                  height: '60vh',
                  width: '100%',
                }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={SDVSites}
                  gridOptions={DEFAULT_GRID_OPTIONS}
                  columnDefs={columnDefs}
                  frameworkComponents={{
                    actionCellRenderer,
                    TextCellRenderer,
                  }}
                  defaultColDef={{
                    ...DEFAULT_COL_DEF,
                    cellClass: ['grid-cell-6n'],
                  }}
                  pagination
                  suppressRowHoverHighlight
                  suppressPaginationPanel
                  paginationPageSize={PER_PAGE_OPTIONS[0]}
                  animateRows
                  onGridSizeChanged={params => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <AgGridPagination gridApi={gridApi} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SDVSitesMatrix;
