import MuiContainer from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

const TContainer = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    height: 'calc(100vh  - 80px - 2.5%)',
    maxWidth: '100%',
  },
}))(MuiContainer);

export default TContainer;
