import {
  GET_FEASIBILITY_TABLE_API_STATE,
  SET_FEASIBILITY_TABLE,
} from '../actions/feasibilityActionType';
import API_STATES from '../constant/api_constants';

const initialPendingState = {
  getFeasibilityTableAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialPendingState,
  sites: [],
  feasibilityTable: [],
};

export default function feasibilityReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_FEASIBILITY_TABLE:
      return { ...state, feasibilityTable: payload };
    case GET_FEASIBILITY_TABLE_API_STATE:
      return { ...state, getFeasibilityTableAPI: payload };
    default:
      return state;
  }
}
