import _ from 'lodash';

/**
 * Returns empty value for a form field type.
 * @param component The field component.
 **/
export const getEmptyFieldValue = component => {
  const { type } = component;
  switch (type) {
    case 'number':
      return null;
    case 'textfield':
      return '';
    case 'textarea':
      return '';
    case 'select':
      return component.multiple ? [] : null;
    case 'checkbox':
      return false;
    case 'radio':
      return null;
    case 'email':
      return '';
    case 'phoneNumber':
      return '';
    case 'datagrid':
      return [getEmptyDataGridData(component.components)];
    default:
      return null;
  }
};

export const noValueFields = ['table', 'content'];

/**
 * Returns an objects having all the fields in a
 * datagrid and their empty values.
 * @param components The datagrid components
 **/
export const getEmptyDataGridData = components => {
  const dataGridData = {};

  components.forEach(component => {
    dataGridData[component.key] = getEmptyFieldValue(component);
  });

  return dataGridData;
};

/**
 * Returns an object having all the form fields and their empty values.
 * @param components All (un-cleaned) components
 **/
export const getEmptyFormData = components => {
  const formData = {};

  components.forEach((component, index) => {
    if (!noValueFields.includes(component.type)) {
      formData[component.key] = getEmptyFieldValue(component);
    }
  });

  return formData;
};

export const hasResponseChanged = ({ newResponse, originalResponse }) => {
  let changed = false;

  if (!_.isEqual(newResponse, originalResponse)) {
    changed = true;
  }

  return changed;
};

const emptyConditionals = Object.freeze(['', null]);

export const isComponentDisplayed = ({ component, formData }) => {
  let display = true;

  if (
    component?.conditional &&
    !emptyConditionals.includes(component?.conditional?.when)
  ) {
    const { when, show, eq } = component.conditional;

    if (formData[when] === eq) {
      display = show;
    } else {
      display = !show;
    }
  }

  return display;
};

export const cleanDependentComponents = ({ components, data }) => {
  let formData = _.cloneDeep(data);

  components?.forEach((component, index) => {
    if (
      component?.conditional &&
      !isComponentDisplayed({ component: component, formData: formData })
    ) {
      if (component.type === 'datagrid') {
        formData[component.key] = [];
      } else if (component.type === 'table') {
        component?.components?.forEach(tableComponent => {
          formData[tableComponent.key] = null;
        });
      } else {
        formData[component.key] = null;
      }
    }
  });

  return formData;
};
