import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '../actions/userActions';
import TButton from './TButton';

const TLogoutConfirmModal = ({ setShowLogoutConfirmModal }) => {
  const dispatch = useDispatch();

  return (
    <Paper elevation={0} style={{ width: '25%' }} className="p-4 custom-modal">
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Are you sure you want to log out?
          </Typography>
        </Grid>
        <Grid item container spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item className="mb-2">
            <TButton
              onClick={() => setShowLogoutConfirmModal(false)}
              variant="outlined"
              color="secondary"
              fullWidth
              size="small"
            >
              Cancel
            </TButton>
          </Grid>
          <Grid item className="mb-2">
            <TButton
              onClick={() => dispatch(logout())}
              variant="contained"
              color="primary"
              fullWidth
              size="small"
            >
              Log out
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TLogoutConfirmModal;
