import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';

import {
  logout,
  setUserAPISuccess,
  updateUserPassword,
} from '../../actions/userActions';
import PasswordValidator from '../../common/PasswordValidator';
import TButton from '../../common/TButton';
import { isPending } from '../../constant/api_constants';
import { ERROR_MESSAGE } from '../../constant/auth_constants';
import { validateForm } from '../../helpers/validateHelper';
import AuthCard from './AuthCard';

const UpdatePassword = props => {
  const { verificationMethod } = props.match.params;
  const { state } = props.location;
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const firstLogin = useSelector(state => state.userReducer.user?.first_login);
  const APISuccess = useSelector(state => state.userReducer.apiSuccess);
  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );
  const updatePasswordAPIState = useSelector(
    state => state.userReducer.updatePasswordAPI
  );
  const isPasswordResetRequired = useSelector(
    state => state.userReducer.user?.is_password_reset_required
  );

  const [formData, setFormData] = useState({
    password: '',
    password1: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showConfirmPassword2, setShowConfirmPassword2] = useState(false);
  const [valErrorList, setValErrorList] = useState([]);
  const [updatePasswordRequest, setUpdatePasswordRequest] = useState(false);

  useEffect(() => {
    if (APISuccess && updatePasswordRequest) {
      dispatch(setUserAPISuccess(null));
      setUpdatePasswordRequest(false);
      dispatch(push('/login'));
    }
  }, [APISuccess, updatePasswordRequest]);

  const handleChange = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(9)
      .matches(/[a-z]/)
      .matches(/[A-Z]/)
      .matches(/\d/)
      .matches(/[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/),
    password1: yup
      .string()
      .oneOf([yup.ref('password'), null])
      .required(),
  });

  const resetPasswordValidationSchema = yup.object().shape({
    password1: yup
      .string()
      .required()
      .min(9)
      .matches(/[a-z]/)
      .matches(/[A-Z]/)
      .matches(/\d/)
      .matches(/[()[\]{}|\\`~!@#$%^&*_\-+=;:'",<>./?]/),
    password: yup.string().required(),
    password2: yup
      .string()
      .oneOf([yup.ref('password1'), null])
      .required(),
  });

  const handleConfirm = () => {
    let validationSchemaObj = isPasswordResetRequired
      ? resetPasswordValidationSchema
      : validationSchema;
    validateForm(validationSchemaObj, formData).then(
      ({ isError, valErrors }) => {
        if (isError) setValErrorList(valErrors);
        else {
          setValErrorList([]);
          let payload;
          if (verificationMethod === 'user') {
            payload = {
              password: formData.password,
              password2: formData.password1,
              verificationMethod,
            };
          } else {
            payload = { ...formData, ...state, verificationMethod };
          }
          dispatch(updateUserPassword(payload));
          setUpdatePasswordRequest(true);
        }
      }
    );
  };

  const cancelUpdate = () => {
    dispatch(logout());
  };

  if (isAuthenticated && !isPasswordResetRequired) {
    return <Redirect to={viewPermissions[0].resource_path} />;
  }

  if (!isAuthenticated && verificationMethod === 'user') {
    return <Redirect to="/login" />;
  }

  return (
    <AuthCard>
      <Grid container spacing={4}>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography align="center" className="fs-xl fw-semibold mb-3">
              Please enter your new password
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label={isPasswordResetRequired ? 'Old Password' : 'Password'}
              value={formData.password}
              onChange={handleChange('password')}
              variant="outlined"
              className="bg-white"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              error={valErrorList.indexOf('password') > -1}
              helperText={
                valErrorList.indexOf('password') > -1
                  ? ERROR_MESSAGE.PASSWORD_ERROR_MESSAGE
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" color="primary" />
                      ) : (
                        <VisibilityOff fontSize="small" color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!isPasswordResetRequired ? (
              <PasswordValidator password={formData.password} />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label={
                isPasswordResetRequired ? 'New Password' : 'Confirm Password'
              }
              value={formData.password1}
              onChange={handleChange('password1')}
              variant="outlined"
              className="bg-white"
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              error={valErrorList.indexOf('password1') > -1}
              helperText={
                valErrorList.indexOf('password1') > -1
                  ? ERROR_MESSAGE.CONFIRM_PASSWORD_ERROR_MESSAGE
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Visibility fontSize="small" color="primary" />
                      ) : (
                        <VisibilityOff fontSize="small" color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {isPasswordResetRequired ? (
              <PasswordValidator password={formData.password1} />
            ) : null}
          </Grid>
          {isPasswordResetRequired ? (
            <Grid item xs={12}>
              <TextField
                size="small"
                label={'Confirm Password'}
                value={formData.password2}
                onChange={handleChange('password2')}
                variant="outlined"
                className="bg-white"
                type={showConfirmPassword2 ? 'text' : 'password'}
                fullWidth
                error={valErrorList.indexOf('password2') > -1}
                helperText={
                  valErrorList.indexOf('password2') > -1
                    ? ERROR_MESSAGE.CONFIRM_PASSWORD_ERROR_MESSAGE
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword2(!showConfirmPassword2)
                        }
                        edge="end"
                      >
                        {showConfirmPassword2 ? (
                          <Visibility fontSize="small" color="primary" />
                        ) : (
                          <VisibilityOff fontSize="small" color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TButton
              onClick={handleConfirm}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              isLoading={isPending(updatePasswordAPIState)}
              className="mt-2"
            >
              <Typography className="fs-l fw-semibold">Confirm</Typography>
            </TButton>
          </Grid>
          <Grid item xs={12}>
            <TButton
              tertiary
              fullWidth
              size="large"
              variant="text"
              onClick={cancelUpdate}
            >
              Cancel
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default UpdatePassword;
