import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCRASDVSites } from '../../actions/craActions';
import { getAllTrials } from '../../actions/trialActions';
import TContainer from '../../common/TContainer';
import SDVSitesMatrix from '../../components/CRA/SDVSitesMatrix';
import { isCompleted } from '../../constant/api_constants';
import { VISIT_STATUS } from '../../constant/cra_constants';
import { SITE_STAGE } from '../../constant/site_constants';

const useStyles = makeStyles(theme => ({
  stickyHeader: {
    position: 'sticky',
    top: '4rem',
    zIndex: 1,
  },
}));

const CRASDVView = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { trialsList, getTrialsAPI } = useSelector(state => state.trialReducer);

  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    dispatch(getAllTrials());
  }, []);

  useEffect(() => {
    if (isCompleted(getTrialsAPI) && trialsList?.length) {
      dispatch(getCRASDVSites({ trialId: trialsList[0].id }));
    }
  }, [getTrialsAPI]);

  return (
    <TContainer>
      <Grid container spacing={4}>
        <Grid item container direction="row" className="mx-2">
          <Grid
            item
            xs
            container
            alignItems="center"
            className={classes.stickyHeader}
          >
            <Typography variant="h5" className="fw-semibold">
              My Sites
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SDVSitesMatrix
            gridApi={gridApi}
            setGridApi={setGridApi}
            stage={SITE_STAGE.INITIATION}
            panel={VISIT_STATUS.SCHEDULED}
          />
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default CRASDVView;
