import { Backdrop, Fade, ListItemText, Modal, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '../../actions/userActions';
import TLogoutConfirmModal from '../../common/TLogoutConfirmModal';
import { StyledMenu, StyledMenuItem } from '../../common/TMenu';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: theme.spacing(6),
    zIndex: 1500,
    right: 0,
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menuItem: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(1.2, 2),
    fontSize: '1em',
  },
}));

const ProfileWidget = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);

  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      <Paper classes={{ root: classes.paper }} elevation={8}>
        <div className="t-menu">
          <StyledMenu
            id="customized-menu"
            className="t-menu-menu"
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={setShowLogoutConfirmModal}>
              <ExitToApp style={{ marginRight: 12 }} />
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
        <Modal
          className="modal-container"
          open={showLogoutConfirmModal}
          onClose={() => setShowLogoutConfirmModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showLogoutConfirmModal}>
            <TLogoutConfirmModal
              setShowLogoutConfirmModal={setShowLogoutConfirmModal}
            />
          </Fade>
        </Modal>
      </Paper>
    </div>
  );
};

export default ProfileWidget;
