/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CHANGE_RSDV_STATUS,
  CHANGE_RSDV_STATUS_API_STATE,
  CREATE_RSDV_DOCUMENT_REQUEST,
  CREATE_RSDV_DOCUMENT_REQUEST_API_STATE,
  GET_CRA_INITIATION_PANEL,
  GET_CRA_INITIATION_PANEL_API_STATE,
  GET_CRA_QUALIFICATION_PANEL,
  GET_CRA_QUALIFICATION_PANEL_API_STATE,
  GET_CRA_SDV_SITES,
  GET_CRA_SDV_SITES_API_STATE,
  GET_PARTICIPANT_RSDV_DOCUMENTS,
  GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE,
  GET_RSDV_DOCUMENT,
  GET_RSDV_DOCUMENT_API_STATE,
  GET_TRIAL_SITE_PARTICIPANTS,
  GET_TRIAL_SITE_PARTICIPANTS_API_STATE,
  PATCH_VISIT,
  PATCH_VISIT_API_STATE,
  SET_CRA_PANEL,
  SET_CRA_SDV_SITES,
  SET_PARTICIPANT_RSDV_DOCUMENTS,
  SET_RSDV_DOCUMENT,
  SET_TRIAL_SITE_PARTICIPANTS,
} from '../actions/craActionType';
import { SET_TOAST_ERROR, SET_TOAST_SUCCESS } from '../actions/toastActionType';
import {
  changeRSDVStatusAPI,
  createRSDVDocumentRequestAPI,
  getCRAInitiationPanelAPI,
  getCRAQualificationPanelAPI,
  getCRASDVSitesAPI,
  getParticipantRSDVDocumentsAPI,
  getRSDVDocumentAPI,
  getTrialSiteParticipantsAPI,
  patchVisitAPI,
} from '../api/cra';
import API_STATE from '../constant/api_constants';
import apiClient from '../helpers/apiclient';

function* getCRAInitiationPanel(action) {
  yield put({
    type: GET_CRA_INITIATION_PANEL_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    let response = yield call(getCRAInitiationPanelAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_CRA_PANEL, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get sites',
      });
    }
  } finally {
    yield put({
      type: GET_CRA_INITIATION_PANEL_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* getCRAQualificationPanel(action) {
  yield put({
    type: GET_CRA_QUALIFICATION_PANEL_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    const response = yield call(getCRAQualificationPanelAPI, action.payload);
    const { data } = response;
    yield put({ type: SET_CRA_PANEL, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get sites',
      });
    }
  } finally {
    yield put({
      type: GET_CRA_QUALIFICATION_PANEL_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* patchVisit(action) {
  yield put({
    type: PATCH_VISIT_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    yield call(patchVisitAPI, action.payload);
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Action failed',
      });
    }
  } finally {
    yield put({
      type: PATCH_VISIT_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* getCRASDVSites(action) {
  yield put({
    type: GET_CRA_SDV_SITES_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    let response = yield call(getCRASDVSitesAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_CRA_SDV_SITES, payload: data });
  } catch (error) {
    yield put({ type: SET_CRA_SDV_SITES, payload: [] });

    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get SDV sites',
      });
    }
  } finally {
    yield put({
      type: GET_CRA_SDV_SITES_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* getTrialSiteParticipants(action) {
  yield put({
    type: GET_TRIAL_SITE_PARTICIPANTS_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    let response = yield call(getTrialSiteParticipantsAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_TRIAL_SITE_PARTICIPANTS, payload: data });
  } catch (error) {
    yield put({ type: SET_TRIAL_SITE_PARTICIPANTS, payload: [] });

    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get participants',
      });
    }
  } finally {
    yield put({
      type: GET_TRIAL_SITE_PARTICIPANTS_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* getRSDVDocument(action) {
  yield put({
    type: GET_RSDV_DOCUMENT_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    apiClient.defaults.responseType = 'arraybuffer';

    let response = yield call(getRSDVDocumentAPI, action.payload);
    const { data, headers } = response;
    const contentType = headers['content-type'];
    yield put({
      type: SET_RSDV_DOCUMENT,
      payload: { content: data, contentType },
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get document',
      });
    }
  } finally {
    delete apiClient.defaults.responseType;

    yield put({
      type: GET_RSDV_DOCUMENT_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* getParticipantRSDVDocuments(action) {
  yield put({
    type: GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    let response = yield call(getParticipantRSDVDocumentsAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_PARTICIPANT_RSDV_DOCUMENTS, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get SDV documents',
      });
    }
  } finally {
    yield put({
      type: GET_PARTICIPANT_RSDV_DOCUMENTS_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* createRSDVDocumentRequest(action) {
  yield put({
    type: CREATE_RSDV_DOCUMENT_REQUEST_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    let response = yield call(createRSDVDocumentRequestAPI, action.payload);
    let { data } = response;
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to create SDV document request',
      });
    }
  } finally {
    yield put({
      type: CREATE_RSDV_DOCUMENT_REQUEST_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* changeRSDVStatus(action) {
  yield put({
    type: CHANGE_RSDV_STATUS_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    let response = yield call(changeRSDVStatusAPI, action.payload);
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'SDV status changed successfully',
    });

    const participant = {
      participantId: action.payload.participant,
    };
    response = yield call(getParticipantRSDVDocumentsAPI, participant);
    let { data } = response;

    yield put({ type: SET_PARTICIPANT_RSDV_DOCUMENTS, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to change RSDV status',
      });
    }
  } finally {
    yield put({
      type: CHANGE_RSDV_STATUS_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* craWatcher() {
  yield takeLatest(GET_CRA_INITIATION_PANEL, getCRAInitiationPanel);
  yield takeLatest(GET_CRA_QUALIFICATION_PANEL, getCRAQualificationPanel);
  yield takeLatest(PATCH_VISIT, patchVisit);
  yield takeLatest(GET_CRA_SDV_SITES, getCRASDVSites);
  yield takeLatest(GET_TRIAL_SITE_PARTICIPANTS, getTrialSiteParticipants);
  yield takeLatest(GET_RSDV_DOCUMENT, getRSDVDocument);
  yield takeLatest(GET_PARTICIPANT_RSDV_DOCUMENTS, getParticipantRSDVDocuments);
  yield takeLatest(CREATE_RSDV_DOCUMENT_REQUEST, createRSDVDocumentRequest);
  yield takeLatest(CHANGE_RSDV_STATUS, changeRSDVStatus);
}

export default craWatcher;
