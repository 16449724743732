import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import useStyles from './classes';

const emptyData = ['', 'none', null, []];

const TRadioField = props => {
  const classes = useStyles();

  const [radioOptions, setRadioOptions] = useState([]);

  const { label, key } = props.field;
  const { value } = props;

  const handleChange = event => {
    const { value: newValue } = event.target;
    props.handleChange(key, newValue);
  };

  useEffect(() => {
    if (props?.dataGrid) {
      setRadioOptions(props?.field?.extra?.values);
    } else {
      setRadioOptions(props?.field?.values);
    }
  }, [props]);

  return (
    <FormControl
      variant="outlined"
      className={`${classes.field} ${
        props?.field?.inline ? classes.inlineField : null
      }`}
      component="fieldset"
      error={props.error}
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
      size="small"
    >
      <FormLabel
        error={props.error}
        style={props.dataGrid ? { display: 'none' } : null}
        className={`${classes.label}${
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
            ? ' required-field'
            : ''
        } `}
      >
        {label}
      </FormLabel>
      <div>
        <Select
          value={value && value !== '' ? value : 'none'}
          onChange={handleChange}
          defaultValue="none"
          fullWidth
          error={props.error}
          className={props?.field?.inline ? classes.inlineInput : null}
          MenuProps={{
            classes: {
              paper: 'select-menu',
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          style={emptyData.includes(value) ? { color: '#9D9D9D' } : null}
        >
          <MenuItem
            style={{ display: 'none' }}
            value="none"
            className={classes.selectMenuItem}
            disabled
          >
            Select one
          </MenuItem>
          {radioOptions?.map(option => (
            <MenuItem
              key={option.key}
              value={option.value}
              className="select-menu-item"
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={props.error}>
          {props.errorMessage}
        </FormHelperText>
      </div>
    </FormControl>
  );
};

export default TRadioField;
