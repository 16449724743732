import React from 'react';

import { SITE_STATES_ENUM } from '../../constant/site_constants';

const WaitlistCellRenderer = params => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {params.data.status === SITE_STATES_ENUM.WAITLISTED ? (
        <div
          style={{
            backgroundColor: '#F2A700',
            fontWeight: 500,
            fontSize: '10px',
            borderRadius: '4px',
            padding: '4px',
            writingMode: 'vertical-lr',
            color: 'white',
            transform: 'rotate(180deg)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          WAITLIST
        </div>
      ) : null}
      <div
        style={{
          marginRight: '15px',
          marginLeft:
            params.data.status === SITE_STATES_ENUM.WAITLISTED
              ? '15px'
              : '35px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        title={params.data.site.doctor}
      >
        {params.data.site.doctor}
      </div>
    </div>
  );
};

export default WaitlistCellRenderer;
