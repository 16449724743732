import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import useStyles from './classes';

const inlineThreshold = 0;

const TRadioField = props => {
  const classes = useStyles();

  const [radioOptions, setRadioOptions] = useState([]);

  const { label, key, values } = props.field;
  const { value } = props;

  const handleChange = event => {
    const { value: newValue } = event.target;
    props.handleChange(key, newValue);
  };

  useEffect(() => {
    if (props?.dataGrid) {
      setRadioOptions(props?.field?.extra?.values);
    } else {
      setRadioOptions(props?.field?.values);
    }
  }, [props]);

  return (
    <FormControl
      className={`${classes.field} ${
        props?.field?.inline ? classes.inlineField : null
      }`}
      component="fieldset"
      error={props.error}
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
    >
      <FormLabel
        error={props.error}
        style={props.dataGrid ? { display: 'none' } : null}
        className={`${classes.label}${
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
            ? ' required-field'
            : ''
        } `}
      >
        {label}
      </FormLabel>
      <br />
      <div>
        <RadioGroup
          aria-label={label}
          name={key}
          value={value}
          onChange={handleChange}
          className={`${props?.field?.inline ? classes.inlineInput : null} ${
            props?.field?.inlineOptions ? classes.inlineOptions : null
          }`}
        >
          {radioOptions?.map(value => (
            <FormControlLabel
              key={value.value}
              value={value.value}
              control={
                <Radio
                  color="primary"
                  className={classes.radio}
                  disableRipple
                />
              }
              label={<Typography variant="body1">{value.label}</Typography>}
              className={classes.radioLabel}
            />
          ))}
        </RadioGroup>
        {values?.length >= inlineThreshold &&
          !props?.field?.validate?.required && (
            <Button
              onClick={() => {
                handleChange({
                  target: { value: null },
                });
              }}
              className={classes.fieldButton}
            >
              Clear selection
            </Button>
          )}
        <FormHelperText error={props.error}>
          {props.errorMessage}
        </FormHelperText>
      </div>
    </FormControl>
  );
};

export default TRadioField;
