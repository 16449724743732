import { Fade, Grid, IconButton, Paper, Tooltip } from '@material-ui/core';
import { ArrowBack, Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuditTrail } from '../actions/documentsActions';
import { TextCellRenderer } from '../common/AgGrid/TextCellRenderers';
import { isPending } from '../constant/api_constants';
import { DOCUMENT_STATUS } from '../constant/document_constants';
import { formatDateTime } from '../helpers/utils';
import { DEFAULT_COL_DEF, DEFAULT_GRID_OPTIONS } from './AgGrid/constants';
import TButton from './TButton';
import TCircularProgress from './TCircularProgress';

const TAuditTrailModal = ({
  documentObj,
  setShowAllDocumentsModal,
  onClose,
}) => {
  const getAuditTrailApi = useSelector(
    state => state.documentsReducer.getAuditTrailApi
  );
  const auditTrail = useSelector(state => state.documentsReducer.auditTrail);
  const user = useSelector(state => state.userReducer.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuditTrail(documentObj));
  }, []);

  const columnDefs = [
    {
      headerName: 'Audit Type',
      field: 'activity_type',
      filter: false,
      cellStyle: { paddingLeft: '32px' },
      width: 100,
    },
    {
      headerName: 'Date and Time of Event',
      field: 'created_at',
      valueFormatter: params => formatDateTime(params.value),
      filter: false,
      width: 100,
    },
    {
      headerName: 'User',
      field: 'user_details.full_name',
      cellRenderer: 'TextCellRenderer',
      filter: false,
      width: 130,
    },
    {
      headerName: 'Logs',
      field: 'log',
      filter: false,
      cellRenderer: 'TextCellRenderer',
    },
    {
      headerName: 'ACTIONS',
      cellRenderer: 'actionCellRenderer',
      filter: false,
      width: 60,
      headerClass: 'text-center',
    },
  ];

  const actionCellRenderer = params => {
    return (
      <div
        className="ag-react-container"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container alignItems="center" spacing={1}>
          {user.role === 'Sponsor' &&
          params.data.status !== DOCUMENT_STATUS.SIGNED ? null : (
            <Grid item>
              <Tooltip title="View document">
                <IconButton
                  onClick={() => window.open(params.data.document_link)}
                >
                  <Visibility color="primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  // const columnDefsChanges = [
  //   {
  //     headerName: 'log',
  //     field: 'Name',
  //     filter: false,
  //     cellStyle: { paddingLeft: '32px' },
  //   },
  //   {
  //     headerName: 'Old Entry',
  //     field: 'Old',
  //     filter: false,
  //     cellStyle: { paddingLeft: '32px' },
  //   },
  //   {
  //     headerName: 'New Entry',
  //     field: 'New',
  //     filter: false,
  //     cellStyle: { paddingLeft: '32px' },
  //   },
  // ];

  // const viewChangesCellRenderer = params => {
  //   return (
  //     <div
  //       className="ag-react-container"
  //       style={{
  //         display: 'flex',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <Grid container alignItems="center" spacing={1}>
  //         <Grid item>
  //           <Tooltip title="View changes">
  //             <IconButton
  //               size="medium"
  //               onClick={() => {
  //                 setChanges({
  //                   show: true,
  //                   data: params.value,
  //                 });
  //               }}
  //             >
  //               <Visibility fontSize="inherit" />
  //             </IconButton>
  //           </Tooltip>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   );
  // };

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      {isPending(getAuditTrailApi) ? (
        <TCircularProgress />
      ) : (
        <Paper
          elevation={0}
          className="p-3 custom-modal"
          style={{ minWidth: '85vw' }}
        >
          <Grid
            container
            className="p-2"
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                <div
                  className="ag-react-container"
                  style={{
                    height: '70vh',
                    width: '100%',
                  }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={auditTrail}
                    gridOptions={DEFAULT_GRID_OPTIONS}
                    frameworkComponents={{
                      actionCellRenderer,
                      TextCellRenderer,
                    }}
                    columnDefs={columnDefs}
                    defaultColDef={DEFAULT_COL_DEF}
                    suppressRowClickSelection
                    animateRows
                    suppressScrollOnNewData
                    onGridSizeChanged={params => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} className="m-2 mb-0">
              <TButton
                variant="outlined"
                color="secondary"
                style={{ float: 'right' }}
                onClick={onClose}
              >
                Close
              </TButton>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default TAuditTrailModal;
