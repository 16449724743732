import './styles/main.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'react-toastify/dist/ReactToastify.css';

import { CircularProgress, ThemeProvider } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import theme from './common/Theme';
import TToastContainer from './common/TToastContainer';
import reportWebVitals from './reportWebVitals';
import store, { history } from './store';
import ErrorBoundary from './views/error/ErrorBoundary';

const Index = () => (
  <div className="index-container">
    <TToastContainer />
    <ErrorBoundary>
      <Suspense fallback={<CircularProgress />}>
        <App />
      </Suspense>
    </ErrorBoundary>
  </div>
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <Index />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
