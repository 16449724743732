const API_STATES = Object.freeze({
  NOT_INITIATED: 'NOT_INITIATED',
  INITIATED: 'INITIATED',
  COMPLETED: 'COMPLETED',
});

export const isPending = apiState => {
  return apiState === API_STATES.INITIATED;
};
export const isCompleted = apiState => {
  return apiState === API_STATES.COMPLETED;
};

export const DOCTOR_APIS = {
  doctor: 'sites/doctor',
  users: 'users',
  trials: 'trials',
  sites: 'sites',
  profile: 'sites/doctor/profiles/',
  siteProfile: 'sites/profiles/',
  areaOfInterest: 'sites/area_of_interest',
  specializations: 'sites/specializations',
  editProfile: 'sites/doctor/profiles/<profile_id>/',
  uploadFile: 'sites/doctor/profiles/<profile_id>/files',
  editSite: 'sites/profiles/<site_id>/',
  createProfile: 'sites/doctor/profiles/',
  createSiteProfile: 'sites/profiles/',
  doctorSDVs: 'rsdv/trial/<trial_id>/participants/',
  participantSDV: '/rsdv/participants/<participant_id>/rsdv/',
  uploadSDV: '/rsdv/<id>/upload_document/',
};

export const SPONSOR_APIS = {
  sites: 'sites',
  trial: 'trials',
  trialSite: 'trials/trialsite',
  users: 'users',
  edms: 'edms',
};

export const CRA_APIS = {
  visitPanel: 'trials/cra/visit_panel',
  users: 'users',
  trials: 'trials',
  rsdv: 'rsdv',
};

export default API_STATES;
