import API_STATES from '../constant/api_constants';
import {
  GET_TRIAL_API_STATE,
  GET_TRIALS_API_STATE,
  GET_VISIT_DETAILS_API_STATE,
  SET_TRIAL,
  SET_TRIAL_STATS,
  SET_TRIALS_LIST,
  SET_VISIT_DETAILS,
  UPDATE_TRIAL_STATE_API_STATE,
} from './../actions/trialActionType';

const initialAPIState = {
  getTrialAPI: API_STATES.NOT_INITIATED,
  getTrialsAPI: API_STATES.NOT_INITIATED,
  createTrialAPI: API_STATES.NOT_INITIATED,
  updateTrialSiteAPI: API_STATES.NOT_INITIATED,
  getVisitDetailsAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialAPIState,
  trial: {},
  trialsList: [],
  trialStats: {},
  visitDetails: [],
};

export default function trialReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_TRIALS_LIST:
      return { ...state, trialsList: payload };
    case SET_TRIAL:
      return { ...state, trial: payload };
    case SET_TRIAL_STATS:
      return { ...state, trialStats: payload };
    case SET_VISIT_DETAILS:
      return { ...state, visitDetails: payload };
    case GET_VISIT_DETAILS_API_STATE:
      return { ...state, getVisitDetailsAPI: payload };
    case GET_TRIALS_API_STATE:
      return { ...state, getTrialsAPI: payload };
    case GET_TRIAL_API_STATE:
      return { ...state, getTrialAPI: payload };
    case UPDATE_TRIAL_STATE_API_STATE:
      return { ...state, updateTrialSiteAPI: payload };
    default:
      return state;
  }
}
