import React from 'react';

export const TextCellRenderer = params => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          // marginRight: '15px',
          // marginLeft: '15px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        title={params.value}
      >
        {params.value}
      </div>
    </div>
  );
};
