import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  border: {
    borderBottom: '2px solid #e9f5ff',
    width: '100%',
  },
}));

const TDivider = ({ className = '', children }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.border} />
      <span>{children}</span>
      <div className={classes.border} />
    </div>
  );
};
export default TDivider;
