export const GET_QUALIFICATION_SITES = 'GET_QUALIFICATION_SITES';
export const SET_QUALIFICATION_SITES = 'SET_QUALIFICATION_SITES';
export const ASSIGN_CRA = 'ASSIGN_CRA';
export const GET_CRAS = 'GET_CRAS';
export const SET_CRAS = 'SET_CRAS';

export const GET_CRAS_API_STATE = 'GET_CRAS_API_STATE';
export const ASSIGN_CRA_API_STATE = 'ASSIGN_CRA_API_STATE';
export const GET_QUALIFICATION_SITES_API_STATE =
  'GET_QUALIFICATION_SITES_API_STATE';
