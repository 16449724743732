import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadDocument } from '../../actions/documentsActions';
import { getQualificationSites } from '../../actions/siteQualificationActions';
import { updateTrialSiteStatus } from '../../actions/trialActions';
import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { TextCellRenderer } from '../../common/AgGrid/TextCellRenderers';
import WaitlistCellRenderer from '../../common/AgGrid/WaitlistCellRenderer';
// import EditIcon from '../../images/Edit.svg';
import { CheckmarkIcon, DownloadIcon, EditIcon } from '../../common/icons';
import TCircularProgress from '../../common/TCircularProgress';
import TSignDocumentModal from '../../common/TSignDocumentModal';
import TSignPasswordModal from '../../common/TSignPasswordModal';
import TViewAllDocumentsModal from '../../common/TViewAllDocumentsModal';
import API_STATE, { isPending } from '../../constant/api_constants';
import { VISIT_STATUS } from '../../constant/cra_constants';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
} from '../../constant/document_constants';
import {
  SITE_STAGE,
  SITE_STAGE_INDEX,
  SITE_STATES_ENUM,
} from '../../constant/site_constants';
import { formatDate } from '../../helpers/utils';
import EmptyListStateImg from '../../images/Empty_State_Empty_List.png';
import AssignCRAModal from './AssignCRAModal';
import ConfirmAssignCRAModal from './ConfirmAssignCRAModal';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '1000px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.16px',
    padding: '25px 48px',
  },
}));

const SiteQualification = () => {
  const classes = useStyles();

  const qualifiedSites = useSelector(
    state => state.siteQualificationReducer.qualifiedSites
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const updateTrialSiteAPI = useSelector(
    state => state.trialReducer.updateTrialSiteAPI
  );
  const assignCraAPI = useSelector(
    state => state.siteQualificationReducer.assignCraAPI
  );
  const getQualificationSitesAPI = useSelector(
    state => state.siteQualificationReducer.getQualificationSitesAPI
  );

  const [showAssignCRAModal, setShowAssignCRAModal] = useState(false);
  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false);
  const [showSignPasswordModal, setShowSignPasswordModal] = useState(false);

  const [showConfirmAssignCRAModal, setShowConfirmAssignCRAModal] =
    useState(false);
  const [showAllDocumentsModal, setShowAllDocumentsModal] = useState(false);
  const [documentObj, setDocumentObj] = useState({});
  const [action, setAction] = useState('');

  // TODO: Check of the below two things can be reused. Blind code due to blockage :sob:
  const [selectedTrialSiteId, setSelectedTrialSiteId] = useState(0);
  const [selectedSite, setSelectedSite] = useState({});
  const [gridApi, setGridApi] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (trialId) {
      dispatch(getQualificationSites(trialId));
    }
  }, [trialId]);

  useEffect(() => {
    if (
      updateTrialSiteAPI === API_STATE.COMPLETED ||
      assignCraAPI === API_STATE.COMPLETED
    ) {
      dispatch(getQualificationSites(trialId));
    }
  }, [updateTrialSiteAPI, assignCraAPI]);

  const columnDefs = [
    {
      headerName: 'Investigator',
      field: 'site.doctor',
      cellStyle: { padding: 0 },
      cellRenderer: 'WaitlistCellRenderer',
      maxWidth: 300,
    },
    {
      headerName: 'Site',
      field: 'site.name',
      cellStyle: { paddingLeft: '16px' },
      cellRenderer: 'TextCellRenderer',
      maxWidth: 300,
    },
    {
      headerName: 'CRA Assigned',
      field: 'visit.cra',
      cellRenderer: 'craCellRenderer',
    },
    {
      headerName: 'Visit Status',
      field: 'visit',
      cellRenderer: 'visitStatusRenderer',
      minWidth: 250,
    },
    {
      headerName: 'Report Status',
      field: 'documents',
      cellRenderer: 'reportStatusRenderer',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      cellRenderer: 'actionCellRenderer',
      maxWidth: 200,
    },
  ];

  const craCellRenderer = params => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          style={{
            marginRight: '5px',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          title={params.data.visit.cra}
        >
          {params.data.visit.cra}
        </div>
        {params.data.visit.cra &&
        params.data.visit.status !== VISIT_STATUS.COMPLETED ? (
          <Tooltip title="Change CRA">
            <IconButton
              size="small"
              onClick={() => {
                setShowAssignCRAModal(true);
                let selectedObj = selectedSite;
                setSelectedSite({
                  ...selectedObj,
                  id: params.data.id,
                  firstTime: false,
                  ...params.data,
                });
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const reportStatusRenderer = params => {
    const report = params.data?.documents[0];

    if (report?.status === DOCUMENT_STATUS.SIGNED) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CheckmarkIcon style={{ fontSize: '16px' }} />
          <div style={{ margin: '0 15px' }}>{report.status}</div>
          <Tooltip title="View document">
            <IconButton
              size="small"
              onClick={() => {
                let docObj = {
                  ...report,
                  trialId: trialId,
                  trialSiteId: params.data.id,
                  siteId: params.data.site.id,
                };
                dispatch(downloadDocument(docObj));
              }}
            >
              <DownloadIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div style={{ marginRight: '5px' }}>{report?.status}</div>
        {report?.status === DOCUMENT_STATUS.RESPONSE_RECEIVED ||
        report?.status === DOCUMENT_STATUS.SIGNED ? (
          <Tooltip title="Show Report">
            <IconButton
              size="small"
              onClick={() => {
                let docObj = {
                  ...report,
                  trialId: trialId,
                  trialSiteId: params.data.id,
                  siteId: params.data.site.id,
                };
                setDocumentObj(docObj);
                if (document.status === DOCUMENT_STATUS.SIGNED) {
                  setAction(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
                } else {
                  setAction(DOCUMENT_MODAL_ACTIONS.APPROVING);
                }
                setShowSignDocumentModal(true);
              }}
            >
              <Visibility color="primary" />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const actionCellRenderer = props => {
    const [stageObj, setStageObj] = useState({
      trialId: trialId,
      trialSiteId: props.data.id,
      siteId: props.data.site.id,
    });
    // TODO: check what does this part do
    useEffect(() => {
      let docObj = documentObj;
      let hasCDA = props.data.documents.find(doc => doc.doc_type === 'CDA');
      docObj.document = hasCDA?.type.replace('Template', '');
      docObj.docType = hasCDA?.doc_type;
      docObj.isPdf = true;
      setDocumentObj(stageObj);
      setAction(
        docObj.status === DOCUMENT_STATUS.RESPONSE_RECEIVED
          ? DOCUMENT_MODAL_ACTIONS.APPROVING
          : DOCUMENT_MODAL_ACTIONS.DOWNLOADING
      );
    });

    const handleUpdateTrialStatus = action => {
      switch (action) {
        case 'shortlist':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: SITE_STAGE.INITIATION,
              status: 'NONE',
              currentStage: SITE_STAGE.QUALIFICATION,
            })
          );
          break;
        case 'unwaitlist':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: SITE_STAGE.QUALIFICATION,
              status: 'NONE',
              currentStage: SITE_STAGE.QUALIFICATION,
            })
          );
          break;
        case 'waitlist':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: 'QUALIFICATION',
              status: 'WAITLISTED',
            })
          );
          break;
        case 'reject':
          dispatch(
            updateTrialSiteStatus({
              trialId: trialId,
              id: props.data.id,
              stage: 'QUALIFICATION',
              status: 'REJECTED',
            })
          );
          break;
        default:
          break;
      }
    };

    return (
      <div className="w-100 h-100 d-flex">
        <Grid container alignItems="center" justify="center" spacing={1}>
          <Select
            size="small"
            size="small"
            value={null}
            className={classes.select}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            input={<OutlinedInput margin="dense" />}
          >
            {!props.data.visit.cra ? (
              <MenuItem
                onClick={() => {
                  setShowAssignCRAModal(true);
                  let selectedObj = selectedSite;
                  setSelectedSite({
                    ...selectedObj,
                    id: props.data.id,
                    firstTime: true,
                  });
                }}
                value="send_CRA"
              >
                Assign CRA
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => handleUpdateTrialStatus('shortlist')}
              value="shortlist"
              disabled={
                !props.data.visit.cra ||
                props.data?.documents[0]?.status !== DOCUMENT_STATUS.SIGNED
              }
            >
              Shortlist this site
            </MenuItem>

            {props.data.status !== SITE_STATES_ENUM.WAITLISTED ? (
              <MenuItem
                onClick={() => handleUpdateTrialStatus('waitlist')}
                value="waitlist"
                disabled={props.data.status === SITE_STATES_ENUM.WAITLISTED}
              >
                Waitlist this site
              </MenuItem>
            ) : null}
            {props.data.status === SITE_STATES_ENUM.WAITLISTED ? (
              <MenuItem
                onClick={() => handleUpdateTrialStatus('unwaitlist')}
                value="waitlist"
              >
                Remove from Waitlist
              </MenuItem>
            ) : null}

            <MenuItem
              onClick={() => handleUpdateTrialStatus('reject')}
              value="reject"
            >
              Reject this site
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowAllDocumentsModal(true);
                setSelectedTrialSiteId(props.data.id);
              }}
            >
              Show all documents
            </MenuItem>
          </Select>
        </Grid>
      </div>
    );
  };

  const visitStatusRenderer = params => {
    switch (params.value.status) {
      case VISIT_STATUS.PENDING:
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Yet to be scheduled
          </div>
        );
      case VISIT_STATUS.SCHEDULED:
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div>Scheduled for</div>
            <div style={{ color: '#008CFF', marginLeft: '2px' }}>
              {formatDate(params.value.scheduled_on)}
            </div>
          </div>
        );
      case VISIT_STATUS.EXECUTED:
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Executed
          </div>
        );
      case VISIT_STATUS.COMPLETED:
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Completed
          </div>
        );
      default:
        return <></>;
    }
  };

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleExportCSV = () => {
    gridApi.exportDataAsCsv();
  };

  return (
    <>
      {isPending(getQualificationSitesAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          {!qualifiedSites?.length ? (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '150px' }}
                    src={EmptyListStateImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  No sites present in this stage
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                container
                justify="space-between"
                className="m-2"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" className="fw-semibold fs-xxl-temp">
                    Site Qualification
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div
                  className="ag-react-container"
                  style={{
                    height: '60vh',
                    width: '100%',
                  }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={qualifiedSites}
                    gridOptions={DEFAULT_GRID_OPTIONS}
                    columnDefs={columnDefs}
                    tooltipShowDelay={0}
                    frameworkComponents={{
                      reportStatusRenderer,
                      visitStatusRenderer,
                      actionCellRenderer,
                      craCellRenderer,
                      WaitlistCellRenderer,
                      TextCellRenderer,
                    }}
                    defaultColDef={{
                      ...DEFAULT_COL_DEF,
                      cellClass: ['grid-cell-6n'],
                    }}
                    pagination
                    suppressRowHoverHighlight
                    suppressPaginationPanel
                    paginationPageSize={PER_PAGE_OPTIONS[0]}
                    animateRows
                    onGridSizeChanged={params => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <AgGridPagination gridApi={gridApi} />
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Modal
        className="modal-container"
        open={showSignDocumentModal}
        onClose={() => setShowSignDocumentModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignDocumentModal}>
          <TSignDocumentModal
            setShowSignDocumentModal={setShowSignDocumentModal}
            action={action}
            documentObj={documentObj}
            setShowSignPasswordModal={setShowSignPasswordModal}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showAllDocumentsModal}
        onClose={() => setShowAllDocumentsModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showAllDocumentsModal}>
          <TViewAllDocumentsModal
            setShowAllDocumentsModal={setShowAllDocumentsModal}
            stage={SITE_STAGE.QUALIFICATION}
            trialSiteId={selectedTrialSiteId}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showSignPasswordModal}
        onClose={() => setShowSignPasswordModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignPasswordModal}>
          <TSignPasswordModal
            setShowSignPasswordModal={setShowSignPasswordModal}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showAssignCRAModal}
        onClose={() => setShowAssignCRAModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showAssignCRAModal}>
          <AssignCRAModal
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            setShowAssignCRAModal={setShowAssignCRAModal}
            setShowConfirmAssignCRAModal={setShowConfirmAssignCRAModal}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showConfirmAssignCRAModal}
        onClose={() => setShowConfirmAssignCRAModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showConfirmAssignCRAModal}>
          <ConfirmAssignCRAModal
            selectedSite={selectedSite}
            setShowConfirmAssignCRAModal={setShowConfirmAssignCRAModal}
          />
        </Fade>
      </Modal>
    </>
  );
};

export default SiteQualification;
