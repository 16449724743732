import { Box } from '@material-ui/core';
import React from 'react';

import ForgotPassword from '../../components/Auth/ForgotPassword';

const ForgotPasswordView = props => {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <ForgotPassword {...props} />
    </Box>
  );
};

export default ForgotPasswordView;
