/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ASSIGN_CRA,
  ASSIGN_CRA_API_STATE,
  GET_CRAS,
  GET_CRAS_API_STATE,
  GET_QUALIFICATION_SITES,
  GET_QUALIFICATION_SITES_API_STATE,
  SET_CRAS,
  SET_QUALIFICATION_SITES,
} from '../actions/siteQualificationActionType';
import { SET_TOAST_ERROR, SET_TOAST_SUCCESS } from '../actions/toastActionType';
import {
  assignCRAAPI,
  getCRAsAPI,
  getSiteQualificationTableAPI,
} from '../api/siteQualification';
import API_STATE from '../constant/api_constants';

function* getQualificationTable(action) {
  yield put({
    type: GET_QUALIFICATION_SITES_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    const response = yield call(getSiteQualificationTableAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_QUALIFICATION_SITES,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching qualification sites',
      });
    }
  } finally {
    yield put({
      type: GET_QUALIFICATION_SITES_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* getCRAs(action) {
  yield put({
    type: GET_CRAS_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    const response = yield call(getCRAsAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_CRAS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching CRAs',
      });
    }
  } finally {
    yield put({
      type: GET_CRAS_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* assignCRA(action) {
  yield put({ type: ASSIGN_CRA_API_STATE, payload: API_STATE.INITIATED });
  try {
    const response = yield call(assignCRAAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'CRA successfully assigned',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to assign CRA',
      });
    }
  } finally {
    yield put({ type: ASSIGN_CRA_API_STATE, payload: API_STATE.COMPLETED });
  }
}

function* siteQualificationWatcher() {
  yield takeLatest(GET_QUALIFICATION_SITES, getQualificationTable);
  yield takeLatest(ASSIGN_CRA, assignCRA);
  yield takeLatest(GET_CRAS, getCRAs);
}

export default siteQualificationWatcher;
