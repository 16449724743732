/**
 * Takes all form fields in a table layout and puts them in the
 * table field's schema, and removes them from the list of fields
 * to remove duplicacy.
 * @param components The form fields (components)
 **/
const fixTableComponents = components => {
  let _components = [...components];
  const duplicateIndices = [];

  _components.forEach((component, componentIndex) => {
    if (component.type === 'table') {
      const { schema } = component;

      schema.forEach((row, rowIndex) => {
        row.forEach((cell, columnIndex) => {
          // Assuming that one cell can only contain one field.
          const fieldKey = cell[0];
          const fieldIndex = _components.findIndex(
            field => field.key === fieldKey
          );

          if (fieldIndex !== -1) {
            const field = {
              ..._components[fieldIndex],
              hasParent: true,
              parentType: 'table',
              parentKey: component.key,
            };
            _components[componentIndex].schema[rowIndex][columnIndex] = field;
            duplicateIndices.push(fieldIndex);
          }
        });
      });
    }
  });

  // Removes all duplicate form fields (which we included in table's schema above.)
  _components = _components.filter(
    (_component, _componentIndex) => !duplicateIndices.includes(_componentIndex)
  );

  return _components;
};

const fixDataGridComponents = components => {
  let _components = [...components];
  const duplicateIndices = [];

  _components.forEach((component, componentIndex) => {
    if (component.type === 'datagrid') {
      component?.components?.forEach((component, cIndex) => {
        const key = component.key;
        const fieldIndex = components.findIndex(field => field.key === key);

        if (fieldIndex !== -1) {
          const field = components[fieldIndex];
          _components[componentIndex].components[cIndex] = field;
          duplicateIndices.push(fieldIndex);
        }
      });
    }
  });

  // Removes all duplicate form fields (which we included in table's schema above.)
  _components = _components.filter(
    (_component, _componentIndex) => !duplicateIndices.includes(_componentIndex)
  );

  return _components;
};

const spreadExtraProps = components => {
  let _components = [...components];
  components.forEach((component, index) => {
    // eslint-disable-next-line no-prototype-builtins
    if (component.hasOwnProperty('extra')) {
      _components[index] = {
        ...component,
        ...component.extra,
      };
    }
  });

  return _components;
};

/**
 * Pre-processes the form components to clean them and remove
 * redundancies.
 * @param components The form fields (components)
 * @returns cleanComponents - The cleaned components
 **/
const formPreProcess = components => {
  const extraSpreadComponents = spreadExtraProps(components);
  const dgFixedComponents = fixDataGridComponents(extraSpreadComponents);
  const cleanComponents = fixTableComponents(dgFixedComponents);

  return cleanComponents;
};

export default formPreProcess;
