import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, AddCircle, ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDoctorProfile, getDoctorProfiles } from '../actions/doctorActions';
import TAddDoctorModal from './TAddDoctorModal';
import TButton from './TButton';

const TDoctorProfileWidget = () => {
  const dispatch = useDispatch();
  const { doctorProfile, doctorProfiles, addDoctorAPI } = useSelector(
    state => state.doctorReducer
  );

  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false);
  const [doctor, setDoctor] = useState({});

  useEffect(() => {
    dispatch(getDoctorProfiles());
  }, [addDoctorAPI]);

  useEffect(() => {
    setDoctor(doctorProfiles[0]);
    dispatch(getDoctorProfile({ profileId: doctorProfiles[0]?.id }));
  }, [doctorProfiles]);

  const handleChange = event => {
    setDoctor(event.target.value);
    if (event.target.value?.id) {
      dispatch(getDoctorProfile({ profileId: event.target.value.id }));
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {doctorProfiles.length ? (
          <>
            <Grid item>
              <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                Doctor ID:
              </Typography>
            </Grid>
            <Grid item>
              {doctor?.user ? (
                <Select
                  size="small"
                  variant="standard"
                  IconComponent={ExpandMore}
                  disableUnderline
                  value={doctor}
                  defaultValue={doctor}
                  onChange={e => handleChange(e)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  className="fw-medium"
                  classes={{ select: 'py-2' }}
                  style={{ fontSize: 18 }}
                >
                  {/* <MenuItem
                style={{ fontSize: 18, backgroundColor: '#F2F9FF' }}
                value={doctorProfiles[0]}
                onClick={() => setShowAddDoctorModal(true)}
              >
                Add new doctor
              </MenuItem> */}
                  {doctorProfiles.map(profile => {
                    return (
                      <MenuItem
                        key={profile.id}
                        style={{ fontSize: 18 }}
                        value={profile}
                      >
                        {profile.user}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
              <IconButton size="medium">
                <AddCircle
                  color="primary"
                  size="large"
                  fontSize="large"
                  onClick={() => setShowAddDoctorModal(true)}
                />
              </IconButton>
            </Grid>
          </>
        ) : (
          <Grid item>
            <TButton
              onClick={() => setShowAddDoctorModal(true)}
              variant="contained"
              color="primary"
            >
              <Add />
              Add doctor
            </TButton>
          </Grid>
        )}
        <Modal
          open={showAddDoctorModal}
          onClose={() => setShowAddDoctorModal(false)}
          className="modal-container"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showAddDoctorModal}>
            <TAddDoctorModal setShowAddDoctorModal={setShowAddDoctorModal} />
          </Fade>
        </Modal>
      </Grid>
    </>
  );
};

export default TDoctorProfileWidget;
