import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';
import checkTokenExpirationMiddleWare from './redux-middleware/checkTokenExpirationMiddleware';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const saga = createSagaMiddleware();

const store = createStore(
  createRootReducer(history), // root reducer with router state
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      saga,
      thunk
      // checkTokenExpirationMiddleWare
    )
  )
);

saga.run(rootSaga);

export default store;
