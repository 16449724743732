/* eslint-disable no-console */
import { push } from 'connected-react-router';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { setLocalStorage } from '../actions/baseActions';
import { SET_TOAST_ERROR, SET_TOAST_SUCCESS } from '../actions/toastActionType';
import {
  LOGIN,
  LOGIN_API_STATE,
  LOGIN_CHECK,
  LOGIN_CHECK_API_STATE,
  LOGOUT,
  REFRESH_TOKEN,
  REQUEST_EMAIL_OTP,
  REQUEST_EMAIL_OTP_API_STATE,
  REQUEST_PHONE_OTP,
  REQUEST_PHONE_OTP_API_STATE,
  SEND_OTP_RESET_PASSWORD,
  SEND_OTP_RESET_PASSWORD_API_STATE,
  SET_IS_AUTH,
  SET_PASSWORD_RESET_REQUIRED,
  SET_USER,
  SET_USER_PERMISSIONS,
  SET_VERIFY_PHONE_API_STATE,
  SIGN_UP,
  SIGN_UP_API_STATE,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_API_STATE,
  USER_API_ERROR,
  USER_API_SUCCESS,
  VERIFY_EMAIL_OTP,
  VERIFY_EMAIL_OTP_API_STATE,
  VERIFY_OTP_RESET_PASSWORD,
  VERIFY_OTP_RESET_PASSWORD_API_STATE,
  VERIFY_PHONE,
  VERIFY_PHONE_OTP,
  VERIFY_PHONE_OTP_API_STATE,
} from '../actions/userActionType';
import {
  loginAPI,
  loginCheckAPI,
  logoutAPI,
  requestEmailOTPAPI,
  requestPhoneOTPAPI,
  sendOTPResetPasswordAPI,
  signUpAPI,
  updateUserPasswordAPI,
  verifyEmailOTPAPI,
  verifyOTPResetPasswordAPI,
  verifyPhoneAPI,
  verifyPhoneOTPAPI,
} from '../api/auth';
import ACCESS_CONSTANT from '../constant/access_constant';
import API_STATE from '../constant/api_constants';
import { getRole } from '../constant/roles_constants';
import apiClient from '../helpers/apiclient';
import { genSleep } from '../helpers/utils';

function* loginFlow(action) {
  yield put({ type: LOGIN_API_STATE, payload: API_STATE.INITIATED });
  try {
    const response = yield call(loginAPI, action.payload);
    const { data } = response;
    if (!data) {
      throw 'Login unsuccesful';
    }

    const { user, access, refresh } = data;
    const feRole = getRole(user.role);
    const userAccess = ACCESS_CONSTANT[feRole];

    setLocalStorage({
      role: feRole,
      token: access,
      refresh: refresh,
    });

    localStorage.setItem('email', action.payload.email);

    yield put({ type: SET_USER, payload: { user } });
    yield put({ type: SET_USER_PERMISSIONS, payload: { access: userAccess } });
    yield put({ type: SET_IS_AUTH, payload: true });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Login failed',
    });
    if (error.response.status === 401) {
      yield put({
        type: USER_API_ERROR,
        payload: 'No active account found with the given credentials',
      });
    }
    localStorage.clear();
  } finally {
    yield put({ type: LOGIN_API_STATE, payload: API_STATE.COMPLETED });
  }
}

function* loginCheckFlow(action) {
  apiClient.defaults.headers['content-type'] = 'application/json';

  yield put({ type: LOGIN_CHECK_API_STATE, payload: API_STATE.INITIATED });
  try {
    delete apiClient.defaults.responseType;

    const refreshToken = localStorage.getItem('refresh');
    const userRole = localStorage.getItem('role');
    if (!refreshToken) throw 'Refresh token does not exist';

    const payload = { refresh: refreshToken };
    const response = yield call(loginCheckAPI, payload);
    const { data } = response;

    const { user, access } = data;

    const feRole = getRole(user.role);
    const userAccess = ACCESS_CONSTANT[feRole];

    setLocalStorage({ role: feRole, token: access });

    yield put({ type: SET_USER, payload: { user } });

    if (userAccess !== userRole) {
      yield put({
        type: SET_USER_PERMISSIONS,
        payload: { access: userAccess },
      });
    }

    yield put({ type: SET_IS_AUTH, payload: true });

    if (action.payload) {
      try {
        action.payload.config.headers.authorization = access;
        yield call(apiClient, action.payload.config);
      } catch (err) {
        console.warn(
          '====== Error Occured while fetching new access token =========',
          err
        );
      }
    }
  } catch (error) {
    localStorage.clear();
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Could not verify login. Please login again',
    });
    yield put({
      type: LOGOUT,
      payload: {},
    });
  } finally {
    yield put({ type: LOGIN_CHECK_API_STATE, payload: API_STATE.COMPLETED });
  }
}

function* refreshTokenFlow() {
  apiClient.defaults.headers['content-type'] = 'application/json';

  try {
    delete apiClient.defaults.responseType;
    const refreshToken = localStorage.getItem('refresh');
    if (!refreshToken) throw 'Refresh token does not exist';
    const payload = { refresh: refreshToken };
    const response = yield call(loginCheckAPI, payload);
    const { data } = response;
    const { access } = data;
    setLocalStorage({ token: access });
  } catch (error) {
    localStorage.clear();
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Session expired.',
    });
    yield put({
      type: LOGOUT,
      payload: {},
    });
  }
}

function* signUp(action) {
  yield put({ type: SIGN_UP_API_STATE, payload: API_STATE.INITIATED });
  localStorage.setItem('email', action.payload.email);
  try {
    const response = yield call(signUpAPI, action.payload);
    const { data } = response;

    const { user, access, refresh } = data;
    const feRole = getRole(user.role);
    const userAccess = ACCESS_CONSTANT[feRole];

    setLocalStorage({
      role: feRole,
      token: access,
      refresh: refresh,
    });

    yield put({ type: SET_USER, payload: { user } });
    yield put({ type: SET_USER_PERMISSIONS, payload: { access: userAccess } });
    yield put({ type: SET_IS_AUTH, payload: true });

    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'User successfully registered',
    });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Error while creating user',
    });
  } finally {
    yield put({
      type: SIGN_UP_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* verifyPhoneFlow(action) {
  yield put({ type: SET_VERIFY_PHONE_API_STATE, payload: API_STATE.INITIATED });
  try {
    yield call(verifyPhoneAPI, action.payload);
    yield put({ type: SET_TOAST_SUCCESS, payload: 'Phone number verified' });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Phone number verification failed',
    });
  } finally {
    yield put({
      type: SET_VERIFY_PHONE_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* logout(action) {
  yield put({ type: LOGIN_API_STATE, payload: API_STATE.INITIATED });
  try {
    yield call(logoutAPI, action.payload);

    yield put({ type: SET_TOAST_SUCCESS, payload: "You've been logged out" });
  } finally {
    localStorage.clear();
    yield put({
      type: LOGIN_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* sendOTPResetPasswordFlow(action) {
  yield put({
    type: SEND_OTP_RESET_PASSWORD_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    yield call(sendOTPResetPasswordAPI, action.payload);
    yield put({ type: USER_API_SUCCESS, payload: true });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Unable to send OTP',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: SEND_OTP_RESET_PASSWORD_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* verifyOTPResetPassword(action) {
  yield put({
    type: VERIFY_OTP_RESET_PASSWORD_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    yield call(verifyOTPResetPasswordAPI, action.payload);
    yield put({ type: USER_API_SUCCESS, payload: true });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'OTP verification failed',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: VERIFY_OTP_RESET_PASSWORD_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* updateUserPassword(action) {
  yield put({
    type: UPDATE_PASSWORD_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    yield call(updateUserPasswordAPI, action.payload);
    yield put({ type: USER_API_SUCCESS, payload: true });
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Password updated successfully',
    });
    if (action.payload.verificationMethod === 'user')
      yield put({ type: SET_PASSWORD_RESET_REQUIRED, payload: false });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Password update failed',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: UPDATE_PASSWORD_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* requestPhoneOTP(action) {
  yield put({
    type: REQUEST_PHONE_OTP_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    yield call(requestPhoneOTPAPI, action.payload);
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Unable to send OTP',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: REQUEST_PHONE_OTP_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* requestEmailOTP(action) {
  yield put({
    type: REQUEST_EMAIL_OTP_API_STATE,
    payload: API_STATE.INITIATED,
  });
  try {
    yield call(requestEmailOTPAPI, action.payload);
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Unable to send OTP',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: REQUEST_EMAIL_OTP_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* verifyPhoneOTP(action) {
  yield put({
    type: VERIFY_PHONE_OTP_API_STATE,
    payload: API_STATE.INITIATED,
  });
  yield genSleep(2000);
  try {
    yield call(verifyPhoneOTPAPI, action.payload);
    yield put({ type: USER_API_SUCCESS, payload: true });
    yield put({ type: LOGIN_CHECK, payload: {} });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Incorrect OTP',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: VERIFY_PHONE_OTP_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* verifyEmailOTP(action) {
  yield put({
    type: VERIFY_EMAIL_OTP_API_STATE,
    payload: API_STATE.INITIATED,
  });
  yield genSleep(2000);
  try {
    yield call(verifyEmailOTPAPI, action.payload);
    yield put({ type: USER_API_SUCCESS, payload: true });
    yield put({ type: LOGIN_CHECK, payload: {} });
  } catch (error) {
    yield put({
      type: SET_TOAST_ERROR,
      payload: 'Incorrect OTP',
    });
    yield put({ type: USER_API_SUCCESS, payload: false });
  } finally {
    yield put({
      type: VERIFY_EMAIL_OTP_API_STATE,
      payload: API_STATE.COMPLETED,
    });
  }
}

function* authWatcher() {
  yield takeLatest(LOGIN, loginFlow);
  yield takeLatest(SIGN_UP, signUp);
  yield takeEvery(LOGIN_CHECK, loginCheckFlow);
  yield takeLatest(VERIFY_PHONE, verifyPhoneFlow);
  yield takeLatest(SEND_OTP_RESET_PASSWORD, sendOTPResetPasswordFlow);
  yield takeLatest(VERIFY_OTP_RESET_PASSWORD, verifyOTPResetPassword);
  yield takeLatest(UPDATE_PASSWORD, updateUserPassword);
  yield takeLatest(REQUEST_PHONE_OTP, requestPhoneOTP);
  yield takeLatest(REQUEST_EMAIL_OTP, requestEmailOTP);
  yield takeLatest(VERIFY_PHONE_OTP, verifyPhoneOTP);
  yield takeLatest(VERIFY_EMAIL_OTP, verifyEmailOTP);
  yield takeLatest(LOGOUT, logout);
  yield takeLatest(REFRESH_TOKEN, refreshTokenFlow);
}

export default authWatcher;
