import { DOCTOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';
import { USER_APIS } from '../routes.constant';

export const loginAPI = async data => {
  const apiUrl = USER_APIS.login;
  return apiClient.post(apiUrl, data);
};

export const loginCheckAPI = async data => {
  const apiUrl = USER_APIS.refreshLogin;
  return apiClient.post(apiUrl, data);
};

export const sendOTPResetPasswordAPI = async data => {
  const apiUrl = USER_APIS.resetPassword;
  return apiClient.post(apiUrl, data);
};

export const verifyPhoneAPI = async data => {
  const apiUrl = USER_APIS.verifyPhone;
  return apiClient.post(apiUrl, data);
};

export const signUpAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.users}/sign_up`;
  return apiClient.post(apiUrl, data);
};

export const verifyOTPResetPasswordAPI = async ({
  verificationMethod,
  ...data
}) => {
  const apiUrl = `${USER_APIS.resetPassword}/${verificationMethod}/validate`;
  return apiClient.post(apiUrl, data);
};

export const updateUserPasswordAPI = async ({
  verificationMethod,
  ...data
}) => {
  if (verificationMethod === 'user') {
    const apiUrl = USER_APIS.firstLoginSetPassword;
    let apiData = {
      old_password: data.password,
      new_password: data.password2,
    };
    return apiClient.patch(apiUrl, apiData);
  }

  const apiUrl = `${USER_APIS.resetPassword}/${verificationMethod}/update`;
  return apiClient.post(apiUrl, data);
};

export const requestPhoneOTPAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.users}/verify_phone`;
  return apiClient.get(apiUrl, data);
};

export const requestEmailOTPAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.users}/verify_email`;
  return apiClient.get(apiUrl, data);
};

export const verifyPhoneOTPAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.users}/verify_phone`;
  return apiClient.post(apiUrl, data);
};

export const verifyEmailOTPAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.users}/verify_email`;
  return apiClient.post(apiUrl, data);
};

export const logoutAPI = async () => {
  const apiUrl = `${DOCTOR_APIS.users}/logout`;
  return apiClient.post(apiUrl);
};
