import { Box } from '@material-ui/core';
import React from 'react';

import UpdatePassword from '../../components/Auth/updatePassword';

const UpdatePasswordView = props => {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <UpdatePassword {...props} />
    </Box>
  );
};

export default UpdatePasswordView;
