import { Box, Typography } from '@material-ui/core';
import React from 'react';

import TDivider from '../../../common/TDivider';
import * as Sections from './Sections';

const ProfileSections = props => {
  return (
    <>
      <Sections.PersonalDetails doctorProfile={props.doctorProfile} />

      <TDivider />

      <Sections.WorkExperience doctorProfile={props.doctorProfile} />

      <TDivider />

      <Sections.Education doctorProfile={props.doctorProfile} />

      <TDivider />

      <Sections.ClinicalResearchExperience
        doctorProfile={props.doctorProfile}
      />

      <TDivider />

      <Sections.CovidClinicalResearchExperience
        doctorProfile={props.doctorProfile}
      />
      <TDivider />

      <Sections.ResearchStaff
        doctorProfile={props.doctorProfile}
        siteProfile={props.siteProfile}
      />
    </>
  );
};

export default ProfileSections;
