import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { patchVisit } from '../../actions/craActions';
import TButton from '../../common/TButton';
import TDatePicker from '../../common/TDatePicker';

const ScheduleVisitModal = ({
  setShowScheduleVisitModal,
  setConfirmationModalLabel,
  setShowConfirmationModal,
  selectedSite,
}) => {
  const [date, setDate] = useState(
    selectedSite.scheduled_on
      ? selectedSite.scheduled_on
      : new Date().toISOString().slice(0, 10)
  );
  const craId = useSelector(state => state.userReducer.user.id);
  const dispatch = useDispatch();

  const handleProceed = () => {
    setShowScheduleVisitModal(false);
    dispatch(
      patchVisit({
        scheduled_on: date,
        cra_id: craId,
        visitId: selectedSite.id,
        status: 2,
      })
    );
    setConfirmationModalLabel('Visit Scheduled');
    setShowConfirmationModal(true);
  };

  const handleDateChange = e => {
    let schDate = new Date(e).toISOString().slice(0, 10);
    setDate(schDate);
  };

  return (
    <Paper elevation={0} className="p-4 custom-modal">
      <Grid
        container
        direction="column"
        className="mx-4"
        spacing={2}
        justify="flex-end"
      >
        <Grid item>
          <Typography
            className="mt-3"
            style={{ font: 'normal normal 600 20px/30px Poppins' }}
          >
            Schedule a visit
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Site Name: {selectedSite?.trial_site?.site?.name}{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Investigator Name: {selectedSite?.trial_site?.site?.doctor}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>Contact Number:</Typography>
        </Grid>
        <Grid item container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Typography>Visit date:</Typography>
          </Grid>
          <Grid item>
            <TDatePicker
              onDateChange={handleDateChange}
              date={date}
              disablePast={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} className="my-3">
          <Grid item>
            <TButton
              onClick={() => setShowScheduleVisitModal(false)}
              variant="outlined"
              color="secondary"
              fullWidth
            >
              Cancel
            </TButton>
          </Grid>
          <Grid item>
            <TButton
              onClick={() => handleProceed()}
              variant="contained"
              color="primary"
              fullWidth
            >
              Schedule Visit
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ScheduleVisitModal;
