import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDoctorProfile, getSiteProfile } from '../../actions/doctorActions';
import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import ProfileEdit from '../../components/Doctor/ProfileEdit';
import ProfileView from '../../components/Doctor/ProfileView';
import { isCompleted } from '../../constant/api_constants';

const DoctorProfileView = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const doctorProfile = useSelector(
    state => state.doctorReducer.doctorProfile[0]
  );
  const {
    doctorProfileAPI,
    siteProfileAPI,
    editDoctorProfileAPI,
    doctorFileUploadApi,
  } = useSelector(state => state.doctorReducer);

  const [view, setView] = useState('view');

  const toggleView = () => {
    setView(prevView => {
      if (prevView === 'view') {
        return 'edit';
      } else if (prevView === 'edit') {
        dispatch(getSiteProfile());
        return 'view';
      }
    });
    if (user.role === 'Clinical Research Coordinator') {
      if (doctorProfile?.id) {
        dispatch(getDoctorProfile({ profileId: doctorProfile.id }));
      }
    } else {
      dispatch(getDoctorProfile());
    }
  };

  useEffect(() => {
    if (user.role === 'Clinical Research Coordinator') {
      dispatch(
        setAppBarConfig({
          showDoctorProfiles: true,
        })
      );
    }
    if (user.role === 'Clinical Research Coordinator') {
      if (doctorProfile?.id) {
        dispatch(getDoctorProfile({ profileId: doctorProfile.id }));
      }
    } else {
      dispatch(getDoctorProfile());
    }
    dispatch(getSiteProfile());
  }, []);

  useEffect(() => {
    if (isCompleted(doctorProfileAPI) && isCompleted(siteProfileAPI)) {
      if (!doctorProfile?.is_profile_created_by_user) {
        setView('edit');
      }
    }
  }, [doctorProfileAPI, siteProfileAPI]);

  return (
    <div className="h-100">
      {view === 'view' && <ProfileView toggleView={toggleView} />}
      {view === 'edit' && <ProfileEdit toggleView={toggleView} />}
    </div>
  );
};

export default DoctorProfileView;
