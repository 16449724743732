import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  ADD_DOCTOR,
  ADD_DOCTOR_API_STATE,
  ADD_GRANT,
  ADD_GRANT_API_STATE,
  CREATE_DOCTOR_PROFILE,
  CREATE_DOCTOR_PROFILE_API_STATE,
  CREATE_PARTICIPANT,
  CREATE_PARTICIPANT_API_STATE,
  CREATE_SITE_PROFILE,
  CREATE_SITE_PROFILE_API_STATE,
  DOCTOR_FILE_UPLOAD,
  DOCTOR_FILE_UPLOAD_API_STATE,
  EDIT_DOCTOR_PROFILE,
  EDIT_DOCTOR_PROFILE_API_STATE,
  EDIT_SITE_PROFILE,
  EDIT_SITE_PROFILE_API_STATE,
  GET_ALL_CITIES,
  GET_DOCTOR_AREA_OF_INTEREST,
  GET_DOCTOR_PROFILE,
  GET_DOCTOR_PROFILES,
  GET_DOCTOR_PROFILES_API_STATE,
  GET_DOCTOR_SPECIALIZATIONS,
  GET_DOCTOR_TRIALS,
  GET_DOCTOR_TRIALS_API_STATE,
  GET_DOCTOR_TRIALS_CRC,
  GET_DOCTOR_TRIALS_CRC_API_STATE,
  GET_FIXED_DOCUMENTS,
  GET_FIXED_DOCUMENTS_API_STATE,
  GET_HOSPITALS,
  GET_HOSPITALS_API_STATE,
  GET_PARTICIPANT_SDV,
  GET_PARTICIPANT_SDV_API_STATE,
  GET_SDV_PARTICIPANTS_LIST,
  GET_SDV_PARTICIPANTS_LIST_API_STATE,
  GET_SITE_PROFILE,
  SET_API_SUCCESS,
  SET_CITIES,
  SET_DOCTOR_AREA_OF_INTEREST,
  SET_DOCTOR_AREA_OF_INTEREST_API_STATE,
  SET_DOCTOR_PROFILE,
  SET_DOCTOR_PROFILE_API_STATE,
  SET_DOCTOR_PROFILES,
  SET_DOCTOR_SPECIALIZATIONS,
  SET_DOCTOR_SPECIALIZATIONS_API_STATE,
  SET_DOCTOR_TRIALS,
  SET_EMPTY_SITE_PROFILE,
  SET_FIXED_DOCUMENTS,
  SET_HOSPITALS,
  SET_PARTIAL_DOCTOR_PROFILE,
  SET_PARTICIPANT_SDV,
  SET_SDV_PARTICIPANTS_LIST,
  SET_SITE_PROFILE,
  SET_SITE_PROFILE_API_STATE,
  UPLOAD_SDV_DOCUMENT,
  UPLOAD_SDV_DOCUMENT_API_STATE,
} from '../actions/doctorActionType';
import { SET_TOAST_ERROR, SET_TOAST_SUCCESS } from '../actions/toastActionType';
import {
  addDoctorAPI,
  addGrantAPI,
  createDoctorProfileAPI,
  createParticipantAPI,
  createSiteProfileAPI,
  deleteDocumentAPI,
  doctorFileUploadAPI,
  editDoctorProfileAPI,
  editSiteProfileAPI,
  getAllCitiesAPI,
  getAllHospitalsAPI,
  getDoctorAreaOfInterestAPI,
  getDoctorProfileAPI,
  getDoctorSpecializationsAPI,
  getDoctorTrialsAPI,
  getDoctorTrialsCRCAPI,
  getFixedDocumentAPI,
  getParticipantSDVAPI,
  getSDVParticipantsListAPI,
  getSiteProfileAPI,
  uploadSDVDocumentAPI,
} from '../api/doctor';
import API_STATES from '../constant/api_constants';
import {
  EMPTY_DOCTOR_PROFILE,
  EMPTY_SITE_PROFILE,
} from '../constant/doctor_constant';
import apiClient from '../helpers/apiclient';

function* getDoctorProfile(action) {
  yield put({
    type: SET_DOCTOR_PROFILE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    // const userRole = localStorage.getItem('role');

    let response = yield call(getDoctorProfileAPI, action.payload);
    const { data } = response;

    // if (userRole === 'CRC') {
    //   yield put({ type: SET_DOCTOR_PROFILES, payload: data });
    //   yield put({
    //     type: SET_DOCTOR_PROFILE,
    //     payload: data[0] ? [data[0]] : [],
    //   });
    // } else {
    // yield put({ type: SET_DOCTOR_PROFILE, payload: data });
    // }
    yield put({ type: SET_DOCTOR_PROFILE, payload: data.id ? [data] : data });
  } catch (error) {
    if (error.response.status !== 404 || error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: "Failed to get doctor's profile.",
      });
    }
  } finally {
    yield put({
      type: SET_DOCTOR_PROFILE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getDoctorProfiles(action) {
  yield put({
    type: GET_DOCTOR_PROFILES_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(getDoctorProfileAPI, action.payload);
    const { data } = response;

    yield put({ type: SET_DOCTOR_PROFILES, payload: data });
  } catch (error) {
    if (error.response.status !== 404 || error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get doctor profiles.',
      });
    }
  } finally {
    yield put({
      type: GET_DOCTOR_PROFILES_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* addGrant(action) {
  yield put({
    type: ADD_GRANT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(addGrantAPI, action.payload);
  } catch (error) {
    if (error.response.status !== 401) {
      if (error.response.status === 403) {
        yield put({
          type: SET_TOAST_ERROR,
          payload: 'Wrong password entered. Try again.',
        });
      } else {
        yield put({
          type: SET_TOAST_ERROR,
          payload: 'Failed to get sites',
        });
      }
    }
  } finally {
    yield put({
      type: ADD_GRANT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getDoctorAreaOfInterest(action) {
  yield put({
    type: SET_DOCTOR_AREA_OF_INTEREST_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(getDoctorAreaOfInterestAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_DOCTOR_AREA_OF_INTEREST, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: "Failed to get doctor's area of interest.",
      });
    }
  } finally {
    yield put({
      type: SET_DOCTOR_AREA_OF_INTEREST_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getDoctorSpecializations(action) {
  yield put({
    type: SET_DOCTOR_SPECIALIZATIONS_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(getDoctorSpecializationsAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_DOCTOR_SPECIALIZATIONS, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: "Failed to get doctor's specializations.",
      });
    }
  } finally {
    yield put({
      type: SET_DOCTOR_SPECIALIZATIONS_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getDoctorTrials(action) {
  yield put({
    type: GET_DOCTOR_TRIALS_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getDoctorTrialsAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_DOCTOR_TRIALS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get trials',
      });
    }
  } finally {
    yield put({
      type: GET_DOCTOR_TRIALS_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getSiteProfile(action) {
  yield put({
    type: SET_SITE_PROFILE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(getSiteProfileAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_SITE_PROFILE, payload: data });
  } catch (error) {
    if (error.response.status === 403) {
      // yield put({
      //   type: SET_TOAST_ERROR,
      //   payload: "You don't have permission to fetch site profile.",
      // });
    } else if (error.response.status !== 404 || error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get site profile.',
      });
    }
  } finally {
    yield put({
      type: SET_SITE_PROFILE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getFixedDocuments(action) {
  yield put({
    type: GET_FIXED_DOCUMENTS_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(getFixedDocumentAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_FIXED_DOCUMENTS, payload: data });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching documents.',
      });
    }
  } finally {
    yield put({
      type: GET_FIXED_DOCUMENTS_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getAllCities(action) {
  yield put({
    type: GET_DOCTOR_TRIALS_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getAllCitiesAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_CITIES,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get cities list',
      });
    }
  } finally {
    yield put({
      type: GET_DOCTOR_TRIALS_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* editDoctorProfile(action) {
  yield put({
    type: EDIT_DOCTOR_PROFILE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  yield put({ type: SET_API_SUCCESS, payload: false });

  try {
    if (action.payload.fileData?.data) {
      yield call(doctorFileUploadAPI, action.payload.fileData);
      yield put({
        type: SET_TOAST_SUCCESS,
        payload: 'Document Uploaded.',
      });
    } else if (action.payload.fileData?.delete) {
      yield call(deleteDocumentAPI, action.payload.fileData);
    }
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to update document.',
      });
    }
  }

  try {
    let response = yield call(editDoctorProfileAPI, action.payload.profile);
    let { data } = response;
    yield put({ type: SET_DOCTOR_PROFILE, payload: [data] });
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Updated profile successfully.',
    });
    yield put({ type: SET_API_SUCCESS, payload: true });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to update profile.',
      });
    }
  } finally {
    yield put({
      type: EDIT_DOCTOR_PROFILE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* editSiteProfile(action) {
  yield put({
    type: EDIT_SITE_PROFILE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    apiClient.defaults.headers['content-type'] = 'application/json';
    let response = yield call(editSiteProfileAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_SITE_PROFILE, payload: data });
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Updated site profile successfully.',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to update site profile.',
      });
    }
  } finally {
    yield put({
      type: EDIT_SITE_PROFILE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* createDoctorProfile(action) {
  yield put({
    type: CREATE_DOCTOR_PROFILE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(createDoctorProfileAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_DOCTOR_PROFILE, payload: data });
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Profile created successfully.',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to create profile.',
      });
    }
  } finally {
    yield put({
      type: CREATE_DOCTOR_PROFILE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* createSiteProfile(action) {
  yield put({
    type: CREATE_SITE_PROFILE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    let response = yield call(createSiteProfileAPI, action.payload);
    let { data } = response;

    yield put({ type: SET_SITE_PROFILE, payload: data });
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Site profile created successfully.',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to create site profile.',
      });
    }
  } finally {
    yield put({
      type: CREATE_SITE_PROFILE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getAllHospitals(action) {
  yield put({
    type: GET_HOSPITALS_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getAllHospitalsAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_HOSPITALS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get hospitals list',
      });
    }
  } finally {
    yield put({
      type: GET_HOSPITALS_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* setPartialDoctorProfile(action) {
  yield put({ type: SET_DOCTOR_PROFILE, payload: action.payload });
}

function* setEmptySiteProfile() {
  yield put({ type: SET_SITE_PROFILE, payload: EMPTY_SITE_PROFILE });
}

function* doctorFileUpload(action) {
  yield put({
    type: DOCTOR_FILE_UPLOAD_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(doctorFileUploadAPI, action.payload);

    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Document Uploaded',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to upload document',
      });
    }
  } finally {
    yield put({
      type: DOCTOR_FILE_UPLOAD_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getDoctorTrialsCRC(action) {
  yield put({
    type: GET_DOCTOR_TRIALS_CRC_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getDoctorTrialsCRCAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_DOCTOR_TRIALS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get trials',
      });
    }
  } finally {
    yield put({
      type: GET_DOCTOR_TRIALS_CRC_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* addDoctor(action) {
  yield put({
    type: ADD_DOCTOR_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(addDoctorAPI, action.payload);
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Doctor profile created successfully',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to create doctor profile',
      });
    }
  } finally {
    yield put({
      type: ADD_DOCTOR_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getSDVParticipantsList(action) {
  yield put({
    type: GET_SDV_PARTICIPANTS_LIST_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getSDVParticipantsListAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_SDV_PARTICIPANTS_LIST,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get participants list',
      });
    }
  } finally {
    yield put({
      type: GET_SDV_PARTICIPANTS_LIST_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getParticipantSDV(action) {
  yield put({
    type: GET_PARTICIPANT_SDV_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getParticipantSDVAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_PARTICIPANT_SDV,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to get participants list',
      });
    }
  } finally {
    yield put({
      type: GET_PARTICIPANT_SDV_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* createParticipant(action) {
  yield put({
    type: CREATE_PARTICIPANT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(createParticipantAPI, action.payload);
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Participant created',
    });
    yield put({
      type: GET_SDV_PARTICIPANTS_LIST,
      payload: {
        trialId: action.payload.trialId,
        doctorId: action.payload.doctorId,
      },
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to create participant',
      });
    }
  } finally {
    yield put({
      type: CREATE_PARTICIPANT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* uploadSDVDocument(action) {
  yield put({
    type: UPLOAD_SDV_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(uploadSDVDocumentAPI, action.payload);

    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Document Uploaded',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Failed to upload document',
      });
    }
  } finally {
    yield put({
      type: UPLOAD_SDV_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* doctorWatcher() {
  yield takeLatest(GET_DOCTOR_PROFILE, getDoctorProfile);
  yield takeLatest(GET_DOCTOR_PROFILES, getDoctorProfiles);
  yield takeLatest(GET_DOCTOR_AREA_OF_INTEREST, getDoctorAreaOfInterest);
  yield takeLatest(GET_DOCTOR_SPECIALIZATIONS, getDoctorSpecializations);
  yield takeLatest(GET_SITE_PROFILE, getSiteProfile);
  yield takeLatest(EDIT_DOCTOR_PROFILE, editDoctorProfile);
  yield takeLatest(EDIT_SITE_PROFILE, editSiteProfile);
  yield takeLatest(CREATE_DOCTOR_PROFILE, createDoctorProfile);
  yield takeLatest(CREATE_SITE_PROFILE, createSiteProfile);
  yield takeLatest(ADD_GRANT, addGrant);
  yield takeLatest(GET_DOCTOR_TRIALS, getDoctorTrials);
  yield takeLatest(GET_HOSPITALS, getAllHospitals);
  yield takeLatest(GET_ALL_CITIES, getAllCities);
  yield takeLatest(SET_PARTIAL_DOCTOR_PROFILE, setPartialDoctorProfile);
  yield takeLatest(SET_EMPTY_SITE_PROFILE, setEmptySiteProfile);
  yield takeLatest(DOCTOR_FILE_UPLOAD, doctorFileUpload);
  yield takeLatest(GET_DOCTOR_TRIALS_CRC, getDoctorTrialsCRC);
  yield takeLatest(GET_FIXED_DOCUMENTS, getFixedDocuments);
  yield takeLatest(ADD_DOCTOR, addDoctor);
  yield takeLatest(GET_SDV_PARTICIPANTS_LIST, getSDVParticipantsList);
  yield takeLatest(GET_PARTICIPANT_SDV, getParticipantSDV);
  yield takeLatest(CREATE_PARTICIPANT, createParticipant);
  yield takeLatest(UPLOAD_SDV_DOCUMENT, uploadSDVDocument);
}

export default doctorWatcher;
