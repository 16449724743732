import _ from 'lodash';

import formPreProcess from './formPreProcess';
import { getEmptyFormData as emptySectionResponse } from './formUtil';

const sectionSchema = {
  id: 0,
  name: '',
  description: '',
  data: [],
};

const getSectionData = ({ section = sectionSchema, sectionResponse }) => {
  const components = [];
  section?.data?.forEach(component => {
    components.push(component.components);
  });

  const cleanComponents = formPreProcess(components);

  const emptyResponse = emptySectionResponse(components);

  let response = emptyResponse;

  if (sectionResponse !== null) {
    let completeResponse = sectionResponse;

    for (const key in sectionResponse) {
      if (Array.isArray(sectionResponse[key])) {
        const emptyDataGridRow = emptyResponse[key][0];

        sectionResponse[key].forEach((row, idx) => {
          completeResponse[key][idx] = {
            ...emptyDataGridRow,
            ...row,
          };
        });
      }
    }

    response = {
      ...emptyResponse,
      ...completeResponse,
    };
  }

  const savedResponse = sectionResponse !== null ? response : null;

  const sectionData = {
    id: section.id,
    name: section.name || '',
    description: section.description || '',
    components: cleanComponents,
    response: response,
    originalResponse: _.cloneDeep(savedResponse),
  };

  return sectionData;
};

export const getSectionsData = data => {
  const sections = Array.isArray(data) ? data : data.sections;

  const sectionsData = [];
  sections?.forEach((section, index) => {
    if (section.response) {
      sectionsData.push(
        getSectionData({
          section: section.data,
          sectionResponse: section.response,
        })
      );
    } else {
      sectionsData.push(
        getSectionData({
          section: section,
          sectionResponse: null,
        })
      );
    }
  });

  return sectionsData;
};

export const getFirstUnfilledSection = (sections = []) => {
  let index = 0,
    counter = 0;

  // find first unfilled section
  for (let i = 0; i < sections.length; i++) {
    counter++;
    if (sections[i]?.originalResponse === null) {
      index = i;
      break;
    }
  }

  if (counter === sections.length) {
    index = sections.length - 1;
  }

  return index;
};
