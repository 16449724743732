/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_FEASIBILITY_TABLE,
  GET_FEASIBILITY_TABLE_API_STATE,
  SET_FEASIBILITY_TABLE,
} from '../actions/feasibilityActionType';
import { SET_TOAST_ERROR } from '../actions/toastActionType';
import { getFeasibilityTableAPI } from '../api/feasibility';
import API_STATES from '../constant/api_constants';

function* getFeasibilityTable(action) {
  yield put({
    type: GET_FEASIBILITY_TABLE_API_STATE,
    payload: API_STATES.INITIATED,
  });

  try {
    const response = yield call(getFeasibilityTableAPI, action.payload);
    const { data } = response;
    // console.log(data);
    yield put({
      type: SET_FEASIBILITY_TABLE,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while getting feasibility sites',
      });
    }
  } finally {
    yield put({
      type: GET_FEASIBILITY_TABLE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* feasibilityWatcher() {
  yield takeLatest(GET_FEASIBILITY_TABLE, getFeasibilityTable);
}

export default feasibilityWatcher;
