import {
  APPROVE_DOCUMENT_API_STATE,
  DIRECT_UPLOAD_DOCUMENT_API_STATE,
  DOWNLOAD_TEMPLATE_IN_PDF_API_STATE,
  E_SIGN_DOCUMENT_API_STATE,
  GENERATE_AND_SEND_DOCUMENT_API_STATE,
  GET_AUDIT_TRAIL_API_STATE,
  GET_DOCUMENT_API_STATE,
  GET_DOCUMENTS_API_STATE,
  SET_AUDIT_TRAIL,
  SET_DOCUMENT,
  SET_DOCUMENT_TEMPLATE_PDF,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENTS,
  SET_E_SIGN_DOCUMENT_LINK,
  SET_ESIGNING_ERROR,
  SET_TEMPLATES,
  SET_UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_API_STATE,
} from '../actions/documentsActionType';
import API_STATES from '../constant/api_constants';

const initialApiStates = {
  getDocumentApi: API_STATES.NOT_INITIATED,
  getDocumentsApi: API_STATES.NOT_INITIATED,
  sendDocumentAPI: API_STATES.NOT_INITIATED,
  approveDocumentApi: API_STATES.NOT_INITIATED,
  eSignDocumentApi: API_STATES.NOT_INITIATED,
  uploadDocumentApi: API_STATES.NOT_INITIATED,
  getAuditTrailApi: API_STATES.NOT_INITIATED,
  directUploadDocumentApi: API_STATES.NOT_INITIATED,
  downloadTemplatePdfApi: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialApiStates,
  document: {},
  documentTemplatePdf: {},
  docType: '',
  documents: [],
  templates: [],
  auditTrail: [],
  eSignDocumentLink: '',
  uploadDocumentSuccess: false,
  esigningError: false,
};

export default function documentsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_DOCUMENT:
      return { ...state, document: payload };
    case SET_DOCUMENT_TYPE:
      return { ...state, docType: payload };
    case SET_DOCUMENTS:
      return { ...state, documents: payload };
    case SET_TEMPLATES:
      return { ...state, templates: payload };
    case SET_DOCUMENT_TEMPLATE_PDF:
      return { ...state, documentTemplatePdf: payload };
    case SET_E_SIGN_DOCUMENT_LINK:
      return { ...state, eSignDocumentLink: payload };
    case SET_AUDIT_TRAIL:
      return { ...state, auditTrail: payload };
    case SET_ESIGNING_ERROR:
      return { ...state, esigningError: payload };
    case SET_UPLOAD_DOCUMENT_SUCCESS:
      return { ...state, uploadDocumentSuccess: payload };
    case GET_DOCUMENT_API_STATE:
      return { ...state, getDocumentApi: payload };
    case GET_DOCUMENTS_API_STATE:
      return { ...state, getDocumentsApi: payload };
    case GENERATE_AND_SEND_DOCUMENT_API_STATE:
      return { ...state, sendDocumentAPI: payload };
    case DOWNLOAD_TEMPLATE_IN_PDF_API_STATE:
      return { ...state, downloadTemplatePdfApi: payload };
    case UPLOAD_DOCUMENT_API_STATE:
      return { ...state, uploadDocumentApi: payload };
    case DIRECT_UPLOAD_DOCUMENT_API_STATE:
      return { ...state, directUploadDocumentApi: payload };
    case APPROVE_DOCUMENT_API_STATE:
      return { ...state, approveDocumentApi: payload };
    case E_SIGN_DOCUMENT_API_STATE:
      return { ...state, eSignDocumentApi: payload };
    case GET_AUDIT_TRAIL_API_STATE:
      return { ...state, getAuditTrailApi: payload };
    default:
      return state;
  }
}
