import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '30px 24px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '8px',
  },
  field: {
    width: '100%',
    padding: '10px 5px',
    borderRadius: '8px',
  },
  inlineField: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  inlineInput: {
    width: '340px',
  },
  inlineOptions: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: '1rem',
    lineHeight: 1.8,
    fontWeight: 500,
    color: '#2c3e50 !important',
    marginBottom: '5px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fieldButton: {
    width: 'min-content',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    alignSelf: 'flex-end',
    color: '#7C7F84',
    float: 'right',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  radio: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  radioLabel: {
    paddingBottom: '15px',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  dataGridComponent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataGrid: {},
  tableCell: {
    border: '1px solid #d3d4d7',
    minWidth: '15rem',
  },
  tableCellBorderLess: {
    border: '0px solid #d3d4d7',
    padding: '0 16px',
    paddingLeft: '0',
  },
}));

export default useStyles;
