import { Box, Chip, IconButton, Typography } from '@material-ui/core';
import { LinkedIn, Twitter } from '@material-ui/icons';
import React from 'react';

import { LinkedInIcon, ResearchGateIcon } from '../../../../common/icons';

export const PersonalDetails = ({ doctorProfile }) => {
  return (
    <Box className="my-3 mx-5">
      <div className="flex my-3 mx-3">
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          Name of Doctor:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          {doctorProfile?.user}
        </Typography>
      </div>
      <div className="flex my-3 mx-3">
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          Trial Site Name:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          {doctorProfile?.hospital_name}
        </Typography>
      </div>
      <div className="flex my-3 mx-3">
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          Speciality:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          {doctorProfile?.field_of_study}
        </Typography>
      </div>
      <div className="flex my-3 mx-3">
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          License Number:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          {doctorProfile?.license_number}
        </Typography>
      </div>
      <div className="flex my-3 mx-3">
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          Issuing Authority:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          {doctorProfile?.issuing_authority}
        </Typography>
      </div>
      <div className="d-flex my-3 mx-3" style={{ alignItems: 'center' }}>
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          Resume:
        </Typography>
        <Typography color="primary" className="fs-xxl fw-medium flex mx-4">
          <a
            className="text-decoration-none c-blue"
            href={doctorProfile?.resume}
            target={'_blank'}
            rel="noreferrer"
          >
            {doctorProfile?.resume_name}
          </a>
        </Typography>
      </div>
      <div className="flex my-3 mx-3">
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          GCP Certificate:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          <a
            className="text-decoration-none c-blue"
            href={doctorProfile?.clinical_experience?.gcp_certificate}
            target={'_blank'}
            rel="noreferrer"
          >
            {doctorProfile?.clinical_experience?.gcp_certificate_name}
          </a>
        </Typography>
      </div>

      <div className="d-flex my-3 mx-3" style={{ alignItems: 'center' }}>
        <Typography
          variant="body1"
          className="fs-xxl fw-semibold c-subheading-text"
        >
          Area of Interest:
        </Typography>
        <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
          <div className="chips-container">
            {doctorProfile?.area_of_interest?.map(value => (
              <Chip
                key={value}
                label={value}
                className="mx-1"
                color="primary"
              />
            ))}
          </div>
        </Typography>
      </div>

      <div className="d-flex my-4 mx-3" style={{ alignItems: 'center' }}>
        <Twitter color="primary" />
        <Typography color="primary" className="fs-xxl fw-medium flex mx-4">
          <a
            className="text-decoration-none c-blue"
            href={doctorProfile?.twitter_profile}
            target="_blank"
            rel="noreferrer"
          >
            {doctorProfile?.twitter_profile}
          </a>
        </Typography>
      </div>
      <div className="d-flex my-4 mx-3" style={{ alignItems: 'center' }}>
        <LinkedInIcon />
        <Typography color="primary" className="fs-xxl fw-medium flex mx-4">
          <a
            className="text-decoration-none c-blue"
            href={doctorProfile?.linkedin_profile}
            target="_blank"
            rel="noreferrer"
          >
            {doctorProfile?.linkedin_profile}
          </a>
        </Typography>
      </div>
      <div className="d-flex my-4 mx-3" style={{ alignItems: 'center' }}>
        <ResearchGateIcon />
        <Typography color="primary" className="fs-xxl fw-medium flex mx-4">
          <a
            className="text-decoration-none c-blue"
            href={doctorProfile?.researchgate_profile}
            target="_blank"
            rel="noreferrer"
          >
            {doctorProfile?.researchgate_profile}
          </a>
        </Typography>
      </div>
    </Box>
  );
};
