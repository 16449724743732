import {
  GET_ALL_TRIALS,
  GET_TRIAL,
  GET_VISIT_DETAILS,
  SET_TRIAL,
  UPDATE_TRIAL_SITE_STATUS,
} from './trialActionType';

export const getAllTrials = payload => ({
  type: GET_ALL_TRIALS,
  payload,
});

export const getTrial = payload => ({
  type: GET_TRIAL,
  payload,
});

export const setTrial = payload => ({
  type: SET_TRIAL,
  payload,
});

export const getVisitDetails = payload => ({
  type: GET_VISIT_DETAILS,
  payload,
});

export const updateTrialSiteStatus = payload => ({
  type: UPDATE_TRIAL_SITE_STATUS,
  payload,
});
