import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';

export const TTableCell = withStyles(theme => ({
  root: {
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    },
    fontWeight: '500 !important',
  },
  head: {
    color: theme.palette.primary.main,
  },
}))(MuiTableCell);

export const TTable = withStyles(() => ({
  root: {
    minWidth: 200,
    border: 'none',
    borderSpacing: '0px 8px !important',
    borderCollapse: 'separate !important',
  },
  table: {
    minWidth: 650,
  },
}))(MuiTable);

export const TTableHead = withStyles(theme => ({
  root: {
    color: '#008cff !important',
    background: 'rgba(0, 140, 255, 0.2) 0% 0% no-repeat padding-box',
    border: '0px',
  },
}))(MuiTableHead);

export const TTableRow = withStyles(theme => ({
  root: {
    borderRadius: '8px !important',
  },
}))(MuiTableRow);

export const TTableContainer = withStyles(theme => ({
  root: {
    border: 'none',
    borderRadius: '8px',
  },
}))(MuiTableContainer);

export const TTableBody = withStyles(theme => ({
  root: {
    border: '7px solid #BFE2FF',
    background: '#F2F9FF',
  },
}))(MuiTableBody);
