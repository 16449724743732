export const S3_BASE_URL = '';
// eslint-disable-next-line no-underscore-dangle
export const _DATE_ = process.env.REACT_APP_DATE || 0; // 1577232000000 = Dec 25
export const BASE_VRF_URL =
  process.env.REACT_APP_BASE_VRF_URL || 'http://localhost:3000'; // 1577232000000 = Dec 25
export const WEEK = 7;

export const ROLES = {
  user: 'User',
};

export const routeToNavTitle = {
  '/login': 'Login',
  '/signup': 'Signup',
};

export const SET_LOCALSTORAGE_PARAMETERS = ['token', 'refresh', 'role'];

export const DEFAULT_LANDING = '/';

export const CONFIG_FILE = {
  ACCESS_CONSTANTS_FILE: 'access_constants_file.json',
};
