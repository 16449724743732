import { DOCTOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';
import apiClientMultipart from '../helpers/apiClientMultipart';

export const getDoctorProfileAPI = async data => {
  let apiUrl;
  if (data?.profileId) {
    apiUrl = `${DOCTOR_APIS.profile}${data.profileId}/`;
  } else {
    apiUrl = `${DOCTOR_APIS.profile}`;
  }
  return apiClient.get(apiUrl);
};

export const getDoctorAreaOfInterestAPI = async () => {
  const apiUrl = DOCTOR_APIS.areaOfInterest;
  return apiClient.get(apiUrl);
};

export const getDoctorSpecializationsAPI = async () => {
  const apiUrl = DOCTOR_APIS.specializations;
  return apiClient.get(apiUrl);
};

export const getSiteProfileAPI = async () => {
  const apiUrl = DOCTOR_APIS.siteProfile;
  return apiClient.get(apiUrl);
};

export const editDoctorProfileAPI = async data => {
  const apiUrl = DOCTOR_APIS.editProfile.replace('<profile_id>', data.id);
  return apiClient.patch(apiUrl, data);
};

export const editSiteProfileAPI = async data => {
  const apiUrl = DOCTOR_APIS.editSite.replace('<site_id>', data.id);
  return apiClient.patch(apiUrl, data);
};

export const createDoctorProfileAPI = async data => {
  const apiUrl = DOCTOR_APIS.createProfile;
  return apiClient.post(apiUrl, data);
};

export const createSiteProfileAPI = async data => {
  const apiUrl = DOCTOR_APIS.createSiteProfile;
  return apiClient.post(apiUrl, data);
};

export const addGrantAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.trials}/${data.trialId}/trialsite/${data.trialSiteId}/add_grant`;
  return apiClient.post(apiUrl, data);
};

export const getDoctorTrialsAPI = async () => {
  const apiUrl = `${DOCTOR_APIS.trials}/my_trials`;
  return apiClient.get(apiUrl);
};

export const getAllCitiesAPI = async () => {
  const apiUrl = `${DOCTOR_APIS.users}/cities`;
  return apiClient.get(apiUrl);
};

export const getAllHospitalsAPI = async () => {
  const apiUrl = `${DOCTOR_APIS.sites}/hospitals`;
  return apiClient.get(apiUrl);
};

export const doctorFileUploadAPI = async data => {
  const apiUrl = DOCTOR_APIS.uploadFile.replace('<profile_id>', data.id);
  return apiClientMultipart.patch(apiUrl, data.data);
};

export const getDoctorTrialsCRCAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.trials}/doctor_trials/${data}`;
  return apiClient.get(apiUrl);
};

export const deleteDocumentAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.profile}${data.id}/files/${data.fileName}`;
  return apiClient.delete(apiUrl);
};

export const getFixedDocumentAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.trials}/fixed_documents/trialsite/${data.trialSiteId}/stage/${data.stageId}`;
  return apiClient.get(apiUrl);
};

export const addDoctorAPI = async data => {
  const apiUrl = `${DOCTOR_APIS.users}/crc/create_doctor`;
  return apiClient.post(apiUrl, data);
};

export const getSDVParticipantsListAPI = async ({ trialId, doctorId }) => {
  const apiUrl = doctorId
    ? DOCTOR_APIS.doctorSDVs
        .replace('<trial_id>', trialId)
        .concat(`doctor/${doctorId}/`)
    : DOCTOR_APIS.doctorSDVs.replace('<trial_id>', trialId);
  return apiClient.get(apiUrl);
};

export const getParticipantSDVAPI = async ({ participant, sdvStatus }) => {
  let apiUrl = DOCTOR_APIS.participantSDV.replace(
    '<participant_id>',
    participant
  );
  if (sdvStatus) apiUrl = apiUrl.concat(`?status=${sdvStatus}`);
  return apiClient.get(apiUrl);
};

export const createParticipantAPI = async ({ trialId, doctorId, ...data }) => {
  const apiUrl = doctorId
    ? DOCTOR_APIS.doctorSDVs
        .replace('<trial_id>', trialId)
        .concat(`doctor/${doctorId}/`)
    : DOCTOR_APIS.doctorSDVs.replace('<trial_id>', trialId);
  return apiClient.post(apiUrl, data);
};

export const uploadSDVDocumentAPI = async ({ id, formData }) => {
  const apiUrl = DOCTOR_APIS.uploadSDV.replace('<id>', id);
  return apiClientMultipart.post(apiUrl, formData);
};
