import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';

import TButton from '../../common/TButton';
import { VISIT_STATUS } from '../../constant/cra_constants';

const CRAInfoModal = ({
  setShowAssignCRAModal,
  setShowCraInfoModal,
  memberDetails,
}) => {
  const handleProceed = () => {
    setShowCraInfoModal(false);
    setShowAssignCRAModal(true);
  };

  return (
    <Paper
      elevation={0}
      style={{ width: '25rem' }}
      className="p-4 custom-modal"
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h5" className="fw-semibold">
            CRA Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            fullWidth
            orientation="horizontal"
            style={{
              height: '2px',
              width: '100%',
              marginBottom: '1rem',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <strong>Name</strong>: {memberDetails.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <strong>Email</strong>: {memberDetails.email}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <strong>Mobile</strong>: {memberDetails.phone}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          container
          direction="row"
          justify="flex-end"
          className="my-2"
          spacing={3}
        >
          {memberDetails.role === 'CRA' &&
          memberDetails.visit_obj.status < VISIT_STATUS.EXECUTED ? (
            <Grid item>
              <TButton
                onClick={() => handleProceed()}
                variant="contained"
                color="primary"
                fullWidth
              >
                Change CRA
              </TButton>
            </Grid>
          ) : null}
          <Grid item>
            <TButton
              onClick={() => setShowCraInfoModal(false)}
              color="secondary"
              variant="outlined"
              fullWidth
            >
              Close
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CRAInfoModal;
