import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

import { COUNTRY_CODES, DEFAULT_COUNTRY_CODE } from '../constant/country_codes';

const useStyles = makeStyles(() => ({
  countryCode: {
    minWidth: '80px',
  },
}));

const TPhoneInput = ({
  onCountryCodeChange = () => {},
  countryCodeValue = DEFAULT_COUNTRY_CODE,
  InputProps,
  ...props
}) => {
  const classes = useStyles();

  const CountryCodes = (
    <Autocomplete
      className={classes.countryCode}
      options={COUNTRY_CODES}
      getOptionLabel={option => option.dial_code}
      getOptionSelected={(option, value) =>
        option.dial_code === value.dial_code
      }
      value={countryCodeValue}
      onChange={(event, newValue) => {
        onCountryCodeChange(newValue);
      }}
      disableClearable
      renderInput={params => (
        <TextField
          size="small"
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );

  return (
    <TextField
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{CountryCodes}</InputAdornment>
        ),
        ...InputProps,
      }}
      inputProps={{ maxLength: 10 }}
      {...props}
    />
  );
};

export default TPhoneInput;
