import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { ToggleButton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { createParticipant } from '../../../actions/doctorActions';
import TButton from '../../../common/TButton';
import TDatePicker from '../../../common/TDatePicker';
import TStyledDialog, {
  TStyledDialogActions,
  TStyledDialogContent,
  TStyledDialogTitle,
} from '../../../common/TStyledDailog';
import { isCompleted, isPending } from '../../../constant/api_constants';
import {
  COHORT_CHOICES,
  ERROR_MESSAGE,
} from '../../../constant/doctor_constant';
import { validateFormWithMessages } from '../../../helpers/validateHelper';
import SDVMatrix from './SDVMatrix';

const useStyles = makeStyles(() => ({
  dialog: {
    width: 450,
    display: 'block',
    overflowY: 'hidden',
  },
}));

const SDV = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.userReducer.user);
  const createParticipantAPI = useSelector(
    state => state.doctorReducer.createParticipantAPI
  );
  const firstDoctorTrial = useSelector(
    state => state.doctorReducer.doctorTrials[0]
  );
  const doctorProfile = useSelector(state => state.doctorReducer.doctorProfile);
  const [open, setOpen] = useState(false);
  const [createRequest, setCreateRequest] = useState(false);
  const [createRequestInitiated, setCreateRequestInitiated] = useState(false);
  const [valErrorList, setValErrorList] = useState([]);
  const [valErrorMessageList, setValErrorMessageList] = useState([]);
  const [formData, setFormData] = useState({
    randomization_id: '',
    cohort: '',
    enrollment_date: null,
  });

  const validator = Yup.object().shape({
    randomization_id: Yup.string().required(
      ERROR_MESSAGE.RANDOMIZATION_ID_ERROR_MESSAGE
    ),
    cohort: Yup.string().required(ERROR_MESSAGE.COHORT_ERROR_MESSAGEL),
    enrollment_date: Yup.date()
      .typeError(ERROR_MESSAGE.DATE_ERROR_MESSAGE)
      .required(ERROR_MESSAGE.DATE_ERROR_MESSAGE),
  });

  const validateAll = async () => {
    let returnVal = false;
    await validateFormWithMessages(validator, formData).then(
      ({ isError, valErrors, errorMessages }) => {
        if (isError) {
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
          returnVal = false;
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
          returnVal = true;
        }
      }
    );
    return returnVal;
  };

  const handleDateChange = e => {
    let schDate = new Date(e).toISOString().slice(0, 10);
    setFormData({ ...formData, enrollment_date: schDate });
  };

  const handleClose = () => {
    setFormData({
      randomization_id: '',
      cohort: '',
      enrollment_date: null,
    });
    setOpen(false);
    setCreateRequest(false);
    setCreateRequestInitiated(false);
    setValErrorList([]);
    setValErrorMessageList({});
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const isFormValid = await validateAll();
    if (isFormValid) {
      if (user.role === 'Clinical Research Coordinator') {
        dispatch(
          createParticipant({
            ...formData,
            trialId: firstDoctorTrial.trial_id,
            doctorId: doctorProfile[0]?.user_id,
          })
        );
      } else {
        dispatch(
          createParticipant({ ...formData, trialId: firstDoctorTrial.trial_id })
        );
      }
      setCreateRequest(true);
    }
  };

  useEffect(() => {
    if (isPending(createParticipantAPI)) setCreateRequestInitiated(true);
    else if (
      isCompleted(createParticipantAPI) &&
      createRequestInitiated &&
      createRequest
    ) {
      handleClose();
    }
  }, [createParticipantAPI, createRequestInitiated, createRequest]);

  return (
    <Grid container>
      <Grid container direction="row" alignItems="center">
        <Typography variant="h5" className="fw-semibold">
          Site Document Verification
        </Typography>
        <Grid item container xs justifyContent="flex-end">
          <TButton
            variant="contained"
            color="primary"
            className="fs-xxl"
            onClick={() => setOpen(true)}
          >
            Add Participant
          </TButton>
        </Grid>
      </Grid>
      <SDVMatrix />
      <TStyledDialog
        BackdropProps={{ className: 'blurred-background' }}
        open={open}
        onClose={handleClose}
      >
        <TStyledDialogTitle>Add Participant</TStyledDialogTitle>
        <TStyledDialogContent className={classes.dialog}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} direction="column">
              <Grid item>
                <div className="mb-1">Participant ID</div>
                <TextField
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: false }}
                  fullWidth
                  value={formData.randomization_id}
                  error={valErrorList.includes('randomization_id')}
                  helperText={
                    valErrorList.includes('randomization_id')
                      ? valErrorMessageList['randomization_id']
                      : ''
                  }
                  onChange={e =>
                    setFormData({
                      ...formData,
                      randomization_id: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item>
                <div className="mb-1">Select cohort</div>
                {COHORT_CHOICES.map((choice, index) => (
                  <ToggleButton
                    key={index}
                    className="text-capitalize me-2"
                    value={choice}
                    selected={formData.cohort === choice}
                    onClick={() => setFormData({ ...formData, cohort: choice })}
                    style={{
                      color: 'rgba(0,0,0,0.6)',
                      border: '1px solid rgba(0,0,0,0.5)',
                    }}
                  >
                    {choice}
                  </ToggleButton>
                ))}
                {valErrorList.includes('cohort') && (
                  <div className="text-danger fs-s py-2 px-3">
                    {valErrorMessageList['cohort']}
                  </div>
                )}
              </Grid>
              <Grid item>
                <div className="mb-1">Date of Enrolment</div>
                <TDatePicker
                  date={formData.enrollment_date}
                  onDateChange={handleDateChange}
                  fullWidth
                  disableFuture
                  error={valErrorList.includes('enrollment_date')}
                  helperText={
                    valErrorList.includes('enrollment_date')
                      ? valErrorMessageList['enrollment_date']
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </TStyledDialogContent>
        <TStyledDialogActions>
          <TButton size="large" variant="outlined" onClick={handleClose}>
            Go Back
          </TButton>
          <TButton
            isLoading={isPending(createParticipantAPI)}
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Add Participant
          </TButton>
        </TStyledDialogActions>
      </TStyledDialog>
    </Grid>
  );
};

export default SDV;
