/* eslint-disable no-console */
import { all, call, spawn } from 'redux-saga/effects';

import authSaga from './authSaga';
import craSaga from './craSaga';
import doctorSaga from './doctorSaga';
import documentsSaga from './documentsSaga';
import feasibilitySaga from './feasilbilitySaga';
import siteIdentificationSaga from './siteIdentificationSaga';
import siteInitiationSaga from './siteInitiationSaga';
import siteQualificationSaga from './siteQualificationSaga';
import trialSaga from './trialSaga';
/**
 * Define the rootSaga to combine sagas
 */
export default function* rootSaga() {
  const sagas = [
    authSaga,
    siteIdentificationSaga,
    feasibilitySaga,
    siteInitiationSaga,
    siteQualificationSaga,
    trialSaga,
    craSaga,
    doctorSaga,
    documentsSaga,
  ];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // console.log(e);
          }
        }
      })
    )
  );
}
