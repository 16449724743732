/* eslint-disable no-console */
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_SHORTLISTED_SITE_API_STATE,
  GET_SHORTLISTED_SITES,
  GET_SITE,
  GET_SITE_API_STATE,
  GET_SITES,
  GET_SITES_API_STATE,
  REMOVE_SHORTLIST_SITE,
  REMOVE_SITE_API_STATE,
  SET_SHORTLISTED_SITE,
  SET_SHORTLISTED_SITES,
  SET_SITE,
  SET_SITES,
  SHORTLIST_SITE,
  SHORTLIST_SITE_API_STATE,
} from '../actions/siteIdentificationActionType';
import { SET_TOAST_ERROR, SET_TOAST_SUCCESS } from '../actions/toastActionType';
import {
  createTrialSiteAPI,
  getAllIdentificationSitesAPI,
  getSelectedSiteInfoAPI,
  getShortlistedSitesAPI,
  removeTrialSiteAPI,
} from '../api/sites';
import API_STATES from '../constant/api_constants';

function* getSites(action) {
  yield put({
    type: GET_SITES_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getAllIdentificationSitesAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_SITES,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while getting sites',
      });
    }
  } finally {
    yield put({ type: GET_SITES_API_STATE, payload: API_STATES.COMPLETED });
  }
}

function* getSite(action) {
  yield put({
    type: GET_SITE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getSelectedSiteInfoAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_SITE,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while getting site information',
      });
    }
  } finally {
    yield put({ type: GET_SITE_API_STATE, payload: API_STATES.COMPLETED });
  }
}

function* getShortlistedSites(action) {
  yield put({
    type: GET_SHORTLISTED_SITE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getShortlistedSitesAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_SHORTLISTED_SITES,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while getting site information',
      });
    }
  } finally {
    yield put({
      type: GET_SHORTLISTED_SITE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* shortlistSite(action) {
  yield put({
    type: SHORTLIST_SITE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(createTrialSiteAPI, action.payload);
    const { data } = response;
    yield put({ type: SET_SHORTLISTED_SITE, payload: data.id });
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Site shortlisted',
    });
    yield put({ type: GET_SITES, payload: action.payload.getSitesParams });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Site Shortlisted',
      });
    }
  } finally {
    yield put({
      type: SHORTLIST_SITE_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* removeShortlistSite(action) {
  yield put({
    type: REMOVE_SITE_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(removeTrialSiteAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Site removed',
    });

    yield put({ type: GET_SITES, payload: action.payload.getSitesParams });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while removing shortlisted site',
      });
    }
  } finally {
    yield put({ type: REMOVE_SITE_API_STATE, payload: API_STATES.COMPLETED });
  }
}

function* siteIdentificationWatcher() {
  yield takeLatest(GET_SITE, getSite);
  yield takeLatest(GET_SITES, getSites);
  yield takeLatest(SHORTLIST_SITE, shortlistSite);
  yield takeLatest(REMOVE_SHORTLIST_SITE, removeShortlistSite);
  yield takeLatest(GET_SHORTLISTED_SITES, getShortlistedSites);
}

export default siteIdentificationWatcher;
