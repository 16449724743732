import { Box } from '@material-ui/core';
import React from 'react';

import { Login } from '../../components/Auth';

const LoginView = props => {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Login {...props} />
    </Box>
  );
};

export default LoginView;
