// @flow
import yaml from 'js-yaml';

import genFileFromS3 from './aws_s3';

export default async function genSitevar(sitevarName) {
  // this would need to have the file extension along with it, until we don't move all
  // the files to yml (currently model_config, voice_config are in json)

  const BUCKET_NAME = 'triomics-assets';
  const fileKey = `sitevars/${sitevarName}`;
  const response = await genFileFromS3(BUCKET_NAME, fileKey);

  const fileExtension = sitevarName.split('.').pop();
  // check if file extension is YAML, if yes transpile to JSON and return
  if (fileExtension === 'yaml' || fileExtension === 'yml') {
    const responseText = await response.text();
    const responseJSON = await yaml.safeLoad(responseText);
    return responseJSON;
  }
  const responseJSON = await response.json();
  return responseJSON;
}
