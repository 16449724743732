import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import TriomicsLogo from '../../images/TriomicsLogo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '30rem',
    backgroundColor: 'white',
  },
  logo: {
    width: '10rem',
  },
}));

const AuthCard = ({ cardHeader, children }) => {
  const classes = useStyles();
  return (
    <Card
      className={`${classes.root} custom-box p-3`}
      style={{ overflow: 'scroll', maxHeight: '90vh' }}
    >
      <CardHeader
        title={
          <>
            <img src={TriomicsLogo} className={`${classes.logo} mb-5`} />
            {cardHeader}
          </>
        }
      />

      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default AuthCard;
