import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TContainer from '../../common/TContainer';
import ProfileView from '../../components/Doctor/ProfileView';
import ProfileSections from '../../components/Doctor/ProfileView/ProfileSections';
import SiteDetails from '../../components/SiteIdentification/SiteDetails';
import DoctorProfileView from '../doctor/DoctorProfileView';

const SiteDetailsView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let titleConfig = {
      text: 'Investigator Profile',
      isLink: true,
      link: `/site-identification`,
      prevIcon: true,
      prevLink: `/site-identification`,
    };
    dispatch(
      setAppBarConfig({
        title: titleConfig,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, []);

  return (
    <TContainer>
      <Grid container spacing={3} className="w-100">
        <SiteDetails />
        {/* <DoctorProfileView /> */}
      </Grid>
    </TContainer>
  );
};

export default SiteDetailsView;
