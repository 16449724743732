import { Button, Grid, ListItemText, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllTrials, getTrial } from '../actions/trialActions';
import { StyledMenu, StyledMenuItem } from './TMenu';

const TTrialWidget = () => {
  const dispatch = useDispatch();
  const trial = useSelector(state => state.trialReducer.trial);
  const trialsList = useSelector(state => state.trialReducer.trialsList);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = trialId => {
    dispatch(getTrial(trialId));
  };

  useEffect(() => {
    dispatch(getAllTrials());
  }, []);

  return (
    <>
      {trial?.id && trialsList.length > 0 && (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={handleOpen}
            >
              <Grid item>
                <Typography
                  className="tt-none fs-l"
                  style={{ fontWeight: 500 }}
                >
                  Trial ID: {trial.name}
                </Typography>
              </Grid>
              <Grid item>
                {anchorEl ? (
                  <ExpandLess className="mx-2" />
                ) : (
                  <ExpandMore className="mx-2" />
                )}
              </Grid>
            </Button>

            <div className="t-menu">
              <StyledMenu
                id="customized-menu"
                className="t-menu-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {trialsList.map((trialItem, idx) => (
                  <StyledMenuItem
                    onClick={() => {
                      handleChange(trialItem.id);
                    }}
                    key={idx}
                    selected={trialItem?.id === trial.id}
                  >
                    <ListItemText
                      primary={
                        <span
                          className={
                            trialItem?.id === trial.id ? 'fw-semibold' : ''
                          }
                        >
                          {trialItem.name}
                        </span>
                      }
                      selected={trialItem?.id === trial.id}
                    />
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TTrialWidget;
