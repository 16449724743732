import {
  GET_SHORTLISTED_SITE_API_STATE,
  GET_SITE_API_STATE,
  GET_SITES_API_STATE,
  REMOVE_SITE_API_STATE,
  SET_SHORTLISTED_SITE,
  SET_SHORTLISTED_SITES,
  SET_SITE,
  SET_SITES,
  SET_TAB_INDEX,
  SHORTLIST_SITE_API_STATE,
} from '../actions/siteIdentificationActionType';
import API_STATES from '../constant/api_constants';

const initialPendingState = {
  getSiteAPI: API_STATES.NOT_INITIATED,
  getSitesAPI: API_STATES.NOT_INITIATED,
  removeSiteAPI: API_STATES.NOT_INITIATED,
  shortlistSiteAPI: API_STATES.NOT_INITIATED,
  getShortlistedSiteAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialPendingState,
  sites: [],
  shortlistedSites: [],
  shortlistedSite: null,
  site: {},
  tabIndex: 0,
};

export default function siteIdentificationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_SITE:
      return { ...state, site: payload };
    case SET_SITES:
      return { ...state, sites: payload };
    case SET_TAB_INDEX:
      return { ...state, tabIndex: payload };
    case SET_SHORTLISTED_SITE:
      return { ...state, shortlistedSite: payload };
    case SET_SHORTLISTED_SITES:
      return { ...state, shortlistedSites: payload };
    case GET_SITE_API_STATE:
      return { ...state, getSiteAPI: payload };
    case GET_SITES_API_STATE:
      return { ...state, getSitesAPI: payload };
    case SHORTLIST_SITE_API_STATE:
      return { ...state, shortlistSiteAPI: payload };
    case GET_SHORTLISTED_SITE_API_STATE:
      return { ...state, getShortlistedSiteAPI: payload };
    case REMOVE_SITE_API_STATE:
      return { ...state, removeSiteAPI: payload };
    default:
      return state;
  }
}
