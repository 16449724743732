import { Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDoctorProfile,
  getDoctorTrials,
  getDoctorTrialsCRC,
  getSiteProfile,
} from '../../actions/doctorActions';
import { setTabIndex } from '../../actions/siteIdentificationActions';
import { getInitiationSiteDetails } from '../../actions/siteInitiationActions';
import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import TContainer from '../../common/TContainer';
import TTabPanel from '../../common/TTabPanel';
import SiteActivation from '../../components/Doctor/SiteActivation';
import SiteVisit from '../../components/Doctor/SiteVisit';
import UpcomingTrial from '../../components/Doctor/UpcomingTrial';
import { isPending } from '../../constant/api_constants';
import { SITE_STAGE_INDEX } from '../../constant/site_constants';
import { CONFIG_FILE } from '../../constants';
import genSitevar from '../../helpers/sitevars';
import EmptyStateDoctorImg from '../../images/Empty_State_Doctors_Dashboard.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    maxHeight: '3em',
  },
  scroller: {
    justifyContent: 'center',
  },
  tab: {
    maxHeight: '3em',
    minHeight: '2.5em',
    minWidth: '5em',
    padding: '0 1.25rem',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '1.125rem',
    textTransform: 'none',
  },
  selected: {
    color: theme.palette.primary.main,
    zIndex: 2,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  indicator: {
    background: theme.palette.primary.main,
    minWidth: '5em',
    height: '4px',
  },
  stickyHeader: {
    position: 'sticky',
    top: '4rem',
    zIndex: 1,
  },
}));

const DoctorUpcomingTrialsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(state => state.userReducer.user);
  const { doctorProfile, doctorProfileAPI } = useSelector(
    state => state.doctorReducer
  );
  const doctorTrials = useSelector(state => state.doctorReducer.doctorTrials);

  const firstDoctorTrial = useSelector(
    state => state.doctorReducer.doctorTrials[0]
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [showTabPanel, setShowTabPanel] = useState({
    showQualificationPanel: 'false',
    showInitiationPanel: false,
  });
  const [selectedTrialId, setSelectedTrialId] = useState();
  const [selectedTrialSiteId, setSelectedTrialSiteId] = useState();

  useEffect(() => {
    genSitevar(CONFIG_FILE.ACCESS_CONSTANTS_FILE).then(res => {
      setShowTabPanel(res);
    });

    if (doctorProfile && user.role === 'Clinical Research Coordinator') {
      dispatch(
        setAppBarConfig({
          showDoctorProfiles: true,
        })
      );
    }

    if (user.role === 'Clinical Research Coordinator') {
      if (doctorProfile?.id) {
        dispatch(getDoctorProfile({ profileId: doctorProfile.id }));
      }
    } else {
      dispatch(getDoctorProfile());
    }
  }, []);

  useEffect(() => {
    setSelectedTrialId(firstDoctorTrial?.trial_id);
    setSelectedTrialSiteId(firstDoctorTrial?.id);
  }, [firstDoctorTrial]);

  useEffect(() => {
    if (user?.verified) {
      if (user.role === 'Clinical Research Coordinator') {
        if (doctorProfile[0]) {
          dispatch(getDoctorTrialsCRC(doctorProfile[0]?.user_id));
        }
      } else {
        dispatch(getDoctorTrials());
      }
    }
  }, [doctorProfile]);

  return (
    <TContainer>
      {isPending(doctorProfileAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          {!doctorProfile?.length || !user?.verified || !doctorTrials.length ? (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '100px' }}
                    src={EmptyStateDoctorImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  {!doctorTrials.length
                    ? 'There are no upcoming trials at the moment. Please check back later.'
                    : 'Your Profile is not verified yet. Our teams will contact you soon.'}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={4}>
              <Grid item container direction="row" className="mx-2">
                <Grid
                  item
                  xs
                  container
                  alignItems="center"
                  className={classes.stickyHeader}
                >
                  <Grid item>
                    <Tabs
                      value={tabIndex}
                      onChange={(_, newValue) => {
                        setTabIndex(newValue);
                      }}
                      classes={{
                        root: classes.root,
                        scroller: classes.scroller,
                        indicator: classes.indicator,
                      }}
                    >
                      <Tab
                        label="FEASIBILITY"
                        classes={{
                          root: classes.tab,
                          selected: classes.selected,
                        }}
                      />
                      {showTabPanel.showQualificationPanel &&
                      firstDoctorTrial.stage >=
                        SITE_STAGE_INDEX.QUALIFICATION ? (
                        <Tab
                          label="SITE VISIT"
                          disabled={firstDoctorTrial.stage < 3}
                          classes={{
                            root: classes.tab,
                            selected: classes.selected,
                          }}
                        />
                      ) : null}
                      {showTabPanel.showInitiationPanel &&
                      firstDoctorTrial.stage >= SITE_STAGE_INDEX.INITIATION ? (
                        <Tab
                          label="SITE ACTIVATION"
                          disabled={firstDoctorTrial.stage < 4}
                          classes={{
                            root: classes.tab,
                            selected: classes.selected,
                          }}
                        />
                      ) : null}
                    </Tabs>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TTabPanel value={tabIndex} index={0}>
                  <Grid item xs={12}>
                    <UpcomingTrial firstDoctorTrial={firstDoctorTrial} />
                  </Grid>
                </TTabPanel>
                <TTabPanel value={tabIndex} index={1}>
                  <Grid item xs={12}>
                    <SiteVisit
                      selectedTrialId={selectedTrialId}
                      selectedTrialSiteId={selectedTrialSiteId}
                    />
                  </Grid>
                </TTabPanel>
                <TTabPanel value={tabIndex} index={2}>
                  <Grid item xs={12}>
                    <SiteActivation
                      doctorTrials={firstDoctorTrial}
                      selectedTrialId={selectedTrialId}
                      selectedTrialSiteId={selectedTrialSiteId}
                    />
                  </Grid>
                </TTabPanel>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </TContainer>
  );
};

export default DoctorUpcomingTrialsView;
