import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import {
  downloadDocument,
  getAllDocuments,
  getDocument,
} from '../actions/documentsActions';
import { PER_PAGE_OPTIONS } from '../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../common/AgGrid/constants';
import { AuditTrailIcon, CheckmarkIcon } from '../common/icons';
import TButton from '../common/TButton';
import TConfirmationModal from '../common/TConfirmationModal';
import TSignDocumentModal from '../common/TSignDocumentModal';
import TSignPasswordModal from '../common/TSignPasswordModal';
import TUploadDocumentsModal from '../common/TUploadDocumentsModal';
import { isPending } from '../constant/api_constants';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
} from '../constant/document_constants';
import TAuditTrailModal from './TAuditTrailModal';
import TCircularProgress from './TCircularProgress';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '1000px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.16px',
    padding: '25px 48px',
  },
}));

const TViewAllDocumentsModal = ({
  stage,
  trialSiteId,
  siteTrialId,
  setShowAllDocumentsModal,
}) => {
  const siteImportantDocuments = useSelector(
    state => state.documentsReducer.documents
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const pdfDocument = useSelector(state => state.documentsReducer.document);
  const getDocumentsApi = useSelector(
    state => state.documentsReducer.getDocumentsApi
  );
  const directUploadDocumentApi = useSelector(
    state => state.documentsReducer.directUploadDocumentApi
  );
  const user = useSelector(state => state.userReducer.user);
  const classes = useStyles();

  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [showUploadDocumentsModal, setShowUploadDocumentsModal] =
    useState(false);
  const [showSignPasswordModal, setShowSignPasswordModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAuditTrailModal, setShowAuditTrailModal] = useState(false);
  const [documentDownloading, setDocumentDownloading] = useState(false);
  const [confirmationModalLabel, setConfirmationModalLabel] = useState('');
  const [action, setAction] = useState(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
  const { id } = useParams();
  const [documentObj, setDocumentObj] = useState({
    trialId: siteTrialId ? siteTrialId : trialId,
    trialSiteId: id ? id : trialSiteId,
  });

  const dispatch = useDispatch();

  const columnDefs = [
    {
      headerName: 'FORM',
      field: 'display_name',
    },
    {
      headerName: 'STATUS',
      cellRenderer: 'documentStatusRenderer',
    },
    {
      headerName: 'ACTIONS',
      cellRenderer: 'actionCellRenderer',
      maxWidth: 200,
      filter: false,
    },
  ];

  useEffect(() => {
    dispatch(
      getAllDocuments({
        trialId: siteTrialId ? siteTrialId : trialId,
        trialSiteId: id ? id : trialSiteId,
      })
    );
  }, [directUploadDocumentApi]);

  useEffect(() => {
    let filteredDoc;
    // if (stage) {
    //   filteredDoc = siteImportantDocuments.filter(
    //     doc => doc.site_stage === stage
    //   );
    // } else filteredDoc = siteImportantDocuments;

    setFilteredDocuments(siteImportantDocuments);
  }, [siteImportantDocuments]);

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  const documentStatusRenderer = params => {
    if (!params.data.status) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ marginRight: '15px' }}>---</div>
        </div>
      );
    }

    if (params.data.is_direct) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ marginRight: '1.125rem' }}>Directly uploaded</div>
        </div>
      );
    }

    if (params.data.status === DOCUMENT_STATUS.SIGNED) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CheckmarkIcon style={{ fontSize: '16px' }} />
          <div style={{ margin: '0 15px' }}>{params.data.status}</div>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <div style={{ marginRight: '15px' }}>{params.data.status}</div>
      </div>
    );
  };

  const actionCellRenderer = params => {
    const handleSetDocumentObj = () => {
      setDocumentObj({
        ...documentObj,
        ...params.data,
      });
    };

    return (
      <div
        className="ag-react-container"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container alignItems="center" spacing={1}>
          {/* {!params.data.status !== DOCUMENT_STATUS.RESPONSE_PENDING ? (
              <Grid item>
                <Tooltip title="Upload document">
                  <IconButton
                    onClick={() => {
                      let docObj = {
                        ...documentObj,
                        document: params.data.type,
                        docType: params.data.doc_type,
                      };
                      setDocumentObj(docObj);
                      setSelectedDocument(params.data);
                      setShowUploadDocumentsModal(true);
                    }}
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null} */}
          {user.role === 'Sponsor' &&
          params.data.status !== DOCUMENT_STATUS.SIGNED ? null : (
            <>
              <Grid item>
                <Tooltip title="View document">
                  <IconButton
                    onClick={() => {
                      if (params.data.type !== 'Document') {
                        setDocumentDownloading(false);
                        setShowSignDocumentModal(true);
                        handleSetDocumentObj();
                      } else {
                        dispatch(
                          downloadDocument({
                            ...params.data,
                            trialId: siteTrialId ? siteTrialId : trialId,
                            trialSiteId: id ? id : trialSiteId,
                          })
                        );
                      }
                    }}
                  >
                    <Visibility color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Audit trail">
                  <IconButton
                    onClick={() => {
                      handleSetDocumentObj();
                      setShowAuditTrailModal(true);
                    }}
                  >
                    <AuditTrailIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          )}
          {/* <Grid item>
            <Tooltip title="Download document">
              <IconButton
                onClick={() => {
                  setDocumentDownloading(true);
                  dispatch(getDocument(stageObj));
                }}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </div>
    );
  };

  return (
    <Paper
      elevation={0}
      className="p-3 custom-modal"
      style={{ minWidth: '65rem' }}
    >
      {isPending(getDocumentsApi) ? (
        <TCircularProgress />
      ) : (
        <>
          <Grid
            container
            className="p-2"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography className="fw-semibold">View Documents</Typography>
            </Grid>
            {user.role !== 'Sponsor' ? (
              <Grid item>
                <TButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    let docObj = {
                      ...documentObj,
                    };
                    setDocumentObj(docObj);
                    setShowUploadDocumentsModal(true);
                  }}
                >
                  Add new document
                </TButton>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="ag-react-container"
                style={{
                  height: '60vh',
                  width: '100%',
                }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={filteredDocuments}
                  gridOptions={DEFAULT_GRID_OPTIONS}
                  columnDefs={columnDefs}
                  frameworkComponents={{
                    actionCellRenderer,
                    documentStatusRenderer,
                  }}
                  defaultColDef={{
                    ...DEFAULT_COL_DEF,
                    cellRendererParams: { checkbox: true },
                  }}
                  pagination
                  suppressPaginationPanel
                  suppressRowHoverHighlight
                  paginationPageSize={PER_PAGE_OPTIONS[0]}
                  animateRows
                  onGridSizeChanged={params => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              className="mt-1"
              spacing={2}
            >
              <Grid item>
                <TButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowAllDocumentsModal(false)}
                >
                  Close
                </TButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Modal
        className="modal-container"
        open={showUploadDocumentsModal}
        onClose={() => setShowUploadDocumentsModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showUploadDocumentsModal}>
          <TUploadDocumentsModal
            setShowUploadDocumentsModal={setShowUploadDocumentsModal}
            setShowConfirmationModal={setShowConfirmationModal}
            setConfirmationModalLabel={setConfirmationModalLabel}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showSignPasswordModal}
        onClose={() => setShowSignPasswordModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignPasswordModal}>
          <TSignPasswordModal
            setShowSignPasswordModal={setShowSignPasswordModal}
            setShowConfirmationModal={setShowConfirmationModal}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
      {/* <Modal
        className="modal-container"
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showConfirmationModal}>
          <TConfirmationModal label={confirmationModalLabel} />
        </Fade>
      </Modal> */}
      <Modal
        className="modal-container"
        open={showAuditTrailModal}
        onClose={() => setShowAuditTrailModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showAuditTrailModal}>
          <TAuditTrailModal
            documentObj={documentObj}
            stage={stage}
            trialSiteId={trialSiteId}
            siteTrialId={siteTrialId}
            setShowAllDocumentsModal={setShowAllDocumentsModal}
            onClose={() => setShowAuditTrailModal(false)}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showSignDocumentModal}
        onClose={() => setShowSignDocumentModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showSignDocumentModal}>
          <TSignDocumentModal
            setShowSignDocumentModal={setShowSignDocumentModal}
            action={action}
            downloading={true}
            documentObj={documentObj}
          />
        </Fade>
      </Modal>
    </Paper>
  );
};

export default TViewAllDocumentsModal;
