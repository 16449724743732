import { Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TButton from '../../common/TButton';
import TContainer from '../../common/TContainer';
import TTabPanel from '../../common/TTabPanel';
import CRAMatrix from '../../components/CRA/CRAMatrix';
import { VISIT_STATUS } from '../../constant/cra_constants';
import { SITE_STAGE } from '../../constant/site_constants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    maxHeight: '3em',
  },
  scroller: {
    justifyContent: 'center',
  },
  tab: {
    maxHeight: '3em',
    minHeight: '2.5em',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '18px',
    textTransform: 'none',
  },
  selected: {
    color: theme.palette.primary.main,
    zIndex: 2,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  indicator: {
    background: theme.palette.primary.main,
    minWidth: '5em',
    height: '4px',
  },
  stickyHeader: {
    position: 'sticky',
    top: '4rem',
    zIndex: 1,
  },
}));

const CRAInitiationView = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [gridApi, setGridApi] = useState(null);

  const dispatch = useDispatch();

  const handleExportCSV = () => {
    gridApi.exportDataAsCsv();
  };

  useEffect(() => {
    let titleConfig = {
      text: (
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          className={classes.stickyHeader}
        >
          <Grid item>Site Initiation </Grid>
          <Grid item>
            <Divider
              orientation="vertical"
              style={{
                background: '#7C7F8450',
                height: '3rem',
                width: '2px',
                marginLeft: '2rem',
              }}
            />
          </Grid>
          <Grid item>
            <Tabs
              value={value}
              onChange={(_, newValue) => setValue(newValue)}
              classes={{
                root: classes.root,
                scroller: classes.scroller,
                indicator: classes.indicator,
              }}
            >
              <Tab
                label="Schedule"
                classes={{ root: classes.tab, selected: classes.selected }}
              />
              <Tab
                label="Completed"
                classes={{ root: classes.tab, selected: classes.selected }}
              />
            </Tabs>
          </Grid>
        </Grid>
      ),
      isLink: false,
      prevIcon: false,
    };
    dispatch(
      setAppBarConfig({
        title: titleConfig,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, [value]);

  return (
    <TContainer>
      <Grid container spacing={4}>
        <Grid item container direction="row" className="mx-2">
          <Grid
            item
            xs
            container
            alignItems="center"
            className={classes.stickyHeader}
          >
            <Typography variant="h5" className="fw-semibold">
              Activtion Visit
            </Typography>{' '}
            <Grid item>
              <Divider
                orientation="vertical"
                style={{
                  background: '#7C7F8450',
                  height: '3rem',
                  width: '2px',
                  marginLeft: '2rem',
                }}
              />
            </Grid>
            <Grid item>
              <Tabs
                value={value}
                onChange={(_, newValue) => setValue(newValue)}
                classes={{
                  root: classes.root,
                  scroller: classes.scroller,
                  indicator: classes.indicator,
                }}
              >
                <Tab
                  label="SCHEDULE"
                  classes={{ root: classes.tab, selected: classes.selected }}
                />
                <Tab
                  label="COMPLETED"
                  classes={{ root: classes.tab, selected: classes.selected }}
                />
              </Tabs>
            </Grid>
          </Grid>
          {/* <Grid item>
            <TButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleExportCSV()}
            >
              Download as CSV
            </TButton>
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <TTabPanel value={value} index={0}>
            <Grid item xs={12}>
              <CRAMatrix
                gridApi={gridApi}
                setGridApi={setGridApi}
                stage={SITE_STAGE.INITIATION}
                panel={VISIT_STATUS.SCHEDULED}
              />
            </Grid>
          </TTabPanel>
          <TTabPanel value={value} index={1}>
            <Grid item xs={12}>
              <CRAMatrix
                gridApi={gridApi}
                setGridApi={setGridApi}
                stage={SITE_STAGE.INITIATION}
                panel={VISIT_STATUS.COMPLETED}
              />
            </Grid>
          </TTabPanel>
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default CRAInitiationView;
