import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isPending } from '../constant/api_constants';
import TButton from './TButton';

const TPasswordModal = ({
  password,
  setPassword,
  handleSubmit,
  setShowPasswordModal,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const addGrantAPI = useSelector(state => state.doctorReducer.addGrantAPI);

  useEffect(() => {
    setPassword('');
  }, []);

  const handleSubmitPassword = () => {
    handleSubmit();
    setShowPasswordModal(false);
  };

  return (
    <Paper elevation={0} className="p-4 custom-modal">
      <Grid container direction="column" spacing={3}>
        <Grid item style={{ width: '25rem' }}>
          <Typography
            className="mt-3"
            style={{ font: 'normal normal medium 18px/32px Poppins' }}
          >
            Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            label="Password"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            value={password}
            variant="outlined"
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility fontSize="small" color="primary" />
                    ) : (
                      <VisibilityOff fontSize="small" color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className="mb-2">
          <TButton
            onClick={() => handleSubmitPassword()}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            isLoading={isPending(addGrantAPI)}
          >
            Submit
          </TButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TPasswordModal;
