import {
  GET_SHORTLISTED_SITES,
  GET_SITE,
  GET_SITES,
  REMOVE_SHORTLIST_SITE,
  SET_TAB_INDEX,
  SHORTLIST_SITE,
  UPDATE_SITE,
} from './siteIdentificationActionType';

export const updateSite = payload => ({
  type: UPDATE_SITE,
  payload,
});

export const getAllIdentificationSites = payload => ({
  type: GET_SITES,
  payload,
});

export const getShortlistedSites = payload => ({
  type: GET_SHORTLISTED_SITES,
  payload,
});

export const getSiteDetails = payload => ({
  type: GET_SITE,
  payload,
});

export const shortlistSite = payload => ({
  type: SHORTLIST_SITE,
  payload,
});

export const removeShortlistedSite = payload => ({
  type: REMOVE_SHORTLIST_SITE,
  payload,
});

export const setTabIndex = payload => {
  return {
    type: SET_TAB_INDEX,
    payload,
  };
};
