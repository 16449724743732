export const DEFAULT_COL_DEF = Object.freeze({
  wrapText: true,
  filter: false,
  sortable: true,
  suppressMovable: true,
  // autoHeight: true,
  filterParams: {
    buttons: ['reset'],
  },
  cellStyle: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
});

export const DEFAULT_GRID_OPTIONS = {
  headerHeight: 64,
  rowHeight: 72,
  rowStyle: {
    borderRadius: '5px',
  },
  suppressPropertyNamesCheck: true,
  icons: {
    menu: '<i class="fa fa-search"/>',
    sortAscending: '<i class="fa fa-caret-up"/>',
    sortDescending: '<i class="fa fa-caret-down"/>',
  },
};
