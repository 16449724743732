import { SET_LOCALSTORAGE_PARAMETERS } from '../constants';

export const setLocalStorage = data => {
  for (const dataItem in data) {
    if (SET_LOCALSTORAGE_PARAMETERS.includes(dataItem)) {
      localStorage.setItem(dataItem, data[dataItem]);
    }
  }
};

export const getLocalStorage = data => {
  return localStorage.getItem(data);
};

export const apiDispatch = (actionType = '', data) => ({
  type: actionType,
  payload: data,
});

export const apiError = (type, error) => {
  const err = { type };
  if (error.response) {
    err.error = {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers,
    };
  } else if (error.request) {
    err.error = {
      data: error.request,
    };
  } else {
    err.error = {
      data: error,
    };
  }

  return err;
};
