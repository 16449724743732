import React from 'react';

import TCard from '../../common/TCard';
import TrialProgressImg from '../../images/TrialProgress.svg';

const TrialProgress = () => {
  return (
    <TCard
      header="TRIAL PROGRESS"
      bodyComponent={
        <img style={{ maxWidth: '100%' }} src={TrialProgressImg} />
      }
    />
  );
};

export default TrialProgress;
