import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  editDoctorProfile,
  getDoctorProfile,
} from '../../../../actions/doctorActions';
import { SET_API_SUCCESS } from '../../../../actions/doctorActionType';
import { SET_TOAST_ERROR } from '../../../../actions/toastActionType';
import TButton from '../../../../common/TButton';
import TCircularProgress from '../../../../common/TCircularProgress';
import TDivider from '../../../../common/TDivider';
import TDropZone from '../../../../common/TDropZone';
import { isCompleted, isPending } from '../../../../constant/api_constants';
import { VALIDATION_ERROR } from '../../../../constant/form_validation';
import {
  validateFormFieldWithMessages,
  validateFormWithMessages,
} from '../../../../helpers/validateHelper';
import SitesIcon from '../../../../images/Sites.svg';
import { v1 as validator } from '../validators';

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const newAddNew = {
  ctri_number: '',
  pub_title: '',
  role: '',
};

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  popperDisablePortal: {
    position: 'relative',
  },
}));

const TabPanel = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [profile, setProfile] = useState();
  const [addNew, setAddNew] = useState(newAddNew);
  const [files, setFiles] = useState([]);
  const [valErrorList, setValErrorList] = useState([]);
  const [valErrorMessageList, setValErrorMessageList] = useState({});
  const [addNewErrorList, setAddNewErrorList] = useState([]);
  const [deleteFile, setDeleteFile] = useState(false);

  const user = useSelector(state => state.userReducer.user);
  const doctorProfile = useSelector(
    state => state.doctorReducer.doctorProfile[0]
  );
  const doctorAreaOfInterest = useSelector(
    state => state.doctorReducer.doctorAreaOfInterest
  );
  const doctorSpecializations = useSelector(
    state => state.doctorReducer.doctorSpecializations
  );
  const { editDoctorProfileAPI, doctorProfileAPI, apiSuccess } = useSelector(
    state => state.doctorReducer
  );

  const validateAll = async () => {
    let returnVal = false;
    await validateFormWithMessages(validator, profile).then(
      ({ isError, valErrors, errorMessages }) => {
        if (isError) {
          dispatch({
            type: SET_TOAST_ERROR,
            payload: VALIDATION_ERROR,
          });
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
          returnVal = false;
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
          returnVal = true;
        }
      }
    );
    return returnVal;
  };

  const validateOnly = (key, innerProfile) => {
    validateFormFieldWithMessages(validator, innerProfile, key).then(
      ({ isError, valErrors, errorMessages }) => {
        if (isError) {
          setValErrorList(valErrors);
          setValErrorMessageList(errorMessages);
        } else {
          setValErrorList([]);
          setValErrorMessageList({});
        }
      }
    );
  };

  const addNewToList = listName => {
    setProfile(prevProfile => {
      let newProfile = { ...prevProfile };
      newProfile.clinical_experience[listName]?.push({
        ...newAddNew,
      });
      return newProfile;
    });
  };

  const editValue = (key, value) => {
    let newProfile;

    if (key === 'ce_q02') {
      if (value === true) {
        newProfile = {
          ...profile,
          clinical_experience: {
            ...profile?.clinical_experience,
            clinical_experience_trial_details: [{ ...newAddNew }],
            [key]: value,
          },
        };
      } else {
        newProfile = {
          ...profile,
          clinical_experience: {
            ...profile?.clinical_experience,
            clinical_experience_trial_details: [],
            [key]: value,
          },
        };
      }
    } else if (key === 'ce_q04') {
      if (value === true) {
        newProfile = {
          ...profile,
          clinical_experience: {
            ...profile?.clinical_experience,
            covid_clinical_experience_trial_details: [{ ...newAddNew }],
            [key]: value,
          },
        };
      } else {
        newProfile = {
          ...profile,
          clinical_experience: {
            ...profile?.clinical_experience,
            covid_clinical_experience_trial_details: [],
            [key]: value,
          },
        };
      }
    } else {
      newProfile = {
        ...profile,
        clinical_experience: {
          ...profile?.clinical_experience,
          [key]: value,
        },
      };
    }
    setProfile(newProfile);
    validateOnly(key, newProfile);
  };

  const editInList = (listName, index, key, value) => {
    let newProfile = { ...profile };
    newProfile.clinical_experience[listName][index][key] = value;
    setProfile(newProfile);
    validateOnly(key, newProfile);
  };

  const removeFromList = (listName, index) => {
    let newProfile = { ...profile };
    newProfile.clinical_experience[listName] = newProfile.clinical_experience[
      listName
    ]?.filter((item, idx) => index !== idx);
    setProfile(newProfile);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isFormValid = await validateAll();

    if (isFormValid) {
      let fileData = {};

      if (files[0] !== undefined) {
        let formData = new FormData();
        formData.append('gcp_certificate', files[0]);
        fileData = {
          data: formData,
          id: doctorProfile?.id,
        };
        // TODO: refactor this part
      } else {
        fileData = {
          fileName: 'gcp_certificate',
          id: doctorProfile?.id,
          delete: deleteFile,
        };
      }

      const combinedData = {
        profile: profile,
        fileData: fileData,
      };

      dispatch(editDoctorProfile(combinedData));
    }
  };

  const deleteUploadedFile = () => {
    setDeleteFile(true);
    setProfile(prevProfile => ({
      ...prevProfile,
      clinical_experience: {
        ...prevProfile.clinical_experience,
        gcp_certificate: null,
      },
    }));
  };

  useEffect(() => {
    if (apiSuccess && !isPending(editDoctorProfileAPI)) props.setTabValue(2);
    if (isCompleted(editDoctorProfileAPI)) {
      dispatch({ type: SET_API_SUCCESS, payload: false });
    }
  }, [apiSuccess, editDoctorProfileAPI]);

  useEffect(() => {
    if (user.role === 'Clinical Research Coordinator') {
      if (doctorProfile?.id) {
        dispatch(getDoctorProfile({ profileId: doctorProfile.id }));
      }
    } else {
      dispatch(getDoctorProfile());
    }
  }, []);

  useEffect(() => {
    setProfile(doctorProfile);
  }, [doctorProfile, doctorAreaOfInterest, doctorSpecializations]);

  return (
    <div className={classes.formWrapper}>
      {isPending(doctorProfileAPI) ? (
        <TCircularProgress />
      ) : (
        <form>
          <Grid container spacing={5}>
            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Grid container spacing={5} className="px-5">
                <Grid item xs={12} className="d-flex">
                  <Grid item xs={9}>
                    <Typography
                      className="fw-medium c-subheading-text"
                      variant="h6"
                    >
                      Do you have any Clinical Research Experience?
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={valErrorList.includes(
                        `clinical_experience.ce_q01`
                      )}
                      size="small"
                    >
                      <Select
                        name="ce_q01"
                        variant="outlined"
                        value={
                          profile?.clinical_experience?.ce_q01 === null
                            ? null
                            : Boolean(profile?.clinical_experience?.ce_q01)
                        }
                        onChange={e => {
                          editValue(e.target.name, e.target.value);
                        }}
                        MenuProps={{
                          classes: {
                            paper: 'select-menu',
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={null} style={{ display: 'none' }}>
                          <em>Select one</em>
                        </MenuItem>
                        {yesNoOptions.map((option, indx) => (
                          <MenuItem
                            value={option.value}
                            key={indx}
                            className="select-menu-item"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {valErrorList.includes(`clinical_experience.ce_q01`)
                          ? valErrorMessageList[`clinical_experience.ce_q01`]
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Grid container spacing={5} className="px-5">
                <Grid item xs={12} className="d-flex">
                  <Grid item xs={9}>
                    <Typography
                      className="fw-medium c-subheading-text"
                      variant="h6"
                    >
                      Do you have any Clinical Trial Experience?
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={valErrorList.includes(
                        `clinical_experience.ce_q02`
                      )}
                      size="small"
                    >
                      <Select
                        name="ce_q02"
                        variant="outlined"
                        value={
                          profile?.clinical_experience?.ce_q02 === null
                            ? null
                            : Boolean(profile?.clinical_experience?.ce_q02)
                        }
                        onChange={e => {
                          editValue(e.target.name, e.target.value);
                        }}
                        MenuProps={{
                          classes: {
                            paper: 'select-menu',
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={null} style={{ display: 'none' }}>
                          <em>Select one</em>
                        </MenuItem>
                        {yesNoOptions.map((option, indx) => (
                          <MenuItem
                            value={option.value}
                            key={indx}
                            className="select-menu-item"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {valErrorList.includes(`clinical_experience.ce_q02`)
                          ? valErrorMessageList[`clinical_experience.ce_q02`]
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {profile?.clinical_experience?.ce_q02 && (
              <>
                <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
                  <Grid container spacing={5} className="px-5">
                    <div
                      style={{
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                      className="d-flex mx-3"
                    >
                      <TButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          addNewToList('clinical_experience_trial_details');
                        }}
                      >
                        Add Trial
                      </TButton>
                    </div>
                  </Grid>
                </Box>

                {profile?.clinical_experience?.clinical_experience_trial_details?.map(
                  (details, idx) => (
                    <>
                      <Box
                        maxWidth="md"
                        className="px-5 my-5"
                        width="100%"
                        key={idx}
                      >
                        <Grid container spacing={5} className="px-5">
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              name="pub_title"
                              variant="outlined"
                              label="Public Title"
                              value={details.pub_title}
                              onChange={e => {
                                editInList(
                                  'clinical_experience_trial_details',
                                  idx,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              fullWidth
                              error={valErrorList.includes(
                                `clinical_experience.clinical_experience_trial_details[${idx}].pub_title`
                              )}
                              helperText={
                                valErrorList.includes(
                                  `clinical_experience.clinical_experience_trial_details[${idx}].pub_title`
                                )
                                  ? valErrorMessageList[
                                      `clinical_experience.clinical_experience_trial_details[${idx}].pub_title`
                                    ]
                                  : ''
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              name="ctri_number"
                              variant="outlined"
                              label="CTRI Number"
                              value={details.ctri_number}
                              onChange={e => {
                                editInList(
                                  'clinical_experience_trial_details',
                                  idx,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              fullWidth
                              error={valErrorList.includes(
                                `clinical_experience.clinical_experience_trial_details[${idx}].ctri_number`
                              )}
                              helperText={
                                valErrorList.includes(
                                  `clinical_experience.clinical_experience_trial_details[${idx}].ctri_number`
                                )
                                  ? valErrorMessageList[
                                      `clinical_experience.clinical_experience_trial_details[${idx}].ctri_number`
                                    ]
                                  : ''
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              name="role"
                              variant="outlined"
                              label="Your Role"
                              value={details.role}
                              onChange={e => {
                                editInList(
                                  'clinical_experience_trial_details',
                                  idx,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              fullWidth
                              error={valErrorList.includes(
                                `clinical_experience.clinical_experience_trial_details[${idx}].role`
                              )}
                              helperText={
                                valErrorList.includes(
                                  `clinical_experience.clinical_experience_trial_details[${idx}].role`
                                )
                                  ? valErrorMessageList[
                                      `clinical_experience.clinical_experience_trial_details[${idx}].role`
                                    ]
                                  : ''
                              }
                            />
                          </Grid>
                        </Grid>

                        {profile?.clinical_experience
                          ?.clinical_experience_trial_details?.length > 1 && (
                          <TButton
                            variant="outlined"
                            color="secondary"
                            className="mx-5 mt-5"
                            onClick={() => {
                              removeFromList(
                                'clinical_experience_trial_details',
                                idx
                              );
                            }}
                          >
                            Remove
                          </TButton>
                        )}
                      </Box>

                      <Grid item xs={12} className="my-3">
                        <TDivider />
                      </Grid>
                    </>
                  )
                )}
              </>
            )}
            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Grid container spacing={5} className="px-5">
                <Grid item xs={12} className="d-flex">
                  <Grid item xs={9}>
                    <Typography
                      className="fw-medium c-subheading-text"
                      variant="h6"
                    >
                      Do you have any Covid Clinical Trial Experience?
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={valErrorList.includes(
                        `clinical_experience.ce_q04`
                      )}
                      size="small"
                    >
                      <Select
                        name="ce_q04"
                        variant="outlined"
                        value={
                          profile?.clinical_experience?.ce_q04 === null
                            ? null
                            : Boolean(profile?.clinical_experience?.ce_q04)
                        }
                        onChange={e => {
                          editValue(e.target.name, e.target.value);
                        }}
                        MenuProps={{
                          classes: {
                            paper: 'select-menu',
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={null} style={{ display: 'none' }}>
                          <em>Select one</em>
                        </MenuItem>
                        {yesNoOptions.map((option, indx) => (
                          <MenuItem
                            value={option.value}
                            key={indx}
                            className="select-menu-item"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {valErrorList.includes(`clinical_experience.ce_q04`)
                          ? valErrorMessageList[`clinical_experience.ce_q04`]
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {profile?.clinical_experience?.ce_q04 && (
              <>
                <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
                  <Grid container spacing={5} className="px-5">
                    <div
                      style={{
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                      className="d-flex mx-3"
                    >
                      <TButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          addNewToList(
                            'covid_clinical_experience_trial_details'
                          );
                        }}
                      >
                        Add Trial
                      </TButton>
                    </div>
                  </Grid>
                </Box>

                {profile?.clinical_experience?.covid_clinical_experience_trial_details?.map(
                  (details, idx) => (
                    <>
                      <Box
                        maxWidth="md"
                        className="px-5 my-5"
                        width="100%"
                        key={idx}
                      >
                        <Grid container spacing={5} className="px-5">
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              name="pub_title"
                              variant="outlined"
                              label="Public Title"
                              value={details.pub_title}
                              onChange={e => {
                                editInList(
                                  'covid_clinical_experience_trial_details',
                                  idx,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              fullWidth
                              error={valErrorList.includes(
                                `clinical_experience.covid_clinical_experience_trial_details[${idx}].pub_title`
                              )}
                              helperText={
                                valErrorList.includes(
                                  `clinical_experience.covid_clinical_experience_trial_details[${idx}].pub_title`
                                )
                                  ? valErrorMessageList[
                                      `clinical_experience.covid_clinical_experience_trial_details[${idx}].pub_title`
                                    ]
                                  : ''
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              name="ctri_number"
                              variant="outlined"
                              label="CTRI Number"
                              value={details.ctri_number}
                              onChange={e => {
                                editInList(
                                  'covid_clinical_experience_trial_details',
                                  idx,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              fullWidth
                              error={valErrorList.includes(
                                `clinical_experience.covid_clinical_experience_trial_details[${idx}].ctri_number`
                              )}
                              helperText={
                                valErrorList.includes(
                                  `clinical_experience.covid_clinical_experience_trial_details[${idx}].ctri_number`
                                )
                                  ? valErrorMessageList[
                                      `clinical_experience.covid_clinical_experience_trial_details[${idx}].ctri_number`
                                    ]
                                  : ''
                              }
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              name="role"
                              variant="outlined"
                              label="Your Role"
                              value={details.role}
                              onChange={e => {
                                editInList(
                                  'covid_clinical_experience_trial_details',
                                  idx,
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              fullWidth
                              error={valErrorList.includes(
                                `clinical_experience.covid_clinical_experience_trial_details[${idx}].role`
                              )}
                              helperText={
                                valErrorList.includes(
                                  `clinical_experience.covid_clinical_experience_trial_details[${idx}].role`
                                )
                                  ? valErrorMessageList[
                                      `clinical_experience.covid_clinical_experience_trial_details[${idx}].role`
                                    ]
                                  : ''
                              }
                            />
                          </Grid>
                        </Grid>

                        {profile?.clinical_experience
                          ?.covid_clinical_experience_trial_details?.length >
                          1 && (
                          <TButton
                            variant="outlined"
                            color="secondary"
                            className="mx-5 mt-5"
                            onClick={() => {
                              removeFromList(
                                'covid_clinical_experience_trial_details',
                                idx
                              );
                            }}
                          >
                            Remove
                          </TButton>
                        )}
                      </Box>

                      <Grid item xs={12} className="my-3">
                        <TDivider />
                      </Grid>
                    </>
                  )
                )}
              </>
            )}
            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Grid container spacing={5} className="px-5">
                <Grid item xs={12} className="d-flex">
                  <Grid item xs={9}>
                    <Typography
                      className="fw-medium c-subheading-text"
                      variant="h6"
                    >
                      Have you undertaken any grant studies?
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={valErrorList.includes(
                        `clinical_experience.ce_q03`
                      )}
                      size="small"
                    >
                      <Select
                        name="ce_q03"
                        variant="outlined"
                        value={
                          profile?.clinical_experience?.ce_q03 === null
                            ? null
                            : Boolean(profile?.clinical_experience?.ce_q03)
                        }
                        onChange={e => {
                          editValue(e.target.name, e.target.value);
                        }}
                        MenuProps={{
                          classes: {
                            paper: 'select-menu',
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={null} style={{ display: 'none' }}>
                          <em>Select one</em>
                        </MenuItem>
                        {yesNoOptions.map((option, indx) => (
                          <MenuItem
                            value={option.value}
                            key={indx}
                            className="select-menu-item"
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {valErrorList.includes(`clinical_experience.ce_q03`)
                          ? valErrorMessageList[`clinical_experience.ce_q03`]
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {profile?.clinical_experience?.ce_q03 && (
              <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
                <Grid container spacing={5} className="px-5">
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      name="grant_details"
                      multiline={true}
                      rows={6}
                      fullWidth
                      label="Details of the grant studies you have undertaken"
                      placeholder="Details of the grant studies you have undertaken"
                      variant="outlined"
                      value={profile?.clinical_experience?.grant_details}
                      onChange={e => {
                        editValue(e.target.name, e.target.value);
                      }}
                      error={valErrorList.includes(
                        `clinical_experience.grant_details`
                      )}
                      helperText={
                        valErrorList.includes(
                          `clinical_experience.grant_details`
                        )
                          ? valErrorMessageList[
                              `clinical_experience.grant_details`
                            ]
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            <Grid item xs={12} className="my-3">
              <TDivider />
            </Grid>

            <Box maxWidth="md" className="px-5 my-5 mt-0" width="100%">
              <Grid container spacing={5} className="px-5">
                <Grid item xs={12}>
                  <Typography
                    className="fw-medium c-subheading-text"
                    variant="h6"
                  >
                    Upload ICH GCP & training certificate (if completed)
                  </Typography>

                  {/* TODO: Fix this */}
                  <Grid container spacing={12} className="pt-4">
                    {profile?.clinical_experience?.gcp_certificate?.length ? (
                      <Paper className="p-2 custom-box-small w-100 ">
                        <Grid
                          item
                          container
                          spacing={2}
                          alignItems="center"
                          justify="space-between"
                        >
                          <Grid item className="mw-3">
                            <img src={SitesIcon} />
                          </Grid>
                          <Grid item xs>
                            <a
                              href={
                                profile?.clinical_experience?.gcp_certificate
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Grid item className="fw-semibold">
                                {
                                  profile?.clinical_experience
                                    ?.gcp_certificate_name
                                }
                              </Grid>
                            </a>
                          </Grid>
                          <Grid item>
                            <IconButton onClick={deleteUploadedFile}>
                              <Delete color="action" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    ) : (
                      <TDropZone
                        setDocumentObj={() => {}}
                        setDocument={setFiles}
                        documentObj={{}}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Grid item xs={12}>
              <Container maxWidth="lg">
                <Box display="flex" flexDirection="row-reverse">
                  <Box>
                    <TButton
                      variant="contained"
                      color="primary"
                      disabled={isPending(editDoctorProfileAPI)}
                      isLoading={isPending(editDoctorProfileAPI)}
                      onClick={e => {
                        onSubmit(e);
                      }}
                    >
                      Save & Continue
                    </TButton>
                  </Box>
                  <Box pr={2}>
                    <TButton
                      variant="outlined"
                      disabled={isPending(editDoctorProfileAPI)}
                      onClick={() => {
                        if (!doctorProfile?.is_profile_created_by_user) {
                          props.setTabValue(0);
                        } else {
                          props.toggleView();
                        }
                      }}
                    >
                      {!doctorProfile?.is_profile_created_by_user
                        ? 'Go Back'
                        : 'Cancel'}
                    </TButton>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default TabPanel;
