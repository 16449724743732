import { Button, Grid, makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAllIdentificationSites,
  removeShortlistedSite,
  shortlistSite,
} from '../../actions/siteIdentificationActions';

const useStyles = makeStyles(theme => ({
  buttonPrimary: {
    fontSize: '14px',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 4,
    padding: '8px',
    borderRadius: '1000px',
    fontWeight: '600',
  },
  buttonSecondary: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
  },
  buttonNegative: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  markerModal: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalFont: {
    font: '14px/25px Poppins !important',
  },
}));

function InfoWindowEx(props) {
  const infoWindowRef = React.createRef();
  const contentElement = document.createElement(`Grid`);
  useEffect(() => {
    ReactDOM.render(React.Children.only(props.children), contentElement);
    infoWindowRef.current.infowindow.setContent(contentElement);
  }, [props.children]);
  return <InfoWindow ref={infoWindowRef} {...props} />;
}

export function MapContainer(props) {
  const classes = useStyles();
  const { google } = props;
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [activeSite, setActiveSite] = useState(null);
  const sites = useSelector(state => state.siteIdentificationReducer.sites);
  const shortlistedSites = useSelector(
    state => state.siteIdentificationReducer.shortlistedSites
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (trialId) {
      let finalFormObj = { ...props.formObj, trialId: trialId };
      dispatch(getAllIdentificationSites(finalFormObj));
    }
  }, [trialId]);

  const onMarkerClick = (props, marker, e, site) => {
    setActiveMarker(marker);
    setShowInfoWindow(true);
    setActiveSite(site);
  };

  const onMapClick = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker(null);
      setActiveSite(null);
    }
  };

  const handleUpdateShortlist = (id, action) => {
    switch (action) {
      case 'add':
        dispatch(
          shortlistSite({
            trial_id: trialId,
            site_id: id,
            getSitesParams: props.formObj,
          })
        );
        break;
      case 'remove':
        // eslint-disable-next-line no-case-declarations
        let site = shortlistedSites.find(
          shortlistedSite => shortlistedSite.site.id === id
        );
        dispatch(
          removeShortlistedSite({
            trialId: trialId,
            id: id,
            getSitesParams: props.formObj,
          })
        );
        break;
      default:
        break;
    }
    setActiveSite({});
  };

  return (
    <Map
      containerStyle={{
        height: '520px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
      style={{ width: '70%', position: 'relative' }}
      google={google}
      initialCenter={{
        lat: 22.52,
        lng: 79.34,
      }}
      zoom={4.2}
      onClick={onMapClick}
    >
      {sites.map(site => {
        return (
          <Marker
            key={site.id}
            name={site.city}
            title={site.city}
            position={{ lat: site.lat, lng: site.lng }}
            onClick={(props, marker, e) =>
              onMarkerClick(props, marker, e, site)
            }
          />
        );
      })}
      <InfoWindowEx marker={activeMarker} visible={showInfoWindow}>
        <>
          {/* If we use material ui component in this component, it will override theming of all the other components as well */}
          {/* https://github.com/fullstackreact/google-maps-react/issues/379 */}
          {activeSite && (
            <div className={classes.markerModal}>
              <div item className="m-1">
                {activeSite.location}
              </div>
              <div item className="m-1">
                <div className={classes.modalFont}>
                  <strong>Name:</strong> {activeSite.doctor}
                </div>
              </div>
              <div item className="m-1 mb-3">
                <div className={classes.modalFont}>
                  <strong>Hospital:</strong> {activeSite.hospital}
                </div>
              </div>
              <Grid item container direction="column">
                {activeSite?.trial_site_details?.stage && (
                  <button
                    className={`${classes.buttonPrimary} ${classes.buttonNegative}`}
                    basic
                    onClick={() => {
                      setShowInfoWindow(false);
                      handleUpdateShortlist(
                        activeSite?.trial_site_details?.id,
                        'remove'
                      );
                    }}
                  >
                    Reject this site
                  </button>
                )}
                {!activeSite?.trial_site_details?.stage && (
                  <button
                    className={classes.buttonPrimary}
                    basic
                    onClick={() => {
                      setShowInfoWindow(false);
                      handleUpdateShortlist(activeSite?.id, 'add');
                    }}
                  >
                    Shortlist site
                  </button>
                )}
                <button
                  className={`${classes.buttonPrimary} ${classes.buttonSecondary}`}
                  basic
                  onClick={() => {
                    dispatch(
                      push(`/site-identification/info/${activeSite.id}`)
                    );
                  }}
                >
                  Know more
                </button>
              </Grid>
            </div>
          )}
          <Grid></Grid>
        </>
      </InfoWindowEx>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCbi6EByhv2NmXJaoEFC317aD96Mv4XJ8s',
})(MapContainer);
