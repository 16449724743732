import React from 'react';

import TCircularProgress from '../../common/TCircularProgress';

const Loader = () => {
  return (
    <div style={{ height: '100%' }}>
      <TCircularProgress />
    </div>
  );
};

export default Loader;
