import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { getCrtTeam } from '../../actions/siteInitiationActions';
import TCircularProgress from '../../common/TCircularProgress';
import {
  TTable,
  TTableBody,
  TTableCell,
  TTableContainer,
  TTableHead,
  TTableRow,
} from '../../common/TTable';
import API_STATES, { isPending } from '../../constant/api_constants';
import AssignCRAModal from '../SiteQualification/AssignCRAModal';
import ConfirmAssignCRAModal from '../SiteQualification/ConfirmAssignCRAModal';
import CRAInfoModal from './CRAInfoModal';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '1000px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.16px',
    padding: '25px 48px',
  },
}));

const ClinicalResearchTeam = ({
  selectedTrialId,
  selectedTrialSiteId,
  trialId,
}) => {
  const classes = useStyles();

  const user = useSelector(state => state.userReducer.user);
  const siteClinicalResearchTeam = useSelector(
    state => state.siteInitiationReducer.siteClinicalResearchTeam
  );
  const getCrtTeamApi = useSelector(
    state => state.siteInitiationReducer.getCrtTeamApi
  );
  const patchVisitAPI = useSelector(state => state.craReducer.patchVisitAPI);

  const [showCraInfoModal, setShowCraInfoModal] = useState(false);
  const [showAssignCRAModal, setShowAssignCRAModal] = useState(false);
  const [showConfirmAssignCRAModal, setShowConfirmAssignCRAModal] =
    useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [selectedMember, setSelectedMember] = useState({});

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if ((selectedTrialId && selectedTrialSiteId) || (trialId && id)) {
      dispatch(
        getCrtTeam({
          trialId: selectedTrialId ? selectedTrialId : trialId,
          trialSiteId: selectedTrialSiteId ? selectedTrialSiteId : id,
        })
      );
    }
  }, [selectedTrialId, selectedTrialSiteId, trialId, id]);

  useEffect(() => {
    if ((selectedTrialId && selectedTrialSiteId) || (trialId && id)) {
      if (patchVisitAPI === API_STATES.COMPLETED) {
        dispatch(
          getCrtTeam({
            trialId: selectedTrialId ? selectedTrialId : trialId,
            trialSiteId: selectedTrialSiteId ? selectedTrialSiteId : id,
          })
        );
      }
    }
  }, [patchVisitAPI]);

  return (
    <>
      {isPending(getCrtTeamApi) || isPending(patchVisitAPI) ? (
        <TCircularProgress />
      ) : (
        <Paper elevation={0} className="p-3 custom-box">
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h6" className="fw-semibold m-1 mb-0 fs-xl">
                Clinical Research Team
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-0 mt-0 ">
              <TTableContainer>
                <TTable size="medium">
                  <TTableHead>
                    <TTableRow>
                      <TTableCell>MEMBER</TTableCell>
                      <TTableCell>NAME</TTableCell>
                      <TTableCell>ACTION</TTableCell>
                    </TTableRow>
                  </TTableHead>
                  <TTableBody>
                    <TTableRow>
                      <TTableCell>CRC</TTableCell>
                      <TTableCell>
                        {siteClinicalResearchTeam?.CRC?.name || '-'}
                      </TTableCell>
                      <TTableCell>
                        {siteClinicalResearchTeam?.CRC?.id ? (
                          <Tooltip title="View CRC Info">
                            <IconButton
                              size="small"
                              disabled={!siteClinicalResearchTeam?.CRC?.id}
                              onClick={() => {
                                setSelectedMember({
                                  ...siteClinicalResearchTeam?.CRC,
                                  role: 'CRC',
                                });
                                setShowCraInfoModal(true);
                              }}
                            >
                              <Visibility
                                color={
                                  siteClinicalResearchTeam?.CRC?.id
                                    ? 'primary'
                                    : 'disabled'
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </TTableCell>
                    </TTableRow>
                    <TTableRow>
                      <TTableCell>CRA</TTableCell>
                      <TTableCell>
                        {siteClinicalResearchTeam?.CRA?.name || '-'}
                      </TTableCell>
                      <TTableCell>
                        {siteClinicalResearchTeam?.CRA?.id ? (
                          <Tooltip title="View CRA Info">
                            <IconButton
                              size="small"
                              disabled={!siteClinicalResearchTeam?.CRA?.id}
                              onClick={() => {
                                setSelectedMember({
                                  ...siteClinicalResearchTeam?.CRA,
                                  role: 'CRA',
                                });
                                setShowCraInfoModal(true);
                                let selectedObj = selectedSite;
                                setSelectedSite({
                                  ...selectedObj,
                                  visit: {
                                    cra_id: parseInt(
                                      siteClinicalResearchTeam?.CRA?.id
                                    ),
                                  },
                                  visitId: siteClinicalResearchTeam?.CRA?.visit,
                                });
                              }}
                            >
                              <Visibility
                                color={
                                  siteClinicalResearchTeam?.CRA?.id
                                    ? 'primary'
                                    : 'disabled'
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </TTableCell>
                    </TTableRow>
                  </TTableBody>
                </TTable>
              </TTableContainer>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Modal
        className="modal-container"
        open={showCraInfoModal}
        onClose={() => setShowCraInfoModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showCraInfoModal}>
          <CRAInfoModal
            memberDetails={selectedMember}
            setShowAssignCRAModal={setShowAssignCRAModal}
            setShowCraInfoModal={setShowCraInfoModal}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showAssignCRAModal}
        onClose={() => setShowAssignCRAModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showAssignCRAModal}>
          <AssignCRAModal
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            setShowAssignCRAModal={setShowAssignCRAModal}
            setShowConfirmAssignCRAModal={setShowConfirmAssignCRAModal}
          />
        </Fade>
      </Modal>
      <Modal
        className="modal-container"
        open={showConfirmAssignCRAModal}
        onClose={() => setShowConfirmAssignCRAModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showConfirmAssignCRAModal}>
          <ConfirmAssignCRAModal
            selectedSite={selectedSite}
            setShowConfirmAssignCRAModal={setShowConfirmAssignCRAModal}
            initiation={true} // to call different API for initiation process
          />
        </Fade>
      </Modal>
    </>
  );
};

export default ClinicalResearchTeam;
