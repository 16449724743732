export const ERROR_MESSAGE = {
  PHONE_ERROR_MESSAGE: 'Please enter a valid phone number',
  EMAIL_ERROR_MESSAGE: 'Please enter a valid email',
  PASSWORD_ERROR_MESSAGE: 'Please enter a password',
  CONFIRM_PASSWORD_ERROR_MESSAGE: 'Confirmation password does not match',
  REQUIRED_MESSAGE: "This field can't be blank",
  DROPDOWN_REQUIRED_MESSAGE: 'Please select one of the options',
};

export const TOKEN_REFRESH_TIME = 24e4;
