import { CardTravel, PeopleAlt, Room } from '@material-ui/icons';

import {
  ActivationDocumentsIcon,
  ActivationVisitIcon,
  DashboardIcon,
  FeasibilityIcon,
  ProfileIcon,
  ShieldIcon,
  SiteIdentificationIcon,
  SiteInitiationIcon,
  SiteQualificationIcon,
  TrialIcon,
} from '../common/icons';
import { CHUNKS } from '../constant/resource_constants';
import {
  ADMIN_DASHBOARD_ACCESS_ROLES,
  CRA_DASHBOARD_ACCESS_ROLES,
  DOCTOR_DASHBOARD_ACCESS_ROLES,
  SPONSOR_DASHBOARD_ACCESS_ROLES,
} from '../constant/roles_constants';
import { CONFIG_FILE } from '../constants';
import genSitevar from '../helpers/sitevars';

const ADMIN_CHUNKS = [
  {
    ...CHUNKS.ADMIN_TRIAL_DASHBOARD,
    icon: <CardTravel />,
    roles: ADMIN_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.ADMIN_SITES_DASHBOARD,
    icon: <Room />,
    roles: ADMIN_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.ADMIN_USERS_DASHBOARD,
    icon: <PeopleAlt />,
    roles: ADMIN_DASHBOARD_ACCESS_ROLES,
  },
];

let DM_CHUNKS = [
  {
    ...CHUNKS.DM_DASHBOARD,

    icon: <DashboardIcon />,
    roles: SPONSOR_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.SITE_IDENTIFICATION,

    icon: <SiteIdentificationIcon />,
    roles: SPONSOR_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.FEASIBILITY,
    icon: <FeasibilityIcon />,
    roles: SPONSOR_DASHBOARD_ACCESS_ROLES,
  },
];

const CRA_CHUNKS = [
  {
    ...CHUNKS.CRA_QUALIFICATION,
    icon: <SiteQualificationIcon />,
    roles: CRA_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.CRA_INITIATION,
    icon: <ActivationVisitIcon />,
    roles: CRA_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.CRA_INITIATION_SITES,
    icon: <ActivationDocumentsIcon />,
    roles: CRA_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.CRA_SDV,
    icon: <ShieldIcon />,
    roles: CRA_DASHBOARD_ACCESS_ROLES,
  },
];

const DOCTOR_CHUNKS = [
  {
    ...CHUNKS.DOCTOR_PROFILE,
    icon: <ProfileIcon />,
    roles: DOCTOR_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.DOCTOR_UPCOMING_TRIALS,
    icon: <TrialIcon />,
    roles: DOCTOR_DASHBOARD_ACCESS_ROLES,
  },
  {
    ...CHUNKS.DOCTOR_SDVS,
    icon: <ShieldIcon />,
    roles: DOCTOR_DASHBOARD_ACCESS_ROLES,
  },
];

export default async viewPermissions => {
  await genSitevar(CONFIG_FILE.ACCESS_CONSTANTS_FILE).then(res => {
    if (res.showQualificationPanel) {
      if (
        !DM_CHUNKS.some(
          chunk => chunk.resource_id === CHUNKS.SITE_QUALIFICATION.resource_id
        )
      ) {
        DM_CHUNKS.push({
          ...CHUNKS.SITE_QUALIFICATION,
          icon: <SiteQualificationIcon />,
          roles: SPONSOR_DASHBOARD_ACCESS_ROLES,
        });
      }
    }

    if (res.showInitiationPanel) {
      if (
        !DM_CHUNKS.some(
          chunk => chunk.resource_id === CHUNKS.SITE_INITIATION.resource_id
        )
      ) {
        DM_CHUNKS.push({
          ...CHUNKS.SITE_INITIATION,
          icon: <SiteInitiationIcon />,
          roles: SPONSOR_DASHBOARD_ACCESS_ROLES,
        });
      }
    }
  });

  const modules = [
    ...ADMIN_CHUNKS,
    ...CRA_CHUNKS,
    ...DM_CHUNKS,
    ...DOCTOR_CHUNKS,
  ];
  let returnModules = [];

  returnModules = modules.filter(elem => {
    return viewPermissions.some(
      permission => permission.resource_path === elem.resource_path
    );
  });

  return returnModules;
};

// export default function (role) {
//   const modules = [...ADMIN_CHUNKS, ...DM_CHUNKS];
//   let returnModules = [];

//   returnModules = modules.filter(elem => elem.roles.includes(role));

//   return returnModules;
// }
