import {
  ADD_DOCTOR,
  ADD_GRANT,
  CREATE_DOCTOR_PROFILE,
  CREATE_PARTICIPANT,
  CREATE_SITE_PROFILE,
  DOCTOR_FILE_UPLOAD,
  EDIT_DOCTOR_PROFILE,
  EDIT_SITE_PROFILE,
  GET_ALL_CITIES,
  GET_DOCTOR_AREA_OF_INTEREST,
  GET_DOCTOR_PROFILE,
  GET_DOCTOR_PROFILES,
  GET_DOCTOR_SPECIALIZATIONS,
  GET_DOCTOR_TRIALS,
  GET_DOCTOR_TRIALS_CRC,
  GET_FIXED_DOCUMENTS,
  GET_HOSPITALS,
  GET_PARTICIPANT_SDV,
  GET_SDV_PARTICIPANTS_LIST,
  GET_SITE_PROFILE,
  SET_EMPTY_SITE_PROFILE,
  SET_PARTIAL_DOCTOR_PROFILE,
  UPLOAD_SDV_DOCUMENT,
} from './doctorActionType';

export const getDoctorProfile = payload => ({
  type: GET_DOCTOR_PROFILE,
  payload,
});

export const getDoctorProfiles = payload => ({
  type: GET_DOCTOR_PROFILES,
  payload,
});

export const getDoctorAreaOfInterest = payload => ({
  type: GET_DOCTOR_AREA_OF_INTEREST,
  payload,
});

export const getDoctorSpecializations = payload => ({
  type: GET_DOCTOR_SPECIALIZATIONS,
  payload,
});

export const getSiteProfile = payload => ({
  type: GET_SITE_PROFILE,
  payload,
});

export const editDoctorProfile = payload => ({
  type: EDIT_DOCTOR_PROFILE,
  payload,
});

export const editSiteProfile = payload => ({
  type: EDIT_SITE_PROFILE,
  payload,
});

export const createDoctorProfile = payload => ({
  type: CREATE_DOCTOR_PROFILE,
  payload,
});

export const createSiteProfile = payload => ({
  type: CREATE_SITE_PROFILE,
  payload,
});

export const addGrant = payload => ({
  type: ADD_GRANT,
  payload,
});

export const getDoctorTrials = payload => ({
  type: GET_DOCTOR_TRIALS,
  payload,
});

export const getAllCities = payload => ({
  type: GET_ALL_CITIES,
  payload,
});

export const getAllHospitals = payload => ({
  type: GET_HOSPITALS,
  payload,
});

export const setPartialDoctorProfile = payload => ({
  type: SET_PARTIAL_DOCTOR_PROFILE,
  payload,
});

export const setEmptySiteProfile = payload => ({
  type: SET_EMPTY_SITE_PROFILE,
  payload,
});

export const doctorFileUpload = payload => ({
  type: DOCTOR_FILE_UPLOAD,
  payload,
});

export const getDoctorTrialsCRC = payload => ({
  type: GET_DOCTOR_TRIALS_CRC,
  payload,
});

export const getFixedDocuments = payload => ({
  type: GET_FIXED_DOCUMENTS,
  payload,
});

export const addDoctor = payload => ({
  type: ADD_DOCTOR,
  payload,
});

export const getSDVParticipantsList = payload => ({
  type: GET_SDV_PARTICIPANTS_LIST,
  payload,
});

export const getParticipantSDV = payload => ({
  type: GET_PARTICIPANT_SDV,
  payload,
});

export const createParticipant = payload => ({
  type: CREATE_PARTICIPANT,
  payload,
});

export const uploadSDVDocument = payload => ({
  type: UPLOAD_SDV_DOCUMENT,
  payload,
});
