import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TContainer from '../../common/TContainer';
import SiteInitiaion from '../../components/SiteInitiation';

const SiteInitiationView = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    let titleConfig = {
      text: 'Site Initiation',
      isLink: false,
      prevIcon: false,
    };
    dispatch(
      setAppBarConfig({
        title: titleConfig,
        showTrials: pathname.includes('site-initiation') ? true : false,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, []);

  return (
    <TContainer>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SiteInitiaion />
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default SiteInitiationView;
