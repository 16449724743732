import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput as Input,
} from '@material-ui/core';
import React from 'react';

import useStyles from './classes';

const TTextArea = props => {
  const classes = useStyles();

  const { label, key } = props.field;
  const { value } = props;

  const handleChange = event => {
    const { value } = event.target;
    props.handleChange(key, value);
  };

  return (
    <FormControl
      className={classes.field}
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
    >
      <FormLabel
        htmlFor={key}
        error={props.error}
        style={props.dataGrid ? { display: 'none' } : null}
        className={`${classes.label}${
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
            ? ' required-field'
            : ''
        } `}
      >
        {label}
      </FormLabel>
      <Input
        id={key}
        aria-describedby="text-area"
        key={key}
        fullWidth
        type="text"
        multiline
        onChange={handleChange}
        value={value}
        placeholder="Your answer"
        error={props.error}
        rows={4}
      />
      <FormHelperText error={props.error}>{props.errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default TTextArea;
