export const EMPTY_DOCTOR_PROFILE = Object.freeze({
  site_name: null,
  user_id: null,
  area_of_interest: [],
  field_of_study: '',
  education: [{ college_name: null, degree: null, graduation_year: null }],
  work_experience: [
    {
      name: null,
      city: '',
      position: null,
      from_year: null,
      to_year: null,
    },
  ],
  clinical_experience: {
    ce_q01: null,
    ce_q02: null,
    ce_q03: null,
    gcp_certificate: null,
    clinical_experience_trial_details: [],
    grant_details: '',
  },
});

export const EMPTY_SITE_PROFILE = Object.freeze({
  type_desc: null,
  crc: [],
  sp_q01: null,
  sp_q02: null,
  sp_q03: null,
  sp_q04: null,
  sp_q05: null,
  sp_q06: null,
  sp_q07: null,
  sp_q08: null,
  sp_q09: null,
  sp_q10: null,
  sp_q11: null,
  sp_q12: null,
  sp_q13: null,
  sp_q14: null,
  sp_q15: null,
  sp_q16: null,
  sp_q17: null,
  sp_q18: null,
});

export const ERROR_MESSAGE = {
  PHONE_ERROR_MESSAGE: 'Please enter a valid phone number',
  EMAIL_ERROR_MESSAGE: 'Please enter a valid email',
  YEAR_ERROR_MESSAGE: 'Please enter a valid year',
  PASSWORD_ERROR_MESSAGE: 'Please enter a password',
  REQUIRED_MESSAGE: "This field can't be blank",
  DROPDOWN_REQUIRED_MESSAGE: 'Please select one of the options',
  MINIMUM_VALUE_MESSAGE: minVal => `Please enter value more than ${minVal}`,
  MAXIMUM_VALUE_MESSAGE: maxVal => `Please enter value less than ${maxVal}`,
  RANDOMIZATION_ID_ERROR_MESSAGE: 'Please enter a valid randomization ID',
  COHORT_ERROR_MESSAGEL: 'Please select a cohort',
  DATE_ERROR_MESSAGE: 'Please enter a valid date',
};

export const SDV_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  EXPIRED: 3,
  REJECTED: 4,
};

export const COHORT_CHOICES = ['Arm 1', 'Arm 2'];
