import {
  FormControl,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell as _TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import { getEmptyDataGridData } from '../../../../helpers/formUtil';
import TButton from '../../../TButton';
import FormField from '../FormField';
import useStyles from './classes';

/**
 Custom TableCell component.
 **/
const TableCell = withStyles(() => ({
  root: {
    border: '1px solid #d3d4d7',
    minWidth: '10rem',
  },
  body: {
    fontSize: 14,
  },
}))(_TableCell);

/**
 Custom data-grid field component.
 **/
const TDataGridField = props => {
  const classes = useStyles();

  const { value: values = [] } = props;
  const { type, label, key } = props.field;

  const handleChange = ({ componentKey, newValue, dataGridIndex }) => {
    const complexKey = {
      fieldKey: key,
      componentKey: componentKey,
      dataGridIndex: dataGridIndex,
    };
    props.handleChange(complexKey, newValue, type);
  };

  const addRow = () => {
    const newRow = getEmptyDataGridData(props?.field?.components);
    const newValue = [...values, newRow];
    props.handleChange(key, newValue);
  };

  const deleteRow = dataGridIndex => {
    const newValue = values.filter((v, dgIndex) => dgIndex !== dataGridIndex);
    props.handleChange(key, newValue);
  };

  return (
    <FormControl className={classes.field}>
      <FormLabel htmlFor={key} className={classes.label}>
        {label}
      </FormLabel>

      <TableContainer className="scrollbar">
        <Table aria-label="datagrid field" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '5rem' }} className={classes.label}>
                S.No.
              </TableCell>

              {props?.field?.components.map(component => (
                <TableCell
                  key={component.key}
                  className={`${classes.label}${
                    component?.extra?.validate?.required
                      ? ' required-field'
                      : ''
                  }`}
                >
                  {component.label}
                </TableCell>
              ))}

              <TableCell
                style={{ minWidth: '5rem' }}
                align="right"
                className={classes.label}
              >
                Delete
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props?.value?.map((value, dataGridIndex) => (
              <TableRow key={`row-${dataGridIndex}`}>
                <TableCell style={{ minWidth: '5rem' }}>
                  {dataGridIndex + 1}
                </TableCell>

                {props?.field?.components.map((component, index) => {
                  return (
                    <TableCell key={`row-${dataGridIndex + 1}-${index}`}>
                      <FormField
                        id={component.key}
                        key={component.key}
                        name={component.key}
                        type={component.type}
                        error={
                          component.errorRows?.includes(dataGridIndex)
                            ? component.error
                            : false
                        }
                        errorMessage={
                          component.errorRows?.includes(dataGridIndex)
                            ? component.errorMessage
                            : false
                        }
                        handleChange={(k, v) => {
                          handleChange({
                            componentKey: k,
                            newValue: v,
                            dataGridIndex: dataGridIndex,
                          });
                        }}
                        value={value[component.key]}
                        field={component}
                        dataGrid={true}
                        dataGridIndex={dataGridIndex}
                      />
                    </TableCell>
                  );
                })}

                <TableCell align="right" style={{ minWidth: '5rem' }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteRow(dataGridIndex)}
                    disabled={false}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <TButton
          onClick={addRow}
          variant="contained"
          color="primary"
          size="medium"
        >
          Add Row
        </TButton>
      </div>
    </FormControl>
  );
};

export default TDataGridField;
