import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TContainer from '../../common/TContainer';
import SiteQualification from '../../components/SiteQualification';

const SiteQualificationView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let titleConfig = {
      text: 'Site Qualification',
      isLink: false,
      prevIcon: false,
    };
    dispatch(
      setAppBarConfig({
        title: titleConfig,
        showTrials: true,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, []);
  return (
    <TContainer>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SiteQualification />
        </Grid>
      </Grid>
    </TContainer>
  );
};

export default SiteQualificationView;
