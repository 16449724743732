import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  logout,
  requestEmailOTP,
  requestPhoneOTP,
  sendOTPResetPassword,
  setUserAPISuccess,
  verifyEmailOTP,
  verifyOTPResetPassword,
  verifyPhoneOTP,
} from '../../actions/userActions';
import TButton from '../../common/TButton';
import TOTPTimer from '../../common/TOTPTimer';
import { isPending } from '../../constant/api_constants';
import AuthCard from './AuthCard';

const useStyles = makeStyles(() => ({
  OTPInput: {
    textAlign: 'center',
  },
}));

const ResetPasswordVerification = props => {
  const { verificationMethod } = props.match.params;
  const { state } = props.location;
  const classes = useStyles();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const APISuccess = useSelector(state => state.userReducer.apiSuccess);
  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );

  const resendOTPAPIState = useSelector(
    state => state.userReducer.sendOTPResetPasswordAPI
  );
  const verifyOTPAPIState = useSelector(
    state => state.userReducer.verifyPhoneOTPAPI
  );

  const verifyEmailOTPAPIState = useSelector(
    state => state.userReducer.verifyEmailOTPAPI
  );
  const email_verified = useSelector(
    state => state.userReducer.user?.email_verified
  );
  const isPasswordResetRequired = useSelector(
    state => state.userReducer.user?.is_password_reset_required
  );

  const [OTP, setOTP] = useState(['', '', '', '', '', '']);
  const [OTPIndex, setOTPIndex] = useState(0);
  const [OTPTimerActive, setOTPTimerActive] = useState(true);
  const [resendOTPRequest, setResendOTPRequest] = useState(false);
  const [verifyOTPRequest, setVerifyOTPRequest] = useState(false);

  useEffect(() => {
    if (APISuccess && verifyOTPRequest) {
      dispatch(setUserAPISuccess(null));
      setResendOTPRequest(false);
      setVerifyOTPRequest(false);
      if (props.location.pathname === '/phone/verify') {
        if (isAuthenticated && !email_verified) {
          dispatch(requestEmailOTP());
          dispatch(push('/email/verify'));
        }
        setOTP(['', '', '', '', '', '']);
      } else if (props.location.pathname === '/email/verify') {
        if (isPasswordResetRequired) {
          dispatch(push('/forgot-password/user/update'));
        } else {
          dispatch(push(viewPermissions[0].resource_path));
        }
      } else {
        dispatch(
          push(`/forgot-password/${verificationMethod}/update`, {
            ...state,
            token: OTP.join(''),
          })
        );
      }
    }
  }, [verifyOTPRequest, APISuccess]);

  useEffect(() => {
    if (APISuccess && resendOTPRequest) {
      dispatch(setUserAPISuccess(null));
      setResendOTPRequest(false);
    }
  }, [resendOTPRequest, APISuccess]);

  const enterOTPDigit = (index, value) => {
    setOTP(prevOTP => {
      let newOTP = prevOTP;
      if (value === '') {
        newOTP[index] = '';
      } else {
        newOTP[index] = value.toString().split('')[0];
      }
      return newOTP;
    });

    setOTPIndex(prevIndex => {
      if (prevIndex < 5) {
        return prevIndex + 1;
      }
    });
  };

  const clearOTP = () => {
    setOTP(['', '', '', '', '', '']);
    setOTPIndex(0);
  };

  const resendOTP = () => {
    if (props.location.pathname === '/phone/verify') {
      dispatch(requestPhoneOTP());
    } else if (props.location.pathname === '/email/verify') {
      dispatch(requestEmailOTP());
    } else {
      dispatch(sendOTPResetPassword(state));
      setResendOTPRequest(true);
      setOTPTimerActive(true);
    }
  };

  const handleVerify = () => {
    const payload = {
      ...state,
      token: OTP.join(''),
      verificationMethod,
    };
    if (props.location.pathname === '/phone/verify') {
      payload.email = localStorage.getItem('email');
      dispatch(verifyPhoneOTP(payload));
    } else if (props.location.pathname === '/email/verify') {
      payload.email = localStorage.getItem('email');
      dispatch(verifyEmailOTP(payload));
    } else {
      dispatch(verifyOTPResetPassword(payload));
    }
    setVerifyOTPRequest(true);
  };

  const goBack = () => {
    if (
      props.location.pathname === '/phone/verify' ||
      props.location.pathname === '/email/verify'
    ) {
      dispatch(logout());
    } else {
      dispatch(push(`/forgot-password/${verificationMethod}`));
    }
  };

  return (
    <AuthCard>
      <Grid container spacing={4}>
        <Grid item container spacing={3} style={{ justifyContent: 'center' }}>
          <Typography align="center" className="fs-xxxl fw-semibold mb-2">
            {verificationMethod === 'phone' ? 'Phone' : 'Email'} Verification
          </Typography>
          <Grid item xs={12} style={{ justifyContent: 'center' }}>
            <Typography align="center" className="fs-xl fw-semibold mb-3">
              In order to verify it's you, an OTP has been sent to your
              <strong> {verificationMethod}.</strong> Please enter the OTP.
            </Typography>
          </Grid>
          <Grid item xs={12} className="flex">
            {OTP.map((value, index) => (
              <TextField
                size="small"
                key={index}
                autoFocus={index === 0}
                focused={index === OTPIndex}
                className="mt-3 mx-2 bg-white"
                autoComplete={false}
                variant="outlined"
                value={OTP[index]}
                onClick={() => setOTPIndex(index)}
                // onFocus={event => event.target.select()}
                onChange={event => {
                  enterOTPDigit(index, event.target.value);
                }}
                inputProps={{
                  className: classes.OTPInput,
                  autoComplete: false,
                }}
                inputRef={input => index === OTPIndex && input && input.focus()}
                fullWidth
              />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="space-between"
            alignItems="center"
            key={verificationMethod}
          >
            {OTPTimerActive ? (
              <div>
                {'Resend OTP in '}
                <TOTPTimer
                  time={60}
                  isActive={OTPTimerActive}
                  onTimerEnd={() => {
                    setOTPTimerActive(false);
                  }}
                />
              </div>
            ) : (
              <Button variant="text" onClick={resendOTP} className="text-link">
                Resend OTP
              </Button>
            )}
            <Button
              variant="text"
              onClick={() => {
                clearOTP();
              }}
              className="text-link"
            >
              Clear
            </Button>
          </Grid>
          <Grid item xs={12} className="mt-2">
            <TButton
              onClick={handleVerify}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              isLoading={
                isPending(verifyOTPAPIState) ||
                isPending(verifyEmailOTPAPIState)
              }
              disabled={
                isPending(resendOTPAPIState) ||
                isPending(verifyOTPAPIState) ||
                isPending(verifyEmailOTPAPIState)
              }
            >
              <Typography className="fs-l fw-semibold">Confirm</Typography>
            </TButton>
          </Grid>
          <Grid item xs={12}>
            <TButton
              tertiary
              fullWidth
              size="large"
              variant="text"
              onClick={goBack}
            >
              Go Back
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

export default ResetPasswordVerification;
