export const ADMIN = 'ADMIN';
export const SPONSOR = 'SPONSOR';
export const USER = 'USER';
export const CRA = 'CRA';
export const DOCTOR = 'DOCTOR';
export const CRC = 'CRC';

export const SPONSOR_DASHBOARD_ACCESS_ROLES = [ADMIN, SPONSOR, CRA];
export const ADMIN_DASHBOARD_ACCESS_ROLES = [ADMIN];
export const CRA_DASHBOARD_ACCESS_ROLES = [CRA];
export const DOCTOR_DASHBOARD_ACCESS_ROLES = [DOCTOR, CRC];

export const getRole = role => {
  switch (role.toUpperCase()) {
    case ADMIN:
      return ADMIN;
    case SPONSOR:
      return SPONSOR;
    case 'CLINICAL RESEARCH ASSOCIATE':
      return CRA;
    case 'CLINICAL RESEARCH COORDINATOR':
      return CRC;
    case DOCTOR:
      return DOCTOR;
    default:
      return USER;
  }
};
