import {
  LOGIN_API_STATE,
  LOGIN_CHECK_API_STATE,
  LOGOUT,
  REQUEST_EMAIL_OTP_API_STATE,
  REQUEST_PHONE_OTP_API_STATE,
  SEND_OTP_RESET_PASSWORD_API_STATE,
  SET_FIRST_LOGIN,
  SET_IS_AUTH,
  SET_PASSWORD_RESET_REQUIRED,
  SET_USER,
  SET_USER_PERMISSIONS,
  SET_VERIFY_PHONE_API_STATE,
  SIGN_UP_API_STATE,
  UPDATE_PASSWORD_API_STATE,
  USER_API_ERROR,
  USER_API_SUCCESS,
  VERIFY_EMAIL_OTP_API_STATE,
  VERIFY_OTP_RESET_PASSWORD_API_STATE,
  VERIFY_PHONE_OTP_API_STATE,
} from '../actions/userActionType';
import { ACTION_NAME } from '../constant/action_constants';
import API_STATES from '../constant/api_constants';

const initialPendingState = {
  loginAPI: API_STATES.NOT_INITIATED,
  loginCheckAPI: API_STATES.NOT_INITIATED,
  signUpAPI: API_STATES.NOT_INITIATED,
  verifyPhoneAPI: API_STATES.NOT_INITIATED,
  sendOTPResetPasswordAPI: API_STATES.NOT_INITIATED,
  verifyOTPResetPasswordAPI: API_STATES.NOT_INITIATED,
  updatePasswordAPI: API_STATES.NOT_INITIATED,
  requestPhoneOTPAPI: API_STATES.NOT_INITIATED,
  requestEmailOTPAPI: API_STATES.NOT_INITIATED,
  verifyPhoneOTPAPI: API_STATES.NOT_INITIATED,
  verifyEmailOTPAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialPendingState,
  isAuth: false,
  isVerified: true,
  error: null,
  isLogout: false,
  user: {},
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER:
      return { ...state, ...payload, isLogout: false };
    case LOGIN_API_STATE:
      return { ...state, ...payload, loginAPI: payload };
    case LOGIN_CHECK_API_STATE:
      return { ...state, ...payload, loginCheckAPI: payload };
    case SIGN_UP_API_STATE:
      return { ...state, ...payload, signUpAPI: payload };
    case USER_API_ERROR:
      return { ...state, ...payload, error: payload };
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case LOGOUT:
      return { ...initialState, isLogout: true };
    case SET_USER_PERMISSIONS: {
      const user = state.user;
      const access = payload.access || state.user.access;
      const viewPermissions = access.filter(permission => {
        return permission.permission_type.some(
          type => type.permission === ACTION_NAME.VIEW && type.permission_value
        );
      });
      return {
        ...state,
        user: { ...user, access, viewPermissions },
      };
    }
    case SET_PASSWORD_RESET_REQUIRED:
      return {
        ...state,
        user: { ...state.user, is_password_reset_required: payload },
      };
    case SET_VERIFY_PHONE_API_STATE:
      return { ...state, verifyPhoneAPI: payload };
    case SEND_OTP_RESET_PASSWORD_API_STATE:
      return { ...state, sendOTPResetPasswordAPI: payload };
    case USER_API_SUCCESS:
      return { ...state, apiSuccess: payload };
    case VERIFY_OTP_RESET_PASSWORD_API_STATE:
      return { ...state, verifyOTPResetPasswordAPI: payload };
    case UPDATE_PASSWORD_API_STATE:
      return { ...state, updatePasswordAPI: payload };
    case REQUEST_PHONE_OTP_API_STATE:
      return { ...state, requestPhoneOTPAPI: payload };
    case REQUEST_EMAIL_OTP_API_STATE:
      return { ...state, requestEmailOTPAPI: payload };
    case VERIFY_PHONE_OTP_API_STATE:
      return { ...state, verifyPhoneOTPAPI: payload };
    case VERIFY_EMAIL_OTP_API_STATE:
      return { ...state, verifyEmailOTPAPI: payload };
    case SET_FIRST_LOGIN:
      return { ...state, user: { ...state.user, first_login: payload } };

    default:
      return state;
  }
}
