import {
  Backdrop,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRSDVDocument } from '../../../actions/craActions';
import { SET_RSDV_DOCUMENT } from '../../../actions/craActionType';
import {
  getParticipantSDV,
  uploadSDVDocument,
} from '../../../actions/doctorActions';
import { PER_PAGE_OPTIONS } from '../../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../../common/AgGrid/constants';
import { TextCellRenderer } from '../../../common/AgGrid/TextCellRenderers.js';
import { UploadIcon } from '../../../common/icons';
import TButton from '../../../common/TButton';
import TCircularProgress from '../../../common/TCircularProgress';
import TDropZone from '../../../common/TDropZone';
import TStyledDialog, {
  TStyledDialogActions,
  TStyledDialogContent,
  TStyledDialogTitle,
} from '../../../common/TStyledDailog';
import { isCompleted, isPending } from '../../../constant/api_constants';
import { SDV_STATUS } from '../../../constant/doctor_constant';
import SDVDocument from '../../../images/SDVDocument.svg';
import SDVDocumentModal from '../../CRA/SDVDocumentModal';

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: '35rem',
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

const SDVDetailModal = ({ participant, sdvStatus }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [gridApi, setGridApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [id, setId] = useState(null);
  const [uploadRequest, setUploadRequest] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [uploadRequestInitiated, setUploadRequestInitiated] = useState(false);
  const sdvs = useSelector(state => state.doctorReducer.participantSDV);
  const getParticipantSDVAPI = useSelector(
    state => state.doctorReducer.getParticipantSDVAPI
  );
  const uploadSDVDocumentAPI = useSelector(
    state => state.doctorReducer.uploadSDVDocumentAPI
  );
  const { getRSDVDocumentAPI, RSDVDocument } = useSelector(
    state => state.craReducer
  );

  const columnDefs = [
    {
      headerName: 'SDV Name',
      field: 'name',
      cellRenderer: 'SDVNameRenderer',
      maxWidth: 250,
    },
    {
      headerName: 'Description',
      field: 'description',
      cellRenderer: 'TextCellRenderer',
    },
  ];
  const openColumnDefs = [
    {
      headerName: 'SDV Name',
      field: 'name',
      cellRenderer: 'SDVNameRenderer',
    },
    {
      headerName: 'Description',
      field: 'description',
      cellRenderer: 'TextCellRenderer',
    },
    {
      headerName: 'Actions',
      filter: false,
      cellRenderer: 'actionCellRenderer',
      width: 100,
    },
  ];

  const SDVNameRenderer = params => {
    return (
      <div className="w-100 h-100">
        <Grid
          alignItems="center"
          className="w-100 h-100"
          spacing={1}
          style={{ display: 'flex' }}
        >
          <img src={SDVDocument} className="mx-3" style={{ width: '17px' }} />
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={params.value}
          >
            {params.value}
          </div>
        </Grid>
      </div>
    );
  };

  const actionCellRenderer = params => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Grid container alignItems="center" spacing={1}>
          {!params.data.document_uploaded ? (
            <Grid item>
              <Tooltip title="Upload document">
                <IconButton
                  onClick={() => {
                    setId(params.data.id);
                    setOpen(true);
                  }}
                >
                  <UploadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="View document">
                <IconButton
                  color="primary"
                  onClick={() => {
                    dispatch(getRSDVDocument({ id: params.data.id }));
                    setShowPDFModal(true);
                  }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    if (participant) {
      dispatch(getParticipantSDV({ participant, sdvStatus }));
    }
  }, [participant]);

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleClose = () => {
    setOpen(false);
    setUploadRequest(false);
    setUploadRequestInitiated(false);
    setId(null);
    setFiles([]);
  };

  const handleSubmit = () => {
    if (files[0] !== undefined) {
      let formData = new FormData();
      formData.append('data', files[0]);

      dispatch(
        uploadSDVDocument({
          formData,
          id,
        })
      );
    }
    setUploadRequest(true);
  };

  useEffect(() => {
    if (isPending(uploadSDVDocumentAPI)) setUploadRequestInitiated(true);
    else if (
      isCompleted(uploadSDVDocumentAPI) &&
      uploadRequestInitiated &&
      uploadRequest
    ) {
      if (participant) dispatch(getParticipantSDV({ participant, sdvStatus }));
      handleClose();
    }
  }, [uploadSDVDocumentAPI, uploadRequestInitiated, uploadRequest]);

  return (
    <Paper
      elevation={0}
      className="p-3 custom-modal"
      style={{ minWidth: '65rem' }}
    >
      {isCompleted(getParticipantSDVAPI) ? (
        <>
          <Grid
            container
            className="p-2"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography className="fw-semibold">View Documents</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} className="mb-2" container spacing={2}>
            <div
              className="ag-react-container"
              style={{
                height: '60vh',
                width: '100%',
              }}
            >
              <AgGridReact
                onGridReady={onGridReady}
                rowData={sdvs}
                gridOptions={DEFAULT_GRID_OPTIONS}
                columnDefs={
                  sdvStatus === SDV_STATUS.OPEN ? openColumnDefs : columnDefs
                }
                tooltipShowDelay={0}
                frameworkComponents={{
                  actionCellRenderer,
                  SDVNameRenderer,
                  TextCellRenderer,
                }}
                defaultColDef={{
                  ...DEFAULT_COL_DEF,
                }}
                pagination
                suppressPaginationPanel
                suppressRowHoverHighlight
                paginationPageSize={PER_PAGE_OPTIONS[4]}
                animateRows
                onGridSizeChanged={params => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </div>
          </Grid>
          <TStyledDialog
            open={open}
            classes={{ paper: classes.dialog }}
            onClose={handleClose}
          >
            <TStyledDialogTitle disableUnderline>
              Upload document
            </TStyledDialogTitle>
            <TStyledDialogContent>
              <TDropZone setDocument={setFiles} />
            </TStyledDialogContent>
            <TStyledDialogActions>
              <TButton variant="outlined" onClick={handleClose}>
                Go Back
              </TButton>
              <TButton
                isLoading={isPending(uploadSDVDocumentAPI)}
                color="primary"
                variant="contained"
                disabled={files?.length <= 0}
                onClick={handleSubmit}
              >
                Submit
              </TButton>
            </TStyledDialogActions>
          </TStyledDialog>
          <Modal
            className="modal-container"
            open={showPDFModal}
            onClose={() => {
              setShowPDFModal(false);
              dispatch({ type: SET_RSDV_DOCUMENT, payload: null });
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showPDFModal}>
              <SDVDocumentModal
                closePDFModal={() => {
                  setShowPDFModal(false);
                  dispatch({ type: SET_RSDV_DOCUMENT, payload: null });
                }}
                docUrl={RSDVDocument?.content}
                docType={RSDVDocument?.contentType}
              />
            </Fade>
          </Modal>
        </>
      ) : (
        <TCircularProgress />
      )}
    </Paper>
  );
};

export default SDVDetailModal;
