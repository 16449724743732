import { CHUNKS } from './constant/resource_constants';
// Layout Types
import { DefaultLayout } from './layouts';
import CRAInitiationView from './views/cra/CRAInitiationView';
import CRAQualificationView from './views/cra/CRAQualificationView';
import CRASDVTrialSiteParticipantsView from './views/cra/CRASDVTrialSiteParticipantsView';
import CRASDVView from './views/cra/CRASDVView';
import DMDashboard from './views/dashboard/DM';
import FeasibilityView from './views/dashboard/FeasibilityView';
import SiteIdentificationView from './views/dashboard/SiteIdentificationView';
import SiteInitiationView from './views/dashboard/SiteInitiationView';
import SiteQualification from './views/dashboard/SiteQualificationView';
import DoctorProfileView from './views/doctor/DoctorProfileView';
import DoctorSDVView from './views/doctor/DoctorSDVView';
import DoctorUpcomingTrialsView from './views/doctor/DoctorUpcomingTrialsView';
import SiteDetailsView from './views/siteIdentification/SiteDetailsView';
import SiteInfoView from './views/SiteInitiation/SiteInfoView';

export default [
  {
    ...CHUNKS.DM_DASHBOARD,
    layout: DefaultLayout,
    component: DMDashboard,
  },
  {
    ...CHUNKS.SITE_IDENTIFICATION,
    layout: DefaultLayout,
    component: SiteIdentificationView,
    exact: true,
  },
  {
    ...CHUNKS.SITE_IDENTIFICATION_SITE_INFO,
    layout: DefaultLayout,
    component: SiteDetailsView,
  },
  {
    ...CHUNKS.FEASIBILITY,
    layout: DefaultLayout,
    component: FeasibilityView,
  },
  {
    ...CHUNKS.SITE_QUALIFICATION,
    layout: DefaultLayout,
    component: SiteQualification,
  },
  {
    ...CHUNKS.SITE_INITIATION,
    layout: DefaultLayout,
    component: SiteInitiationView,
    exact: true,
  },
  {
    ...CHUNKS.SITE_INITIATION_SITE_INFO,
    layout: DefaultLayout,
    component: SiteInfoView,
  },
  {
    ...CHUNKS.CRA_INITIATION_SITES,
    layout: DefaultLayout,
    component: SiteInitiationView,
    exact: true,
  },
  {
    ...CHUNKS.CRA_INITIATION_DOCUMENTS,
    layout: DefaultLayout,
    component: SiteInitiationView,
    exact: true,
  },
  {
    ...CHUNKS.CRA_INITIATION_DOCUMENTS_INFO,
    layout: DefaultLayout,
    component: SiteInfoView,
  },
  {
    ...CHUNKS.CRA_INITIATION,
    layout: DefaultLayout,
    component: CRAInitiationView,
  },
  {
    ...CHUNKS.CRA_QUALIFICATION,
    layout: DefaultLayout,
    component: CRAQualificationView,
  },
  {
    ...CHUNKS.CRA_SDV,
    layout: DefaultLayout,
    component: CRASDVView,
    exact: true,
  },
  {
    ...CHUNKS.CRA_SDV_TRIAL_SITE_PARTICIPANTS,
    layout: DefaultLayout,
    component: CRASDVTrialSiteParticipantsView,
    exact: true,
  },
  {
    ...CHUNKS.DOCTOR_PROFILE,
    layout: DefaultLayout,
    component: DoctorProfileView,
  },
  {
    ...CHUNKS.DOCTOR_UPCOMING_TRIALS,
    layout: DefaultLayout,
    component: DoctorUpcomingTrialsView,
  },
  {
    ...CHUNKS.DOCTOR_SDVS,
    layout: DefaultLayout,
    component: DoctorSDVView,
  },
];
