import React, { useEffect, useState } from 'react';

const TOTPTimer = ({ time, isActive, onTimerEnd }) => {
  const [timeRemaining, setTimeRemaining] = useState(time);

  useEffect(() => {
    let timer;
    if (isActive && timeRemaining > 0) {
      timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
    } else if (timeRemaining <= 0) {
      clearTimeout(timer);
      onTimerEnd();
    } else {
      clearTimeout(timer);
      setTimeRemaining(time);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isActive, timeRemaining]);

  const formatTime = time => {
    if (time < 10) return `0${time}`;
    return time;
  };

  return (
    <span>{`${Math.floor(timeRemaining / 60)}:${formatTime(
      Math.floor(timeRemaining % 60)
    )}`}</span>
  );
};

export default TOTPTimer;
