import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import TOAST_TYPES from '../constant/toast_constant';

const TToastContainer = () => {
  const toastType = useSelector(state => state.toastReducer.toastType);
  const message = useSelector(state => state.toastReducer.message);
  const timestamp = useSelector(state => state.toastReducer.timestamp);
  const color = useSelector(state => state.toastReducer.color);

  useEffect(() => {
    switch (toastType) {
      case TOAST_TYPES.SUCCESS:
        toast.success(message);
        return;
      case TOAST_TYPES.ERROR:
        toast.error(message);
        return;
      case TOAST_TYPES.INFO:
        toast.info(message);
        break;
      default:
        break;
    }
  }, [toastType, message, timestamp]);

  return (
    <ToastContainer
      toastStyle={{ backgroundColor: color, fontSize: '1rem' }}
      position="bottom-left"
      autoClose={3000}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  );
};

export default TToastContainer;
