import {
  LOGIN,
  LOGIN_CHECK,
  LOGOUT,
  REFRESH_TOKEN,
  REQUEST_EMAIL_OTP,
  REQUEST_PHONE_OTP,
  SEND_OTP_RESET_PASSWORD,
  SIGN_UP,
  UPDATE_PASSWORD,
  USER_API_SUCCESS,
  VERIFY_EMAIL_OTP,
  VERIFY_OTP_RESET_PASSWORD,
  VERIFY_PHONE,
  VERIFY_PHONE_OTP,
} from './userActionType';

export const login = payload => ({
  type: LOGIN,
  payload,
});

export const signUp = payload => ({
  type: SIGN_UP,
  payload,
});

export const loginCheck = payload => ({
  type: LOGIN_CHECK,
  payload,
});

export const logout = payload => ({
  type: LOGOUT,
  payload,
});

export const verifyPhone = payload => ({
  type: VERIFY_PHONE,
  payload,
});

export const sendOTPResetPassword = payload => ({
  type: SEND_OTP_RESET_PASSWORD,
  payload,
});

export const verifyOTPResetPassword = payload => ({
  type: VERIFY_OTP_RESET_PASSWORD,
  payload,
});

export const setUserAPISuccess = payload => ({
  type: USER_API_SUCCESS,
  payload,
});

export const updateUserPassword = payload => ({
  type: UPDATE_PASSWORD,
  payload,
});

export const requestPhoneOTP = payload => ({
  type: REQUEST_PHONE_OTP,
  payload,
});

export const requestEmailOTP = payload => ({
  type: REQUEST_EMAIL_OTP,
  payload,
});

export const verifyPhoneOTP = payload => ({
  type: VERIFY_PHONE_OTP,
  payload,
});

export const verifyEmailOTP = payload => ({
  type: VERIFY_EMAIL_OTP,
  payload,
});

export const refreshToken = payload => ({
  type: REFRESH_TOKEN,
  payload,
});
