import {
  Backdrop,
  Button,
  Checkbox,
  Fade,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';
import { RSDV_STATUS_NUMBERS } from '../../constant/cra_constants';
import { formatDate } from '../../helpers/utils';
import RequestSDVModal from './RequestSDVModal';
import ViewSDVDocumentsModal from './ViewSDVDocumentsModal';

const SDVTrialSiteParticipantsMatrix = ({
  gridApi,
  setGridApi,
  showRequestSDVModal,
  openRequestSDVModal,
  closeRequestSDVModal,
  selectParticipant,
  selectedParticipants,
  setSelectedParticipants,
  submitSDVRequest,
}) => {
  const { trialSiteParticipants, getTrialSiteParticipantsAPI } = useSelector(
    state => state.craReducer
  );

  const [showSDVDocumentsModal, setShowSDVDocumentsModal] = useState(false);
  const [currentParticipantId, setCurrentParticipantId] = useState();
  const [filter, setFilter] = useState(null);

  const columnDefs = [
    {
      headerName: 'Participant ID',
      field: 'randomization_id',
      cellStyle: { justifyContent: 'center' },
      cellRenderer: 'participantCellRenderer',
      minWidth: 200,
    },
    {
      headerName: 'Cohort',
      field: 'cohort',
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Enrolment Date',
      field: 'enrollment_date',
      valueFormatter: params => formatDate(params.value),
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Open SDVs',
      field: 'open_count',
      cellRenderer: 'SDVDocumentsCellRenderer',
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Closed SDVs',
      field: 'closed_count',
      cellRenderer: 'SDVDocumentsCellRenderer',
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Expired SDVs',
      field: 'expired_count',
      cellRenderer: 'SDVDocumentsCellRenderer',
      cellStyle: { justifyContent: 'center' },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      cellRenderer: 'actionCellRenderer',
    },
  ];

  const participantCellRenderer = params => {
    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center" spacing={1}>
          <Checkbox
            color="primary"
            onChange={(event, checked) =>
              selectParticipant({
                participantId: params.data.id,
                checked: checked,
              })
            }
          />
          <Typography>{params.value}</Typography>
        </Grid>
      </div>
    );
  };

  const SDVDocumentsCellRenderer = params => {
    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center">
          <Tooltip title="View SDVs">
            <Typography
              className="w-100 fw-semibold c-blue hover-pointer"
              style={{ textAlign: 'center' }}
              onClick={() => {
                const sdvStatus = RSDV_STATUS_NUMBERS[params.column.colId];
                setFilter(sdvStatus);
                setCurrentParticipantId(params.data.id);
                setShowSDVDocumentsModal(true);
              }}
            >
              <u>{params.value}</u>
            </Typography>
          </Tooltip>
        </Grid>
      </div>
    );
  };

  const actionCellRenderer = params => {
    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center" spacing={1}>
          <Button
            variant="outlined"
            color="secondary"
            className="tt-none"
            onClick={() => {
              setSelectedParticipants([params.data.id]);
              openRequestSDVModal({ single: true });
            }}
          >
            Request SDV
          </Button>
        </Grid>
      </div>
    );
  };

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      {isPending(getTrialSiteParticipantsAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="ag-react-container"
                style={{
                  height: '60vh',
                  width: '100%',
                }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={trialSiteParticipants}
                  gridOptions={{ ...DEFAULT_GRID_OPTIONS }}
                  columnDefs={columnDefs}
                  frameworkComponents={{
                    participantCellRenderer,
                    SDVDocumentsCellRenderer,
                    actionCellRenderer,
                  }}
                  defaultColDef={{
                    ...DEFAULT_COL_DEF,
                    cellClass: ['grid-cell-7n'],
                  }}
                  pagination
                  suppressRowHoverHighlight
                  suppressPaginationPanel
                  paginationPageSize={PER_PAGE_OPTIONS[0]}
                  animateRows
                  onGridSizeChanged={params => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <AgGridPagination gridApi={gridApi} />
            </Grid>
          </Grid>

          <Modal
            className="modal-container"
            open={showSDVDocumentsModal}
            onClose={() => setShowSDVDocumentsModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showSDVDocumentsModal}>
              <ViewSDVDocumentsModal
                participantId={currentParticipantId}
                setShowSDVDocumentsModal={setShowSDVDocumentsModal}
                closeSDVDocumentsModal={() => setShowSDVDocumentsModal(false)}
                filter={filter}
              />
            </Fade>
          </Modal>

          <Modal
            className="modal-container"
            open={showRequestSDVModal}
            onClose={closeRequestSDVModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showRequestSDVModal}>
              <RequestSDVModal
                participantIds={selectedParticipants}
                closeModal={closeRequestSDVModal}
                submitRequest={submitSDVRequest}
              />
            </Fade>
          </Modal>
        </>
      )}
    </>
  );
};

export default SDVTrialSiteParticipantsMatrix;
