export const DOCUMENT_TYPE = Object.freeze({
  CDA: 'CDA',
  FEASIBILITY: 'Feasibility',
  QUALIFICATION_REPORT: 'Qualification_report',
  INITIATION_REPORT: 'Initiation_report',
  CTA_STATUS: 'cta_status',
  IP_CLEARANCE: 'IP_clearance',
  IRB_APPROVAL: 'IRB_approval',
});

export const DOCUMENT_STATUS = Object.freeze({
  DRAFT_PENDING: 'Draft Pending',
  RESPONSE_PENDING: 'Response Pending',
  RESPONSE_UPLOADED: 'Response Uploaded',
  RESPONSE_RECEIVED: 'Response Received',
  SIGNED: 'Signed',
});

export const DOCUMENT_MODAL_ACTIONS = Object.freeze({
  SIGNING: 'signing',
  UPLOADING: 'uploading',
  APPROVING: 'approving',
  DOWNLOADING: 'downloading',
});

export const SIGNING_METHOD = Object.freeze({
  AADHAAR: 1,
  EMAIL: 2,
});
