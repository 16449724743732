import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { loginCheck, refreshToken } from './actions/userActions';
import { isPending } from './constant/api_constants';
import { TOKEN_REFRESH_TIME } from './constant/auth_constants';
import ProtectedRoute from './ProtectedRoute';
import routes from './routes';
import Loader from './views/assets/Loader';
import ForgotPasswordView from './views/auth/ForgotPassword';
import LoginView from './views/auth/LoginView';
import ResetPasswordVerificationView from './views/auth/ResetPasswordVerificationView';
import SignUpView from './views/auth/SignUpView';
import UpdatePasswordView from './views/auth/UpdatePassword';
import HomeView from './views/home/HomeView';

const App = () => {
  const dispatch = useDispatch();

  const isTokenPresent = !!localStorage.getItem('token');
  const isLogout = useSelector(state => state.userReducer.isLogout);
  const isAuth = useSelector(state => state.userReducer.isAuth);
  const loginCheckAPI = useSelector(state => state.userReducer.loginCheckAPI);

  useEffect(() => {
    if (isTokenPresent) {
      dispatch(loginCheck());
    }
  }, []);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      if (!!localStorage.getItem('token') && !isPending(loginCheckAPI)) {
        dispatch(refreshToken());
      }
    }, TOKEN_REFRESH_TIME);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLogout) {
      dispatch(push('/login'));
    }
  }, [isLogout]);

  return (
    <div className="app-container">
      <Switch>
        {routes.map(route => (
          <ProtectedRoute
            key={route.resource_id}
            isAuthenticated={isAuth}
            layout={route.layout}
            path={route.resource_path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        {/* <Route exact path="/" component={HomeView} /> */}
        <Route exact path="/login" component={LoginView} />
        <Route exact path="/signup" component={SignUpView} />
        <Route
          exact
          path="/forgot-password/:verificationMethod"
          component={ForgotPasswordView}
        />
        <Route
          exact
          path="/forgot-password/:verificationMethod/verify"
          component={ResetPasswordVerificationView}
        />
        <Route
          exact
          path="/:verificationMethod/verify"
          component={ResetPasswordVerificationView}
        />
        <Route
          exact
          path="/forgot-password/:verificationMethod/update"
          component={UpdatePasswordView}
        />
        <Route exact path="/signing" component={Loader} />

        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    </div>
  );
};

export default App;
