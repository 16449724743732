export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const GENERATE_AND_SEND_DOCUMENT = 'GENERATE_AND_SEND_DOCUMENT';
export const APPROVE_DOCUMENT = 'APPROVE_DOCUMENT';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const E_SIGN_DOCUMENT = 'E_SIGN_DOCUMENT';
export const SET_E_SIGN_DOCUMENT_LINK = 'SET_E_SIGN_DOCUMENT_LINK';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const DIRECT_UPLOAD_DOCUMENT = 'DIRECT_UPLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const SET_UPLOAD_DOCUMENT_SUCCESS = 'SET_UPLOAD_DOCUMENT_SUCCESS';
export const GET_AUDIT_TRAIL = 'GET_AUDIT_TRAIL';
export const SET_AUDIT_TRAIL = 'SET_AUDIT_TRAIL';
export const DOWNLOAD_TEMPLATE_IN_PDF = 'DOWNLOAD_TEMPLATE_IN_PDF';
export const SET_DOCUMENT_TEMPLATE_PDF = 'SET_DOCUMENT_TEMPLATE_PDF';
export const SET_ESIGNING_ERROR = 'SET_ESIGNING_ERROR';

export const GET_DOCUMENTS_API_STATE = 'GET_DOCUMENTS_API_STATE';
export const GET_DOCUMENT_API_STATE = 'GET_DOCUMENT_API_STATE';
export const GENERATE_AND_SEND_DOCUMENT_API_STATE =
  'GENERATE_AND_SEND_DOCUMENT_API_STATE';
export const APPROVE_DOCUMENT_API_STATE = 'APPROVE_DOCUMENT_API_STATE';
export const GET_TEMPLATES_API_STATE = 'GET_TEMPLATES_API_STATE';
export const UPLOAD_DOCUMENT_API_STATE = 'UPLOAD_DOCUMENT_API_STATE';
export const GET_AUDIT_TRAIL_API_STATE = 'GET_AUDIT_TRAIL_API_STATE';
export const DOWNLOAD_TEMPLATE_IN_PDF_API_STATE =
  'DOWNLOAD_TEMPLATE_IN_PDF_API_STATE';
export const DIRECT_UPLOAD_DOCUMENT_API_STATE =
  'DIRECT_UPLOAD_DOCUMENT_API_STATE';
export const E_SIGN_DOCUMENT_API_STATE = 'E_SIGN_DOCUMENT_API_STATE';
