import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TDivider from '../../../../common/TDivider';
import {
  TTable,
  TTableBody,
  TTableCell,
  TTableContainer,
  TTableHead,
  TTableRow,
} from '../../../../common/TTable';
import {
  SITE_QUESTIONS as questionText,
  SITE_QUESTIONS_LIST as questionList,
} from '../../../../constant/site_constants';
import { SMODetails } from './SMODetails';

const getQuestionAnswers = siteProfile => {
  const questionAnswers = [];

  for (const key in siteProfile) {
    const answerOfQuestion = siteProfile[key];
    let answer = '';
    let color = 'c-content-text';

    if (answerOfQuestion === true) {
      answer = 'Yes';
      color = 'c-green';
    } else if (answerOfQuestion === false) {
      answer = 'No';
      color = 'c-red';
    } else {
      answer = answerOfQuestion;
      color = 'c-content-text';
    }

    if (key.includes('sp_q')) {
      const questionMeta = questionList.find(q => q.key === key);
      let extra = {};
      let display = true;

      if (questionMeta !== undefined) {
        extra = questionMeta;

        if (questionMeta?.dependency?.dependent) {
          display =
            siteProfile[questionMeta?.dependency?.key] ===
            questionMeta?.dependency?.value;
        }
      }

      questionAnswers.push({
        question: questionText[key],
        answer: answer,
        color: color,
        display: display,
        ...extra,
      });
    }
  }

  return questionAnswers;
};

const ResearchStaffTable = ({ crcArr }) => {
  return (
    <Grid item>
      <Grid item className="p-4">
        <TTableContainer>
          <TTable size="medium">
            <TTableHead>
              <TTableRow>
                <TTableCell style={{ textAlign: 'center' }}>Name</TTableCell>
                <TTableCell style={{ textAlign: 'center' }}>
                  Position
                </TTableCell>
                <TTableCell style={{ textAlign: 'center' }}>Email</TTableCell>
                <TTableCell style={{ textAlign: 'center' }}>Contact</TTableCell>
              </TTableRow>
            </TTableHead>
            <TTableBody>
              {crcArr?.map((crc, indx) => (
                <TTableRow key={indx}>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.name}
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.Position}
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.Email}
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.Contact}
                  </TTableCell>
                </TTableRow>
              ))}
            </TTableBody>
          </TTable>
        </TTableContainer>
      </Grid>
    </Grid>
  );
};

export const ResearchStaff = ({ siteProfile, doctorProfile }) => {
  const user = useSelector(state => state.userReducer.user);

  const [quesAns, setQuesAns] = useState([]);
  const [gridData, setGridData] = useState(siteProfile?.crc || []);

  useEffect(() => {
    if (siteProfile) {
      const quesAndAns = getQuestionAnswers(siteProfile);
      setQuesAns(quesAndAns);
      setGridData(siteProfile.crc);
    }
  }, [siteProfile]);

  return (
    <Box className="my-5">
      <Typography
        as="h2"
        variant="h2"
        className="fs-xxl-temp fw-semibold c-subheading-text mx-5"
      >
        Research Staff
      </Typography>
      {!user?.verified ? (
        <Grid item xs={12}>
          <Card className="my-5 mt-3 message-card message-card-negative">
            <CardContent>
              <Typography className="c-red fw-semibold fs-xxl">
                You don't have permission to view Site Profile. Contact us to
                get your account verified.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <>
          {siteProfile?.sp_q01 !== null ? (
            <>
              <Box className="mx-5">
                <Box className="my-2 mx-3">
                  {gridData?.length > 0 && (
                    <ResearchStaffTable crcArr={gridData} />
                  )}
                </Box>
              </Box>
              <TDivider />
              {siteProfile?.smo?.length ? (
                <>
                  <Box className="mx-5 my-5">
                    <Box className="my-2 mx-1">
                      <SMODetails smoDetails={siteProfile.smo} />
                    </Box>
                  </Box>
                  <TDivider />
                </>
              ) : null}
              <Box className="my-5 mx-5">
                <Typography
                  as="h2"
                  variant="h2"
                  className="fs-xxl-temp fw-semibold c-subheading-text"
                >
                  Site Details
                </Typography>
              </Box>
              <Box className="mx-5">
                <Box className="my-5 mx-5">
                  {quesAns?.map((item, index) => (
                    <>
                      {item.display && (
                        <div className="flex my-4" key={index}>
                          <Typography
                            variant="body1"
                            className="fw-semibold c-subheading-text"
                          >
                            {item.question}
                          </Typography>
                          <Typography
                            className={`fw-medium c-content-text flex mx-2 ${item.color}`}
                          >
                            {`${item.startAdorment ? item.startAdorment : ''} ${
                              item.answer
                            } ${item.endAdorment ? item.endAdorment : ''}`}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
            </>
          ) : (
            <div className="my-5 mx-5">
              <Typography className="fs-xxl fw-medium c-disabled-text flex mx-3">
                Nothing to show.
              </Typography>
            </div>
          )}
        </>
      )}
    </Box>
  );
};
