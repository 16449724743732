import {
  ASSIGN_CRA_API_STATE,
  GET_CRAS_API_STATE,
  GET_QUALIFICATION_SITES_API_STATE,
  SET_CRAS,
  SET_QUALIFICATION_SITES,
} from '../actions/siteQualificationActionType';
import API_STATES from '../constant/api_constants';

const initialAPIState = {
  getQualificationSitesAPI: API_STATES.NOT_INITIATED,
  assignCraAPI: API_STATES.NOT_INITIATED,
  getCRAsAPI: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialAPIState,
  qualifiedSites: [],
  cras: [],
};

export default function siteQualificationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_QUALIFICATION_SITES:
      return { ...state, qualifiedSites: payload };
    case SET_CRAS:
      return { ...state, cras: payload };
    case ASSIGN_CRA_API_STATE:
      return { ...state, assignCraAPI: payload };
    case GET_QUALIFICATION_SITES_API_STATE:
      return { ...state, getQualificationSitesAPI: payload };
    case GET_CRAS_API_STATE:
      return { ...state, getCRAsAPI: payload };
    default:
      return state;
  }
}
