import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  downloadTemplateInPDF,
  getDocument,
  uploadDocument,
} from '../actions/documentsActions';
import { SET_E_SIGN_DOCUMENT_LINK } from '../actions/documentsActionType';
import { SET_TOAST_SUCCESS } from '../actions/toastActionType';
import API_STATES, { isCompleted, isPending } from '../constant/api_constants';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
  SIGNING_METHOD,
} from '../constant/document_constants';
import {
  getFirstUnfilledSection,
  getSectionsData,
} from '../helpers/sectionData';
import Forms from './FormRenderer/Forms';
import TButton from './TButton';
import TCircularProgress from './TCircularProgress';
import TPDFRenderer from './TPDFRenderer';

const TSignDocumentsModal = ({
  setShowSignDocumentModal,
  setShowSignPasswordModal,
  action,
  documentObj,
  canSign = true,
}) => {
  const {
    getDocumentApi,
    document,
    uploadDocumentApi,
    documentTemplatePdf,
    downloadTemplatePdfApi,
  } = useSelector(state => state.documentsReducer);
  const user = useSelector(state => state.userReducer.user);
  const trialId = useSelector(state => state.trialReducer.trial.id);

  const [finalDocumentObj, setFinalDocumentObj] = useState(documentObj);
  const [formResponse, setFormResponse] = useState([]);
  const [isDirect, setIsDirect] = useState(false);
  const [documentDownloading, setDocumentDownloading] = useState(false);
  const [templateDownloading, setTemplateDownloading] = useState(false);
  const [currentFormSection, setCurrentFormSection] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    let docObj = {
      ...documentObj,
      trialId: documentObj.trialId ? documentObj.trialId : trialId,
      isPdf:
        documentObj?.status === DOCUMENT_STATUS.RESPONSE_RECEIVED ||
        documentObj?.status === DOCUMENT_STATUS.SIGNED ||
        (documentObj?.status === DOCUMENT_STATUS.RESPONSE_UPLOADED &&
          documentObj?.sign_type === SIGNING_METHOD.AADHAAR),
    };
    setFinalDocumentObj(docObj);
    dispatch(getDocument(docObj));
  }, [documentObj]);

  useEffect(() => {
    if (isCompleted(uploadDocumentApi) && finalDocumentObj.type === 'Form') {
      dispatch(getDocument(finalDocumentObj));
    }
  }, [uploadDocumentApi]);

  useEffect(() => {
    if (document.formio_data) {
      const sections = getSectionsData(document.formio_data);
      const firstUnfilledSection = getFirstUnfilledSection(sections);

      setFormResponse(sections);
      setCurrentFormSection(firstUnfilledSection);
    }

    dispatch({ type: SET_E_SIGN_DOCUMENT_LINK, payload: '' });
  }, [document]);

  useEffect(() => {
    if (documentTemplatePdf && templateDownloading) {
      setTimeout(() => {}, 1000);
      const docURL = window.URL.createObjectURL(
        new Blob([documentTemplatePdf], { type: 'application/pdf' })
      );

      window.open(docURL);
    }
  }, [documentTemplatePdf]);

  const handleProceed = data => {
    if (documentObj.type !== 'Form' || document?.byteLength) {
      setShowSignDocumentModal(false);
    }
    switch (action) {
      case DOCUMENT_MODAL_ACTIONS.SIGNING:
        setDocumentDownloading(false);
        setShowSignPasswordModal(true);
        break;
      case DOCUMENT_MODAL_ACTIONS.UPLOADING:
        dispatch(
          uploadDocument({
            ...finalDocumentObj,
            ...data,
            confirmationStatus: isDirect ? '?confirm=True' : '',
          })
        );
        break;
      case DOCUMENT_MODAL_ACTIONS.DOWNLOADING:
        setDocumentDownloading(true);
        break;
      case DOCUMENT_MODAL_ACTIONS.APPROVING:
        if (finalDocumentObj.sign_type === SIGNING_METHOD.EMAIL) {
          dispatch({
            type: SET_TOAST_SUCCESS,
            payload: 'Please continue the process from email',
          });
        } else {
          setShowSignPasswordModal(true);
        }
        break;
    }

    // To remove any existing link in the redux
    dispatch({
      type: SET_E_SIGN_DOCUMENT_LINK,
      payload: '',
    });
  };

  const FormRenderer = () => {
    switch (document.type) {
      case 'form':
        return (
          <div className="form-container">
            <Forms
              forms={formResponse}
              setFormData={setFormResponse}
              onSectionSubmit={data => {
                if (!isDirect) {
                  dispatch(
                    uploadDocument({
                      ...finalDocumentObj,
                      ...data,
                      confirmationStatus: isDirect ? '?confirm=True' : '',
                    })
                  );
                }
              }}
              onSign={() => {
                setDocumentDownloading(false);
                setShowSignPasswordModal(true);
              }}
              onClose={() => {
                setShowSignDocumentModal(false);
              }}
              disabled={action !== DOCUMENT_MODAL_ACTIONS.UPLOADING}
              isLoading={isPending(uploadDocumentApi)}
              canSubmit={
                user.role !== 'Sponsor' &&
                ['Response Pending', 'Response Uploaded'].includes(
                  documentObj.status
                )
              }
              tab={currentFormSection}
              setTab={setCurrentFormSection}
              canSign={canSign}
            />
          </div>
        );
      case 'html':
        return (
          <div
            style={{ maxHeight: '70vh', height: '70vh', overflow: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: document.html }}
          />
        );
      case 'pdf':
        return <TPDFRenderer document={document.link} />;
    }
  };

  const buttonLabel = () => {
    switch (action) {
      case DOCUMENT_MODAL_ACTIONS.SIGNING:
        return 'Sign';
      case DOCUMENT_MODAL_ACTIONS.DOWNLOADING:
        return 'Download';
      case DOCUMENT_MODAL_ACTIONS.UPLOADING:
        return 'Review';
      case DOCUMENT_MODAL_ACTIONS.APPROVING:
        return 'Approve';
    }
  };

  return (
    <Paper
      elevation={0}
      className={`p-4 ${
        document?.byteLength ? 'custom-modal-document' : 'custom-modal'
      }`}
      style={{
        minHeight: '80vh',
        width: document?.byteLength ? '45rem' : '80vw',
      }}
    >
      {isPending(getDocumentApi) ? (
        <div style={{ height: '70vh' }}>
          <TCircularProgress />
        </div>
      ) : (
        <Grid container spacing={4} justify="flex-end">
          <Grid
            item
            xs={12}
            style={{
              maxHeight: document?.byteLength ? '90vh' : '100%',
              overflow: 'scroll',
              paddingBottom: 0,
            }}
          >
            {FormRenderer()}
          </Grid>
          {document?.confirmationStatus ? (
            <Grid item xs={12} style={{ maxHeight: '100%', minHeight: '70vh' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDirect}
                    onChange={e => setIsDirect(e.target.checked)}
                    color="primary"
                  />
                }
                label="Confirm Submit"
              />
            </Grid>
          ) : null}
          {(documentObj.type !== 'Form' ||
            document?.byteLength ||
            !['Response Pending', 'Response Uploaded'].includes(
              documentObj.status
            )) && (
            <>
              <Grid item>
                <TButton
                  onClick={() => setShowSignDocumentModal(false)}
                  variant="outlined"
                  color="secondary"
                >
                  Close
                </TButton>
              </Grid>
              {document.type === 'pdf' ? (
                <Grid item>
                  <TButton
                    onClick={() => {
                      window.open(document.link);
                    }}
                    variant="contained"
                    color="primary"
                    isLoading={isPending(downloadTemplatePdfApi)}
                  >
                    Download Document
                  </TButton>
                </Grid>
              ) : null}
              {action === DOCUMENT_MODAL_ACTIONS.SIGNING ? (
                canSign ? (
                  <Grid item>
                    <TButton
                      onClick={() => handleProceed()}
                      variant="contained"
                      color="primary"
                    >
                      {buttonLabel()}
                    </TButton>
                  </Grid>
                ) : null
              ) : document?.byteLength ||
                action !== DOCUMENT_MODAL_ACTIONS.DOWNLOADING ? (
                <Grid item>
                  <TButton
                    onClick={() => handleProceed()}
                    variant="contained"
                    color="primary"
                  >
                    {buttonLabel()}
                  </TButton>
                </Grid>
              ) : null}
            </>
          )}
        </Grid>
      )}
    </Paper>
  );
};

export default TSignDocumentsModal;
