import { createSelector } from 'reselect';

const permissionSelector = createSelector(
  state => state.userReducer.user.viewPermissions,
  webItems =>
    webItems?.map(webItem => ({
      resourcePath: webItem.resource_path,
      permission: webItem.permission_type,
      resourceId: webItem.resource_id,
    }))
);

export default permissionSelector;
