import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput as Input,
} from '@material-ui/core';
import React from 'react';

import useStyles from './classes';

const TTextField = props => {
  const classes = useStyles();

  const { label, key } = props.field;
  const { value } = props;

  const handleChange = event => {
    const { value: newValue } = event.target;
    props.handleChange(key, newValue);
  };

  return (
    <FormControl
      className={`${classes.field} ${
        props?.field?.inline ? classes.inlineField : null
      }`}
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
    >
      <FormLabel
        htmlFor={key}
        error={props.error}
        style={props.dataGrid ? { display: 'none' } : null}
        className={`${classes.label}${
          props?.field?.validate?.required ||
          props?.field?.extra?.validate?.required
            ? ' required-field'
            : ''
        } `}
      >
        {label}
      </FormLabel>
      <div>
        <Input
          id={key}
          key={key}
          name={key}
          fullWidth
          type="text"
          onChange={handleChange}
          value={value}
          placeholder="Your answer"
          error={props.error}
          className={props?.field?.inline ? classes.inlineInput : null}
        />
        <FormHelperText error={props.error}>
          {props.errorMessage}
        </FormHelperText>
      </div>
    </FormControl>
  );
};

export default TTextField;
