import { Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDoctorProfile,
  getDoctorTrials,
  getDoctorTrialsCRC,
  getSDVParticipantsList,
} from '../../actions/doctorActions';
import { setAppBarConfig } from '../../actions/utilsActions';
import TCircularProgress from '../../common/TCircularProgress';
import SDV from '../../components/Doctor/SDV';
import { isCompleted, isPending } from '../../constant/api_constants';
import EmptyStateDoctorImg from '../../images/Empty_State_Doctors_Dashboard.png';

const DoctorSDVView = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const { doctorProfile, doctorProfileAPI, getSDVParticipantsAPI } =
    useSelector(state => state.doctorReducer);
  const firstDoctorTrial = useSelector(
    state => state.doctorReducer.doctorTrials[0]
  );

  useEffect(() => {
    if (doctorProfile && user.role === 'Clinical Research Coordinator') {
      dispatch(
        setAppBarConfig({
          showDoctorProfiles: true,
        })
      );
    }

    if (user.role === 'Clinical Research Coordinator') {
      if (doctorProfile?.id) {
        dispatch(getDoctorProfile({ profileId: doctorProfile.id }));
      }
    } else {
      dispatch(getDoctorProfile());
    }
  }, [user]);

  useEffect(() => {
    if (user?.verified) {
      if (user.role === 'Clinical Research Coordinator') {
        if (doctorProfile[0]) {
          dispatch(getDoctorTrialsCRC(doctorProfile[0]?.user_id));
        }
      } else {
        dispatch(getDoctorTrials());
      }
    }
  }, [doctorProfile[0]?.user_id]);

  useEffect(() => {
    if (firstDoctorTrial?.trial_id) {
      user.role === 'Clinical Research Coordinator' && doctorProfile
        ? dispatch(
            getSDVParticipantsList({
              trialId: firstDoctorTrial?.trial_id,
              doctorId: doctorProfile[0].user_id,
            })
          )
        : dispatch(
            getSDVParticipantsList({ trialId: firstDoctorTrial?.trial_id })
          );
    }
  }, [firstDoctorTrial?.trial_id, doctorProfile[0]?.user_id]);

  return (
    <div className="h-100 py-4 px-5">
      {isPending(doctorProfileAPI) || isPending(getSDVParticipantsAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          {!doctorProfile?.length ||
          !user?.verified ||
          !firstDoctorTrial?.trial_id ? (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '100px' }}
                    src={EmptyStateDoctorImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  {!firstDoctorTrial?.trial_id
                    ? 'There are no upcoming trials at the moment. Please check back later.'
                    : 'Your Profile is not verified yet. Our teams will contact you soon.'}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            isCompleted(getSDVParticipantsAPI) && <SDV />
          )}
        </>
      )}
    </div>
  );
};

export default DoctorSDVView;
