export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_API_STATE = 'SIGN_UP_API_STATE';
export const LOGIN_CHECK = 'LOGIN_CHECK';
export const LOGIN_API_STATE = 'LOGIN_API_STATE';
export const LOGIN_CHECK_API_STATE = 'LOGIN_CHECK_API_STATE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const REQUEST_PHONE_OTP = 'REQUEST_PHONE_OTP';
export const REQUEST_EMAIL_OTP = 'REQUEST_EMAIL_OTP';
export const VERIFY_PHONE_OTP = 'VERIFY_PHONE_OTP';
export const VERIFY_EMAIL_OTP = 'VERIFY_EMAIL_OTP';
export const SET_PASSWORD_RESET_REQUIRED = 'SET_PASSWORD_RESET_REQUIRED';

export const REQUEST_PHONE_OTP_API_STATE = 'REQUEST_PHONE_OTP_API_STATE';
export const REQUEST_EMAIL_OTP_API_STATE = 'REQUEST_EMAIL_OTP_API_STATE';
export const VERIFY_PHONE_OTP_API_STATE = 'VERIFY_PHONE_OTP_API_STATE';
export const VERIFY_EMAIL_OTP_API_STATE = 'VERIFY_EMAIL_OTP_API_STATE';

export const SET_USER = 'SET_USER';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_IS_AUTH = 'SET_IS_AUTH';

export const SET_TOKEN_EXPIRY = 'SET_TOKEN_EXPIRY';

export const USER_API_ERROR = 'USER_API_ERROR';

export const USER_API_SUCCESS = 'USER_API_SUCCESS';

export const VERIFY_PHONE = 'VERIFY_PHONE';
export const SET_VERIFY_PHONE_API_STATE = 'SET_VERIFY_PHONE_API_STATE';

export const SEND_OTP_RESET_PASSWORD = 'SEND_OTP_RESET_PASSWORD';
export const SEND_OTP_RESET_PASSWORD_API_STATE =
  'SEND_OTP_RESET_PASSWORD_API_STATE';
export const VERIFY_OTP_RESET_PASSWORD = 'VERIFY_OTP_RESET_PASSWORD';
export const VERIFY_OTP_RESET_PASSWORD_API_STATE =
  'VERIFY_OTP_RESET_PASSWORD_API_STATE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_API_STATE = 'UPDATE_PASSWORD_API_STATE';
export const LOGOUT_API_STATE = 'LOGOUT_API_STATE';

export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
