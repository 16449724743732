import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore, NavigateBefore, NavigateNext } from '@material-ui/icons';
import React, { forwardRef, useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 56,
  },
  select: {
    fontSize: '0.75rem',
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  selectMenu: {
    fontSize: '0.75rem',
  },
  iconButton: {
    minWidth: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '2rem',
    height: '2rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  button: { textTransform: 'none' },
}));

export const PER_PAGE_OPTIONS = [10, 25, 50, 100, 200];

const AgGridPagination = ({ gridApi }) => {
  const classes = useStyles();
  const [pageLowerLimitCount, setPageLowerLimitCount] = useState(1);
  const [pageUpperLimitCount, setPageUpperLimitCount] = useState(10);

  const getPaginationIndicator = () => {
    if (gridApi) {
      const pageSize = gridApi.paginationGetPageSize();
      const rowCount = gridApi.paginationGetRowCount();
      const currPage = gridApi.paginationGetCurrentPage() + 1;
      const lowerLimitCount = (currPage - 1) * pageSize + 1;
      const upperLimitCount =
        currPage * pageSize <= rowCount ? currPage * pageSize : rowCount;
      setPageLowerLimitCount(lowerLimitCount);
      setPageUpperLimitCount(upperLimitCount);
    }
    return null;
  };

  const onNext = () => {
    gridApi.paginationGoToNextPage();
    getPaginationIndicator();
  };

  const onPrevious = () => {
    gridApi.paginationGoToPreviousPage();
    getPaginationIndicator();
  };

  const handleChange = event => {
    gridApi.paginationSetPageSize(Number(event.target.value));
    getPaginationIndicator();
  };

  useEffect(() => {
    getPaginationIndicator();
  }, [gridApi]);

  return (
    <Grid
      container
      spacing={1}
      style={{ padding: '1rem' }}
      justify="space-between"
    >
      <Grid item container xs={2} spacing={1}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" color="textSecondary">
            Rows per page:
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl className={classes.formControl}>
            <Select
              classes={{
                select: classes.select,
              }}
              onChange={handleChange}
              defaultValue={10}
              disableUnderline
              IconComponent={forwardRef((props, ref) => (
                <ExpandMore
                  style={{ top: 'calc(50% - 10px)' }}
                  fontSize="small"
                  {...props}
                  ref={ref}
                />
              ))}
              size="small"
            >
              {PER_PAGE_OPTIONS.map(option => (
                <MenuItem
                  classes={{ root: classes.selectMenu }}
                  key={option}
                  value={option}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={1} justify="flex-end" xs={2} wrap="nowrap">
        <Grid item xs="auto">
          <Tooltip title="Previous page">
            <IconButton size="small" onClick={onPrevious}>
              <NavigateBefore color="action" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item container alignItems="center" justify="center">
          {gridApi && (
            <Typography variant="caption" color="textSecondary">
              {Math.ceil(gridApi.paginationGetCurrentPage() + 1)} of{' '}
              {Math.ceil(gridApi.paginationGetTotalPages())}
            </Typography>
          )}
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Next page">
            <IconButton size="small" onClick={onNext}>
              <NavigateNext color="action" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AgGridPagination;
