import { useSelector } from 'react-redux';

import viewPermissionSelector from '../../selectors/viewPermissionSelector';

const DEFAULT_RESOURCE_PATH = '/dashboard';

const isAccessAllowed = (userPermissions, resourcePath) => {
  let isAllowed = true;

  if (resourcePath === DEFAULT_RESOURCE_PATH) {
    return true;
  }

  if (userPermissions) {
    const filterModules = userPermissions.filter(elem => {
      const index = resourcePath.indexOf(elem.resourcePath);

      if (index !== -1) {
        return true;
      }
      return false;
    });

    isAllowed = !!filterModules.length;
  }

  return isAllowed;
};

const useAccess = resourcePath => {
  const userPermissions = useSelector(viewPermissionSelector);
  const permitted = isAccessAllowed(userPermissions, resourcePath);

  return permitted;
};

export default useAccess;
