import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { directUploadDocument } from '../actions/documentsActions';
import { isPending } from '../constant/api_constants';
import TButton from './TButton';
import TDropZone from './TDropZone';

const TUploadDocumentsModal = ({
  setShowUploadDocumentsModal,
  setShowConfirmationModal,
  setConfirmationModalLabel,
  documentType,
  documentObj,
}) => {
  const uploadDocumentApi = useSelector(
    state => state.documentsReducer.uploadDocumentApi
  );
  const uploadDocumentSuccess = useSelector(
    state => state.documentsReducer.uploadDocumentSuccess
  );

  const user = useSelector(state => state.userReducer.user);

  const [documentName, setDocumentName] = useState('');
  const [files, setFiles] = useState([]);
  const [confirmationStatus, setConfirmationStatus] = useState(false);

  const dispatch = useDispatch();

  const handleUpload = () => {
    if (files[0] !== undefined) {
      let formData = new FormData();
      formData.append('data', files[0]);

      dispatch(
        directUploadDocument({
          ...documentObj,
          documentName: documentName,
          data: formData,
          confirmationStatus: confirmationStatus ? '?confirm=True' : '',
        })
      );
      setShowUploadDocumentsModal(false);
    }
  };

  useEffect(() => {
    if (!isPending(uploadDocumentApi) && uploadDocumentSuccess) {
      setShowConfirmationModal(true);
      setConfirmationModalLabel(`${documentType} uploaded successfully`);
    }
  }, [uploadDocumentApi]);

  return (
    <Paper elevation={0} className="p-4 custom-modal">
      <Grid container direction="column" style={{ width: '45rem' }} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">Name of the document</Typography>
          <TextField
            size="small"
            value={documentName}
            variant="outlined"
            fullWidth
            style={{ backgroundColor: 'white' }}
            onChange={e => setDocumentName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Upload document</Typography>
          <TDropZone setDocument={setFiles} />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} style={{ maxHeight: '35rem' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmationStatus}
                  onChange={e => setConfirmationStatus(e.target.checked)}
                  color="primary"
                />
              }
              label={
                user.role === 'Doctor'
                  ? 'I understand that this document will be shared with the Sponsor and CRA.'
                  : 'I understand that this document will be shared with the Sponsor and Doctor.'
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className="mb-2"
          justify="flex-end"
          spacing={2}
        >
          <Grid item>
            <TButton
              onClick={() => setShowUploadDocumentsModal(false)}
              variant="outlined"
              color="secondary"
              fullWidth
            >
              Close
            </TButton>
          </Grid>
          <Grid item>
            <TButton
              onClick={handleUpload}
              disabled={!confirmationStatus || !files.length || !documentName}
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </TButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TUploadDocumentsModal;
