import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (error) {
      return { hasError: true };
    }
    return { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    // console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container">
          <div className="title">Something just went wrong!</div>
          <div>
            The page you are looking for didn’t load. We willl fix it soon!
          </div>
          <div
            className="fw-bold text-primary hand-pointer"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try Reloading the Page
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
