import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIos } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TDoctorProfileWidget from '../common/TDoctorProfileWidget';
import TTrialWidget from '../common/TTrialWidget';
import ProfileWidget from '../components/ProfileWidget';
import NotificationIcon from '../images/Notification.svg';
import TriomicsLogo from '../images/TriomicsLogo.svg';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  appBar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(4),
    width: '100%',
    boxShadow: '0px 1px 4px #15223214',
    zIndex: theme.zIndex.drawer + 1,
  },
  trialContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    width: 280,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    width: 2,
    backgroundColor: '#7C7F84',
  },
}));

const TAppBar = () => {
  const classes = useStyles();
  const appBarConfig = useSelector(state => state.utilsReducer.appBarConfig);

  const appBarTitleText = appBarConfig?.title?.text;
  const isAppBarTitleLink = appBarConfig?.title?.isLink;
  const appBarTitleLink = appBarConfig?.title?.link;
  const hasPrevIcon = appBarConfig?.title?.prevIcon;
  const prevLink = appBarConfig?.title?.prevLink;
  const showTrials = appBarConfig?.showTrials;
  const showDoctorProfiles = appBarConfig?.showDoctorProfiles;

  const appBarTitle = (
    <Typography className="fw-semibold fs-xl" noWrap>
      {hasPrevIcon && (
        <Link to={prevLink}>
          <IconButton className="p-0">
            <ArrowBackIos fontSize="small" />
          </IconButton>
        </Link>
      )}
      {isAppBarTitleLink ? (
        <Link to={appBarTitleLink} className="text-decoration-none text-reset">
          {appBarTitleText}
        </Link>
      ) : (
        appBarTitleText
      )}
    </Typography>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="inherit"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={1} alignItems="center">
          <Grid container item xs spacing={5}>
            <Grid item className={classes.logo}>
              <img
                style={{ width: '154px', height: 'auto' }}
                src={TriomicsLogo}
              />
            </Grid>
            {/* <Grid item xs>
              {appBarTitle}
            </Grid> */}
          </Grid>
          {showTrials && (
            <Grid item className={`me-2 ${classes.trialContainer}`}>
              <TTrialWidget />
              <div className={`${classes.separator} mx-4`} />
            </Grid>
          )}
          {showDoctorProfiles && (
            <Grid item className={`me-2 ${classes.trialContainer}`}>
              <TDoctorProfileWidget />
              <div className={`${classes.separator} mx-4`} />
            </Grid>
          )}
          {/* <Grid item>
            <img style={{ marginRight: 24 }} src={NotificationIcon} />
          </Grid> */}
          <Grid item>
            <ProfileWidget />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TAppBar;
