import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  header: {
    height: '48px',
    backgroundColor: `#BFE2FF`,
    boxShadow: '0px 0px 6px #0000001A',
    borderRadius: '16px 16px 0px 0px',
    textAlign: 'center',
    font: 'normal normal 600 18px/48px Poppins',
    color: '#2C3E50',
  },
  body: {
    background: 'white',
    padding: '20px',
    borderRadius: '0px 0px 16px 16px',
    boxShadow: '0px 0px 6px #0000001A',
  },
}));

const TCard = ({ header, bodyComponent }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.header}>
        {header}
      </Grid>
      {bodyComponent ? (
        <Grid item xs={12} className={classes.body}>
          {bodyComponent}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default TCard;
