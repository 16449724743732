export const RESOURCE_ID_MAP = Object.freeze({
  TRIAL_MATRIX: 'TS101',
});

export const RESOURCE_KEYS = Object.freeze({
  TRIAL_MATRIX: 'TRIAL_MATRIX',
});

export const CHUNKS = Object.freeze({
  ADMIN_TRIAL_DASHBOARD: {
    resource_id: 'P101',
    resource_name: 'Trial Dashboard',
    resource_path: '/admin/trial-dashboard',
  },
  ADMIN_SITES_DASHBOARD: {
    resource_id: 'P102',
    resource_name: 'Sites',
    resource_path: '/admin/sites',
  },
  ADMIN_USERS_DASHBOARD: {
    resource_id: 'P103',
    resource_name: 'Users',
    resource_path: '/admin/users',
  },
  DM_DASHBOARD: {
    resource_id: 'P104',
    resource_name: 'Dashboard',
    resource_path: '/dashboard',
    navigation_name: 'dashboard',
  },
  SITE_IDENTIFICATION: {
    resource_id: 'P104',
    resource_name: 'Identification',
    resource_path: '/site-identification',
    navigation_name: 'site-identification',
  },
  FEASIBILITY: {
    resource_id: 'P105',
    resource_name: 'Feasibility',
    resource_path: '/feasibility',
    navigation_name: 'feasibility',
  },
  SITE_QUALIFICATION: {
    resource_id: 'P106',
    resource_name: 'Site Qualification',
    resource_path: '/site-qualification',
    navigation_name: 'site-qualification',
  },
  SITE_INITIATION: {
    resource_id: 'P107',
    resource_name: 'Site Activation',
    resource_path: '/site-initiation',
    navigation_name: 'site-initiation',
  },
  SITE_INITIATION_SITE_INFO: {
    resource_id: 'P108',
    resource_name: 'Site Activation - Info',
    resource_path: '/site-initiation/info/trial/:trialId/:id/site/:siteId',
    navigation_name: 'site-initiation',
  },
  SITE_IDENTIFICATION_SITE_INFO: {
    resource_id: 'P111',
    resource_name: 'Site Info',
    resource_path: '/site-identification/info/:id',
    navigation_name: 'site-identification',
  },
  CRA_INITIATION: {
    resource_id: 'P109',
    resource_name: 'Activation Visit',
    resource_path: '/cra-initiation',
    navigation_name: 'cra-initiation',
  },
  CRA_QUALIFICATION: {
    resource_id: 'P110',
    resource_name: 'Qualification Visit',
    resource_path: '/cra-qualification',
    navigation_name: 'cra-qualification',
  },
  CRA_INITIATION_SITES: {
    resource_id: 'P110',
    resource_name: 'Site Activation',
    resource_path: '/cra-initiation-sites',
    navigation_name: 'cra-initiation-sites',
  },
  CRA_INITIATION_DOCUMENTS_INFO: {
    resource_id: 'P113',
    resource_name: 'Activation - Info',
    resource_path: '/cra-initiation-sites/info/trial/:trialId/:id/site/:siteId',
    navigation_name: 'cra-initiation-sites',
  },
  CRA_INITIATION_DOCUMENTS: {
    resource_id: 'P112',
    resource_name: 'Activation',
    resource_path: '/cra-initiation-documents',
    navigation_name: 'cra-initiation-documents',
  },
  CRA_SDV: {
    resource_id: 'P115',
    resource_name: 'Remote Monitoring',
    resource_path: '/cra-sdv',
    navigation_name: 'cra-sdv',
  },
  CRA_SDV_TRIAL_SITE_PARTICIPANTS: {
    resource_id: 'P116',
    resource_name: 'Site SDVs',
    resource_path: '/cra-sdv/trial-site/:trialSiteId/participants',
    navigation_name: 'cra-sdv',
  },
  DOCTOR_PROFILE: {
    resource_id: 'P113',
    resource_name: 'Profile',
    resource_path: '/profile',
    navigation_name: 'profile',
  },
  DOCTOR_UPCOMING_TRIALS: {
    resource_id: 'P114',
    resource_name: 'Upcoming Trials',
    resource_path: '/upcoming-trials',
    navigation_name: 'upcoming-trials',
  },
  DOCTOR_SDVS: {
    resource_id: 'P115',
    resource_name: 'Remote Monitoring',
    resource_path: '/sdv',
    navigation_name: 'sdv',
  },
});
