import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { patchVisit } from '../../actions/craActions';
import { assignCRA } from '../../actions/siteQualificationActions';
import TButton from '../../common/TButton';
import API_STATES, { isPending } from '../../constant/api_constants';

const ConfirmAssignCRAModal = ({
  selectedSite,
  setShowConfirmAssignCRAModal,
  initiation,
}) => {
  const assignCraAPI = useSelector(
    state => state.siteQualificationReducer.assignCraAPI
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const dispatch = useDispatch();

  const handleProceed = () => {
    setShowConfirmAssignCRAModal(false);
  };

  const handleAssignCRA = () => {
    if (initiation) {
      dispatch(patchVisit(selectedSite));
    } else {
      dispatch(assignCRA({ trialId: trialId, site: selectedSite }));
    }
    setShowConfirmAssignCRAModal(false);
  };

  return (
    <Paper elevation={0} className="p-4 custom-modal">
      <Grid container direction="column" spacing={2} justify="flex-end">
        <Grid item>
          <Typography
            className="mt-3"
            style={{ font: 'normal normal medium 18px/32px Poppins' }}
          >
            Are you sure you want to{' '}
            {selectedSite.firstTime ? 'assign a new' : 'change the'} CRA for
            this site?
          </Typography>
        </Grid>
        <Grid item>
          <TButton
            onClick={() => handleAssignCRA()}
            variant="contained"
            color="primary"
            fullWidth
            isLoading={isPending(assignCraAPI)}
          >
            Yes, {selectedSite.firstTime ? 'Assign' : 'Change'} the CRA
          </TButton>
        </Grid>
        <Grid item>
          <TButton
            onClick={() => handleProceed()}
            variant="text"
            color="primary"
            fullWidth
          >
            {selectedSite.firstTime ? 'Cancel' : 'No, keep the original CRA'}
          </TButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConfirmAssignCRAModal;
