/* eslint-disable react/prop-types */
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: props => ({
    height: props.size === 'small' ? 32 : props.size === 'large' ? 48 : 36,
    textTransform: 'none',
    borderRadius: '1000px',
    padding: '0 32px',
    fontWeight: 500,
  }),
}));

const Spinner = props => <CircularProgress {...props} />;

const TButton = ({
  disabled = false,
  isLoading = false,
  size = 'medium',
  children,
  className = '',
  ...props
}) => {
  const classes = useStyles({ size });
  const tButtonClassName = clsx(classes.root, className);
  const spinnerSize = size === 'small' ? 20 : size === 'large' ? 24 : 22;
  return (
    <Button
      className={tButtonClassName}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? <Spinner size={spinnerSize} /> : children}
    </Button>
  );
};
export default TButton;
