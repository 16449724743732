import * as Yup from 'yup';

import { VALIDATION_MESSAGES as VM } from '../../../../constant/form_validation';

export const workExperience = Yup.object({
  name: Yup.string().nullable().required(VM.REQUIRED_ERR),
  city: Yup.string().required(VM.REQUIRED_ERR),
  position: Yup.string().required(VM.REQUIRED_ERR),
  currently_working: Yup.boolean(),
  from_year: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .min(1900, VM.MIN_VAL_ERR(1900))
    .max(new Date().getFullYear(), VM.MAX_VAL_ERR(new Date().getFullYear()))
    .required(VM.REQUIRED_ERR),
  to_year: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .nullable()
    .max(new Date().getFullYear(), VM.MAX_VAL_ERR(new Date().getFullYear()))
    .min(Yup.ref('from_year'), VM.MIN_VAL_ERR(`'From year'`))
    .when('currently_working', {
      is: false,
      then: Yup.number(VM.NUMBER_ERR)
        .typeError(VM.NUMBER_ERR)
        .required(VM.REQUIRED_ERR),
    }),
});

export const education = Yup.object({
  college_name: Yup.string().required(VM.REQUIRED_ERR),
  degree: Yup.string().required(VM.REQUIRED_ERR),
  currently_studying: Yup.boolean(),
  graduation_year: Yup.number(VM.NUMBER_ERR)
    .typeError(VM.NUMBER_ERR)
    .nullable()
    .when('currently_studying', {
      is: false,
      then: Yup.number(VM.NUMBER_ERR)
        .typeError(VM.NUMBER_ERR)
        .max(new Date().getFullYear(), VM.MAX_VAL_ERR(new Date().getFullYear()))
        .min(1970, VM.MIN_VAL_ERR(1970))
        .required(VM.REQUIRED_ERR),
    }),
});

const cETDetails = Yup.object({
  ctri_number: Yup.string().required(VM.REQUIRED_ERR),
  pub_title: Yup.string().required(VM.REQUIRED_ERR),
  role: Yup.string().required(VM.REQUIRED_ERR),
});

export const clinicalExperience = Yup.object({
  clinical_experience: Yup.object().shape({
    ce_q01: Yup.bool().nullable().required(VM.REQUIRED_ERR),
    ce_q02: Yup.bool().nullable().required(VM.REQUIRED_ERR),
    ce_q03: Yup.bool().nullable().required(VM.REQUIRED_ERR),
    ce_q04: Yup.bool().nullable().required(VM.REQUIRED_ERR),
    clinical_experience_trial_details: Yup.array()
      .of(cETDetails)
      .when('ce_q02', {
        is: true,
        then: Yup.array().of(cETDetails).min(1, VM.REQUIRED_ERR),
      }),
    covid_clinical_experience_trial_details: Yup.array()
      .of(cETDetails)
      .when('ce_q04', {
        is: true,
        then: Yup.array().of(cETDetails).min(1, VM.REQUIRED_ERR),
      }),
    grant_details: Yup.string()
      .nullable()
      .when('ce_q03', {
        is: true,
        then: Yup.string(VM.REQUIRED_ERR).nullable().required(VM.REQUIRED_ERR),
      }),
  }),
});

export const validatorPartial = Yup.object().shape({
  linkedin_profile: Yup.string().url(VM.URL_ERR).nullable(),
  twitter_profile: Yup.string().url(VM.URL_ERR).nullable(),
  researchgate_profile: Yup.string().url(VM.URL_ERR).nullable(),
  area_of_interest: Yup.array().of(Yup.string()),
  field_of_study: Yup.string().nullable().required(VM.SELECT_ERR),
  education: Yup.array().of(education).min(1),
  work_experience: Yup.array().of(workExperience).min(1),
});

export const validator = validatorPartial.concat(clinicalExperience);
