export const SITE_INITIATION_STATES = Object.freeze({
  EXECUTED: 'executed',
  PENDING: 'pending',
  DOCUMENT_SENT: 'document_sent',
});

export const SITE_STATES_ENUM = Object.freeze({
  NONE: 1,
  WAITLISTED: 2,
  REJECTED: 3,
});

export const SITE_STATES = Object.freeze({
  SHORTLISTED: 'SHORTLISTED',
  WAITLISTED: 'WAITLISTED',
  NONE: 'NONE',
  REJECTED: 'REJECTED',
});

export const SITE_STAGE = Object.freeze({
  SHORTLISTED: 'SHORTLISTED',
  FEASIBILITY: 'FEASIBILITY',
  QUALIFICATION: 'QUALIFICATION',
  INITIATION: 'INITIATION',
});

export const SITE_STAGE_INDEX = Object.freeze({
  SHORTLISTING: 1,
  FEASIBILITY: 2,
  QUALIFICATION: 3,
  INITIATION: 4,
  ACTIVE: 5,
});

export const SITE_VISIT_STATUS = Object.freeze({
  1: 'Yet to be scheduled',
  2: 'Scheduled',
  3: 'Executed',
  4: 'Completed',
});

export const SITE_VISIT_STATUS_NUM = Object.freeze({
  'Yet to be scheduled': 1,
  SCHEDULED: 2,
  EXECUTED: 3,
  COMPLETED: 4,
});

export const SITE_VISIT_TYPE = Object.freeze({
  3: 'Qualification',
  4: 'Initiation',
});

export const SITE_QUESTIONS = Object.freeze({
  sp_q01: 'Has your hospital conducted clinical trials in last two years:',
  sp_q02: 'Can your site run BA/BE studies:',
  sp_q03: 'Does your site have a clinical Pharmacology Unit:',
  sp_q04: 'Does your site have a Central EHR:',
  sp_q05: 'Have you updated your EHR in the last year:',
  sp_q06: 'Does your site have SMO:',
  sp_q07: 'Total Number of Beds:',
  sp_q08: 'Total Number of ICU Beds:',
  sp_q09: 'Total Number of Daycare Beds:',
  sp_q10: 'Does your site have an Institutional Ethics Committee:',
  sp_q11: 'Frequency of IEC meeting:',
  sp_q12:
    'Minimum number of days before the meeting for the document submission:',
  sp_q13: 'Ethics Committee document approval time:',
  sp_q14: 'IEC fees:',
  sp_q15: 'IEC registration number:',
  sp_q16: 'Can your IEC provide an expedited approval?',
  sp_q17: 'If your IEC can provide expedited approval, what is the extra fees?',
  sp_q18:
    'If your IEC can provided expedited approval, what will be the duration?',
  sp_q19: 'What translations are you expecting for IEC Dossier?',
});

export const SITE_QUESTIONS_LIST = Object.freeze([
  {
    key: 'sp_q01',
    question: 'Has your hospital conducted clinical trials in last two years:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q02',
    question: 'Can your site run BA/BE studies:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q03',
    question: 'Does your site have a clinical Pharmacology Unit:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q04',
    question: 'Does your site have a Central EHR:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q05',
    question: 'Have you updated your EHR in the last year:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q06',
    question: 'Does your site have SMO:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'smo_detail',
    dependency: {
      dependent: true,
      key: 'sp_q06',
      value: true,
    },
  },
  {
    key: 'sp_q07',
    question: 'Total Number of Beds:',
    type: 'number',
    endAdorment: 'Beds',
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q08',
    question: 'Total Number of ICU Beds:',
    type: 'number',
    endAdorment: 'Beds',
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q09',
    question: 'Total Number of Daycare Beds:',
    type: 'number',
    endAdorment: 'Beds',
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q10',
    question: 'Does your site have an Institutional Ethics Committee:',
    type: 'boolean',
    endAdorment: null,
    dependency: {
      dependent: false,
      key: null,
      value: null,
    },
  },
  {
    key: 'sp_q11',
    question: 'Frequency of IEC meeting:',
    type: 'number',
    endAdorment: 'Day(s)',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q12',
    question:
      'Minimum number of days before the meeting for the document submission:',
    type: 'number',
    endAdorment: 'Day(s)',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q13',
    question: 'Ethics Committee document approval time:',
    type: 'number',
    endAdorment: 'Day(s)',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q14',
    question: 'IEC fees:',
    type: 'number',
    startAdorment: 'INR',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q15',
    question: 'IEC registration number:',
    type: 'text',
    startAdorment: '',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q19',
    question: 'What translations are you expecting for IEC Dossier?',
    type: 'text',
    helperText: 'Example: English, Hindi, etc.',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q16',
    question: 'Can your IEC provide an expedited approval?',
    type: 'boolean',
    startAdorment: '',
    dependency: {
      dependent: true,
      key: 'sp_q10',
      value: true,
    },
  },
  {
    key: 'sp_q17',
    question:
      'If your IEC can provide expedited approval, what is the extra fees?',
    type: 'number',
    startAdorment: 'INR',
    dependency: {
      dependent: true,
      key: 'sp_q16',
      value: true,
    },
  },
  {
    key: 'sp_q18',
    question:
      'If your IEC can provide expedited approval, what will be the duration?',
    type: 'number',
    endAdorment: 'Day(s)',
    dependency: {
      dependent: true,
      key: 'sp_q16',
      value: true,
    },
  },
]);

export const SITE_TYPES = Object.freeze([
  'Government Hospital',
  'Private Hospital',
  'Private Multi-specialty Hospital',
  'University Hospital / Medical College',
  'Community Hospital',
  'Private Clinic',
  'Independent Research Site',
  'Trust Hospital',
]);
