import { Box, Collapse, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import TButton from '../../../../common/TButton';

export const SMODetails = ({ smoDetails }) => {
  return (
    <Box>
      <Typography
        as="h2"
        variant="h2"
        className="fs-xxl-temp fw-semibold c-subheading-text"
      >
        SMO Details
      </Typography>
      <Box className="my-2 mx-3">
        {smoDetails?.length ? (
          <Box>
            <div className="flex my-3">
              <Typography
                variant="body1"
                className="fs-xxl fw-semibold c-subheading-text"
              >
                SMO Name:
              </Typography>
              <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
                {smoDetails[0]?.smo_name}
              </Typography>
            </div>
            <div className="flex my-3">
              <Typography
                variant="body1"
                className="fs-xxl fw-semibold c-subheading-text"
              >
                SMO Contact Person:
              </Typography>
              <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
                {smoDetails[0]?.contact_person_name}
              </Typography>
            </div>
            <div className="flex my-3">
              <Typography
                variant="body1"
                className="fs-xxl fw-semibold c-subheading-text"
              >
                Email ID:
              </Typography>
              <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
                {smoDetails[0]?.email}
              </Typography>
            </div>
            <div className="flex my-3">
              <Typography
                variant="body1"
                className="fs-xxl fw-semibold c-subheading-text"
              >
                Contact:
              </Typography>
              <Typography className="fs-xxl fw-medium c-content-text flex mx-2">
                {smoDetails[0]?.contact}
              </Typography>
            </div>
          </Box>
        ) : (
          <div className="my-4">
            <Typography className="fs-xxl fw-medium c-disabled-text flex mx-3">
              Nothing to show.
            </Typography>
          </div>
        )}
      </Box>
    </Box>
  );
};
