/* eslint-disable no-console */
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import {
  APPROVE_DOCUMENT,
  APPROVE_DOCUMENT_API_STATE,
  DIRECT_UPLOAD_DOCUMENT,
  DIRECT_UPLOAD_DOCUMENT_API_STATE,
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_TEMPLATE_IN_PDF,
  DOWNLOAD_TEMPLATE_IN_PDF_API_STATE,
  E_SIGN_DOCUMENT,
  E_SIGN_DOCUMENT_API_STATE,
  GENERATE_AND_SEND_DOCUMENT,
  GENERATE_AND_SEND_DOCUMENT_API_STATE,
  GET_ALL_DOCUMENTS,
  GET_ALL_TEMPLATES,
  GET_AUDIT_TRAIL,
  GET_AUDIT_TRAIL_API_STATE,
  GET_DOCUMENT,
  GET_DOCUMENT_API_STATE,
  GET_DOCUMENTS_API_STATE,
  GET_TEMPLATES_API_STATE,
  SET_AUDIT_TRAIL,
  SET_DOCUMENT,
  SET_DOCUMENT_TEMPLATE_PDF,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENTS,
  SET_E_SIGN_DOCUMENT_LINK,
  SET_ESIGNING_ERROR,
  SET_TEMPLATES,
  SET_UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_API_STATE,
} from '../actions/documentsActionType';
import { SET_TOAST_ERROR, SET_TOAST_SUCCESS } from '../actions/toastActionType';
import {
  approveDocumentAPI,
  directUploadDocumentAPI,
  documentStatusPollingAPI,
  downloadTemplateInPDFAPI,
  eSignDocumentAPI,
  getAllDocumentsAPI,
  getAllTemplatesAPI,
  getAuditTrailAPI,
  getDocumentAPI,
  sendDocumentAPI,
  uploadDocumentAPI,
} from '../api/documents';
import API_STATES from '../constant/api_constants';
import { SIGNING_METHOD } from '../constant/document_constants';
import apiClient from '../helpers/apiclient';

function* getDocument(action) {
  yield put({
    type: GET_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    // if (action.payload.isPdf) {
    //   apiClient.defaults.responseType = 'arraybuffer';
    // }

    const response = yield call(getDocumentAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_DOCUMENT,
      payload: data,
    });
    yield put({
      type: SET_DOCUMENT_TYPE,
      payload: action.payload.document,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching documents',
      });
      yield put({
        type: SET_DOCUMENT,
        payload: [],
      });
      yield put({
        type: SET_DOCUMENT_TYPE,
        payload: '',
      });
    }
  } finally {
    // This is done to reset the responseType from arraybuffer
    delete apiClient.defaults.responseType;
    yield put({
      type: GET_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* downloadDocument(action) {
  yield put({
    type: GET_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getDocumentAPI, action.payload);
    const { data } = response;

    window.open(data.link);
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching documents',
      });
    }
  } finally {
    yield put({
      type: GET_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getAllDocuments(action) {
  yield put({
    type: GET_DOCUMENTS_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getAllDocumentsAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_DOCUMENTS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching documents',
      });
      yield put({
        type: SET_DOCUMENTS,
        payload: [],
      });
    }
  } finally {
    yield put({
      type: GET_DOCUMENTS_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getAllTemplates(action) {
  yield put({
    type: GET_TEMPLATES_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getAllTemplatesAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_TEMPLATES,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching templates',
      });
    }
  } finally {
    yield put({
      type: GET_TEMPLATES_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* generateAndSendDocument(action) {
  // console.log(action);
  yield put({
    type: GENERATE_AND_SEND_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(sendDocumentAPI, action.payload);
    const { data } = response;
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Document sent successfully',
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while generating document',
      });
    }
  } finally {
    yield put({
      type: GENERATE_AND_SEND_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* approveDocument(action) {
  yield put({
    type: APPROVE_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(approveDocumentAPI, action.payload);
    const { uuid } = response.data;

    // TODO: make another polling function and use saga race effect
    let processStatus = 0;
    let pollCount = 0;
    let url_to_sign;

    if (action.payload.sign_type === SIGNING_METHOD.AADHAAR) {
      while (processStatus !== 3 && pollCount <= 10) {
        try {
          const response = yield call(documentStatusPollingAPI, {
            ...action.payload,
            signingMethod: 'aadhaar',
            uuid: uuid,
          });
          processStatus = response.data.status;
          url_to_sign = response.data.url_to_sign;
        } catch (error) {}
        pollCount++;
        yield delay(5 * 1000);
      }

      if (pollCount > 10) throw 'Something went wrong. Please try again';

      yield put({
        type: SET_E_SIGN_DOCUMENT_LINK,
        payload: url_to_sign,
      });
    }

    pollCount = 0;

    while (processStatus < 4 && pollCount <= 10) {
      yield delay(10 * 1000);
      try {
        const response = yield call(documentStatusPollingAPI, {
          ...action.payload,
          signingMethod: 'aadhaar',
          uuid: uuid,
        });
        processStatus = response.data.status;
      } catch (error) {}
      pollCount++;
    }

    if (pollCount > 10) throw 'Something went wrong. Please try again';
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while generating document',
      });
    }
  } finally {
    yield put({
      type: APPROVE_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* eSignDocument(action) {
  yield put({
    type: SET_E_SIGN_DOCUMENT_LINK,
    payload: '',
  });
  yield put({
    type: E_SIGN_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(eSignDocumentAPI, action.payload);
    const { uuid } = response.data;

    // TODO: make another polling function and use saga race effect
    let processStatus = 0;
    let pollCount = 0;
    let url_to_sign;

    if (action.payload.signingMethod === 'aadhaar_sign') {
      while (processStatus !== 3 && pollCount <= 10) {
        try {
          const response = yield call(documentStatusPollingAPI, {
            ...action.payload,
            signingMethod: action.payload.signingMethod.replace('_sign', ''),
            uuid: uuid,
          });
          processStatus = response.data.status;
          url_to_sign = response.data.url_to_sign;
        } catch (error) {}
        pollCount++;
        yield delay(5 * 1000);
      }

      if (pollCount > 10) throw 'Something went wrong. Please try again';

      yield put({
        type: SET_E_SIGN_DOCUMENT_LINK,
        payload: url_to_sign,
      });
    }

    pollCount = 0;

    while (processStatus < 4 && pollCount <= 10) {
      yield delay(10 * 1000);
      try {
        const response = yield call(documentStatusPollingAPI, {
          ...action.payload,
          signingMethod: action.payload.signingMethod.replace('_sign', ''),
          uuid: uuid,
        });
        processStatus = response.data.status;
      } catch (error) {}
      pollCount++;
    }
    if (pollCount > 10) throw 'Something went wrong. Please try again';
  } catch (error) {
    if (error.response.status !== 401) {
      if (error.response.status === 403) {
        yield put({
          type: SET_TOAST_ERROR,
          payload: 'Wrong password entered. Please try again.',
        });
      } else {
        yield put({
          type: SET_TOAST_ERROR,
          payload: 'Error while generating document',
        });
      }
      yield put({
        type: SET_ESIGNING_ERROR,
        payload: true,
      });
    }
  } finally {
    yield put({
      type: E_SIGN_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* uploadDocument(action) {
  yield put({
    type: UPLOAD_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(uploadDocumentAPI, action.payload);
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Document reviewed successfully',
    });
    yield put({
      type: SET_UPLOAD_DOCUMENT_SUCCESS,
      payload: true,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while reviewing documents',
      });
    }
  } finally {
    yield put({
      type: UPLOAD_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* directUploadDocument(action) {
  yield put({
    type: DIRECT_UPLOAD_DOCUMENT_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    yield call(directUploadDocumentAPI, action.payload);
    yield put({
      type: SET_TOAST_SUCCESS,
      payload: 'Document uploaded successfully',
    });
    yield put({
      type: SET_UPLOAD_DOCUMENT_SUCCESS,
      payload: true,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while uploading documents',
      });
    }
  } finally {
    yield put({
      type: DIRECT_UPLOAD_DOCUMENT_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* getAuditTrail(action) {
  yield put({
    type: GET_AUDIT_TRAIL_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    const response = yield call(getAuditTrailAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_AUDIT_TRAIL,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching audit trail',
      });
    }
  } finally {
    yield put({
      type: GET_AUDIT_TRAIL_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* downloadTemplateInPDF(action) {
  yield put({
    type: DOWNLOAD_TEMPLATE_IN_PDF_API_STATE,
    payload: API_STATES.INITIATED,
  });
  try {
    apiClient.defaults.responseType = 'arraybuffer';

    const response = yield call(downloadTemplateInPDFAPI, action.payload);
    const { data } = response;

    yield put({
      type: SET_DOCUMENT_TEMPLATE_PDF,
      payload: data,
    });
  } catch (error) {
    if (error.response.status !== 401) {
      yield put({
        type: SET_TOAST_ERROR,
        payload: 'Error while fetching template',
      });
    }
  } finally {
    // This is done to reset the responseType from arraybuffer
    delete apiClient.defaults.responseType;
    yield put({
      type: DOWNLOAD_TEMPLATE_IN_PDF_API_STATE,
      payload: API_STATES.COMPLETED,
    });
  }
}

function* documentsWatcher() {
  yield takeLatest(GET_ALL_DOCUMENTS, getAllDocuments);
  yield takeLatest(DOWNLOAD_DOCUMENT, downloadDocument);
  yield takeLatest(GET_DOCUMENT, getDocument);
  yield takeLatest(GET_ALL_TEMPLATES, getAllTemplates);
  yield takeLatest(GENERATE_AND_SEND_DOCUMENT, generateAndSendDocument);
  yield takeLatest(APPROVE_DOCUMENT, approveDocument);
  yield takeLatest(E_SIGN_DOCUMENT, eSignDocument);
  yield takeLatest(UPLOAD_DOCUMENT, uploadDocument);
  yield takeLatest(DIRECT_UPLOAD_DOCUMENT, directUploadDocument);
  yield takeLatest(GET_AUDIT_TRAIL, getAuditTrail);
  yield takeLatest(DOWNLOAD_TEMPLATE_IN_PDF, downloadTemplateInPDF);
}

export default documentsWatcher;
