import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { RemoveCircleOutline, Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getShortlistedSites,
  removeShortlistedSite,
} from '../../actions/siteIdentificationActions';
import { updateTrialSiteStatus } from '../../actions/trialActions';
import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { ShortlistSiteIcon } from '../../common/icons';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import API_STATES, { isPending } from '../../constant/api_constants';
import { SITE_STAGE } from '../../constant/site_constants';
import EmptyListStateImg from '../../images/Empty_State_Empty_List.png';

const ShortlistedSites = ({ gridApi, setGridApi }) => {
  const shortlistedSites = useSelector(
    state => state.siteIdentificationReducer.shortlistedSites
  );
  const getShortlistedSiteAPI = useSelector(
    state => state.siteIdentificationReducer.getShortlistedSiteAPI
  );
  const removeSiteAPI = useSelector(
    state => state.siteIdentificationReducer.removeSiteAPI
  );
  const updateTrialSiteAPI = useSelector(
    state => state.trialReducer.updateTrialSiteAPI
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);

  const dispatch = useDispatch();

  const columnDefs = [
    {
      headerName: 'Investigator',
      field: 'site.doctor',
      cellStyle: { paddingLeft: '3%' },
    },
    {
      headerName: 'Site',
      field: 'site.hospital',
    },
    {
      headerName: 'Location',
      field: 'site.location',
    },
    {
      headerName: 'ACTIONS',
      cellRenderer: 'actionCellRenderer',
      maxWidth: 200,
      filter: false,
      // cellClass: 'align-column-center',
    },
  ];

  useEffect(() => {
    if (trialId) {
      dispatch(getShortlistedSites(trialId));
    }
  }, [trialId]);

  useEffect(() => {
    if (
      removeSiteAPI === API_STATES.COMPLETED ||
      updateTrialSiteAPI === API_STATES.COMPLETED
    ) {
      dispatch(getShortlistedSites(trialId));
    }
  }, [removeSiteAPI, updateTrialSiteAPI]);

  const handleSiteInfo = id => {
    dispatch(push(`/site-identification/info/${id}`));
  };

  const handleMoveToFeasibility = id => {
    dispatch(
      updateTrialSiteStatus({
        trialId: trialId,
        id: id,
        stage: SITE_STAGE.FEASIBILITY,
        status: 'NONE',
        currentStage: SITE_STAGE.SHORTLISTED,
      })
    );
  };

  const handleRemoveSite = id => {
    dispatch(removeShortlistedSite({ trialId: trialId, id: id }));
  };

  const actionCellRenderer = params => {
    return (
      <div className="w-100 h-100 d-flex">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Tooltip title="Move to Feasibility">
              <IconButton
                size="small"
                onClick={() => handleMoveToFeasibility(params.data.id)}
              >
                <ShortlistSiteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="View Site Info">
              <IconButton
                size="small"
                onClick={() => handleSiteInfo(params.data.site.id)}
              >
                <Visibility color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Remove shortlisted Site">
              <IconButton
                size="small"
                onClick={() => handleRemoveSite(params.data.id)}
              >
                <RemoveCircleOutline color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    );
  };

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  return (
    <>
      {isPending(getShortlistedSiteAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          {!shortlistedSites?.length ? (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '100px' }}
                    src={EmptyListStateImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  No sites present in this stage
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  className="ag-react-container"
                  style={{
                    height: '60vh',
                    width: '100%',
                  }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={shortlistedSites}
                    gridOptions={DEFAULT_GRID_OPTIONS}
                    columnDefs={columnDefs}
                    frameworkComponents={{
                      actionCellRenderer,
                    }}
                    defaultColDef={{
                      ...DEFAULT_COL_DEF,
                      cellClass: ['grid-cell-4n'],
                    }}
                    pagination
                    suppressPaginationPanel
                    suppressRowHoverHighlight
                    paginationPageSize={PER_PAGE_OPTIONS[0]}
                    animateRows
                    onGridSizeChanged={params => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <AgGridPagination gridApi={gridApi} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ShortlistedSites;
