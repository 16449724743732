import { SPONSOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';
import apiClientMultipart from '../helpers/apiClientMultipart';

export const getDocumentAPI = async data => {
  // console.log(data)
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/get/${data.type}/${data.doc_type}/`;
  return apiClient.get(apiUrl);
};

export const getAllTemplatesAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data}/template/all/`;
  return apiClient.get(apiUrl);
};

export const getAllDocumentsAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/meta/get_all/`;
  return apiClient.get(apiUrl);
};

export const sendDocumentAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/send/${data.type}/${data.doc_type}/`;
  return apiClient.post(apiUrl);
};

export const approveDocumentAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/approve/${data.type}/${data.doc_type}/`;
  return apiClient.post(apiUrl, data);
};

export const eSignDocumentAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/${data.signingMethod}/${data.type}/${data.doc_type}/`;
  return apiClient.post(apiUrl, data);
};

export const documentStatusPollingAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/check/${data.signingMethod}/${data.uuid}/`;
  return apiClient.get(apiUrl);
};

export const uploadDocumentAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/upload/${data.type}/${data.doc_type}/${data.confirmationStatus}`;
  return apiClient.post(apiUrl, data);
};

export const directUploadDocumentAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/direct_upload/Document/${data.documentName}/${data.confirmationStatus}`;
  return apiClientMultipart.post(apiUrl, data.data);
};

export const getAuditTrailAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/get_trail/${data.type}/${data.doc_type}/`;
  return apiClient.get(apiUrl);
};

export const downloadTemplateInPDFAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.edms}/${data.trialId}/trialsite/${data.trialSiteId}/temp_download/${data.type}/${data.doc_type}/`;
  return apiClient.get(apiUrl);
};
