import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { getCrtTeam } from '../../actions/siteInitiationActions';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';

const ClinicalResearchTeam = ({ trialId }) => {
  const siteClinicalResearchTeam = useSelector(
    state => state.siteInitiationReducer.siteClinicalResearchTeam
  );
  const getCrtTeamApi = useSelector(
    state => state.siteInitiationReducer.getCrtTeamApi
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCrtTeam({ trialId: trialId, trialSiteId: id }));
  }, []);

  return (
    <>
      {isPending(getCrtTeamApi) ? (
        <TCircularProgress />
      ) : (
        <Paper elevation={0} className="p-4 custom-box h-100">
          <Grid container className="h-100" spacing={2}>
            <Grid item>
              <Typography variant="h6" className="fw-semibold">
                Clinical Research Coordinator
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <strong>Name of CRC:</strong>{' '}
                {siteClinicalResearchTeam?.CRC?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <strong>Contact No:</strong>{' '}
                {siteClinicalResearchTeam?.CRC?.phone}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <strong>Email ID:</strong>{' '}
                {siteClinicalResearchTeam?.CRC?.email}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ClinicalResearchTeam;
