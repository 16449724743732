import {
  Backdrop,
  Fade,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import {
  getCRAInitiationPanel,
  getCRAQualificationPanel,
  patchVisit,
  setSites,
} from '../../actions/craActions';
import {
  eSignDocument,
  getDocument,
  uploadDocument,
} from '../../actions/documentsActions';
import { SET_E_SIGN_DOCUMENT_LINK } from '../../actions/documentsActionType';
import { SET_TOAST_ERROR } from '../../actions/toastActionType';
import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { TextCellRenderer } from '../../common/AgGrid/TextCellRenderers';
import TCircularProgress from '../../common/TCircularProgress';
import TConfirmationModal from '../../common/TConfirmationModal';
import TSignDocumentModal from '../../common/TSignDocumentModal';
import TSigningMethodModal from '../../common/TSigningMethodModal';
import TUploadDocumentsModal from '../../common/TUploadDocumentsModal';
import TViewAllDocumentsModal from '../../common/TViewAllDocumentsModal';
import API_STATES, { isPending } from '../../constant/api_constants';
import { VISIT_STATUS } from '../../constant/cra_constants';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
  SIGNING_METHOD,
} from '../../constant/document_constants';
import {
  SITE_STAGE,
  SITE_VISIT_STATUS_NUM,
} from '../../constant/site_constants';
import { formatDate } from '../../helpers/utils';
import GreenCheckMark from '../../images/GreenCheckMark.svg';
import ScheduleVisitModal from './ScheduleVisitModal';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: '150px',
    maxWidth: '300px',
  },
  button: {
    borderRadius: '1000px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.16px',
    padding: '25px 48px',
  },
}));

const CRAMatrix = ({ panel, stage, gridApi, setGridApi }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const sites = useSelector(state => state.craReducer.sites);
  const {
    patchVisitAPI,
    getCraInititionPanelAPI,
    getCraQualificationPanelAPI,
  } = useSelector(state => state.craReducer);
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const eSignDocumentLink = useSelector(
    state => state.documentsReducer.eSignDocumentLink
  );

  const [showScheduleVisitModal, setShowScheduleVisitModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalLabel, setConfirmationModalLabel] = useState(false);
  const [showUploadDocumentsModal, setShowUploadDocumentsModal] =
    useState(false);
  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false);
  const [showSigningMethodModal, setShowSigningMethodModal] = useState(false);
  const [showAllDocumentsModal, setShowAllDocumentsModal] = useState(false);
  // TODO: Check of the below two things can be reused. Blind code due to blockage :sob:
  const [selectedTrialSiteId, setSelectedTrialSiteId] = useState(0);
  const [selectedTrialId, setSelectedTrialId] = useState(0);
  const [selectedSite, setSelectedSite] = useState({});
  const [filteredSites, setFilteredSites] = useState([]);
  const [action, setAction] = useState('');
  const [documentObj, setDocumentObj] = useState({
    trialId: trialId,
    trialSiteId: '',
    docType: '',
    document: '',
    password: '',
    signingMethod: '',
  });

  useEffect(() => {
    switch (stage) {
      case SITE_STAGE.INITIATION:
        dispatch(getCRAInitiationPanel());
        break;
      case SITE_STAGE.QUALIFICATION:
        dispatch(getCRAQualificationPanel());
        break;
    }
  }, []);

  useEffect(() => {
    switch (stage) {
      case SITE_STAGE.INITIATION:
        dispatch(getCRAInitiationPanel());
        break;
      case SITE_STAGE.QUALIFICATION:
        dispatch(getCRAQualificationPanel());
        break;
    }
  }, [stage]);

  useEffect(() => {
    if (patchVisitAPI === API_STATES.COMPLETED) {
      switch (stage) {
        case SITE_STAGE.INITIATION:
          dispatch(getCRAInitiationPanel());
          break;
        case SITE_STAGE.QUALIFICATION:
          dispatch(getCRAQualificationPanel());
          break;
      }
    }
  }, [patchVisitAPI]);

  useEffect(() => {
    let parsedSites = [];
    switch (panel) {
      case VISIT_STATUS.SCHEDULED:
        parsedSites = sites.filter(
          site => site.status !== VISIT_STATUS.COMPLETED
        );
        break;
      case VISIT_STATUS.COMPLETED:
        parsedSites = sites.filter(
          site => site.status === VISIT_STATUS.COMPLETED
        );
        break;
    }
    setFilteredSites(parsedSites);
  }, [sites]);

  // TODO: Testing needed for this part
  // useEffect(() => {
  //   if (eSignDocumentLink) {
  //     window.open(eSignDocumentLink, '_blank');
  //     dispatch({ type: SET_E_SIGN_DOCUMENT_LINK, payload: false });
  //   }
  // }, [eSignDocumentLink]);

  const columnDefs = [
    {
      headerName: 'Site Name',
      field: 'trial_site.site.hospital',
      cellRenderer: 'TextCellRenderer',
      maxWidth: 200,
    },
    {
      headerName: 'Site Investigator',
      field: 'trial_site.site.doctor',
      cellRenderer: 'TextCellRenderer',
    },
    {
      headerName: 'Address',
      field: 'trial_site.site.address',
      minWidth: 300,
      cellRenderer: 'TextCellRenderer',
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusRenderer',
    },
    {
      headerName: 'Actions',
      field: 'actions',
      filter: false,
      cellRenderer: 'actionCellRenderer',
      maxWidth: 200,
    },
  ];

  const handleMarkComplete = visitId => {
    dispatch(
      patchVisit({
        visitId: visitId,
        status: 3,
      })
    );
  };

  const actionCellRenderer = params => {
    const [doc, setDoc] = useState({});
    const status = params.data.status;

    useEffect(() => {
      let docType = pathname.includes('cra-initiation')
        ? 'Initiation_report'
        : 'Qualification_report';

      let document = params.data.trial_site.documents.find(document => {
        return document.doc_type === docType;
      });
      setDoc(document);
    }, []);

    const handleGetDocument = () => {
      let docObj = {
        ...documentObj,
        ...doc,
        trialId: params.data.trial_id,
        trialSiteId: params.data.trial_site.id,
      };

      setDocumentObj(docObj);
      if (docObj.type && docObj.doc_type) {
        // dispatch(getDocument(docObj));
        setShowSignDocumentModal(true);
      } else {
        dispatch({
          type: SET_TOAST_ERROR,
          payload: 'No form available',
        });
      }
    };

    const handleChange = event => {
      setSelectedSite(params.data);
    };

    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center" spacing={1}>
          <Select
            size="small"
            onClick={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            defaultValue=""
            value={null}
            className={classes.select}
            input={<OutlinedInput margin="dense" />}
          >
            {/* {status === VISIT_STATUS.SCHEDULED ? (
              <MenuItem
                onClick={() => handleMarkComplete(params.data.id)}
                value="mark_as_complete"
              >
                Mark as complete
              </MenuItem>
            ) : null} */}

            {status === VISIT_STATUS.PENDING ? (
              <MenuItem
                onClick={() => setShowScheduleVisitModal(true)}
                value="schedule_a_visit"
              >
                Schedule a visit
              </MenuItem>
            ) : null}

            {status === VISIT_STATUS.SCHEDULED &&
            doc?.status === DOCUMENT_STATUS.RESPONSE_PENDING ? (
              <MenuItem
                onClick={() => setShowScheduleVisitModal(true)}
                value="reschedule_visit"
              >
                Reschedule visit
              </MenuItem>
            ) : null}

            {doc?.status !== DOCUMENT_STATUS.SIGNED &&
            doc?.status !== DOCUMENT_STATUS.RESPONSE_RECEIVED &&
            status === VISIT_STATUS.SCHEDULED ? (
              <MenuItem
                onClick={() => handleMarkComplete(params.data.id)}
                value="mark_as_executed"
              >
                Mark as executed
              </MenuItem>
            ) : null}

            {doc?.status !== DOCUMENT_STATUS.SIGNED &&
            doc?.status !== DOCUMENT_STATUS.RESPONSE_RECEIVED &&
            params.data.status === SITE_VISIT_STATUS_NUM.EXECUTED &&
            params.data.scheduled_on ? (
              <MenuItem
                onClick={() => {
                  setAction(DOCUMENT_MODAL_ACTIONS.UPLOADING);
                  handleGetDocument();
                }}
                value="schedule_a_visit"
              >
                Fill Form
              </MenuItem>
            ) : null}

            {(doc?.status === DOCUMENT_STATUS.RESPONSE_UPLOADED ||
              status === 'Report Signed' ||
              status === 'Report Submitted') &&
            params.data.status === SITE_VISIT_STATUS_NUM.EXECUTED ? (
              <>
                <MenuItem
                  onClick={() => {
                    setAction(DOCUMENT_MODAL_ACTIONS.SIGNING);
                    handleGetDocument();
                  }}
                  disabled={document?.signed}
                  value="view_and_sign"
                >
                  View and Sign
                </MenuItem>
              </>
            ) : null}

            {doc?.status === DOCUMENT_STATUS.RESPONSE_RECEIVED ||
            doc?.status === DOCUMENT_STATUS.SIGNED ? (
              <MenuItem
                onClick={() => {
                  setAction(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
                  handleGetDocument();
                }}
              >
                View Form
              </MenuItem>
            ) : null}

            {/* {pathname.includes('cra-initiation') ? (
              <MenuItem
                onClick={() =>
                  dispatch(
                    push(
                      `/cra-initiation-sites/info/${params.data.trial_site.id}/site/${params.data.trial_site.site.id}`
                    )
                  )
                }
                value="schedule_a_visit"
              >
                View Site Info
              </MenuItem>
            ) : null} */}
            <MenuItem
              onClick={() => {
                setShowAllDocumentsModal(true);
                setSelectedTrialSiteId(params.data.trial_site.id);
                setSelectedTrialId(params.data.trial_id);
              }}
            >
              View / Add documents
            </MenuItem>
          </Select>
        </Grid>
      </div>
    );
  };

  const statusRenderer = params => {
    switch (params.data.status) {
      case VISIT_STATUS.PENDING:
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Yet to be Scheduled
          </div>
        );
      case VISIT_STATUS.SCHEDULED:
        return (
          <div>
            <img
              style={{ color: 'green', marginRight: '8px', height: '16px' }}
              src={GreenCheckMark}
            />
            <span>Scheduled on</span>
            <span style={{ color: '#008CFF', marginLeft: '2px' }}>
              {formatDate(params.data.scheduled_on)}
            </span>
          </div>
        );
      case VISIT_STATUS.EXECUTED:
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <img
              style={{ color: 'green', marginRight: '10px', height: '16px' }}
              src={GreenCheckMark}
            />
            Executed
          </div>
        );
      case VISIT_STATUS.COMPLETED:
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Completed
          </div>
        );
      default:
        return <></>;
    }
  };

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const handleUploadDocument = () => {
    // console.log(documentObj);
    dispatch(uploadDocument(documentObj));
  };

  return (
    <>
      {isPending(getCraQualificationPanelAPI) ||
      isPending(getCraInititionPanelAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="ag-react-container"
                style={{
                  height: '60vh',
                  width: '100%',
                }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={filteredSites}
                  gridOptions={DEFAULT_GRID_OPTIONS}
                  columnDefs={columnDefs}
                  frameworkComponents={{
                    statusRenderer,
                    actionCellRenderer,
                    TextCellRenderer,
                  }}
                  defaultColDef={{
                    ...DEFAULT_COL_DEF,
                    cellClass: ['grid-cell-5n'],
                  }}
                  pagination
                  suppressRowHoverHighlight
                  suppressPaginationPanel
                  paginationPageSize={PER_PAGE_OPTIONS[0]}
                  animateRows
                  onGridSizeChanged={params => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <AgGridPagination gridApi={gridApi} />
            </Grid>
          </Grid>
          <Modal
            className="modal-container"
            open={showScheduleVisitModal}
            onClose={() => setShowScheduleVisitModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showScheduleVisitModal}>
              <ScheduleVisitModal
                setShowScheduleVisitModal={setShowScheduleVisitModal}
                setConfirmationModalLabel={setConfirmationModalLabel}
                setShowConfirmationModal={setShowConfirmationModal}
                selectedSite={selectedSite}
              />
            </Fade>
          </Modal>
          <Modal
            className="modal-container"
            open={showAllDocumentsModal}
            onClose={() => setShowAllDocumentsModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showAllDocumentsModal}>
              <TViewAllDocumentsModal
                setShowAllDocumentsModal={setShowAllDocumentsModal}
                stage={stage}
                trialSiteId={selectedTrialSiteId}
                siteTrialId={selectedTrialId}
              />
            </Fade>
          </Modal>
          <Modal
            className="modal-container"
            open={showSignDocumentModal}
            onClose={() => setShowSignDocumentModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showSignDocumentModal}>
              <TSignDocumentModal
                action={action}
                documentObj={documentObj}
                setShowSignDocumentModal={setShowSignDocumentModal}
                setShowSignPasswordModal={setShowSigningMethodModal}
              />
            </Fade>
          </Modal>
          <Modal
            className="modal-container"
            open={showSigningMethodModal}
            onClose={() => setShowSigningMethodModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showSigningMethodModal}>
              <TSigningMethodModal
                setShowSigningMethodModal={setShowSigningMethodModal}
                documentObj={documentObj}
                setDocumentObj={setDocumentObj}
              />
            </Fade>
          </Modal>
          {/* <Modal
            className="modal-container"
            open={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={showConfirmationModal}>
              <TConfirmationModal label={confirmationModalLabel} />
            </Fade>
          </Modal> */}
        </>
      )}
    </>
  );
};

export default CRAMatrix;
