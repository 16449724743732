import { Box, Typography } from '@material-ui/core';
import React from 'react';

import {
  TTable,
  TTableBody,
  TTableCell,
  TTableContainer,
  TTableHead,
  TTableRow,
} from '../../../../common/TTable';

export const WorkExperience = ({ doctorProfile }) => {
  return (
    <Box className="m-5">
      <Typography
        as="h2"
        variant="h2"
        className="fs-xxl-temp fw-semibold c-subheading-text"
      >
        Work Experience
      </Typography>
      <Box className="m-3">
        {doctorProfile?.work_experience?.length ? (
          <TTableContainer>
            <TTable size="medium">
              <TTableHead>
                <TTableRow>
                  <TTableCell style={{ textAlign: 'center' }}>
                    Hoapital Name
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    Position
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    Duration
                  </TTableCell>
                </TTableRow>
              </TTableHead>
              <TTableBody>
                {doctorProfile?.work_experience?.map((wx, indx) => (
                  <TTableRow key={indx}>
                    <TTableCell style={{ textAlign: 'center' }}>
                      {wx?.name}
                    </TTableCell>
                    <TTableCell style={{ textAlign: 'center' }}>
                      {wx?.position}
                    </TTableCell>
                    <TTableCell style={{ textAlign: 'center' }}>
                      {`${wx?.from_year} - ${
                        wx?.currently_working ? 'Present' : wx?.to_year
                      }`}
                    </TTableCell>
                  </TTableRow>
                ))}
              </TTableBody>
            </TTable>
          </TTableContainer>
        ) : (
          <div className="my-4">
            <Typography className="fs-xxl fw-medium c-disabled-text flex mx-3">
              Nothing to show.
            </Typography>
          </div>
        )}
      </Box>
    </Box>
  );
};
