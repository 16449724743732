export const CREATE_DOCTOR_PROFILE = 'CREATE_DOCTOR_PROFILE';
export const CREATE_SITE_PROFILE = 'CREATE_SITE_PROFILE';
export const GET_DOCTOR_PROFILE = 'GET_DOCTOR_PROFILE';
export const SET_DOCTOR_PROFILE = 'SET_DOCTOR_PROFILE';
export const GET_DOCTOR_AREA_OF_INTEREST = 'GET_DOCTOR_AREA_OF_INTEREST';
export const SET_DOCTOR_AREA_OF_INTEREST = 'SET_DOCTOR_AREA_OF_INTEREST';
export const GET_DOCTOR_SPECIALIZATIONS = 'GET_DOCTOR_SPECIALIZATIONS';
export const SET_DOCTOR_SPECIALIZATIONS = 'SET_DOCTOR_SPECIALIZATIONS';
export const GET_SITE_PROFILE = 'GET_SITE_PROFILE';
export const SET_SITE_PROFILE = 'SET_SITE_PROFILE';
export const EDIT_DOCTOR_PROFILE = 'EDIT_DOCTOR_PROFILE';
export const EDIT_SITE_PROFILE = 'EDIT_SITE_PROFILE';
export const DOCTOR_FILE_UPLOAD = 'DOCTOR_FILE_UPLOAD';
export const ADD_GRANT = 'ADD_GRANT';
export const GET_DOCTOR_TRIALS = 'GET_DOCTOR_TRIALS';
export const SET_DOCTOR_TRIALS = 'SET_DOCTOR_TRIALS';
export const SET_HOSPITALS = 'SET_HOSPITALS';
export const GET_HOSPITALS = 'GET_HOSPITALS';
export const SET_CITIES = 'SET_CITIES';
export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const SET_PARTIAL_DOCTOR_PROFILE = 'SET_PARTIAL_DOCTOR_PROFILE';
export const SET_EMPTY_SITE_PROFILE = 'SET_EMPTY_SITE_PROFILE';
export const GET_DOCTOR_PROFILES = 'GET_DOCTOR_PROFILES';
export const SET_DOCTOR_PROFILES = 'SET_DOCTOR_PROFILES';
export const GET_DOCTOR_TRIALS_CRC = 'GET_DOCTOR_TRIALS_CRC';
export const GET_FIXED_DOCUMENTS = 'GET_FIXED_DOCUMENTS';
export const SET_FIXED_DOCUMENTS = 'SET_FIXED_DOCUMENTS';
export const SET_API_SUCCESS = 'SET_API_SUCCESS';
export const ADD_DOCTOR = 'ADD_DOCTOR';

export const ADD_DOCTOR_API_STATE = 'ADD_DOCTOR_API_STATE';
export const CREATE_SITE_PROFILE_API_STATE = 'CREATE_SITE_PROFILE_API_STATE';
export const CREATE_DOCTOR_PROFILE_API_STATE =
  'CREATE_DOCTOR_PROFILE_API_STATE';
export const GET_DOCTOR_PROFILE_API_STATE = 'GET_DOCTOR_PROFILE_API_STATE';
export const SET_DOCTOR_PROFILE_API_STATE = 'SET_DOCTOR_PROFILE_API_STATE';
export const GET_DOCTOR_AREA_OF_INTEREST_API_STATE =
  'GET_DOCTOR_AREA_OF_INTEREST_API_STATE';
export const SET_DOCTOR_AREA_OF_INTEREST_API_STATE =
  'SET_DOCTOR_AREA_OF_INTEREST_API_STATE';
export const GET_DOCTOR_SPECIALIZATIONS_API_STATE =
  'GET_DOCTOR_SPECIALIZATIONS_API_STATE';
export const SET_DOCTOR_SPECIALIZATIONS_API_STATE =
  'SET_DOCTOR_SPECIALIZATIONS_API_STATE';
export const GET_SITE_PROFILE_API_STATE = 'GET_SITE_PROFILE_API_STATE';
export const SET_SITE_PROFILE_API_STATE = 'SET_SITE_PROFILE_API_STATE';
export const EDIT_DOCTOR_PROFILE_API_STATE = 'EDIT_DOCTOR_PROFILE_API_STATE';
export const DOCTOR_FILE_UPLOAD_API_STATE = 'DOCTOR_FILE_UPLOAD_API_STATE';
export const EDIT_SITE_PROFILE_API_STATE = 'EDIT_SITE_PROFILE_API_STATE';
export const ADD_GRANT_API_STATE = 'ADD_GRANT_API_STATE';
export const GET_DOCTOR_TRIALS_API_STATE = 'GET_DOCTOR_TRIALS_API_STATE';
export const GET_HOSPITALS_API_STATE = 'GET_HOSPITALS_API_STATE';
export const GET_DOCTOR_PROFILES_API_STATE = 'GET_DOCTOR_PROFILES_API_STATE';
export const GET_DOCTOR_TRIALS_CRC_API_STATE =
  'GET_DOCTOR_TRIALS_CRC_API_STATE';
export const GET_FIXED_DOCUMENTS_API_STATE = 'GET_FIXED_DOCUMENTS_API_STATE';

export const GET_SDV_PARTICIPANTS_LIST = 'GET_SDV_PARTICIPANTS_LIST';
export const GET_SDV_PARTICIPANTS_LIST_API_STATE =
  'GET_SDV_PARTICIPANTS_LIST_API_STATE';
export const SET_SDV_PARTICIPANTS_LIST = 'SET_SDV_PARTICIPANTS_LIST';
export const GET_PARTICIPANT_SDV = 'GET_PARTICIPANT_SDV';
export const GET_PARTICIPANT_SDV_API_STATE = 'GET_PARTICIPANT_SDV_API_STATE';
export const SET_PARTICIPANT_SDV = 'SET_PARTICIPANT_SDV';
export const CREATE_PARTICIPANT = 'CREATE_PARTICIPANT';
export const CREATE_PARTICIPANT_API_STATE = 'CREATE_PARTICIPANT_API_STATE';
export const UPLOAD_SDV_DOCUMENT = 'UPLOAD_SDV_DOCUMENT';
export const UPLOAD_SDV_DOCUMENT_API_STATE = 'UPLOAD_SDV_DOCUMENT_API_STATE';
