import {
  CHANGE_RSDV_STATUS,
  CREATE_RSDV_DOCUMENT_REQUEST,
  GET_CRA_INITIATION_PANEL,
  GET_CRA_QUALIFICATION_PANEL,
  GET_CRA_SDV_SITES,
  GET_PARTICIPANT_RSDV_DOCUMENTS,
  GET_RSDV_DOCUMENT,
  GET_TRIAL_SITE_PARTICIPANTS,
  PATCH_VISIT,
  SET_CRA_PANEL,
} from './craActionType';

export const getCRAInitiationPanel = payload => ({
  type: GET_CRA_INITIATION_PANEL,
  payload,
});

export const setSites = payload => ({
  type: SET_CRA_PANEL,
  payload,
});

export const getCRAQualificationPanel = payload => ({
  type: GET_CRA_QUALIFICATION_PANEL,
  payload,
});

export const patchVisit = payload => ({
  type: PATCH_VISIT,
  payload,
});

export const getCRASDVSites = payload => ({
  type: GET_CRA_SDV_SITES,
  payload,
});

export const getTrialSiteParticipants = payload => ({
  type: GET_TRIAL_SITE_PARTICIPANTS,
  payload,
});

export const getRSDVDocument = payload => ({
  type: GET_RSDV_DOCUMENT,
  payload,
});

export const getParticipantRSDVDocuments = payload => ({
  type: GET_PARTICIPANT_RSDV_DOCUMENTS,
  payload,
});

export const createRSDVDocumentRequest = payload => ({
  type: CREATE_RSDV_DOCUMENT_REQUEST,
  payload,
});

export const changeRSDVStatus = payload => ({
  type: CHANGE_RSDV_STATUS,
  payload,
});
