export const validateForm = async (schema, obj) => {
  const result = { isError: false, valErrors: [] };
  try {
    const isValid = await schema.isValid(obj);
    if (isValid) {
      return result;
    } else {
      await schema.validate(obj, { abortEarly: false });
    }
  } catch (err) {
    if (err.inner) {
      let tempList = [];
      err.inner.forEach(element => {
        tempList.push(element.path);
      });
      result.isError = true;
      result.valErrors = tempList;
      return result;
    }
  }
};

export const validateFormWithMessages = async (schema, obj) => {
  const result = { isError: false, valErrors: [], errorMessages: {} };

  try {
    const isValid = await schema.isValid(obj);

    if (isValid) {
      return result;
    } else {
      await schema.validate(obj, { abortEarly: false });
    }
  } catch (err) {
    if (err.inner) {
      let errorMessages = {};
      let tempList = [];

      err.inner.forEach(element => {
        tempList.push(element.path);
        errorMessages[element.path] = element.message;
      });

      result.isError = true;
      result.valErrors = tempList;
      result.errorMessages = errorMessages;

      return result;
    }
  }
};

export const validateFormField = async (schema, obj, key) => {
  const result = { isError: false, valErrors: [] };
  try {
    const isValid = await schema.isValid(obj);
    if (isValid) {
      return result;
    } else {
      await schema.validate(obj, { abortEarly: false });
    }
  } catch (err) {
    if (err.inner) {
      let tempList = [];
      err.inner.forEach(element => {
        if (element.path.includes(key)) {
          tempList.push(element.path);
        }
      });
      result.isError = true;
      result.valErrors = tempList;
      return result;
    }
  }
};

export const validateFormFieldWithMessages = async (schema, obj, key) => {
  const result = { isError: false, valErrors: [], errorMessages: {} };

  try {
    const isValid = await schema.isValid(obj);

    if (isValid) {
      return result;
    } else {
      await schema.validate(obj, { abortEarly: false });
    }
  } catch (err) {
    if (err.inner) {
      let errorMessages = {};
      let tempList = [];

      err.inner.forEach(element => {
        if (element.path.includes(key)) {
          tempList.push(element.path);
          errorMessages[element.path] = element.message;
        }
      });

      result.isError = true;
      result.valErrors = tempList;
      result.errorMessages = errorMessages;

      return result;
    }
  }
};
