import {
  GET_CRT_TEAM_API_STATE,
  GET_INITIATION_SITE_API_STATE,
  GET_INITIATION_SITES_API_STATE,
  SET_CRT_TEAM,
  SET_INITIATION_SITE,
  SET_INITIATION_SITES,
} from '../actions/siteInitiationActionType';
import API_STATES from '../constant/api_constants';

const initialApiStates = {
  getInitiationSitesApi: API_STATES.NOT_INITIATED,
  getInitiationSiteApi: API_STATES.NOT_INITIATED,
  getCrtTeamApi: API_STATES.NOT_INITIATED,
};

const initialState = {
  ...initialApiStates,
  sites: [],
  site: {},
  siteClinicalResearchTeam: [],
};

export default function siteInitiationReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_INITIATION_SITES:
      return { ...state, sites: payload };
    case SET_INITIATION_SITE:
      return { ...state, site: payload };
    case SET_CRT_TEAM:
      return { ...state, siteClinicalResearchTeam: payload };
    case GET_INITIATION_SITES_API_STATE:
      return { ...state, getInitiationSitesApi: payload };
    case GET_INITIATION_SITE_API_STATE:
      return { ...state, getInitiationSiteApi: payload };
    case GET_CRT_TEAM_API_STATE:
      return { ...state, getInitiationSiteApi: payload };
    default:
      return state;
  }
}
