export const USER_APIS = {
  user: 'user',
  login: 'users/login',
  refreshLogin: '/users/token/refresh',
  signup: 'signup',
  verifyPhone: '/users/verify/phone',
  resetPassword: '/users/reset_password',
  firstLoginSetPassword: '/users/change_password',
};

export const OPEN_ROUTES = [
  '/',
  '/login',
  '/forgot-password/',
  '/verify',
  '/update',
  '/signing',
];
