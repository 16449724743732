import { IconButton, Typography } from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import React from 'react';

import { formHandler } from '../../../helpers/formHandler';
import { cleanDependentComponents } from '../../../helpers/formUtil';
import FormRenderer from './FormRenderer';

const Form = props => {
  const { name, components, response } = props.data;

  const handleChange = (key, value, type = '', parentProps = {}) => {
    let newSectionData = formHandler({
      key: key,
      value: value,
      type: type,
      sectionIndex: props.index,
      formData: props.formData,
      parentProps: parentProps,
    });

    const formData = newSectionData[props.index].response;

    const cleanData = cleanDependentComponents({
      components: props?.data?.components,
      data: formData,
    });

    newSectionData[props.index].response = cleanData;

    props.setFormData(newSectionData);
  };

  return (
    <div id="crf-container">
      <Typography
        variant="h4"
        className="fw-semibold c-subheading-text"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '1.5rem',
        }}
      >
        <span>
          {props.isSaved && <CheckCircle className="mx-2" />}
          {name}
        </span>
        <span>
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </span>
      </Typography>
      <FormRenderer
        components={components}
        formData={response}
        handleChange={handleChange}
      />
    </div>
  );
};

export default Form;
