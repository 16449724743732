import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import {
  login,
  requestEmailOTP,
  requestPhoneOTP,
} from '../../actions/userActions';
import { USER_API_ERROR } from '../../actions/userActionType';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';
import AuthCard from './AuthCard';

const Login = () => {
  const dispatch = useDispatch();

  const loginAPI = useSelector(state => state.userReducer.loginAPI);
  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const errorMsg = useSelector(state => state.userReducer.error);
  const isPasswordResetRequired = useSelector(
    state => state.userReducer.user?.is_password_reset_required
  );

  const phone_verified = useSelector(
    state => state.userReducer.user?.phone_verified
  );
  const email_verified = useSelector(
    state => state.userReducer.user?.email_verified
  );
  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleLogin = () => {
    if (formData.email && formData.password) {
      dispatch(login(formData));
    } else {
      dispatch({
        type: USER_API_ERROR,
        payload: "Email and password can't be blank",
      });
    }
  };

  if (isAuthenticated && !phone_verified) {
    dispatch(requestPhoneOTP());
    dispatch(push('/phone/verify'));
  } else if (isAuthenticated && !email_verified) {
    dispatch(requestEmailOTP());
    return <Redirect to="/email/verify" />;
  } else if (isAuthenticated && isPasswordResetRequired) {
    return <Redirect to="/forgot-password/user/update" />;
  } else if (isAuthenticated) {
    return <Redirect to={viewPermissions[0].resource_path} />;
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <>
      {isPending(loginAPI) ? (
        <TCircularProgress />
      ) : (
        <AuthCard
          cardHeader={
            <>
              <Typography
                variant="h5"
                style={{ color: '#3c3c3c' }}
                className="fw-medium"
              >
                Sign in to your
              </Typography>
              <Typography variant="h4" className="fw-semibold">
                Triomics Account
              </Typography>
            </>
          }
        >
          <Grid container spacing={4}>
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={formData.email}
                  required
                  onChange={handleChange('email')}
                  variant="outlined"
                  className="bg-white"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Password"
                  value={formData.password}
                  onChange={handleChange('password')}
                  variant="outlined"
                  required
                  onKeyDown={e => handleKeyPress(e)}
                  className="bg-white"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility fontSize="small" color="primary" />
                          ) : (
                            <VisibilityOff fontSize="small" color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {errorMsg && (
                <Grid container item xs={12}>
                  <Typography variant="body1" color="error">
                    <ErrorOutline color="error" /> {errorMsg}
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                style={{ justifyContent: 'flex-end' }}
              >
                <Link className="fs-s" to="/forgot-password/email">
                  <Typography variant="body1">Forgot password?</Typography>
                </Link>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <TButton
                    onClick={handleLogin}
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    isLoading={isPending(loginAPI)}
                  >
                    <Typography className="fs-l fw-semibold">Login</Typography>
                  </TButton>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Don't have an account?{' '}
                    <a
                      href="/signup"
                      style={{
                        color: '#008CFF',
                        fontWeight: 600,
                        textUnderlineOffset: 4,
                      }}
                    >
                      SIGN UP!
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AuthCard>
      )}
    </>
  );
};

export default Login;
