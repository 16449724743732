import {
  RESET_APP_BAR_CONFIG,
  SET_APP_BAR_CONFIG,
  TOGGLE_SIDEBAR_MENU,
} from '../actions/utilsActionType';

const initialTitleConfig = {
  text: 'Triomics',
  isLink: false,
  link: '',
  prevIcon: false,
  prevLink: '',
};

const initialAppBarConfig = {
  title: {
    ...initialTitleConfig,
  },
  showTrials: false,
  showDoctorProfiles: false,
};

const initialState = {
  isSidebarOpen: false,
  appBarConfig: { ...initialAppBarConfig },
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_SIDEBAR_MENU:
      return { ...state, isSidebarOpen: payload };
    case SET_APP_BAR_CONFIG:
      return { ...state, appBarConfig: { ...state.appBarConfig, ...payload } };
    case RESET_APP_BAR_CONFIG:
      return { ...state, appBarConfig: { ...initialAppBarConfig } };
    default:
      return state;
  }
}
