import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTrial } from '../../actions/trialActions';
import { resetAppBarConfig, setAppBarConfig } from '../../actions/utilsActions';
import TCircularProgress from '../../common/TCircularProgress';
import TContainer from '../../common/TContainer';
import InfoTile from '../../components/Dashboard/InfoTile';
import TrialProgress from '../../components/Dashboard/TrialProgress';
import { isCompleted, isPending } from '../../constant/api_constants';

const DMDashboard = () => {
  const getTrialsAPI = useSelector(state => state.trialReducer.getTrialsAPI);
  const trialDetails = useSelector(state => state.trialReducer.trial);
  const trialStats = useSelector(state => state.trialReducer.trialStats);
  const getTrialAPI = useSelector(state => state.trialReducer.getTrialAPI);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isCompleted(getTrialsAPI) && trialDetails.id) {
      dispatch(getTrial(trialDetails.id));
    }
  }, [getTrialsAPI]);

  useEffect(() => {
    let titleConfig = {
      text: '',
      isLink: false,
      prevIcon: false,
    };
    dispatch(
      setAppBarConfig({
        title: titleConfig,
        showTrials: true,
      })
    );
    return () => {
      dispatch(resetAppBarConfig());
    };
  }, []);

  return (
    <TContainer>
      {isPending(getTrialAPI) || isPending(getTrialsAPI) ? (
        <TCircularProgress />
      ) : (
        <Grid container spacing={6}>
          <Grid item container spacing={2} className="fs-xxl-temp fw-semibold">
            <Grid item xs="auto">
              Title:
            </Grid>
            <Grid item xs>
              {trialDetails.scientific_name
                ? trialDetails.scientific_name
                : trialDetails.name}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TrialProgress />
          </Grid>
          <Grid item container spacing={2}>
            <InfoTile
              label={'SITES IDENTIFIED'}
              value={trialStats.shortlisted}
            />
            <InfoTile
              label={'FEASIBILITY COMPLETED'}
              value={trialStats.feasibility}
            />
            <InfoTile label={'SITES QUALIFIED'} value={trialStats.qualified} />
            <InfoTile
              label={'SITE INITIATION COMPLETED'}
              value={trialStats.initiated}
            />
          </Grid>
        </Grid>
      )}
    </TContainer>
  );
};

export default DMDashboard;
