import {
  GET_CRT_TEAM,
  GET_INITIATION_SITE,
  GET_INITIATION_SITES,
} from './siteInitiationActionType';

export const getInitiationSites = payload => ({
  type: GET_INITIATION_SITES,
  payload,
});

export const getInitiationSiteDetails = payload => ({
  type: GET_INITIATION_SITE,
  payload,
});

export const getCrtTeam = payload => ({
  type: GET_CRT_TEAM,
  payload,
});
