import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import craReducer from './craReducer';
import doctorReducer from './doctorReducer';
import documentsReducer from './documentsReducer';
import eventReducer from './eventReducer';
import feasibilityReducer from './feasibilityReducer';
import siteIdentificationReducer from './siteIdentificationReducer';
import siteInitiationReducer from './siteInitiationReducer';
import siteQualificationReducer from './siteQualificationReducer';
import toastReducer from './toastReducer';
import trialReducer from './trialReducer';
import userReducer from './userReducer';
import utilsReducer from './utilsReducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    eventReducer,
    userReducer,
    utilsReducer,
    siteInitiationReducer,
    siteIdentificationReducer,
    feasibilityReducer,
    craReducer,
    siteQualificationReducer,
    documentsReducer,
    trialReducer,
    doctorReducer,
    toastReducer,
  });

export default createRootReducer;
