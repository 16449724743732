import {
  APPROVE_DOCUMENT,
  DIRECT_UPLOAD_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_TEMPLATE_IN_PDF,
  E_SIGN_DOCUMENT,
  GENERATE_AND_SEND_DOCUMENT,
  GET_ALL_DOCUMENTS,
  GET_ALL_TEMPLATES,
  GET_AUDIT_TRAIL,
  GET_DOCUMENT,
  UPLOAD_DOCUMENT,
} from './documentsActionType';

export const getDocument = payload => ({
  type: GET_DOCUMENT,
  payload,
});

export const downloadDocument = payload => ({
  type: DOWNLOAD_DOCUMENT,
  payload,
});

export const getAllDocuments = payload => ({
  type: GET_ALL_DOCUMENTS,
  payload,
});

export const getAllTemplates = payload => ({
  type: GET_ALL_TEMPLATES,
  payload,
});

export const generateAndSignDocument = payload => ({
  type: GENERATE_AND_SEND_DOCUMENT,
  payload,
});

export const approveDocument = payload => ({
  type: APPROVE_DOCUMENT,
  payload,
});

export const eSignDocument = payload => ({
  type: E_SIGN_DOCUMENT,
  payload,
});

export const uploadDocument = payload => ({
  type: UPLOAD_DOCUMENT,
  payload,
});

export const directUploadDocument = payload => ({
  type: DIRECT_UPLOAD_DOCUMENT,
  payload,
});

export const getAuditTrail = payload => ({
  type: GET_AUDIT_TRAIL,
  payload,
});

export const downloadTemplateInPDF = payload => ({
  type: DOWNLOAD_TEMPLATE_IN_PDF,
  payload,
});
