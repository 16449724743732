import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addGrant,
  getDoctorTrials,
  getDoctorTrialsCRC,
  getFixedDocuments,
} from '../../actions/doctorActions';
import { getAllDocuments, getDocument } from '../../actions/documentsActions';
import { SET_DOCUMENTS } from '../../actions/documentsActionType';
import { SET_TOAST_ERROR } from '../../actions/toastActionType';
import { DownloadIcon } from '../../common/icons';
import TAddDoctorModal from '../../common/TAddDoctorModal';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import TPasswordModal from '../../common/TPasswordModal';
import TSignDocumentModal from '../../common/TSignDocumentModal';
import TSigningMethodModal from '../../common/TSigningMethodModal';
import TUploadDocumentsModal from '../../common/TUploadDocumentsModal';
import TViewAllDocumentsModal from '../../common/TViewAllDocumentsModal';
import API_STATES, {
  isCompleted,
  isPending,
} from '../../constant/api_constants';
import {
  DOCUMENT_MODAL_ACTIONS,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  SIGNING_METHOD,
} from '../../constant/document_constants';
import { SITE_STAGE } from '../../constant/site_constants';
import EmptyStateDoctorImg from '../../images/Empty_State_Doctors_Dashboard.png';

const ActionItems = ({
  document,
  setAction,
  setShowSignDocumentModal,
  setDocumentDownloading,
  documentObj,
  setDocumentObj,
  setDocumentType,
  canSign,
}) => {
  const dispatch = useDispatch();

  let uploadingAction =
    document.type === 'Document'
      ? DOCUMENT_MODAL_ACTIONS.DOWNLOADING
      : DOCUMENT_MODAL_ACTIONS.SIGNING;

  const handleGetDocument = () => {
    setDocumentType(documentObj.type);
    setDocumentObj(documentObj);
    setDocumentDownloading(false);
  };

  switch (document.status) {
    case DOCUMENT_STATUS.RESPONSE_PENDING:
      return (
        <Grid item>
          <TButton
            onClick={() => {
              handleGetDocument();
              setAction(DOCUMENT_MODAL_ACTIONS.UPLOADING);
              setShowSignDocumentModal(true);
            }}
            variant="contained"
            color="primary"
          >
            Review and Upload
          </TButton>
        </Grid>
      );

    case DOCUMENT_STATUS.RESPONSE_UPLOADED:
      return (
        <Grid item>
          <TButton
            onClick={() => {
              handleGetDocument();
              setAction(uploadingAction);
              setShowSignDocumentModal(true);
            }}
            variant="contained"
            color="primary"
          >
            {document.type === 'Document' || !canSign
              ? 'View'
              : 'Review and Sign'}
          </TButton>
        </Grid>
      );

    case DOCUMENT_STATUS.RESPONSE_RECEIVED:
      return (
        <Grid item>
          <Typography
            style={{ color: '#7C7F84' }}
            variant="body1"
            className="fw-semibold"
          >
            Awaiting Approval
          </Typography>
        </Grid>
      );

    case DOCUMENT_STATUS.SIGNED:
      return (
        <Grid item>
          {/* <IconButton
            size="large"
            onClick={() => {
              handleGetDocument();
              setDocumentDownloading(true);
              dispatch(getDocument(documentObj));
            }}
          >
            <DownloadIcon color="primary" style={{ transform: 'scale(1.5)' }} />
          </IconButton> */}
          <TButton
            variant="contained"
            color="primary"
            onClick={() => {
              handleGetDocument();
              setAction(DOCUMENT_MODAL_ACTIONS.DOWNLOADING);
              setShowSignDocumentModal(true);
            }}
          >
            View
          </TButton>
        </Grid>
      );

    default:
      return <></>;
  }
};

const UpcomingTrial = ({ firstDoctorTrial }) => {
  const doctorTrials = useSelector(
    state => state.doctorReducer.doctorTrials[0]
  );
  const { documents, getDocumentsApi, sendDocumentAPI, uploadDocumentApi } =
    useSelector(state => state.documentsReducer);
  const pdfDocument = useSelector(state => state.documentsReducer.document);
  const user = useSelector(state => state.userReducer.user);

  const { addGrantAPI, doctorProfile, fixedDocuments } = useSelector(
    state => state.doctorReducer
  );

  const [grantValue, setGrantValue] = useState(null);
  const [action, setAction] = useState(null);
  const [documentObj, setDocumentObj] = useState({});
  const [showSignDocumentModal, setShowSignDocumentModal] = useState(false);
  const [showSigningMethodModal, setShowSigningMethodModal] = useState(false);
  const [documentDownloading, setDocumentDownloading] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [feasibilityDocs, setFeasibilityDocs] = useState([]);
  const [showGrant, setShowGrant] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [cdaDocument, setCDADocument] = useState({});
  // const [showUploadDocumentsModal, setShowUploadDocumentsModal] =
  //   useState(false);
  const [showAllDocumentsModal, setShowAllDocumentsModal] = useState(false);
  const [feasibilityDocument, setFeasibilityDocument] = useState({});

  const dispatch = useDispatch();

  const handleProceed = () => {
    if (grantValue !== null && grantValue > 0) {
      setShowPasswordModal(true);
    } else {
      dispatch({
        type: SET_TOAST_ERROR,
        payload: 'Please enter a valid investigator grant fees',
      });
    }
  };

  useEffect(() => {
    if (isCompleted(addGrantAPI)) {
      if (user.role === 'Clinical Research Coordinator') {
        dispatch(getDoctorTrialsCRC(doctorProfile[0].user_id));
      } else {
        dispatch(getDoctorTrials());
      }
    }
  }, [addGrantAPI]);

  useEffect(() => {
    if (firstDoctorTrial) {
      if (cdaDocument?.status === DOCUMENT_STATUS.SIGNED) {
        dispatch(
          getFixedDocuments({ trialSiteId: firstDoctorTrial.id, stageId: 2 })
        );
      }
    }
  }, [firstDoctorTrial, cdaDocument]);

  useEffect(() => {
    // let feasibilityDocuments = documents.filter(
    //   doc =>
    //     doc.site_stage === SITE_STAGE.FEASIBILITY &&
    //     doc.doc_type !== DOCUMENT_TYPE.CDA
    // );
    if (!showAllDocumentsModal) {
      let cdaDoc = documents.find(doc => doc.doc_type === DOCUMENT_TYPE.CDA);
      let feasibilityDoc = documents.find(
        doc => doc.doc_type === DOCUMENT_TYPE.FEASIBILITY
      );

      // setFeasibilityDocs(feasibilityDocuments.sort((a, b) => a.order - b.order));
      // setFeasibilityDocs(feasibilityDocuments);
      setCDADocument(cdaDoc);
      setFeasibilityDocument(feasibilityDoc);
      setShowGrant(
        documents.some(
          doc =>
            doc.doc_type === 'Feasibility' &&
            doc.status === DOCUMENT_STATUS.SIGNED
        )
      );
    }
  }, [documents]);

  useEffect(() => {
    if (pdfDocument?.byteLength && documentDownloading) {
      const docURL = window.URL.createObjectURL(
        new Blob([pdfDocument], { type: 'application/pdf' })
      );
      window.open(docURL);
    }
  }, [pdfDocument, documentDownloading]);

  useEffect(() => {
    setDocumentObj({
      ...documentObj,
      trialId: firstDoctorTrial.trial_id,
      trialSiteId: firstDoctorTrial.id,
    });
    if (firstDoctorTrial.trial_id) {
      if (!showAllDocumentsModal) {
        dispatch(
          getAllDocuments({
            trialId: firstDoctorTrial.trial_id,
            trialSiteId: firstDoctorTrial.id,
          })
        );
      }
    } else {
      dispatch({
        type: SET_DOCUMENTS,
        payload: [],
      });
    }
  }, [firstDoctorTrial, doctorTrials]);

  useEffect(() => {
    if (
      (addGrantAPI === API_STATES.COMPLETED ||
        sendDocumentAPI === API_STATES.COMPLETED ||
        uploadDocumentApi === API_STATES.COMPLETED) &&
      documentType !== 'Form'
    ) {
      if (firstDoctorTrial.trial_id) {
        if (!showAllDocumentsModal) {
          dispatch(
            getAllDocuments({
              trialId: firstDoctorTrial.trial_id,
              trialSiteId: firstDoctorTrial.id,
            })
          );
        }
      }
    }
  }, [addGrantAPI, sendDocumentAPI, uploadDocumentApi]);

  return (
    <>
      {isPending(addGrantAPI) ||
      (isPending(getDocumentsApi) && !showAllDocumentsModal) ? (
        <TCircularProgress />
      ) : (
        <>
          {!cdaDocument?.doc_type ? (
            <Grid
              container
              className="w-100"
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <img
                  style={{ height: '250px', marginTop: '10px' }}
                  src={EmptyStateDoctorImg}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  {!doctorTrials.length
                    ? 'There are no upcoming trials at the moment. Please check back later.'
                    : 'Your Profile is not verified yet. Our teams will contact you soon.'}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container className="w-100 px-4" spacing={3}>
                <Grid item xs={12} className="d-flex justify-content-end">
                  <TButton
                    variant="contained"
                    color="primary"
                    // onClick={() => setShowUploadDocumentsModal(true)}
                    onClick={() => setShowAllDocumentsModal(true)}
                  >
                    Upload Document
                  </TButton>
                </Grid>
                {cdaDocument?.doc_type ? (
                  <Grid item xs={12}>
                    <Paper className="custom-box-small p-3">
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        spacing={3}
                        className="px-4 py-2"
                      >
                        <Grid item>
                          <Typography variant="body1" className="fw-medium">
                            {cdaDocument.doc_type}
                          </Typography>
                        </Grid>
                        <ActionItems
                          documentObj={{
                            ...documentObj,
                            ...cdaDocument,
                            docType: cdaDocument.doc_type,
                            document: cdaDocument.type,
                            isPdf:
                              cdaDocument?.status ===
                                DOCUMENT_STATUS.RESPONSE_RECEIVED ||
                              cdaDocument.status === DOCUMENT_STATUS.SIGNED ||
                              (cdaDocument.status ===
                                DOCUMENT_STATUS.RESPONSE_UPLOADED &&
                                cdaDocument.sign_type ===
                                  SIGNING_METHOD.AADHAAR),
                          }}
                          canSign={user.role === 'Doctor'}
                          setDocumentObj={setDocumentObj}
                          document={cdaDocument}
                          setAction={setAction}
                          setDocumentDownloading={setDocumentDownloading}
                          setShowSignDocumentModal={setShowSignDocumentModal}
                          setDocumentType={setDocumentType}
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                ) : null}

                {fixedDocuments.length > 0
                  ? fixedDocuments.map(doc => {
                      return (
                        <Grid key={doc} item xs={12}>
                          <Paper className="custom-box-small p-3">
                            <Grid
                              container
                              alignItems="center"
                              justify="space-between"
                              spacing={3}
                              className="px-4 py-2"
                            >
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  className="fw-medium"
                                >
                                  {doc.name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <TButton
                                  variant="contained"
                                  color="primary"
                                  style={{ color: 'white' }}
                                  href={doc.file_url}
                                  target="_blank"
                                >
                                  View
                                </TButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })
                  : null}

                {feasibilityDocument ? (
                  <Grid item xs={12}>
                    <Paper className="custom-box-small p-3">
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        spacing={3}
                        className="px-4 py-2"
                      >
                        <Grid item>
                          <Typography variant="body1" className="fw-medium">
                            {feasibilityDocument.doc_type}
                          </Typography>
                        </Grid>
                        <ActionItems
                          documentObj={{
                            ...documentObj,
                            ...feasibilityDocument,
                            docType: feasibilityDocument.doc_type,
                            document: feasibilityDocument.type,
                            isPdf:
                              feasibilityDocument?.status ===
                                DOCUMENT_STATUS.RESPONSE_RECEIVED ||
                              feasibilityDocument.status ===
                                DOCUMENT_STATUS.SIGNED ||
                              (feasibilityDocument.status ===
                                DOCUMENT_STATUS.RESPONSE_UPLOADED &&
                                feasibilityDocument.sign_type ===
                                  SIGNING_METHOD.AADHAAR),
                          }}
                          canSign={user.role === 'Doctor'}
                          setDocumentObj={setDocumentObj}
                          document={feasibilityDocument}
                          setAction={setAction}
                          setDocumentDownloading={setDocumentDownloading}
                          setShowSignDocumentModal={setShowSignDocumentModal}
                          setDocumentType={setDocumentType}
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                ) : null}

                {/* {feasibilityDocs?.length ? (
                  <>
                    {feasibilityDocs.map(feasibilityDocument => {
                      return (
                        <Grid item xs={12} key={feasibilityDocument}>
                          <Paper className="custom-box-small p-3">
                            <Grid
                              container
                              alignItems="center"
                              justify="space-between"
                              spacing={3}
                              className="px-5 py-2"
                            >
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  className="fw-medium"
                                >
                                  {feasibilityDocument.doc_type}
                                </Typography>
                              </Grid>
                              <ActionItems
                                documentObj={{
                                  ...documentObj,
                                  ...feasibilityDocument,
                                  docType: feasibilityDocument.doc_type,
                                  document: feasibilityDocument.type,
                                  isPdf:
                                    feasibilityDocument?.status ===
                                      DOCUMENT_STATUS.RESPONSE_RECEIVED ||
                                    feasibilityDocument.status ===
                                      DOCUMENT_STATUS.SIGNED ||
                                    (feasibilityDocument.status ===
                                      DOCUMENT_STATUS.RESPONSE_UPLOADED &&
                                      feasibilityDocument.sign_type ===
                                        SIGNING_METHOD.AADHAAR),
                                }}
                                canSign={user.role === 'Doctor'}
                                setDocumentObj={setDocumentObj}
                                document={feasibilityDocument}
                                setAction={setAction}
                                setDocumentDownloading={setDocumentDownloading}
                                setShowSignDocumentModal={
                                  setShowSignDocumentModal
                                }
                                setDocumentType={setDocumentType}
                              />
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </>
                ) : null} */}

                {showGrant && parseInt(doctorTrials?.grant) === 0 ? (
                  <Grid item xs={12}>
                    <Paper className="custom-box-small p-3">
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-end"
                        spacing={3}
                        className="px-4 py-2"
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="body1" className="fw-medium">
                              Investigator Grant Fees
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              size="small"
                              type="number"
                              autoComplete={false}
                              value={grantValue}
                              onChange={e => setGrantValue(e.target.value)}
                              variant="outlined"
                              placeholder="Enter Grant Fees"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <TButton
                            onClick={handleProceed}
                            variant="contained"
                            color="primary"
                          >
                            Review
                          </TButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ) : null}

                {doctorTrials && parseInt(doctorTrials?.grant) !== 0 ? (
                  <Grid item xs={12}>
                    <Paper className="custom-box-small p-3">
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-end"
                        spacing={3}
                        className="px-4 py-2"
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="body1" className="fw-medium">
                              Investigator Grant Fees
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" className="fw-medium">
                              INR {parseFloat(doctorTrials?.grant).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ) : null}
              </Grid>
              <Modal
                className="modal-container"
                open={showSignDocumentModal}
                onClose={() => setShowSignDocumentModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={showSignDocumentModal}>
                  <TSignDocumentModal
                    action={action}
                    documentObj={documentObj}
                    setShowSignDocumentModal={setShowSignDocumentModal}
                    setShowSignPasswordModal={setShowSigningMethodModal}
                    canSign={user.role === 'Doctor'}
                  />
                </Fade>
              </Modal>
              <Modal
                className="modal-container"
                open={showAllDocumentsModal}
                onClose={() => setShowAllDocumentsModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={showAllDocumentsModal}>
                  <TViewAllDocumentsModal
                    setShowAllDocumentsModal={setShowAllDocumentsModal}
                    stage={SITE_STAGE.FEASIBILITY}
                    trialSiteId={documentObj.trialSiteId}
                    siteTrialId={documentObj.trialId}
                  />
                </Fade>
              </Modal>

              <Modal
                className="modal-container"
                open={showPasswordModal}
                onClose={() => setShowPasswordModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={showPasswordModal}>
                  <TPasswordModal
                    password={password}
                    setPassword={setPassword}
                    handleSubmit={() =>
                      dispatch(
                        addGrant({
                          ...documentObj,
                          grant: grantValue,
                          password: password,
                        })
                      )
                    }
                    setShowPasswordModal={setShowPasswordModal}
                  />
                </Fade>
              </Modal>
              {/* <Modal
                className="modal-container"
                open={showUploadDocumentsModal}
                onClose={() => setShowUploadDocumentsModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={showUploadDocumentsModal}>
                  <TUploadDocumentsModal
                    setShowUploadDocumentsModal={setShowUploadDocumentsModal}
                    documentType={'Document'}
                    documentObj={documentObj}
                  />
                </Fade>
              </Modal> */}
              <Modal
                className="modal-container"
                open={showSigningMethodModal}
                onClose={() => setShowSigningMethodModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={showSigningMethodModal}>
                  <TSigningMethodModal
                    setShowSigningMethodModal={setShowSigningMethodModal}
                    documentObj={documentObj}
                    setDocumentObj={setDocumentObj}
                  />
                </Fade>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UpcomingTrial;
