import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeRSDVStatus,
  getParticipantRSDVDocuments,
  getRSDVDocument,
} from '../../actions/craActions';
import { SET_RSDV_DOCUMENT } from '../../actions/craActionType';
import { PER_PAGE_OPTIONS } from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import { TextCellRenderer } from '../../common/AgGrid/TextCellRenderers.js';
import { CheckmarkIcon } from '../../common/icons';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';
import { RSDV_STATUS, RSDV_STATUS_COLORS } from '../../constant/cra_constants';
import GreenCheckMark from '../../images/GreenCheckMark.svg';
import SDVDocument from '../../images/SDVDocument.svg';
import SDVDocumentModal from '../CRA/SDVDocumentModal';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

const SDVStatus = Object.freeze({
  OPEN: 1,
  CLOSED: 2,
  EXPIRED: 3,
  REJECTED: 4,
});

const ViewSDVDocumentsModal = ({
  participantId,
  closeSDVDocumentsModal,
  filter,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filteredSDVs, setFilteredSDVs] = useState([]);
  const [showPDFModal, setShowPDFModal] = useState(false);

  const {
    getParticipantRSDVDocumentsAPI,
    participantRSDVDocuments,
    getRSDVDocumentAPI,
    RSDVDocument,
  } = useSelector(state => state.craReducer);

  const changeSDVStatus = ({ id, status, participant }) => {
    const data = {
      id,
      participant,
      data: {
        status: status,
      },
    };

    dispatch(changeRSDVStatus(data));
  };

  const columnDefs = [
    {
      headerName: 'SDV Name',
      field: 'name',
      cellRenderer: 'documentNameRenderer',
    },
    {
      headerName: 'Description',
      field: 'description',
      minWidth: 300,
      cellRenderer: 'TextCellRenderer',
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: 'statusCellRenderer',
    },
    {
      headerName: 'Actions',
      cellRenderer: 'actionCellRenderer',
      maxWidth: 150,
      filter: false,
      cellStyle: { padding: 0 },
    },
  ];

  const columnDefsExpired = [
    {
      headerName: 'SDV Name',
      field: 'name',
      cellRenderer: 'documentNameRenderer',
      maxWidth: 250,
    },
    {
      headerName: 'Description',
      field: 'description',
      cellRenderer: 'TextCellRenderer',
    },
  ];

  useEffect(() => {
    if (!isNaN(participantId)) {
      dispatch(
        getParticipantRSDVDocuments({
          participantId: participantId,
          filter: filter,
        })
      );
    }
  }, [participantId]);

  useEffect(() => {
    setFilteredSDVs(participantRSDVDocuments);
  }, [participantRSDVDocuments, filter]);

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  const documentNameRenderer = params => {
    return (
      <div className="w-100 h-100">
        <Grid
          alignItems="center"
          className="w-100 h-100"
          spacing={1}
          style={{ display: 'flex' }}
        >
          <img src={SDVDocument} className="mx-3" style={{ width: '17px' }} />
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={params.value}
          >
            {params.value}
          </div>
        </Grid>
      </div>
    );
  };

  const statusCellRenderer = params => {
    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center" spacing={1}>
          <Typography
            className="fw-semibold"
            style={{
              color: `${
                params.value === 1 && params.data.expires_after
                  ? RSDV_STATUS_COLORS(params.data.expires_after)
                  : 'black'
              }`,
            }}
          >
            {params.data.expires_after
              ? RSDV_STATUS(params.value, params.data.expires_after)
              : 'Document yet to be uploaded'}
          </Typography>
        </Grid>
      </div>
    );
  };

  const actionCellRenderer = params => {
    return (
      <div className="w-100 d-flex h-100">
        <Grid container alignItems="center">
          <Tooltip title="View document">
            <IconButton
              color="primary"
              disabled={!params.data.document_uploaded}
              onClick={() => {
                dispatch(getRSDVDocument({ id: params.data.id }));
                setShowPDFModal(true);
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>

          <Tooltip title="Close SDV">
            <IconButton
              disabled={
                params.data.status !== SDVStatus.OPEN ||
                !params.data.document_uploaded
              }
              onClick={() =>
                changeSDVStatus({
                  id: params.data.id,
                  status: 2,
                  participant: participantId,
                })
              }
            >
              <CheckmarkIcon
                style={{ width: 17 }}
                color={
                  params.data.status !== SDVStatus.OPEN ||
                  !params.data.document_uploaded
                    ? 'grey'
                    : '#1ABC9C'
                }
              />
              {/* <img src={GreenCheckMark} style={{ width: '17px' }} /> */}
            </IconButton>
          </Tooltip>
        </Grid>
      </div>
    );
  };

  return (
    <Paper
      elevation={0}
      className="p-3 custom-modal"
      style={{ minWidth: '65rem' }}
    >
      {isPending(getParticipantRSDVDocumentsAPI) ? (
        <TCircularProgress />
      ) : (
        <>
          <Grid
            container
            className="p-2"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography className="fw-semibold">View Documents</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="ag-react-container"
                style={{
                  height: '60vh',
                  width: '100%',
                }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={filteredSDVs}
                  gridOptions={DEFAULT_GRID_OPTIONS}
                  columnDefs={filter === 1 ? columnDefs : columnDefsExpired}
                  frameworkComponents={{
                    actionCellRenderer,
                    documentNameRenderer,
                    statusCellRenderer,
                    TextCellRenderer,
                  }}
                  defaultColDef={{
                    ...DEFAULT_COL_DEF,
                    cellRendererParams: { checkbox: true },
                  }}
                  pagination
                  suppressPaginationPanel
                  suppressRowHoverHighlight
                  paginationPageSize={PER_PAGE_OPTIONS[4]}
                  animateRows
                  onGridSizeChanged={params => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              className="mt-1"
              spacing={2}
            >
              <Grid item>
                <TButton
                  variant="outlined"
                  color="secondary"
                  onClick={closeSDVDocumentsModal}
                >
                  Close
                </TButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Modal
        className="modal-container"
        open={showPDFModal}
        onClose={() => {
          setShowPDFModal(false);
          dispatch({ type: SET_RSDV_DOCUMENT, payload: null });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showPDFModal}>
          <SDVDocumentModal
            closePDFModal={() => {
              setShowPDFModal(false);
              dispatch({ type: SET_RSDV_DOCUMENT, payload: null });
            }}
            docUrl={RSDVDocument?.content}
            docType={RSDVDocument?.contentType}
          />
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ViewSDVDocumentsModal;
