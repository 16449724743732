import { CssBaseline, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import TCircularProgress from '../common/TCircularProgress';
import TAppBar from './TAppBar';
import TSideNav from './TSideNav';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    position: 'relative',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(10),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    display: 'flex',
    flex: 1,
    maxHeight: '100%',
    overflowX: 'auto',
    width: '100%',
    transition: 'width 250ms cubic-bezier(.4,0,0,1) 0ms',
    zIndex: 1,
    flexDirection: 'column',
  },
  link: {
    color: '#4E4E4E',
  },
}));

const DefaultLayout = ({ children }) => {
  const classes = useStyles();

  const isAuthenticated = useSelector(state => state.userReducer.isAuth);
  const viewPermissions = useSelector(
    state => state.userReducer.user.viewPermissions
  );

  if (!isAuthenticated || !viewPermissions) {
    return <TCircularProgress />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TAppBar />
      <TSideNav />
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        <Paper
          style={{
            margin: '2.5%',
            minHeight: 'calc(100vh - 80px - 88px - 5%)',
          }}
          elevation={0}
        >
          {children}
        </Paper>
        <div
          style={{
            margin: '0 2.5% 2.5% 2.5%',
            marginTop: '0',
            color: '#4E4E4E',
          }}
        >
          © 2021 Copyright Triomics ·{' '}
          <a
            href="https://triomics.in/terms"
            className={classes.link}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          ·{' '}
          <a
            href="https://triomics.in/privacy"
            className={classes.link}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          ·
        </div>
      </main>
    </div>
  );
};

export default DefaultLayout;
