import { FormControl, FormLabel } from '@material-ui/core';
import React from 'react';

import useStyles from './classes';

const RenderHTML = ({ html }) => (
  <span
    className="c-content-text form-html-content"
    dangerouslySetInnerHTML={{ __html: html }}
  ></span>
);

const TContent = props => {
  const classes = useStyles();
  const { html } = props.field;

  return (
    <FormControl
      className={classes.field}
      component="fieldset"
      style={props.dataGrid || props.tableCell ? { padding: 0 } : null}
    >
      <FormLabel className={classes.label}>
        <RenderHTML html={html} />
      </FormLabel>
    </FormControl>
  );
};

export default TContent;
