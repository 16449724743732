import { SPONSOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';

export const getSiteQualificationTableAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data}/site_qualification/`;
  return apiClient.get(apiUrl);
};

export const getCRAsAPI = async () => {
  const apiUrl = `${SPONSOR_APIS.trial}/CRAs/`;
  return apiClient.get(apiUrl);
};

export const assignCRAAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trialSite}/assignCRA/${data.site.id}/`;
  return apiClient.patch(apiUrl, data.site);
};
