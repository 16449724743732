import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#BFE2FF', //your color
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#BFE2FF',
          borderRadius: 8,
        },

        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: '2px solid',
          borderColor: '#BFE2FF',
          borderRadius: 8,
        },
      },
    },

    MuiInputBase: {
      root: {
        height: 40,
        width: 170,
        backgroundColor: '#FAFDFF',
        borderColor: '#BFE2FF',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: '#',
        color: '#',
      },
    },
    MuiPickersDay: {
      daySelected: {
        // backgroundColor: "#FF0000"
      },
      day: {
        // backgroundColor: "#FF0000"
      },
      dayHover: {
        backgroundColor: '#FF0000',
      },
    },
  },
});

const TDatePicker = ({ ...props }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="space-around">
          <DatePicker
            inputVariant="outlined"
            variant="inline"
            format="dd/MM/yyyy"
            emptyLabel="DD/MM/YYYY"
            margin="normal"
            id="date-picker-inline"
            value={props.date}
            className="m-0 p-0"
            onChange={e => props.onDateChange(e)}
            {...props}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default TDatePicker;
