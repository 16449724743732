import { validateForm } from './formValidator';

export const formHandler = ({
  key,
  value,
  type,
  sectionIndex,
  formData,
  parentProps,
}) => {
  switch (type) {
    case 'datagrid': {
      // Special logic to update datagrid components.

      // Here, 'field' refers to the DataGrid field & 'component' refers
      // to a particular component inside that DataGrid field.

      const { fieldKey, componentKey, dataGridIndex } = key;

      let newFormData = [...formData];
      let sectionData = newFormData[sectionIndex];
      let sectionResponse = sectionData.response;
      const dataGridResponse = sectionResponse[fieldKey][dataGridIndex];

      let field = sectionData.components.find(f => f.key === fieldKey);

      if (field?.type === 'datagrid') {
        sectionResponse[fieldKey][dataGridIndex][componentKey] = value;

        newFormData[sectionIndex] = {
          ...sectionData,
          response: sectionResponse,
        };

        const parentIndex = sectionData.components.findIndex(
          c => c.key === fieldKey
        );

        let parentComponent = sectionData.components[parentIndex] || undefined;

        if (parentComponent !== undefined) {
          const componentIndex = parentComponent.components.findIndex(
            c => c.key === componentKey
          );

          const component = parentComponent.components[componentIndex];

          if (component !== undefined) {
            const validity = validateForm([component], dataGridResponse);

            parentComponent.components[componentIndex] = validity.components[0];

            sectionData.components[parentIndex] = parentComponent;
          }
        }
      }

      return newFormData;
    }

    case 'tableField': {
      let newFormData = [...formData];
      let sectionData = newFormData[sectionIndex];

      const newResponse = {
        ...sectionData.response,
        [key]: value,
      };

      const parentIndex = sectionData.components.findIndex(
        c => c.key === parentProps.key
      );
      let parentComponent = sectionData.components[parentIndex] || undefined;

      if (parentComponent !== undefined) {
        const componentIndex = parentComponent.schema[
          parentProps.row
        ].findIndex(c => c.key === key);

        const component =
          parentComponent.schema[parentProps.row][componentIndex] || undefined;

        if (component !== undefined) {
          const validity = validateForm([component], newResponse);

          parentComponent.schema[parentProps.row][componentIndex] =
            validity.components[0];

          sectionData.components[parentIndex] = parentComponent;
        }
      }

      newFormData[sectionIndex] = {
        ...sectionData,
        response: newResponse,
      };

      return newFormData;
    }

    default: {
      let newFormData = [...formData];
      let sectionData = newFormData[sectionIndex];

      const newResponse = {
        ...sectionData.response,
        [key]: value,
      };

      const componentIndex = sectionData.components.findIndex(
        c => c.key === key
      );
      const component = sectionData.components[componentIndex] || undefined;

      if (component !== undefined) {
        const validity = validateForm([component], newResponse);

        sectionData.components[componentIndex] = validity.components[0];
      }

      newFormData[sectionIndex] = {
        ...sectionData,
        response: newResponse,
      };

      return newFormData;
    }
  }
};
