import {
  RESET_APP_BAR_CONFIG,
  SET_APP_BAR_CONFIG,
  TOGGLE_SIDEBAR_MENU,
} from './utilsActionType.js';

export const toggleSideBarMenu = toggle => {
  return {
    type: TOGGLE_SIDEBAR_MENU,
    payload: toggle,
  };
};

export const setAppBarConfig = appBarConfig => {
  return {
    type: SET_APP_BAR_CONFIG,
    payload: appBarConfig,
  };
};

export const resetAppBarConfig = () => {
  return {
    type: RESET_APP_BAR_CONFIG,
    payload: {},
  };
};
