export const VALIDATION_MESSAGES = Object.freeze({
  REQUIRED_ERR: `This field is requierd`,
  NUMBER_ERR: `Please enter a valid number`,
  SELECT_ERR: `Please select an option`,
  MIN_VAL_ERR: minVal =>
    `Please enter a number more than or equal to ${minVal}`,
  MAX_VAL_ERR: maxVal =>
    `Please enter a number less than or equal to ${maxVal}`,
  GREATER_THAN_ERR: minVal => `Please enter a number more than ${minVal}`,
  LESS_THAN_ERR: maxVal => `Please enter a number less than ${maxVal}`,
  LENGTH_ERR: length => `Please enter ${length} characters`,
  MIN_LENGTH_ERR: minLength => `Please enter atleast ${minLength} characters`,
  MAX_LENGTH_ERR: maxLength => `Please enter atmost ${maxLength} characters`,
  EXACT_LENGTH_ERR: exactLength =>
    `Please enter exactly ${exactLength} characters`,
  DECIMAL_ERR: dPlcs => `Please enter a number with ${dPlcs} decimal places`,
  EMAIL_ERR: `Please enter a valid email address`,
  PHONE_ERR: `Please enter a valid mobile number`,
  URL_ERR: `Please enter a valid URL`,
});

export const VALIDATION_ERROR =
  'Please fix the errors mentioned above and try to submit again.';
