import { SPONSOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';

export const getInitiationSitesAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data}/site_initiation/`;
  return apiClient.get(apiUrl);
};

export const getCrtTeamAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data.trialId}/trialsite/${data.trialSiteId}/crt/`;
  return apiClient.get(apiUrl);
};
