import { CircularProgress, Container } from '@material-ui/core';
import React from 'react';

const TCircularProgress = props => (
  <Container
    fixed
    style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    {...props}
  >
    <CircularProgress />
  </Container>
);

export default TCircularProgress;
