import { SPONSOR_APIS } from '../constant/api_constants';
import apiClient from '../helpers/apiclient';

export const getAllIdentificationSitesAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.sites}/with_trial_status/${data.trialId}`;
  // const apiUrl = `${SPONSOR_APIS.sites}/filter?previous_exp=${data.previousExperience}&competing_study=${data.investigatorAbility}&${data.population}`;
  return apiClient.get(apiUrl);
};

export const getShortlistedSitesAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data}/shortlisted/`;
  return apiClient.get(apiUrl);
};

export const getSelectedSiteInfoAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.sites}/detail/${data.siteId}/?trial_id=${data.trialId}`;
  return apiClient.get(apiUrl);
};

export const createTrialSiteAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/${data.trial_id}/trialsite/`;
  return apiClient.post(apiUrl, data);
};

export const removeTrialSiteAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.trial}/trialsite/${data.id}/`;
  return apiClient.delete(apiUrl);
};

export const getSiteAPI = async data => {
  const apiUrl = `${SPONSOR_APIS.sites}/detail/${data}/`;
  return apiClient.get(apiUrl);
};
