import { Grid, IconButton, Typography } from '@material-ui/core';
import { Pageview } from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { getCRAInitiationPanel } from '../../actions/craActions';
import { getInitiationSites } from '../../actions/siteInitiationActions';
import { SET_TRIAL } from '../../actions/trialActionType';
import AgGridPagination, {
  PER_PAGE_OPTIONS,
} from '../../common/AgGrid/AgGridPagination';
import {
  DEFAULT_COL_DEF,
  DEFAULT_GRID_OPTIONS,
} from '../../common/AgGrid/constants';
import TCircularProgress from '../../common/TCircularProgress';
import { isPending } from '../../constant/api_constants';
import EmptyListStateImg from '../../images/Empty_State_Empty_List.png';

const SiteIntitiation = () => {
  const { pathname } = useLocation();

  const sites = useSelector(state => state.siteInitiationReducer.sites);
  const craSites = useSelector(state => state.craReducer.sites);

  const trialId = useSelector(state => state.trialReducer.trial.id);
  const getInitiationSitesApi = useSelector(
    state => state.siteInitiationReducer.getInitiationSitesApi
  );

  const [gridApi, setGridApi] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pathname.includes('cra')) {
      if (trialId) {
        dispatch(getInitiationSites(trialId));
      }
    } else {
      dispatch(getCRAInitiationPanel());
    }
  }, [trialId]);

  const columnDefs = [
    {
      headerName: 'Investigator',
      field: 'site.doctor',
      cellStyle: { paddingLeft: '32px' },
    },
    {
      headerName: 'Site',
      field: 'site.hospital',
    },
    {
      headerName: 'Documents',
      field: 'documents',
      cellRenderer: 'documentsCellRenderer',
    },
    {
      headerName: 'Status',
      field: 'cda_status',
      cellRenderer: 'documentStatusRenderer',
      filter: false,
    },
  ];

  const craColumnDefs = [
    {
      headerName: 'Investigator',
      field: 'trial_site.site.doctor',
      cellStyle: { paddingLeft: '32px' },
    },
    {
      headerName: 'Site',
      field: 'trial_site.site.name',
    },
    {
      headerName: 'Documents',
      field: 'trial_site.documents',
      cellRenderer: 'documentsCellRenderer',
    },
    {
      headerName: 'Status',
      field: 'trial_site.cda_status',
      cellRenderer: 'documentStatusRenderer',
      filter: false,
    },
  ];

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    params.api.addGlobalListener((type, e) => {
      if (type === 'rowClicked' || type === 'rowSelected') {
        if (!pathname.includes('cra')) {
          dispatch(
            push(
              `/site-initiation/info/trial/${trialId}/${e.data.id}/site/${e.data.site.id}`
            )
          );
        } else {
          dispatch({ type: SET_TRIAL, payload: { id: e.data.trial_id } });
          dispatch(
            push(
              `/cra-initiation-sites/info/trial/${e.data.trial_id}/${e.data.trial_site.id}/site/${e.data.trial_site.site.id}`
            )
          );
        }
      }
    });
  };

  const versionCellRenderer = params => {
    return (
      <div className="d-flex align-items-center">
        <Grid container alignItems="center">
          {params.data.status.map(version => (
            <Grid item container key={version.id} spacing={1}>
              <Grid item xs>
                {version.status}
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  href={version.link ? version.link : null}
                >
                  <Pageview fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const documentsCellRenderer = () => {
    return (
      <Grid container direction="column" justifyContent="center" spacing={3}>
        <Grid item>CTA Status</Grid>
        <Grid item>IRB Approval</Grid>
        <Grid item>IP Clearance</Grid>
      </Grid>
    );
  };

  const documentStatusRenderer = params => {
    return (
      <Grid container direction="column" justifyContent="center" spacing={3}>
        <Grid item>
          {
            params?.data?.documents?.find(doc => doc.doc_type == 'CTA_status')
              ?.status
          }
        </Grid>
        <Grid item>
          {
            params?.data?.documents?.find(doc => doc.doc_type == 'IP_clearance')
              ?.status
          }
        </Grid>
        <Grid item>
          {
            params?.data?.documents?.find(doc => doc.doc_type == 'IRB_approval')
              ?.status
          }
        </Grid>
      </Grid>
    );
  };

  const gridOptions = {
    ...DEFAULT_GRID_OPTIONS,
    rowHeight: 160,
  };

  return (
    <>
      {isPending(getInitiationSitesApi) ? (
        <TCircularProgress />
      ) : (
        <>
          {!sites?.length && !craSites?.length ? (
            <Grid container justify="center">
              <Grid item xs={12} container justify="center">
                <Grid item>
                  <img
                    style={{ height: '250px', marginTop: '150px' }}
                    src={EmptyListStateImg}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className="text-center mt-5">
                  No sites present in this stage
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                container
                justify="space-between"
                className="m-2"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" className="fw-semibold fs-xxl-temp">
                    Site Activation
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="ag-react-container"
                  style={{
                    height: '60vh',
                    width: '100%',
                  }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    rowData={pathname.includes('cra') ? craSites : sites}
                    gridOptions={gridOptions}
                    columnDefs={
                      pathname.includes('cra') ? craColumnDefs : columnDefs
                    }
                    frameworkComponents={{
                      versionCellRenderer,
                      documentsCellRenderer,
                      documentStatusRenderer,
                    }}
                    defaultColDef={{
                      ...DEFAULT_COL_DEF,
                      autoHeight: false,
                      // cellClass: ['grid-cell-n'],
                    }}
                    pagination
                    suppressRowHoverHighlight={false}
                    suppressPaginationPanel
                    paginationPageSize={PER_PAGE_OPTIONS[0]}
                    animateRows
                    onGridSizeChanged={params => {
                      params.api.sizeColumnsToFit();
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <AgGridPagination gridApi={gridApi} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default SiteIntitiation;
