import { Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import TButton from '../../common/TButton';

const RequestSDVModal = ({ participantIds, closeModal, submitRequest }) => {
  const [data, setData] = useState({
    name: '',
    description: '',
  });

  const handleChange = event => {
    setData(data => ({
      ...data,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Paper
      elevation={0}
      className="p-4 custom-modal"
      style={{ maxWidth: '40rem' }}
    >
      <Grid container spacing={4}>
        <Grid item>
          <Typography variant="h6">Request SDV</Typography>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Name"
              placeholder="Name"
              name="name"
              value={data.name}
              required
              onChange={handleChange}
              variant="outlined"
              className="bg-white"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Description"
              placeholder="Description"
              name="description"
              value={data.description}
              multiline
              rows={4}
              required
              onChange={handleChange}
              variant="outlined"
              className="bg-white"
              fullWidth
            />
          </Grid>
          <Grid item container direction="row" justify="flex-end" spacing={2}>
            <Grid item>
              <TButton
                color="secondary"
                variant="outlined"
                onClick={closeModal}
              >
                Close
              </TButton>
            </Grid>
            <Grid item>
              <TButton
                color="primary"
                variant="contained"
                onClick={() => submitRequest(data)}
                disabled={
                  !participantIds.length ||
                  !data.name.length ||
                  !data.description.length
                }
              >
                Request
              </TButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RequestSDVModal;
