export const GET_SITES = 'GET_SITES';
export const SET_SITES = 'SET_SITES';
export const GET_SITE = 'GET_SITE';
export const SET_SITE = 'SET_SITE';
export const GET_SHORTLISTED_SITES = 'GET_SHORTLISTED_SITES';
export const SET_SHORTLISTED_SITE = 'SET_SHORTLISTED_SITE';
export const SET_SHORTLISTED_SITES = 'SET_SHORTLISTED_SITES';
export const GET_ALL_IDENTIFICATION_SITE = 'GET_ALL_IDENTIFICATION_SITE';
export const SHORTLIST_SITE = 'SHORTLIST_SITE';
export const REMOVE_SHORTLIST_SITE = 'REMOVE_SHORTLIST_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';

export const GET_SITE_API_STATE = 'GET_SITE_API_STATE';
export const GET_SITES_API_STATE = 'GET_SITES_API_STATE';
export const SHORTLIST_SITE_API_STATE = 'SHORTLIST_SITE_API_STATE';
export const REMOVE_SITE_API_STATE = 'REMOVE_SITE_API_STATE';
export const GET_SHORTLISTED_SITE_API_STATE = 'GET_SHORTLISTED_SITE_API_STATE';
