import { createMuiTheme } from '@material-ui/core/styles';

const fontFamilyArr = [
  'Poppins',
  '"Open Sans"',
  'sans-serif',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const colors = {
  peach: '#FF6F8B',
  blue: '#008CFF',
  yellow: '#F8D45C',
  purple: '#DD8CE0',
  green: '#39DA8A',
  orangeLight: '#FFB761',
  greyDark: '#A1A1A1',
};

const palette = {
  primary: { main: '#008CFF' },
  secondary: { light: '#FFD0D4', main: '#2C3E50', dark: '#F63E35' },
  success: { light: '#BEEECB', main: '#39DA8A', dark: '#00B444' },
  info: { light: '#20A2FF', main: '#256EFF', dark: '#3635CC' },
  warning: { light: '#FFF8DF', main: '#FFD23F', dark: '#FFBD00' },
  error: { light: '#ff5a45', main: '#DB4E54', dark: '#8f0000' },
  background: { default: '#F6FBFF' },
  divider: '#7C7F8450',
  text: {
    primary: '#110D09',
    secondary: '#6B6B6B',
    disabled: '#B3B3B3',
    hint: '#D7D7D7',
  },
};

export const theme = createMuiTheme({
  typography: {
    fontFamily: fontFamilyArr.join(','),
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: '1rem',
        '@media (max-width:1400px)': {
          fontSize: '0.875rem',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
      },
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: palette.primary.main,
          },
        },
        '& .MuiInputBase-root.Mui-disabled': {
          color: palette.text.primary,
        },
      },
    },
    MuiSelect: {
      root: {
        '& .MuiSelect-root': {
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: palette.primary.main,
            },
          },
        },
      },
    },
  },
  palette,
});

export const rippleStyles = () => ({
  rippleVisible: {
    opacity: 0.5,
  },
  '@keyframes mui-ripple-enter': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.5,
    },
  },
});

export default theme;
