import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import {
  getShortlistedSites,
  getSiteDetails,
  setTabIndex,
  shortlistSite,
} from '../../actions/siteIdentificationActions';
import { updateTrialSiteStatus } from '../../actions/trialActions';
import TButton from '../../common/TButton';
import TCircularProgress from '../../common/TCircularProgress';
import {
  TTable,
  TTableBody,
  TTableCell,
  TTableContainer,
  TTableHead,
  TTableRow,
} from '../../common/TTable';
import { isCompleted } from '../../constant/api_constants';
import { SITE_STAGE } from '../../constant/site_constants';
import ProfileSections from '../Doctor/ProfileView/ProfileSections';

const StyleQA = ({ question, response }) => {
  return (
    <Grid item className="mx-4">
      <Typography
        variant="body1"
        className="fs-xxl fw-semibold c-subheading-text"
        display="inline"
        color="primary"
      >
        {question}
      </Typography>{' '}
      <Typography variant="body1" display="inline">
        {response}
      </Typography>
    </Grid>
  );
};

const PreviousExperienceQA = ({ question, response }) => {
  // console.log('DEBUG', response);
  return (
    <Grid item className="mx-4">
      <Typography
        variant="body1"
        className="fs-xxl fw-semibold c-subheading-text"
        display="inline"
        color="primary"
      >
        {question}
      </Typography>{' '}
      <Grid item container>
        <ul style={{ fontSize: '30px', margin: 0 }}>
          {response?.length &&
            response?.map(res => {
              return (
                <li key={res}>
                  <Typography variant="body1">
                    {res?.ctri_number} - {res?.pub_title}
                  </Typography>
                </li>
              );
            })}
        </ul>
      </Grid>
    </Grid>
  );
};

const ResearchStaff = ({ question, crcArr }) => {
  return (
    <Grid item>
      <Typography
        variant="body1"
        className="fw-semibold"
        display="inline"
        color="primary"
        style={{ textTransform: 'uppercase' }}
      >
        {question}
      </Typography>{' '}
      <Grid item className="p-4">
        <TTableContainer>
          <TTable size="medium">
            <TTableHead>
              <TTableRow>
                <TTableCell style={{ textAlign: 'center' }}>Name</TTableCell>
                <TTableCell style={{ textAlign: 'center' }}>
                  Position
                </TTableCell>
                <TTableCell style={{ textAlign: 'center' }}>Email</TTableCell>
                <TTableCell style={{ textAlign: 'center' }}>Contact</TTableCell>
              </TTableRow>
            </TTableHead>
            <TTableBody>
              {crcArr?.map((crc, index) => (
                <TTableRow key={index}>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.name}
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.Position}
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.Email}
                  </TTableCell>
                  <TTableCell style={{ textAlign: 'center' }}>
                    {crc?.Contact}
                  </TTableCell>
                </TTableRow>
              ))}
            </TTableBody>
          </TTable>
        </TTableContainer>
      </Grid>
    </Grid>
  );
};

const WorkExp = ({ response }) => {
  const [toggle, setToggle] = useState(false);
  const [length, setLength] = useState(1);

  useEffect(() => {
    toggle ? setLength(response?.length) : setLength(1);
  }, [toggle]);

  return (
    <Grid item>
      <Divider className="mb-4" />
      <Typography
        variant="body1"
        className="fw-semibold"
        display="inline"
        color="primary"
        style={{ textTransform: 'uppercase' }}
      >
        Work Experience
      </Typography>
      <Grid container>
        {response?.slice(0, `${length}`).map(exp => (
          <Grid container key={exp}>
            <Grid item>
              <StyleQA question="Hospital Name:" response={exp?.name} />
              <StyleQA question="Position:" response={exp?.position} />
              <StyleQA
                question="Duration:"
                response={`${exp.from_year} - ${exp.to_year}`}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" className="mt-2">
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setToggle(!toggle)}
            style={{
              borderRadius: 50,
              width: 150,
            }}
          >
            {toggle ? 'Hide' : 'Show More'}
          </Button>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

const PrevEducation = ({ response }) => {
  const [toggle, setToggle] = useState(false);
  const [length, setLength] = useState(1);

  useEffect(() => {
    toggle ? setLength(response?.length) : setLength(1);
  }, [toggle]);

  return (
    <Grid item>
      <Divider className="mb-4" />
      <Typography
        variant="body1"
        className="fw-semibold"
        display="inline"
        color="primary"
        style={{ textTransform: 'uppercase' }}
      >
        Education
      </Typography>
      <Grid container>
        {response?.slice(0, `${length}`).map(education => (
          <Grid container key={education}>
            <Grid item>
              <StyleQA
                question="College Name:"
                response={education?.college_name}
              />
              <StyleQA question="Degree:" response={education?.degree} />
              <StyleQA
                question="Graduation:"
                response={education?.graduation_year}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" className="mt-2">
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setToggle(!toggle)}
            style={{
              borderRadius: 50,
              width: 150,
            }}
          >
            {toggle ? 'Hide' : 'Show More'}
          </Button>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SiteInfo = () => {
  const site = useSelector(state => state.siteIdentificationReducer.site);
  const getSiteAPI = useSelector(
    state => state.siteIdentificationReducer.getSiteAPI
  );
  const trialId = useSelector(state => state.trialReducer.trial.id);
  const shortlistedSites = useSelector(
    state => state.siteIdentificationReducer.shortlistedSites
  );
  const shortlistSiteAPI = useSelector(
    state => state.siteIdentificationReducer.shortlistSiteAPI
  );
  const getShortlistedSiteAPI = useSelector(
    state => state.siteIdentificationReducer.getShortlistedSiteAPI
  );
  const [isShortlisted, setIsShortlisted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (trialId) {
      dispatch(getSiteDetails({ siteId: id, trialId: trialId }));
      dispatch(getShortlistedSites(trialId));
    }
  }, [shortlistSiteAPI]);

  useEffect(() => {
    setIsShortlisted(
      shortlistedSites.find(
        shortlistedSite => shortlistedSite.site.id === site.id
      )
    );
  }, [shortlistedSites, site]);

  useEffect(() => {
    if (isCompleted(getShortlistedSiteAPI) && isCompleted(getSiteAPI)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [getShortlistedSiteAPI, getSiteAPI]);

  return (
    <Paper elevation={0} className="p-4 w-100">
      {isLoading ? (
        <Grid container className="h-100 w-100" direction="column" spacing={3}>
          <TCircularProgress />
        </Grid>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: 'space-between' }}
          >
            <Grid item container xs={6}>
              <Link
                to="/site-identification"
                className="text-decoration-none text-reset color-black"
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  className="h-100"
                >
                  <Grid item>
                    <IconButton size="small">
                      <ArrowBackIos />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className="fw-semibold">
                      Investigator Profile & Site Details
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            {!site?.trialsite_details ? (
              <Grid item>
                <TButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (!isShortlisted) {
                      dispatch(
                        shortlistSite({ trial_id: trialId, site_id: id })
                      );
                      dispatch(setTabIndex(1));
                    } else {
                      dispatch(
                        dispatch(
                          updateTrialSiteStatus({
                            trialId: trialId,
                            id: isShortlisted.id,
                            stage: 'FEASIBILITY',
                            status: 'NONE',
                            currentStage: SITE_STAGE.SHORTLISTED,
                          })
                        )
                      );
                    }
                  }}
                >
                  {!isShortlisted ? `Shortlist site` : `Move to feasibility`}
                </TButton>
              </Grid>
            ) : null}
          </Grid>
          <ProfileSections
            doctorProfile={site?.doctor?.profile}
            siteProfile={site?.hospital?.hospital_profile}
          />
        </Grid>
      )}
    </Paper>
  );
};

export default SiteInfo;
